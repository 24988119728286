import axios from 'axios';
import Config from '../../app/config';
import * as UserServices from "./user-service";
import {  removeCookie } from "./cookies";

const instance = token => {
	let headers = {};
	token = UserServices.getToken();
	if (token) headers['authorization'] = token ? 'Bearer ' + token : undefined;

	var item = axios.create({
		baseURL: Config.API,
		headers: headers,
		transformResponse: [
			function(data) {
				if(data && data.error && data.error.code === 403){
					// Get all keys from localStorage
					const allKeys = Object.keys(localStorage);

					// Terms to check for
					const termsToRemove = ["localErythma", "localExcoriation", "localInduration", "localLichenification", "localScaling"];

					// Iterate through keys and remove those containing the specified terms
					allKeys.forEach(key => {
						if (termsToRemove.some(term => key.includes(term))) {
							localStorage.removeItem(key);
						}
					});
					
					localStorage.removeItem("tokenData");
					localStorage.removeItem("studyId");
					localStorage.removeItem("studyTitle");
					localStorage.removeItem("roleName");
					localStorage.removeItem("localErythmaValueFull");
					localStorage.removeItem("localIndurationValueFull");
					localStorage.removeItem("localExcoriationValueFull");
					localStorage.removeItem("localLichenificationValueFull");
					localStorage.removeItem("localScalingValueFull");
					removeCookie("tokenData");
					window.location.href = "/login";
				}
				return data;
			}
		],
		validateStatus: function(status) {
			if (status === 403 || status === 401) {
				localStorage.removeItem("tokenData");
				localStorage.removeItem("studyId");
				localStorage.removeItem("studyTitle");
				localStorage.removeItem("roleName");
				removeCookie("tokenData");
				window.location.href = "/login";
			}
			return status >= 200 && status < 300; // default
		}
	});
	return item;
};

export default {
	request(options, token) {
		return instance(token).request(options);
	}
};
