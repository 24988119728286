import { combineReducers } from "redux";
import authReducer from "./auth-reducer";
import paginationReducer from "./pagination-reducer";
import userReducer from "./users-reducer";
import patientReducer from "./patient-reducer";
import studyReducer from "./study-reducer";
import appointmentReducer from "./appointment-reducer";
import chatReducer from "./chat-reducer";
import messagesReducer from "./messages-reducer";
import taskReducer from "./task-reducer";
import diaryReducer from "./patient-diary-reducer";
import adverseEventReducer from "./adverse-event-reducer";
import activityReducer from "./patient-activity-reducer";
import easiScoreReducer from "./easi-score-reducer";
import surveyReducer from "./survey-reducer";
import ticketReducer from "./ticket-reducer";
import medicationReducer from "./medication-reducer";
import drugLogReducer from "./study-drug-log-reducer";
import medicalHistoryReducer from "./medical-history-reducer";
import labReducer from "./lab-reducer";

const reducers = combineReducers({
  auth: authReducer,
  pagination: paginationReducer,
  user: userReducer,
  patient: patientReducer,
  study: studyReducer,
  appointment: appointmentReducer,
  chat: chatReducer,
  task: taskReducer,
  recentMessages: messagesReducer,
  diary: diaryReducer,
  adverseEvents: adverseEventReducer,
  activity: activityReducer,
  easiScore: easiScoreReducer,
  survey: surveyReducer,
  ticket: ticketReducer,
  medication: medicationReducer,
  drugLogs: drugLogReducer,
  medicalHistory: medicalHistoryReducer,
  labData:labReducer
});
export default reducers;
