import AxiosAuth2 from "../../services/axios-service-auth";

const listAllMedicalHistory = (patientId, studyId) => {
  return AxiosAuth2.post("/api/medicalHistory/answers/reviewMedicalHistory?PatientId="+patientId+"&StudyId="+studyId, undefined, undefined);
};

const listAllMedicalHistoryNih = (patientId, studyId) => {
  return AxiosAuth2.post("/api/medicalHistory/answers/reviewMedicalHistoryForNihStudy?PatientId="+patientId+"&StudyId="+studyId, undefined, undefined);
};

export default {
  listAllMedicalHistory,
  listAllMedicalHistoryNih
};