import React, { Component } from "react";
import { Spin, Card } from "antd";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import SideBar from "../layout/sidebar/sidebar.jsx";
import options from "../layout/sidebar/dashboard-options";
import { Layout} from "antd";
import 'antd/dist/antd.css';
import { Table, Input, Button, Space} from 'antd';
import Highlighter from 'react-highlight-words';
import { SearchOutlined } from '@ant-design/icons';
import * as PatientActions from "../../redux/actions/patient-actions";
import * as AdverseEventActions from "../../redux/actions/adverse-event-actions";
import UserHeader from "../layout/header.jsx";

const { Header, Sider, Content } = Layout;

class EventLogs extends Component {
  state = {
    searchText: '',
    searchedColumn: '',
    submitted: false,
    loading: true, 
    isModalVisible:false,
    imageUrl:'',
    photoLoading:false,
    logs: undefined
  };

  async componentDidMount() {
    let eventId = this.props.match.params.eventId;
    await this.props.getAdverseEventLogs(eventId);
    if (this.props.adverseEventLogsList ) {
      this.setState({ logs: this.props.adverseEventLogsList, loading: false })
    }
    else{
      this.setState({loading: false })
    }
  };

  getColumnSearchProps = dataIndex => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={node => {
            this.searchInput = node;
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button onClick={() => this.handleReset(clearFilters)} size="small" style={{ width: 90 }}>
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({ closeDropdown: false });
              this.setState({
                searchText: selectedKeys[0],
                searchedColumn: dataIndex,
              });
            }}
          >
            Filter
          </Button>
        </Space>
      </div>
    ),
    filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
        : '',
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        setTimeout(() => this.searchInput.select(), 100);
      }
    },
    render: text =>
      this.state.searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[this.state.searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
          text
        ),
  });

  handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    this.setState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex,
    });
  };

  handleReset = clearFilters => {
    clearFilters();
    this.setState({ searchText: '' });
  };

  render() {
    const { loading } = this.state;
    const columns = [
      {
        title: 'Changed',
        dataIndex: 'changedContent',
        key: 'changedContent',
        width: '30%',
        ...this.getColumnSearchProps('changedContent'),
        //sorter: (a, b) => a.title.localeCompare(b.title),
      },
      {
        title: 'Modified By',
        dataIndex: 'modifiedBy',
        key: 'modifiedBy',
        width: '30%',
        ...this.getColumnSearchProps('modifiedBy'),
        //sorter: (a, b) => a.title.localeCompare(b.title),
      },
      {
        title: 'Action',
        dataIndex: 'action',
        key: 'action',
        width: '30%',
        ...this.getColumnSearchProps('action'),
      //  sorter: (a, b) => a.title.localeCompare(b.title),
      },
    ];
    return (

      <Layout>
        <Sider
          breakpoint="xl"
          collapsedWidth="0"
          width="260px"
          className="sidebar"
          onBreakpoint={(broken) => {
            console.log(broken);
          }}
          onCollapse={(collapsed, type) => {
            console.log(collapsed, type);
          }}
        >
          <SideBar {...this.props} options={options.dashBoardOptions} />
        </Sider>
        <Layout className="site-layout">
          <Header
            className="site-layout-background"
            style={{
              background: "#f2f5f9",
              zIndex: 100,
              top: 0,
              width: "100%",
              padding: 0,
            }}
          >
            <UserHeader
              {...this.props}
              isCollapsed={this.state.collapsed}
              toggleSidebar={this.toggleSidebar}
            />
          </Header>
          <Content className="site-layout-background" >
            <Card title={""} extra={<Button >Event Logs</Button>}>
              <Spin spinning={loading}>
                <Table columns={columns} dataSource={this.state.logs} />
              </Spin>
            </Card>
          </Content>
        </Layout>
      </Layout>
    );
  }
}

function mapStateToProps(state) {
  return {
    adverseEventLogsList:state.adverseEvents.adverseEventLogsList
  };
}
function mapDispatchToProps(dispatch) {
  return bindActionCreators({ ...PatientActions, ...AdverseEventActions }, dispatch);
}
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(EventLogs)
);
