import API from "../api/studies-api";
import { Types } from "../constants/study-types";

export function getStudies() {
  return async function (dispatch, getState) {
    try {
      let resp = await API.getStudies();
      if (resp && resp.data && resp.data.length > 0) {
        dispatch({
          type: Types.STUDY_LIST,
          payload: resp.data
        });
      } else {
        dispatch({
          type: Types.STUDY_NOT_FOUND,
          payload: "Study not found"
        });
      }
      return resp;
    } catch (e) {
      return { error: true };
    }
  };
}

export function getStudyById(studyId) {
  return async function (dispatch, getState) {
    try {
      let resp = await API.getStudyById(studyId);
      if (resp && resp.data) {
        return resp;
      }
    } catch (e) {
      return { error: true };
    }
  };
}

export function setDefaultStudyId(studyId) {
  return async function (dispatch, getState) {
    dispatch({
      type: Types.GET_DEFAULT_STUDY,
      payload: studyId});
  };
}

