import API from "../api/patients-api";
import { Types } from "../constants/patient-types";

export function getPatientsList(studyId) {
  return async function (dispatch, getState) {
    try {
      let resp = await API.getPatientsList(studyId);
      return resp;
    } catch (e) {
      return { error: true };
    }
  };
}

export function getApprovedPatientsList(studyId) {
  return async function (dispatch, getState) {
    try {
      let resp = await API.getApprovedPatientsList(studyId);
      return resp;
    } catch (e) {
      return { error: true };
    }
  };
}

export function getLocalState(data) {
  return async function (dispatch, getState) {
    try {
      dispatch({
        type: Types.WEEK_BY_PARTS,
        payload: data
      });
    } catch (e) {
      return { error: true };
    }
  };
}

export function getApprovedPatientsListJanssen() {
  return async function (dispatch, getState) {
    try {
      let resp = await API.getApprovedPatientsListJanssen();
      return resp;
    } catch (e) {
      return { error: true };
    }
  };
}

export function getApprovedPatientsListParade() {
  return async function (dispatch, getState) {
    try {
      let resp = await API.getApprovedPatientsListParade();
      return resp;
    } catch (e) {
      return { error: true };
    }
  };
}


export function getApprovedPatientsListNih() {
  return async function (dispatch, getState) {
    try {
      let resp = await API.getApprovedPatientsListNih();
      return resp;
    } catch (e) {
      return { error: true };
    }
  };
}


export function getDisapprovedPatientsList(studyId) {
  return async function (dispatch, getState) {
    try {
      let resp = await API.getDisapprovedPatientsList(studyId);
      return resp;
    } catch (e) {
      return { error: true };
    }
  };
}
export function getDisqualifiedPatientsList(studyId) {
  return async function (dispatch, getState) {
    try {
      let resp = await API.getDisqualifiedPatientsList(studyId);
      return resp;
    } catch (e) {
      return { error: true };
    }
  };
}

export function getInActivePatientsList(studyId) {
  return async function (dispatch, getState) {
    try {
      let resp = await API.getInActivePatientsList(studyId);
      return resp;
    } catch (e) {
      return { error: true };
    }
  };
}

export function getInactivePatientsForOhsu(studyId) {
  return async function (dispatch, getState) {
    try {
      let resp = await API.getInactivePatientsForOhsu(studyId);
      return resp;
    } catch (e) {
      return { error: true };
    }
  };
}


export function getTasksList(studyId) {
  return async function (dispatch, getState) {
    try {
      let resp = await API.getTasksList(studyId);
      return resp;
    } catch (e) {
      return { error: true };
    }
  };
}

export function approvePatient(userId, status) {

  return async function (dispatch, _getState) {
    try {
      let data = {
        userId: userId,
        status: status,
      };

      let resp = await API.approvePatient(data);
      if (resp && resp.data && resp.data.length > 0) {
        dispatch({
          type: Types.GET_APPROVED_PATIENTS,
          payload: resp.data
        });
      }
      return resp;
    } catch (e) {
      return e;
    }
  };
}

// export function addPatientParade(password, patientId, userRoles) {
//   return async function (dispatch, _getState) {
//     try {
//       let data = {
//         password: password,
//         patientId: patientId,
//         // userRoles: userRoles,
//       };
//       console.log('data=>',data);

//       let resp = await API.addPatientParade(data);
//       console.log('resp=>',resp);
//       if (resp && resp.data) {
//         dispatch({
//           type: Types.REGISTER_USER_SUCCESS,
//           payload: resp.data,
//         });
//       }
//       return resp;
//     } catch (e) {
//       dispatch({
//         type: Types.REGISTER_USER_FAILURE,
//         payload: e,
//       });
//       return e;
//     }
//   };
// }


export function addPatientParade(password, patientId, userRoles) {
  return async function (dispatch, _getState) {
    try {
      let data = {
        password: password,
        patientId: patientId,
        // userRoles: userRoles, // Commented out if not needed
      };

      let resp = await API.addPatientParade(data);

      if (resp && resp.data) {
        dispatch({
          type: Types.REGISTER_USER_SUCCESS,
          payload: resp.data,
        });
      }
      return resp;
    } catch (e) {
      if (e.response && e.response.status === 400) {
        // Handle status 400 error
        dispatch({
          type: Types.REGISTER_USER_FAILURE,
          payload: e.response.data,
        });
      } else {
        // Handle other errors
        dispatch({
          type: Types.REGISTER_USER_FAILURE,
          payload: e,
        });
      }
      return e.response || e;
    }
  };
}

export function getAuditLogs(userId) {

  return async function (_dispatch, _getState) {
    try {
      return await API.getAuditLogs(userId);
    } catch (e) {
      return e;
    }
  };
}


export function clearScreeningQuestionState() {

  return async function (dispatch, getState) {
    dispatch({
      type: Types.PATIENT_DATA,
      payload: undefined
    });
    dispatch({
      type: Types.STUDY_IMAGES,
      payload: undefined
    });
    dispatch({
      type: Types.SCREENING_QUES_ANS_LISTING,
      payload: undefined
    });
  };
}


export function getPatientByIdJanssen(userId) {

  return async function (dispatch, getState) {
    try {
      let data = {
        userId: userId
      };
      await API.getPatientByIdJanssen(data)
        .then((resp) => {
          if (resp && resp.data && resp.data.length > 0) {

            dispatch({
              type: Types.PATIENT_DATA,
              payload: resp.data
            });
          } else {
            dispatch({
              type: Types.PATIENT_DATA_NOT_FOUND,
              payload: "Study not found"
            });
          }
          return resp;
        })
    } catch (e) {
      return { error: true };
    }
  };
}

export function getPatientByIdParade(userId) {

  return async function (dispatch, getState) {
    try {
      let data = {
        userId: userId
      };
      await API.getPatientByIdParade(data)
        .then((resp) => {
          if (resp && resp.data && resp.data.length > 0) {

            dispatch({
              type: Types.PATIENT_DATA,
              payload: resp.data
            });
          } else {
            dispatch({
              type: Types.PATIENT_DATA_NOT_FOUND,
              payload: "Study not found"
            });
          }
          return resp;
        })
    } catch (e) {
      console.log('e',e);
      return { error: true };
    }
  };
}


export function getPatientByIdNih(userId) {

  return async function (dispatch, getState) {
    try {
      let data = {
        userId: userId
      };
      await API.getPatientByIdNih(data)
        .then((resp) => {
          if (resp && resp.data && resp.data.length > 0) {

            dispatch({
              type: Types.PATIENT_DATA,
              payload: resp.data
            });
          } else {
            dispatch({
              type: Types.PATIENT_DATA_NOT_FOUND,
              payload: "Study not found"
            });
          }
          return resp;
        })
    } catch (e) {
      console.log('e',e);
      return { error: true };
    }
  };
}

export function getPatientById(userId) {

  return async function (dispatch, getState) {
    try {
      let data = {
        userId: userId
      };
      await API.getPatientById(data)
        .then((resp) => {
          if (resp && resp.data && resp.data.length > 0) {

            dispatch({
              type: Types.PATIENT_DATA,
              payload: resp.data
            });
          } else {
            dispatch({
              type: Types.PATIENT_DATA_NOT_FOUND,
              payload: "Study not found"
            });
          }
          return resp;
        })
    } catch (e) {
      return { error: true };
    }
  };
}

export function getPatientSelfEstimation(month, patientId) {
  return async function (dispatch, _getState) {
    try {
      let resp = await API.getPatientSelfEstimation(month, patientId);
      if (resp && resp.data) {

        dispatch({
          type: Types.PATIENT_SELF_ESTIMATION,
          payload: resp.data
        });
      }
      return resp;
    } catch (e) {
      return { error: true };
    }
  };
}

export function getPatientSelfEstimationJanssen(week, patientId) {
  return async function (dispatch, _getState) {
    try {
      let resp = await API.getPatientSelfEstimationJanssen(week, patientId);
      if (resp && resp.data) {

        dispatch({
          type: Types.PATIENT_SELF_ESTIMATION_JANSSEN,
          payload: resp.data
        });
      }
      return resp;
    } catch (e) {
      return { error: true };
    }
  };
}

export function updateSelfEstimationScores(data) {

  return async function (dispatch, getState) {
    try {

      await API.updateSelfEstimationScores(data)
        .then((resp) => {
          if (resp && resp.data) {
            dispatch({
              type: Types.UPDATE_PATIENT_SELF_ESTIMATION,
              payload: resp.data
            });
          }

          return resp;

        })
    } catch (e) {
      return { error: true };
    }
  };
}

export function getQuesAnsList(studyId, type, userId) {
  return async function (dispatch, getState) {
    try {
      let data = {
        studyId: studyId,
        type: type,
        userId: userId
      };
      await API.getQuesAnsList(data)
        .then((response) => {
          if (response && response.data && response.data.length > 0) {
            dispatch({
              type: Types.SCREENING_QUES_ANS_LISTING,
              payload: response.data
            });

            var lastEntry = response.data[response.data.length - 1];
            if (lastEntry && lastEntry.studyImages) {
              var studyImages = lastEntry.studyImages;
              var imageData = [];
              getImageData(studyImages, imageData, dispatch);
            }
          } else if (response && (response.error || (response.data && response.data.length === 0))) {
            dispatch({
              type: Types.SCREENING_QUES_ANS_LISTING_ERROR,
              payload: response
            });
          }
          return response;
        })
    } catch (e) {
      return { error: true };
    }
  };
}


export function getPhotoGalleryByWeek(studyId, week, patientId, name) {
  return async function (dispatch, getState) {

    try {
      let data = {
        studyId: studyId,
        month: week,
        patientId: patientId,
        partType: name === "ALL" ? null : name
      };
      let response = await API.getPhotoGalleryByWeek(data);
      let resp = await API.getPatientSelfEstimation(week, patientId);
      if (resp && resp.data) {

        dispatch({
          type: Types.PATIENT_SELF_ESTIMATION,
          payload: resp.data
        });
      }
      if (response && response.data) {
        dispatch({
          type: Types.PHOTO_GALLERY_BY_WEEK,
          payload: response.data
        });

        let headImages = [];
        let trunkImages = [];
        let upperExtImages = [];
        let lowerExtImages = [];
        let studyImages = [];
        let allImages = [];

        if (response && response.data) {
          if (name === "HEAD") {
            headImages.push(...response.data.headImages);
            if (response.data.fullBodyImages) {
              headImages.push(...response.data.fullBodyImages);
            }
            if (response.data.optionalImages) {
              headImages.push(...response.data.optionalImages);
            }
            if (response.data.closeUpImages) {
              headImages.push(...response.data.closeUpImages);
            }

            studyImages = headImages;
          }
          if (name === "TRUNK") {

            // if(response.data.trunkImages && response.data.trunkImages.length>0){
            trunkImages.push(...response.data.trunkImages);
            if (response.data.fullBodyImages) {
              trunkImages.push(...response.data.fullBodyImages);
            }
            if (response.data.optionalImages) {
              trunkImages.push(...response.data.optionalImages)
            }
            if (response.data.closeUpImages) {
              trunkImages.push(...response.data.closeUpImages)
            }

            studyImages = trunkImages;
            //}
          }
          if (name === "UPPER_EXTREMITY") {

            //  if(response.data.upperExtImages && response.data.upperExtImages.length>0){
            upperExtImages.push(...response.data.upperExtImages);
            if (response.data.fullBodyImages) {
              upperExtImages.push(...response.data.fullBodyImages);
            }
            if (response.data.optionalImages) {
              upperExtImages.push(...response.data.optionalImages)
            }
            if (response.data.closeUpImages) {
              upperExtImages.push(...response.data.closeUpImages)
            }
            studyImages = upperExtImages;
          }
          //}
          if (name === "LOWER_EXTREMITY") {

            // if(response.data.lowerExtImages && response.data.lowerExtImages.length>0){

            lowerExtImages.push(...response.data.lowerExtImages);
            if (response.data.fullBodyImages) {
              lowerExtImages.push(...response.data.fullBodyImages);
            }
            if (response.data.optionalImages) {
              lowerExtImages.push(...response.data.optionalImages)
            }
            if (response.data.closeUpImages) {
              lowerExtImages.push(...response.data.closeUpImages)
            }
            studyImages = lowerExtImages;
          }

          if (name === "ALL") {
            allImages.push(...response.data.allImages);
            studyImages = allImages;
          }
          // }
          //var studyImages = headImages;
          var imageData = [];
          await getPhotoGalleryData(studyImages, imageData, dispatch);


          if (response.data.score != null) {
            dispatch({
              type: Types.EASI_SCORES,
              payload: response.data.score
            });
          }
          else {
            dispatch({
              type: Types.EASI_SCORES,
              payload: "No Data Found"
            });
          }

        }
      } else if (response && (response.error || (response.data && response.data.length === 0))) {
        dispatch({
          type: Types.PHOTO_GALLERY_BY_WEEK_ERROR,
          payload: undefined
        });
      }
      return response;

    } catch (e) {
      return { error: true };
    }
  };
}

export function getJansennPhotoGalleryByWeek(studyId, week, patientId, name) {
  return async function (dispatch, getState) {
    try {
      let data = {
        studyId: studyId,
        week: week,
        patientId: patientId,
        partType: name === "ALL" ? null : name
      };
      let response = await API.getJansennPhotoGalleryByWeek(data);
      let resp = await API.getPatientSelfEstimationJanssen(week, patientId);
      if (resp && resp.data) {

        dispatch({
          type: Types.PATIENT_SELF_ESTIMATION_JANSSEN,
          payload: resp.data
        });
      }
      if (response && response.data) {
        dispatch({
          type: Types.JANSENN_PHOTO_GALLERY_BY_WEEK,
          payload: response.data
        });

        let fullBodyImages = [];
        let headAndNeckImages = [];
        let upperBodyImages = [];
        let lowerBodyImages = [];
        let studyImages = [];
        let standardizedCloseUpImages = [];
        let allImages = [];

        if (response && response.data) {
          if (name === "HEAD_AND_NECK") {
            headAndNeckImages.push(...response.data.headAndNeckImages);
            // if (response.data.fullBodyImages) {
            //   headAndNeckImages.push(...response.data.fullBodyImages);
            // }
            // if (response.data.optionalImages) {
            //   headAndNeckImages.push(...response.data.optionalImages);
            // }
            // if (response.data.standardizedCloseUpImages) {
            //   headAndNeckImages.push(...response.data.standardizedCloseUpImages);
            // }
            // if (response.data.headAndNeckImages) {
            //   headAndNeckImages.push(...response.data.headAndNeckImages);
            // }

            studyImages = headAndNeckImages;
          }
          if (name === "FULL_BODY") {
            fullBodyImages.push(...response.data.fullBodyImages);
            // if (response.data.fullBodyImages) {
            //   fullBodyImages.push(...response.data.fullBodyImages);
            // }
            // if (response.data.optionalImages) {
            //   fullBodyImages.push(...response.data.optionalImages);
            // }
            // if (response.data.standardizedCloseUpImages) {
            //   fullBodyImages.push(...response.data.standardizedCloseUpImages);
            // }
            // if (response.data.fullBodyImages) {
            //   fullBodyImages.push(...response.data.fullBodyImages);
            // }

            studyImages = fullBodyImages;
          }
          if (name === "UPPER_BODY") {

            // if(response.data.trunkImages && response.data.trunkImages.length>0){
              upperBodyImages.push(...response.data.upperBodyImages);
            // if (response.data.fullBodyImages) {
            //   upperBodyImages.push(...response.data.fullBodyImages);
            // }
            // if (response.data.optionalImages) {
            //   upperBodyImages.push(...response.data.optionalImages)
            // }
            // if (response.data.standardizedCloseUpImages) {
            //   upperBodyImages.push(...response.data.standardizedCloseUpImages)
            // }
            // if (response.data.upperBodyImages) {
            //   upperBodyImages.push(...response.data.upperBodyImages)
            // }

            studyImages = upperBodyImages;
            //}
          }
          if (name === "LOWER_BODY") {

            //  if(response.data.upperExtImages && response.data.upperExtImages.length>0){
              lowerBodyImages.push(...response.data.lowerBodyImages);
            // if (response.data.fullBodyImages) {
            //   lowerBodyImages.push(...response.data.fullBodyImages);
            // }
            // if (response.data.optionalImages) {
            //   lowerBodyImages.push(...response.data.optionalImages)
            // }
            // if (response.data.standardizedCloseUpImages) {
            //   lowerBodyImages.push(...response.data.standardizedCloseUpImages)
            // }
            studyImages = lowerBodyImages;
          }
          //}
          if (name === "STANDARDISED_CLOSE_UP") {

            // if(response.data.lowerExtImages && response.data.lowerExtImages.length>0){

            standardizedCloseUpImages.push(...response.data.standardizedCloseUpImages);
            // if (response.data.fullBodyImages) {
            //   standardizedCloseUpImages.push(...response.data.fullBodyImages);
            // }
            // if (response.data.optionalImages) {
            //   standardizedCloseUpImages.push(...response.data.optionalImages)
            // }
            // if (response.data.standardizedCloseUpImages) {
            //   standardizedCloseUpImages.push(...response.data.standardizedCloseUpImages)
            // }
            studyImages = standardizedCloseUpImages;
          }

          if (name === "ALL") {
            allImages.push(...response.data.allImages);
            studyImages = allImages;
          }
          // }
          //var studyImages = headImages;
          var imageData = [];
          await getPhotoGalleryDataJanssen(studyImages, imageData, dispatch);


          if (response.data.score != null) {
            dispatch({
              type: Types.EASI_SCORES,
              payload: response.data.score
            });
          }
          else {
            dispatch({
              type: Types.EASI_SCORES,
              payload: "No Data Found"
            });
          }

        }
      } else if (response && (response.error || (response.data && response.data.length === 0))) {
        dispatch({
          type: Types.PHOTO_GALLERY_BY_WEEK_ERROR,
          payload: undefined
        });
      }
      return response;

    } catch (e) {
      return { error: true };
    }
  };
}

export function getNihPhotoGallery(month, name, patientId, studyId, week ) {
  return async function (dispatch, getState) {
    try {
      let data = {
        month: month,
        partType: name === "ALL" ? null : name,
        patientId: patientId,
        studyId: studyId,
        week: week,
      };
      let response = await API.getNihPhotoGallery(data);
      // console.log('response',response);
      // let resp = await API.getPatientSelfEstimationJanssen(week, patientId);
      // if (resp && resp.data) {

      //   dispatch({
      //     type: Types.PATIENT_SELF_ESTIMATION_JANSSEN,
      //     payload: resp.data
      //   });
      // }
      if (response && response.data) {
        dispatch({
          type: Types.NIH_PHOTO_GALLERY,
          payload: response.data
        });

        let fullBodyImages = [];
        let headAndNeckImages = [];
        let upperBodyImages = [];
        let lowerBodyImages = [];
        let studyImages = [];
        let standardizedCloseUpImages = [];
        let allImages = [];

        if (response && response.data) {
          if (name === "HEAD_AND_NECK") {
            headAndNeckImages.push(...response.data.headAndNeckImages);
            // if (response.data.fullBodyImages) {
            //   headAndNeckImages.push(...response.data.fullBodyImages);
            // }
            // if (response.data.optionalImages) {
            //   headAndNeckImages.push(...response.data.optionalImages);
            // }
            // if (response.data.standardizedCloseUpImages) {
            //   headAndNeckImages.push(...response.data.standardizedCloseUpImages);
            // }
            // if (response.data.headAndNeckImages) {
            //   headAndNeckImages.push(...response.data.headAndNeckImages);
            // }

            studyImages = headAndNeckImages;
          }
          if (name === "FULL_BODY") {
            fullBodyImages.push(...response.data.fullBodyImages);
            // if (response.data.fullBodyImages) {
            //   fullBodyImages.push(...response.data.fullBodyImages);
            // }
            // if (response.data.optionalImages) {
            //   fullBodyImages.push(...response.data.optionalImages);
            // }
            // if (response.data.standardizedCloseUpImages) {
            //   fullBodyImages.push(...response.data.standardizedCloseUpImages);
            // }
            // if (response.data.fullBodyImages) {
            //   fullBodyImages.push(...response.data.fullBodyImages);
            // }

            studyImages = fullBodyImages;
          }
          if (name === "UPPER_BODY") {

            // if(response.data.trunkImages && response.data.trunkImages.length>0){
              upperBodyImages.push(...response.data.upperBodyImages);
            // if (response.data.fullBodyImages) {
            //   upperBodyImages.push(...response.data.fullBodyImages);
            // }
            // if (response.data.optionalImages) {
            //   upperBodyImages.push(...response.data.optionalImages)
            // }
            // if (response.data.standardizedCloseUpImages) {
            //   upperBodyImages.push(...response.data.standardizedCloseUpImages)
            // }
            // if (response.data.upperBodyImages) {
            //   upperBodyImages.push(...response.data.upperBodyImages)
            // }

            studyImages = upperBodyImages;
            //}
          }
          if (name === "LOWER_BODY") {

            //  if(response.data.upperExtImages && response.data.upperExtImages.length>0){
              lowerBodyImages.push(...response.data.lowerBodyImages);
            // if (response.data.fullBodyImages) {
            //   lowerBodyImages.push(...response.data.fullBodyImages);
            // }
            // if (response.data.optionalImages) {
            //   lowerBodyImages.push(...response.data.optionalImages)
            // }
            // if (response.data.standardizedCloseUpImages) {
            //   lowerBodyImages.push(...response.data.standardizedCloseUpImages)
            // }
            studyImages = lowerBodyImages;
          }
          //}
          if (name === "STANDARDISED_CLOSE_UP") {

            // if(response.data.lowerExtImages && response.data.lowerExtImages.length>0){

            standardizedCloseUpImages.push(...response.data.standardizedCloseUpImages);
            // if (response.data.fullBodyImages) {
            //   standardizedCloseUpImages.push(...response.data.fullBodyImages);
            // }
            // if (response.data.optionalImages) {
            //   standardizedCloseUpImages.push(...response.data.optionalImages)
            // }
            // if (response.data.standardizedCloseUpImages) {
            //   standardizedCloseUpImages.push(...response.data.standardizedCloseUpImages)
            // }
            studyImages = standardizedCloseUpImages;
          }

          if (name === "ALL") {
            allImages.push(...response.data.allImages);
            studyImages = allImages;
          }
          // }
          //var studyImages = headImages;
          var imageData = [];
          await getPhotoGalleryDataJanssenWeek(studyImages, imageData, dispatch);


          if (response.data.score != null) {
            dispatch({
              type: Types.EASI_SCORES,
              payload: response.data.score
            });
          }
          else {
            dispatch({
              type: Types.EASI_SCORES,
              payload: "No Data Found"
            });
          }

        }
      } else if (response && (response.error || (response.data && response.data.length === 0))) {
        dispatch({
          type: Types.NIH_PHOTO_GALLERY_ERROR,
          payload: undefined
        });
      }
      return response;

    } catch (e) {
      return { error: true };
    }
  };
}

export function getNihPhotosSurveyScores(month, name, patientId, studyId, week ) {
  return async function (dispatch, getState) {
    try {
      let data = {
        month: month,
        partType: name === "ALL" ? null : name,
        patientId: patientId,
        studyId: studyId,
        week: week,
      };
      let response = await API.getNihPhotoGallery(data);
      if (response && response.data) {
        dispatch({
          type: Types.NIH_PHOTO_GALLERY,
          payload: response.data
        });
        

        let fullBodyImages = [];
        let headAndNeckImages = [];
        let upperBodyImages = [];
        let lowerBodyImages = [];
        let studyImages = [];
        let standardizedCloseUpImages = [];
        let allImages = [];

        if (response && response.data) {
          if (name === "HEAD_AND_NECK") {
            headAndNeckImages.push(...response.data.headAndNeckImages, ...response.data.fullBodyImages, ...response.data.standardizedCloseUpImages, ...response.data.standardizedCloseUpImages);

            studyImages = headAndNeckImages;
          }
          if (name === "FULL_BODY") {
            fullBodyImages.push(...response.data.fullBodyImages, ...response.data.upperBodyImages, );
            studyImages = fullBodyImages;
          }
          if (name === "UPPER_BODY") {

              upperBodyImages.push(...response.data.upperBodyImages, ...response.data.fullBodyImages, ...response.data.standardizedCloseUpImages);

            studyImages = upperBodyImages;

          }
          if (name === "LOWER_BODY") {
              lowerBodyImages.push(...response.data.lowerBodyImages, ...response.data.fullBodyImages, ...response.data.standardizedCloseUpImages);

            studyImages = lowerBodyImages;
          }
          var imageData = [];
          await getPhotoGalleryDataJanssenWeek(studyImages, imageData, dispatch);


          if (response.data.score != null) {
            dispatch({
              type: Types.EASI_SCORES,
              payload: response.data.score
            });
          }
          else {
            dispatch({
              type: Types.EASI_SCORES,
              payload: "No Data Found"
            });
          }

        }
      } else if (response && (response.error || (response.data && response.data.length === 0))) {
        dispatch({
          type: Types.NIH_PHOTO_GALLERY_ERROR,
          payload: undefined
        });
      }
      return response;

    } catch (e) {
      return { error: true };
    }
  };
}

export function getJansennPhotoGalleryByWeekNew(section, patientId, studyId, month, week) {
  return async function (dispatch, getState) {
    try {
      let data = {
        section: section,
        studyId: studyId,
        week: week,
        patientId: patientId,
        month: month,
        // partType: name === "ALL" ? null : name
      };
      let response = await API.getJansennPhotoGalleryByWeekNew(data);

      if (response && response.data) {
        dispatch({
          type: Types.JANSSEN_PHOTO_GALLERY_NEW,
          payload: response.data
        });

        let section1 = [];
        let section2 = [];
        let section3 = [];
        let section4 = [];
        let section5 = [];
        let allImages = [];
        let studyImages = [];

        if (response && response.data) {
          if (section === "section1") {
            section1.push(...response.data.section1);
            studyImages = section1;
          }
          if (section === "section2") {
            section2.push(...response.data.section2);
            studyImages = section2;
          }
          if (section === "section3") {
            section3.push(...response.data.section3);
            studyImages = section3;
          }
          if (section === "section4") {
            section4.push(...response.data.section4);
            studyImages = section4;
          }
          if (section === "section5") {
            section5.push(...response.data.section5);
            studyImages = section5;
          }
          if (section === "allImages") {
            allImages.push(...response.data.allImages);
            studyImages = allImages;
          }
          
          var imageData = [];
          await getPhotoGalleryDataJanssenWeek(studyImages, imageData, dispatch);


          if (response.data.score != null) {
            dispatch({
              type: Types.EASI_SCORES,
              payload: response.data.score
            });
          }
          else {
            dispatch({
              type: Types.EASI_SCORES,
              payload: "No Data Found"
            });
          }

        }
      } else if (response && (response.error || (response.data && response.data.length === 0))) {
        dispatch({
          type: Types.PHOTO_GALLERY_BY_WEEK_ERROR,
          payload: undefined
        });
      }
      return response;

    } catch (e) {
      return { error: true };
    }
  };
}

export function getAllOhsuImagesForPhysicianPortal(imageName, userId, studyId, quatar) {
  return async function (dispatch, getState) {
    try {
      let data = {
        imageName: imageName,
        userId: userId,
        studyId: studyId,
        quatar: quatar
        // partType: name === "ALL" ? null : name
      };
      let response = await API.getAllOhsuImagesForPhysicianPortal(data);

      if (response && response.data) {
        dispatch({
          type: Types.JANSSEN_PHOTO_GALLERY_NEW,
          payload: response.data
        });

        let rightElbowCrease = [];
        let leftElbowCrease = [];
        let creaseBehindRightKnee = [];
        let creaseBehindLeftKnee = [];
        let frontOfNeck = [];
        let optionalImages = [];
        let allImages = [];
        let studyImages = [];

        if (response && response.data) {
          if (imageName === "rightElbowCrease") {
            rightElbowCrease.push(...response.data.rightElbowCrease);
            studyImages = rightElbowCrease;
          }
          if (imageName === "leftElbowCrease") {
            leftElbowCrease.push(...response.data.leftElbowCrease);
            studyImages = leftElbowCrease;
          }
          if (imageName === "creaseBehindRightKnee") {
            creaseBehindRightKnee.push(...response.data.creaseBehindRightKnee);
            studyImages = creaseBehindRightKnee;
          }
          if (imageName === "creaseBehindLeftKnee") {
            creaseBehindLeftKnee.push(...response.data.creaseBehindLeftKnee);
            studyImages = creaseBehindLeftKnee;
          }
          if (imageName === "frontOfNeck") {
            frontOfNeck.push(...response.data.frontOfNeck);
            studyImages = frontOfNeck;
          }
          if (imageName === "optionalImages") {
            optionalImages.push(...response.data.optionalImages);
            studyImages = optionalImages;
          }
          if (imageName === "allImages") {
            allImages.push(...response.data.allImages);
            studyImages = allImages;
          }
          
          var imageData = [];
          await getPhotoGalleryParade(studyImages, imageData, imageName, dispatch);


          if (response.data.score != null) {
            dispatch({
              type: Types.EASI_SCORES,
              payload: response.data.score
            });
          }
          else {
            dispatch({
              type: Types.EASI_SCORES,
              payload: "No Data Found"
            });
          }

        }
      } else if (response && (response.error || (response.data && response.data.length === 0))) {
        dispatch({
          type: Types.PHOTO_GALLERY_BY_WEEK_ERROR,
          payload: undefined
        });
      } else {
        dispatch({
          type: Types.JANSSEN_PHOTO_GALLERY_NEW,
          payload: undefined
        });
        dispatch({
          type: Types.PHOTO_GALLERY_BY_WEEK,
          payload: undefined,
        });
      }
      return response;

    } catch (e) {
      return { error: true };
    }
  };
}

export function getNewRashImages(userId, uploadDate, quatar) {
  return async function (dispatch, getState) {
    try {
      let data = {
        userId: userId,
        uploadDate: uploadDate,
        quatar: quatar,
      };
      dispatch({
        type: Types.RESET_PHOTO_GALLERY_BY_WEEK,  // This action will clear the gallery state
      });

      let response = await API.getNewRashImages(data); 
      let studyImages = [];
      if (response && response.data) {
        studyImages = response.data
        var imageData = [];

        for (let i = 0; i < studyImages.length; i++) {
          const currentImage = studyImages[i];
          if (Array.isArray(currentImage.filename)) {
            for (let j = 0; j < currentImage.filename.length; j++) {
              const resp = await API.getJanssenPatientSurveyImage(`${currentImage.imageUrl}/${currentImage.filename[j]}`);
              
              if (resp) {
                imageData.push({ desc: studyImages[i].imageName, 
                  url: resp, imgName: studyImages[i].name, 
                  description: studyImages[i].description, 
                  title: studyImages[i].title, 
                  imageStatus: studyImages[i].imageStatus });
              }
            }
          } else {
            const resp = await API.getJanssenPatientSurveyImage(currentImage.imageUrl);
            if (resp) {
              imageData.push({ desc: 'Affected area', url: resp });
            }
          }
        }

        await dispatch({
          type: Types.PHOTO_GALLERY_BY_WEEK,
          payload: imageData,
        });
    
        return imageData;

      }

    } catch (e) {
      return { error: true };
    }
  };
}

export function getJansennPhotoGallerySurveyScoreByWeek(studyId, week, patientId, name) {
  return async function (dispatch, getState) {
    try {
      let data = {
        studyId: studyId,
        week: week,
        patientId: patientId,
        partType: name === "ALL" ? null : name
      };
      let response = await API.getJansennPhotoGalleryByWeek(data);
      let resp = await API.getPatientSelfEstimationJanssen(week, patientId);
      if (resp && resp.data) {

        dispatch({
          type: Types.PATIENT_SELF_ESTIMATION_JANSSEN,
          payload: resp.data
        });
      }
      if (response && response.data) {
        dispatch({
          type: Types.JANSENN_PHOTO_GALLERY_BY_WEEK,
          payload: response.data
        });

        let fullBodyImages = [];
        let headAndNeckImages = [];
        let upperBodyImages = [];
        let lowerBodyImages = [];
        let studyImages = [];
        let standardizedCloseUpImages = [];
        let allImages = [];

        if (response && response.data) {
          if (name === "HEAD_AND_NECK") {
            headAndNeckImages.push(...response.data.headAndNeckImages);
            // if (response.data.fullBodyImages) {
            //   headAndNeckImages.push(...response.data.fullBodyImages);
            // }
            // if (response.data.optionalImages) {
            //   headAndNeckImages.push(...response.data.optionalImages);
            // }
            // if (response.data.standardizedCloseUpImages) {
            //   headAndNeckImages.push(...response.data.standardizedCloseUpImages);
            // }

            studyImages = headAndNeckImages;
          }
          if (name === "FULL_BODY") {
            fullBodyImages.push(...response.data.fullBodyImages);
            // if (response.data.upperBodyImages) {
            //   fullBodyImages.push(...response.data.upperBodyImages);
            // }
            // if (response.data.optionalImages) {
            //   fullBodyImages.push(...response.data.optionalImages);
            // }
            // if (response.data.standardizedCloseUpImages) {
            //   fullBodyImages.push(...response.data.standardizedCloseUpImages);
            // }

            studyImages = fullBodyImages;
          }
          if (name === "UPPER_BODY") {

            // if(response.data.trunkImages && response.data.trunkImages.length>0){
              upperBodyImages.push(...response.data.upperBodyImages);
            // if (response.data.fullBodyImages) {
            //   upperBodyImages.push(...response.data.fullBodyImages);
            // }
            // if (response.data.optionalImages) {
            //   upperBodyImages.push(...response.data.optionalImages)
            // }
            // if (response.data.standardizedCloseUpImages) {
            //   upperBodyImages.push(...response.data.standardizedCloseUpImages)
            // }

            studyImages = upperBodyImages;
            //}
          }
          if (name === "LOWER_BODY") {

            //  if(response.data.upperExtImages && response.data.upperExtImages.length>0){
              lowerBodyImages.push(...response.data.lowerBodyImages);
            // if (response.data.fullBodyImages) {
            //   lowerBodyImages.push(...response.data.fullBodyImages);
            // }
            // if (response.data.optionalImages) {
            //   lowerBodyImages.push(...response.data.optionalImages)
            // }
            // if (response.data.standardizedCloseUpImages) {
            //   lowerBodyImages.push(...response.data.standardizedCloseUpImages)
            // }
            studyImages = lowerBodyImages;
          }
          //}
          if (name === "STANDARDISED_CLOSE_UP") {

            // if(response.data.lowerExtImages && response.data.lowerExtImages.length>0){

            standardizedCloseUpImages.push(...response.data.standardizedCloseUpImages);
            // if (response.data.fullBodyImages) {
            //   standardizedCloseUpImages.push(...response.data.fullBodyImages);
            // }
            // if (response.data.optionalImages) {
            //   standardizedCloseUpImages.push(...response.data.optionalImages)
            // }
            // if (response.data.standardizedCloseUpImages) {
            //   standardizedCloseUpImages.push(...response.data.standardizedCloseUpImages)
            // }
            studyImages = standardizedCloseUpImages;
          }

          if (name === "ALL") {
            allImages.push(...response.data.allImages);
            studyImages = allImages;
          }
          // }
          //var studyImages = headImages;
          var imageData = [];
          await getPhotoGalleryDataJanssen(studyImages, imageData, dispatch);


          if (response.data.score != null) {
            dispatch({
              type: Types.EASI_SCORES,
              payload: response.data.score
            });
          }
          else {
            dispatch({
              type: Types.EASI_SCORES,
              payload: "No Data Found"
            });
          }

        }
      } else if (response && (response.error || (response.data && response.data.length === 0))) {
        dispatch({
          type: Types.PHOTO_GALLERY_BY_WEEK_ERROR,
          payload: undefined
        });
      }
      return response;

    } catch (e) {
      return { error: true };
    }
  };
}

export function getAllImagesAndScoreForPhysicianPortal(studyId, week, patientId, name) {
  return async function (dispatch, getState) {
    try {
      let data = {
        studyId: studyId,
        week: week,
        patientId: patientId,
        partType: name === "ALL" ? null : name
      };
      let response = await API.getAllImagesAndScoreForPhysicianPortal(data);
      let resp = await API.getPatientSelfEstimationJanssen(week, patientId);
      if (resp && resp.data) {

        dispatch({
          type: Types.PATIENT_SELF_ESTIMATION_JANSSEN,
          payload: resp.data
        });
      }
      if (response && response.data) {
        dispatch({
          type: Types.JANSENN_IMAGES_SCORES,
          payload: response.data
        });

        let fullBodyImages = [];
        let headAndNeckImages = [];
        let upperBodyImages = [];
        let lowerBodyImages = [];
        let studyImages = [];
        let standardizedCloseUpImages = [];
        let allImages = [];



        if (response && response.data) {
          if (name === "HEAD_AND_NECK") {
            headAndNeckImages.push(...response.data.headAndNeckImages);

            studyImages = headAndNeckImages;
          }
          if (name === "FULL_BODY") {
            fullBodyImages.push(...response.data.fullBodyImages);

            studyImages = fullBodyImages;
          }
          if (name === "UPPER_BODY") {

              upperBodyImages.push(...response.data.upperBodyImages);

            studyImages = upperBodyImages;
          }
          if (name === "LOWER_BODY") {

              lowerBodyImages.push(...response.data.lowerBodyImages);
            studyImages = lowerBodyImages;
          }
          //}
          if (name === "STANDARDISED_CLOSE_UP") {


            standardizedCloseUpImages.push(...response.data.standardizedCloseUpImages);
            studyImages = standardizedCloseUpImages;
          }

          if (name === "ALL") {
            allImages.push(...response.data.allImages);
            studyImages = allImages;
          }
          var imageData = [];
          let abc = await getPhotoGalleryDataJanssenWeek(studyImages, imageData, dispatch);


          if (response.data.score != null) {
            dispatch({
              type: Types.EASI_SCORES,
              payload: response.data.score
            });
          }
          else {
            dispatch({
              type: Types.EASI_SCORES,
              payload: "No Data Found"
            });
          }

        }
      } else if (response && (response.error || (response.data && response.data.length === 0))) {
        dispatch({
          type: Types.PHOTO_GALLERY_BY_WEEK_ERROR,
          payload: undefined
        });
      }
      return response;

    } catch (e) {
      return { error: true };
    }
  };
}


const getPhotoGalleryData = async (studyImages, imageData, dispatch) => {

  var i;
  var j;
  for (i = 0; i < studyImages.length; i++) {
    if (typeof (studyImages[i].filename) !== 'string') {
      for (j = 0; j < studyImages[i].filename.length; j++) {
        let resp = await API.getPatientSurveyImage(studyImages[i].imageUrl + "/" + studyImages[i].filename[j]);
        if (resp) {
          let data = URL.createObjectURL(resp);
          imageData.push({ desc: studyImages[i].name, url: data });
        }
      }
    } else {  //to be used only if list contains affectedAreaImages within allImages 
      let resp = await API.getAffectedAreaImage(studyImages[i].imageUrl);
      if (resp) {
        let data = URL.createObjectURL(resp);
        imageData.push({ desc: "Affected area", url: data });
      }
    }
  }

  dispatch({
    type: Types.PHOTO_GALLERY,
    payload: imageData,

  });
  return imageData;

}


const getPhotoGalleryDataJanssen = async (studyImages, imageData, dispatch) => {
  var i;
  var j;
  for (i = 0; i < studyImages.length; i++) {
    if (typeof (studyImages[i].filename) !== 'string') {
      for (j = 0; j < studyImages[i].filename.length; j++) {
        let resp = await API.getJanssenPatientSurveyImage(studyImages[i].imageUrl + "/" + studyImages[i].filename[j]);
        if (resp) {
          let data = URL.createObjectURL(resp);
          imageData.push({ desc: studyImages[i].name, url: data });
        }
      }
    } else {  //to be used only if list contains affectedAreaImages within allImages 
      let resp = await API.getJanssenPatientSurveyImage(studyImages[i].imageUrl);
      if (resp) {
        let data = URL.createObjectURL(resp);
        imageData.push({ desc: "Affected area", url: data });
      }
    }
  }

  dispatch({
    type: Types.PHOTO_GALLERY_BY_WEEK,
    payload: imageData,

  });
  return imageData;

}

// const getPhotoGalleryParadeNewRash = async (studyImages, imageData, dispatch) => {
//   console.log('working',studyImages);
//   debugger
  
//   var i;
//   var j;
//   for (i = 0; i < studyImages.length; i++) {
//     console.log('working 1');
//     if (typeof (studyImages[i].filename) !== 'string') {
//       console.log('working 2');
//       for (j = 0; j < studyImages[i].filename.length; j++) {
//         console.log('working 3');
//         let resp = await API.getJanssenPatientSurveyImage(studyImages[i].imageUrl + "/" + studyImages[i].filename[j]);
//         console.log('resp',resp);
        
//         if (resp) {
//           console.log('respiiiii');
          
//           await imageData.push({ 
//             desc: studyImages[i].imageName, 
//             url: resp, 
//             imgName: studyImages[i].name, 
//             description: studyImages[i].description, 
//             title: studyImages[i].title, 
//           });
//         }
//       }
//     } else {  //to be used only if list contains affectedAreaImages within allImages 
//       let resp = await API.getJanssenPatientSurveyImage(studyImages[i].imageUrl);
//       if (resp) {
//         imageData.push({ desc: "Affected area", url: resp });
//       }
//     }
//   }
  

//   await dispatch({
//     type: Types.PHOTO_GALLERY_BY_WEEK,
//     payload: imageData,

//   });
//   return imageData;

// }

const getPhotoGalleryParade = async (studyImages, imageData, imageName, dispatch) => {
  dispatch({
    type: Types.RESET_PHOTO_GALLERY_BY_WEEK,  // This action will clear the gallery state
  });
  
  var i;
  var j;
  for (i = 0; i < studyImages.length; i++) {
    if (imageName === "allImages" && studyImages[i].imageStatus !== "Active") {
      continue;  // Skip this iteration if the image is not active
    }
    if (typeof (studyImages[i].filename) !== 'string') {
      for (j = 0; j < studyImages[i].filename.length; j++) {
        let resp = await API.getJanssenPatientSurveyImage(studyImages[i].imageUrl + "/" + studyImages[i].filename[j]);
        if (resp) {
          imageData.push({ 
            desc: studyImages[i].imageName, 
            url: resp, imgName: studyImages[i].name, 
            description: studyImages[i].description, 
            title: studyImages[i].title, 
            imageStatus: studyImages[i].imageStatus });
        }
      }
    } else {  //to be used only if list contains affectedAreaImages within allImages 
      let resp = await API.getJanssenPatientSurveyImage(studyImages[i].imageUrl);
      if (resp) {
        imageData.push({ desc: "Affected area", url: resp });
      }
    }
  }
  console.log('imageData',imageData);
  

  dispatch({
    type: Types.PHOTO_GALLERY_BY_WEEK,
    payload: imageData,

  });
  return imageData;

}

const getPhotoGalleryDataJanssenWeek = async (studyImages, imageData, dispatch) => {
  var i;
  var j;
  for (i = 0; i < studyImages.length; i++) {
    if (typeof (studyImages[i].filename) !== 'string') {
      for (j = 0; j < studyImages[i].filename.length; j++) {
        // console.log('studyImages-->',studyImages[i].name);
        // console.log('studyImagesFilnema-->',studyImages[i].filename);
        // let desc = studyImages[i].name ? studyImages[i].name : studyImages[i].filename;
        // let allRes = API.getJanssenPatientSurveyImage(studyImages[i].imageUrl + "/" + studyImages[i].filename[j]);
        // imageData.push({ desc: desc, url: allRes});
        // console.log('imageData',imageData);
        let desc;
        if (studyImages[i].filename[j].includes('Adverse')) {
          const parts = studyImages[i].filename[j].split('_');
          const adverseIndex = parts.findIndex(part => part.includes('Adverse'));
          // console.log('adverseIndex',adverseIndex);
          desc = parts.slice(adverseIndex)[0];
          // console.log('desc',desc);
        } else if(studyImages[i].imageUrl.includes('affectedAreaImages')) {
          desc = studyImages[i].description;
        } else if(studyImages[i].imageUrl.includes('photoDiary')) {
          desc = studyImages[i].title;
        } else {
          desc = studyImages[i].name ? studyImages[i].name : studyImages[i].filename[j];
        }
        let allRes = API.getJanssenPatientSurveyImage(studyImages[i].imageUrl + "/" + studyImages[i].filename[j]);
        imageData.push({ desc: desc, url: allRes});
        // console.log('imageData',imageData);
      }
    } else {  //to be used only if list contains affectedAreaImages within allImages 
      let resp = await API.getAffectedAreaImage(studyImages[i].imageUrl);
      if (resp) {
        let data = URL.createObjectURL(resp);
        imageData.push({ desc: "Affected area", url: data });
      }
    }
  }

  dispatch({
    type: Types.PHOTO_GALLERY_BY_WEEK,
    payload: imageData,

  });
  return imageData;

}

// const getPhotoGalleryDataJanssen = async (studyImages, imageData, dispatch) => {
//   const batchSize = 4;
//   const totalImages = studyImages.length;
//   let currentIndex = 0;
//   let fetchedImages = 0;

//   while (fetchedImages < totalImages) {
//     console.log('currentIndexcurrentIndex',currentIndex);
//     console.log('fetchedImagesfetchedImages',fetchedImages);
//     let batch = studyImages.slice(currentIndex, currentIndex + batchSize);
//     console.log('batchbatch',batch);
//     console.log('studyImagesstudyImages',studyImages);

//     let imagesProcessedInBatch = 0;

//     for (const studyImage of batch) {
//       console.log('imagesProcessedInBatchimagesProcessedInBatch',imagesProcessedInBatch);
//       console.log('batchSizebatchSize 111',batchSize);
//       if (imagesProcessedInBatch >= batchSize) {
//         console.log('4 fetched');
//         break; // Break the loop if 4 images have been processed in this batch
//       }

//       if (typeof studyImage.filename !== 'string') {
//         console.log('yess', typeof studyImage.filename);
//         for (const filename of studyImage.filename) {
//           let resp = await API.getPatientSurveyImage(studyImage.imageUrl + "/" + filename);
//           if (resp) {
//             let data = URL.createObjectURL(resp);
//             imageData.push({ desc: studyImage.name, url: data });
//             fetchedImages++;
//             imagesProcessedInBatch++;
//           }
//           console.log('imageDataimageData ifffff',imageData);
//           console.log('fetchedImages ifffffff',fetchedImages);
//           console.log('imagesProcessedInBatch ifffff',imagesProcessedInBatch);
//         }
//       } else {
//         let resp = await API.getAffectedAreaImage(studyImage.imageUrl);
//         if (resp) {
//           let data = URL.createObjectURL(resp);
//           imageData.push({ desc: "Affected area", url: data });
//           fetchedImages++;
//           imagesProcessedInBatch++;
//         }
//         console.log('imageDataimageData elseeee',imageData);
//           console.log('fetchedImages elseeee',fetchedImages);
//           console.log('imagesProcessedInBatch elseeee',imagesProcessedInBatch);
//       }
//     }

//     dispatch({
//       type: Types.PHOTO_GALLERY_BY_WEEK,
//       payload: [...imageData], // Create a new array to avoid mutating the original imageData
//     });

//     // Check if the loop should stop fetching more images
//     if (fetchedImages >= totalImages) {
//       console.log('breakeddd');
//       break;
//     }

//     currentIndex += batchSize;
//   }

//   return imageData;
// };





const getImageData = async (studyImages, imageData, dispatch) => {
  var i;
  var j;
  for (i = 0; i < studyImages.length; i++) {
    for (j = 0; j < studyImages[i].filename.length; j++) {
      let resp = await API.getPatientStudyImage(studyImages[i].imageUrl + "/" + studyImages[i].filename[j]);
      if (resp) {
        let data = URL.createObjectURL(resp);
        imageData.push({ desc: studyImages[i].fileDescription[j], url: data });
      }
    }
  }
  dispatch({
    type: Types.STUDY_IMAGES,
    payload: imageData
  });
}

export function sendPendingTasks(studyId, patientId) {
  return async function (dispatch, getState) {
    try {
      let resp = await API.sendPendingTasks(studyId, patientId);
      return resp;
    } catch (e) {
      return { error: true };
    }
  };
}

export function sendPendingTasksJanssen(studyId, patientId) {
  return async function (dispatch, getState) {
    try {
      let resp = await API.sendPendingTasksJanssen(studyId, patientId);
      return resp;
    } catch (e) {
      return { error: true };
    }
  };
}

export function deleteDuplicateTasks(studyId, patientId) {
  return async function (dispatch, getState) {
    try {
      let resp = await API.deleteDuplicateTasks(studyId, patientId);
      return resp;
    } catch (e) {
      return { error: true };
    }
  };
}

export function deleteDuplicateTasksJanssen(studyId, patientId) {
  return async function (dispatch, getState) {
    try {
      let resp = await API.deleteDuplicateTasksJanssen(studyId, patientId);
      return resp;
    } catch (e) {
      return { error: true };
    }
  };
}

export function downloadImages(studyId, week, patientId, partTypeName) {
  return async function (dispatch, getState) {
    try {
      let data = {
        studyId: studyId,
        month: week,
        patientId: patientId,
        partType: partTypeName === "ALL" ? null : partTypeName
      };
      return await API.downloadImages(data);
    } catch (e) {
      return { error: true };
    }
  };
}

export function downloadImagesJanssen(studyId, week, patientId, partTypeName) {
  return async function (dispatch, getState) {
    try {
      let data = {
        studyId: studyId,
        week: week,
        patientId: patientId,
        partType: partTypeName === "ALL" ? null : partTypeName
      };
      return await API.downloadImagesJanssen(data);
    } catch (e) {
      return { error: true };
    }
  };
}


export function downloadImagesJanssenNew(section, patientId, studyId, month, week) {
  return async function (dispatch, getState) {
    try {
      let data = {
        section: section,
        patientId: patientId,
        studyId: studyId,
        month: month,
        week: week,
      };
      return await API.downloadImagesJanssenNew(data);
    } catch (e) {
      return { error: true };
    }
  };
}

export function downloadImagesOhsu(imageName, studyId, quatar, userId) {
  return async function (dispatch, getState) {
    try {
      let data = {
        imageName: imageName,
        studyId: studyId,
        quatar: quatar,
        userId: userId,
      };
      return await API.downloadImagesOhsu(data);
    } catch (e) {
      return { error: true };
    }
  };
}

export function downloadNewRashImages(imageName, studyId, quatar, userId, date) {
  return async function (dispatch, getState) {
    try {
      let data = {
        imageName: imageName,
        studyId: studyId,
        quatar: quatar,
        userId: userId,
        date: date
      };
      return await API.downloadNewRashImages(data);
    } catch (e) {
      return { error: true };
    }
  };
}

export function deleteZipFile() {
  return async function (dispatch, getState) {
    try {
      let resp = await API.deleteZipFile();
      return resp;
    } catch (e) {
      return { error: true };
    }
  };
}


export function downloadImagesNih(month, partType, patientId, studyId, week) {
  return async function (dispatch, getState) {
    try {
      let data = {
        month: month,
        partType: partType,
        patientId: patientId,
        studyId: studyId,
        week: week,
      };
      return await API.downloadImagesNih(data);
    } catch (e) {
      return { error: true };
    }
  };
}

export function downloadPatientTasks(studyId, patientId) {
  return async function (dispatch, getState) {
    try {
      return await API.downloadPatientTasks(studyId, patientId);
    } catch (e) {
      return { error: true };
    }
  };
}

export function downloadParticipantData(fileName) {
  return async function (dispatch, getState) {
    try {
      return await API.downloadParticipantData(fileName);
    } catch (e) {
      return { error: true };
    }
  };
}

export function downloadHealthDataNih(fileName) {
  return async function (dispatch, getState) {
    try {
      return await API.downloadHealthDataNih(fileName);
    } catch (e) {
      return { error: true };
    }
  };
}

export function downloadPatientDataNih(fileName) {
  return async function (dispatch, getState) {
    try {
      return await API.downloadPatientDataNih(fileName);
    } catch (e) {
      return { error: true };
    }
  };
}

export function deleteDirectoryFiles() {
  return async function (dispatch, getState) {
    try {
      return await API.deleteDirectoryFiles();
    } catch (e) {
      return { error: true };
    }
  };
}

export function uploadBulkUsers(file) {
  return async function(dispatch, _getState) {
    try {
      let resp = await API.uploadBulkUsers(file);
      console.log(resp)
      if (resp && resp.data) {
        dispatch({
          type: Types.BULK_USER_IMPORT,
          payload: resp.data
        });
      }
      return resp;
    } catch (e) {
      return { error: true };
    }
  };
}

export function uploadPartPhoto(userId, title, month, partImage) {
  return async function(dispatch, _getState) {
    try {
      let data = {
        userId: userId,
        title: title,
        month: month,
        partImage: partImage,
      };
      let resp = await API.uploadPartPhoto(data);
      console.log(resp)
      if (resp && resp.data) {
        dispatch({
          type: Types.UPLOAD_PHOTO,
          payload: resp.data
        });
      }
      return resp;
    } catch (e) {
      return { error: true };
    }
  };
}

export function saveImage(partImage, userId, description, title, prevFileName, quatar) {
  return async function(dispatch, _getState) {
    try {
      let data = {
        partImage: partImage,
        userId: userId,
        description: description,
        title: title,
        prevFileName: prevFileName,
        quatar: quatar
      };
      console.log("data:=>ac",data);
      
      let resp = await API.saveImage(data);
      console.log("resp=>",resp)
      if (resp && resp.data) {
        dispatch({
          type: Types.UPLOAD_PHOTO,
          payload: resp.data
        });
      }
      return resp;
    } catch (e) {
      return { error: true };
    }
  };
}

export function saveRashImage(partImage, userId, description, title, prevFileName, quatar) {
  return async function(dispatch, _getState) {
    try {
      let data = {
        partImage: partImage,
        userId: userId,
        description: description,
        title: title,
        prevFileName: prevFileName,
        quatar: quatar
      };      
      let resp = await API.saveRashImage(data);
      if (resp && resp.data) {
        dispatch({
          type: Types.UPLOAD_PHOTO,
          payload: resp.data
        });
      }
      return resp;
    } catch (e) {
      return { error: true };
    }
  };
}


export function deleteParadeImage(imageName, studyId, quatar, userId) {
  return async function(dispatch, _getState) {
    try {
      let data = {
        imageName: imageName,
        studyId: studyId,
        quatar: quatar,
        userId: userId
      };      
      let resp = await API.deleteParadeImage(data);
      console.log(resp);
      return resp;
    } catch (e) {
      return { error: true };
    }
  };
}


export function deleteRashParadeImage(imageName, studyId, quatar, userId) {
  return async function(dispatch, _getState) {
    try {
      let data = {
        imageName: imageName,
        studyId: studyId,
        quatar: quatar,
        userId: userId
      };      
      let resp = await API.deleteRashParadeImage(data);
      console.log(resp);
      return resp;
    } catch (e) {
      return { error: true };
    }
  };
}

export function getNewRashTask(userId, quatar) {
  return async function (dispatch, getState) {
    try {
      let resp = await API.getNewRashTask(userId, quatar);
      
      return resp;
    } catch (e) {
      return { error: true };
    }
  };
}
