import React from 'react';

const headingStyle = {
    textAlign: 'center',
    fontSize: '26px',
    fontWeight: 'bold',
    paddingBottom: '12px',
};

const headingsStyle = {
    marginBottom: '12px',
    fontWeight: 'bold',
};

const divStyle = {
    padding: '50px 50px',
};

const ulStyle = {
    listStyle: 'circle',
    paddingLeft: '20px',
    paddingRight: '20px',
    paddingBottom: '12px',
};

const liStyle = {
    padding: '5px 0px',
};

const pStyle = {
    paddingBottom: '12px',
};


const PrivacyPolicyOhsu = () => {
    return (
        <div className='terms-conditions' style={divStyle}>
            <h1 style={headingStyle}>Privacy Policy</h1>
                <p style={pStyle}>University of California, San Francisco associates of the Department of Dermatology—
                    Psoriasis and Skin Treatment Center (jointly referred to as “we”), has formulated this
                    Privacy Policy (“Policy”) to outline its online data safeguarding practices. During your
                    use of the SkinTracker application while participating in the PARADE study, this Policy
                    explains the purpose and specifics of the information that the University of California,
                    San Francisco’s Department of Dermatology collects about you. Furthermore, it
                    illustrates how the data collected about you will be utilized, how you can exercise
                    control over your personal data, and how to get in touch with us in case you require any
                    assistance.</p>

                <p style={pStyle}>Our Privacy Policy outlines the details of our data collection, storage, and processing
                    methods for the SkinTracker application. We strongly encourage you to thoroughly
                    review our Privacy Policy as it pertains to the entirety of our agreement. Should you
                    disagree with any part of it, please refrain from using the SkinTracker application.
                    Before using the SkinTracker application, we recommended that you review this Policy
                    closely. Your data will be used only as described in this Policy.</p>

                <p style={pStyle}>Please bear in mind that this Policy is an integral component of the Terms of Use of the
                    SkinTracker application. We again encourage you to read this privacy policy carefully as
                    it will help you make informed decisions about sharing your personal information with
                    us.</p>

            <h4 style={headingsStyle}>What Information Does University of California, San Francisco’s Department of
                    Dermatology—Psoriasis and Skin Treatment Center Collect?</h4>
                <p style={pStyle}>We aim to conduct high quality research via the data collected and utilized within the
                    SkinTracker application. All data entered into the SkinTracker application may be used
                    for the PARADE study and other research-related purposes.</p>

            <h4 style={headingsStyle}>Personal Identification Information</h4>
                <p style={pStyle}>Your personal information is voluntarily submitted to us when you consent to enroll as a
                    subject in the PARADE SkinTracker project. The kind of personal information we collect
                    hinges on what information you enter into the SkinTracker application. Examples of
                    personal information we may collect include:</p>

                <ul style={ulStyle}>
                    <li style={liStyle}><b>Photographs:</b>All personal data provided to use must be authentic, whole, and
                        precise to the best of your knowledge, as the quality of our research is dependent
                        upon this. Further, if there are any changes to the information that you have
                        entered into the SkinTracker application, we must be notified.</li>
                </ul>
            <h4 style={headingsStyle}>Non-Personal Identification Information</h4>
                <p style={pStyle}>With the download and use of the SkinTracker application, information about your
                    iPhone device, operating system, and preferred language are collected. This is for the
                    purpose of ensuring appropriate formatting and functionality of the SkinTracker
                    application.</p>
                <p style={pStyle}>There is no other non-personal data collected through use of the SkinTracker
                    application. Specifically, there is no use of cookies and no required payment.</p>

            <h4 style={headingsStyle}>When Do We Collect Your Personal Data?</h4>
                <p style={pStyle}>We shall exclusively gather and employ Users’ personal data under the following
                    conditions:</p>
                <ul style={ulStyle}>
                    <li style={liStyle}>If you have been consented to enroll in the PARADE study</li>
                    <li style={liStyle}>If you have thoroughly read and agree to our Terms & Conditions</li>
                    <li style={liStyle}>If you have thoroughly read and agree to our Privacy Policy</li>
                </ul>

            <h4 style={headingsStyle}>How Does the University of California, San Francisco’s Department of Dermatology--
                Psoriasis and Skin Treatment Center Use Your Collected Information?</h4>

                <p style={pStyle}>We are committed to safeguarding your privacy with the assistance of RedBlink, a well-
                    recognized software development company. We collect only the data that is necessary
                    to meet the aims of the PARADE project and to conduct high-quality research.</p>

                <p style={pStyle}>The University of California, San Francisco’s Department of Dermatology collects and
                    uses personal data for the following purpose:</p>
                <ul style={ulStyle}>
                    <li style={liStyle}><b>Conduct High-Quality Research:</b> We are committed to conducting ethical high-
                    quality research that meets the aims of our project.</li>
                </ul>
            

            <h4 style={headingsStyle}>What is Our Data Selling Policy?</h4>
                <p style={pStyle}>We will never sell your data. Your personal and non-personal information are protected
                    and confidential.</p>

                <p style={pStyle}>We implement a range of careful procedures to ensure that the collection, storage, and
                    processing of your personal data and login credentials are done in a manner that is
                    secure and protected from any unsanctioned access, alteration, or disclosure. We
                    employ rigorous security measures to safeguard your sensitive data against any kind of
                    unauthorized intrusion or destruction.</p>


            <h4 style={headingsStyle}>When Will We Share Your Personal Data?</h4>
                <p style={pStyle}>The University of California, San Francisco’s Department of Dermatology will never
                    share your personal data when it is associated with personal identifiers.</p>

            <h4 style={headingsStyle}>How Long Will We Keep Your Data?</h4>
                <p style={pStyle}>We will retain all de-identified data collected from the SkinTracker application for
                    research purposes for an indefinite period of time. The purpose of this data is for
                    research purposes alone.</p>

            <h4 style={headingsStyle}>What is the Compliance with Children’s Online Privacy Protection Act?</h4>
                <p style={pStyle}>Legal guardians of minors enrolled in the PARADE study will use the SkinTracker
                    application as part of the research study. However, data belonging to minors will not be
                    personally identifiable in any research-related publications or data sharing. The legal
                    guardian of the minor will be responsible for providing consent on behalf of the minor.
                    Additionally, the guardian will be responsible for managing the minor's personal data,
                    including requests for data deletion or withdrawal from the study. Any data that has
                    already been shared in de-identified form for research purposes will not be retracted.</p>

            <h4 style={headingsStyle}>Is There Any Involvement of Third Parties?</h4>
                <p style={pStyle}>We will use a third-party application programming interface (API), Sightengine, which is
                    used for image quality analysis of photos taken by SkinTracker application users.
                    Images are shared with Sightengine for the sole purpose of immediate image quality
                    analysis and will be deleted by this API afterwards.</p>

            <h4 style={headingsStyle}>What Does Our Web Cookies Policy Entail?</h4>
                <p>We will not use cookies or other tracking technologies.</p>
            
            <h4 style={headingsStyle}>How Does the University of California, San Francisco’s Department of Dermatology--
                Psoriasis and Skin Treatment Center Ensure the Security of Its Users’ Data?</h4>

                <p style={pStyle}>We fully understand the utmost importance of safeguarding and protecting your
                    confidential data. Thus, we, along with RedBlink, implement various measures and
                    protocols to ensure your information remains protected against any form of
                    unauthorized access or breach. SSL encryption techniques are employed, serving as the
                    foundation of our strategy to shield your sensitive data against any form of misuse,
                    modification, interference, or inadvertent disclosure.</p>

                <p style={pStyle}>However, it is imperative to note that that entering of any material within an application
                    poses a risk for a breach of confidentiality.</p>

            <h4 style={headingsStyle}>Does the University of California, San Francisco’s Department of Dermatology--
                Psoriasis and Skin Treatment Center Offer “Do Not Track” Features For User Privacy?</h4>

                <p style={pStyle}>We do not use a Do-Not-Track (DNT) feature for the SkinTracker as it is an application
                    with no online-browser functionality or use.</p>
            
            <h4 style={headingsStyle}>What Are GDPR Data Protection Rights For Personal Data?</h4>

                <p style={pStyle}>The General Data Protection Regular (GDPR) refers to a privacy notice for users in the
                    European Union (E.U.). As all enrolled subjects into the SkinTracker project will be
                    application users based within the United States, the GDPR is not applicable.</p>
            
            <h4 style={headingsStyle}>What Are Consumer Privacy Rights in the United States?</h4>
                <p style={pStyle}>U.S. residents have specific privacy rights regarding their personal information. These
                    rights include the ability to know what personal information is collected about them, the
                    right to request that businesses and other entities delete their personal information, and
                    the right to opt out of the sale of their personal information.</p>

            <p style={pStyle}>We will never sell the personal information of individuals using the SkinTracker
                application, as this is research. Users have the option to withdraw from the study at any
                time and request that any data previously entered by them during the course of the
                study be destroyed. However, any data already sent to research partners or published in
                de-identified form will not be retracted.</p>
                
            <h4 style={headingsStyle}>Is the Policy Subject to Change?</h4>
            <p style={pStyle}>We maintain that our Privacy Policy will not be modified during the course of your use of
                the SkinTracker application. We will ensure that the “effective date” will be visible at the
                beginning of this Privacy Policy, so you can be certain you are viewing the latest version.</p>

            <h4 style={headingsStyle}>Do You Have Any Questions?</h4>
            <p style={pStyle}>If you have inquiries about this Privacy Policy, please do not hesitate to get in touch with
                us at any time by sending an e-mail to <a href='mailto:parade@ohsu.edu'>parade@ohsu.edu</a>.</p>
        </div>
    );
};

export default PrivacyPolicyOhsu;