export function initFakeBackend() {
  let staticLoginUsers = [
    {
      id: 1,
      username: "admin",
      password: "admin",
      firstName: "UCSF",
      lastName: "Admin",
      email: "admin@mail.com",
      phone: "1111111111",
    },
    {
      id: 2,
      username: "user",
      password: "user",
      firstName: "UCSF",
      lastName: "User",
      email: "user@mail.com",
      phone: "0000000000",
    },
  ];
  let users = getUsers(1, 41);
  let roles = getRoles(1, 11);
  let patients = getPatients(1, 5);
  let recordCountPerPage = 10;

  function getDataByEntity(entity) {
    if (entity === "users") return users;
    if (entity === "roles") return roles;
    else if (entity === "patients") return patients;
  }

  function getUrlEntity(url) {
    var reUrlPath = /(?:\w+:)?\/\/[^/]+([^?#]+)/;
    var path = url.match(reUrlPath).pop();
    var pathArray = path.split("/").filter(function(el) {
      return el !== null && el !== "";
    });
    return pathArray;
  }

  function getUsers(pageNo, count) {
    let tempItems = [];
    for (let i = 1; i <= count; i++) {
      let no = (pageNo - 1) * count + i;
      tempItems.push({
        id: no,
        firstname: "first" + no,
        lastname: "last" + no,
        email: "email1@yahoo.com" + no,
        phone: "00000000" + no,
        address1: "location1 " + no,
        address2: "location2 " + no,
        state: "state" + no,
        country: "country" + no,
        postcode: "zip" + no,
        role: getRoles(1, 10)[Math.floor(Math.random() * 5) + 1].name,
        company: "Test company",
        permissions: "Permission " + i,
        auCode: "Au code " + i,
        restrictedProducts: "Product " + i,
        workStatus: i % 2,
        status: i % 2 === 0 ? "active" : "inactive",
      });
    }
    return tempItems.reverse();
  }

  function getRoles(pageNo, count) {
    let tempItems = [
      {
        id: 1,
        name: "Super Admin",
        usersCount: 1,
        description: "Test description",
        status: true,
      },
      {
        id: 2,
        name: "Admin",
        usersCount: 17,
        description: "Test description",
        status: false,
      },
      {
        id: 3,
        name: "Supervisor",
        usersCount: 12,
        description: "Test description",
        status: false,
      },
      {
        id: 4,
        usersCount: 3,
        description: "Test description",
        name: "CSR",
        status: true,
      },
      {
        id: 5,
        usersCount: 10,
        description: "Test description",
        name: "Employee",
        status: true,
      },
      {
        id: 6,
        usersCount: 2,
        description: "Test description",
        name: "Finance",
        status: true,
      },
    ];
    return tempItems;
  }

  function getPatients(pageNo, count) {
    let tempItems = [];
    for (let i = 1; i <= count; i++) {
      let no = (pageNo - 1) * count + i;
      tempItems.push({
        id: no,
        name: "Patient" + no,
        content: "Test" + no,
        week: "Week 24/52",
      });
    }
    return tempItems;
  }

  function getItemsByPage(pageNo, count, entity) {
    let data = getDataByEntity(entity);
    console.log("[fake:fetch] GET getItemsByPage " + data.length);
    console.log(
      "[fake:fetch] GET getItemsByPage " +
        (pageNo - 1) * count +
        " : " +
        pageNo * count
    );
    //console.log("[fake:fetch] GET getItemsByPage " + JSON.stringify(items.slice(((pageNo-1)*count),(pageNo*count))));
    return data.slice((pageNo - 1) * count, pageNo * count);
  }

  let realfetch = window.fetch;
  window.fetch = function(url, opts) {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        let pathArray = getUrlEntity(url);
        let entity = "";
        if (pathArray.length >= 2) {
          entity = pathArray[1];
        }
        if (url.indexOf("/login") > -1) {
          let params = JSON.parse(opts.body);
          let filteredUsers = staticLoginUsers.filter((user) => {
            return (
              user.username === params.username &&
              user.password === params.password
            );
          });
          if (filteredUsers.length > 0) {
            let user = filteredUsers[0];
            let responseJson = {
              accessToken: {
                token:
                  "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiJwcmFzaGFudC5zaGFybWFAcmVkYmxpbmsubmV0IiwianRpIjoiYzQzYTg0NTEtYzlmNC00NGQ3LThmYzItYTU4OTM2NzU2MTJiIiwiaWF0IjoxNTgxNjY2NDYyLCJyb2wiOiJfQXBpX0FjY2VzcyIsImlkIjoiYzAyMzM1YjAtNGRkOC00NmUxLWI1NmMtOTFmYWE3NDIyNmI1IiwiQ2xpZW50Q29kZSI6IkZQPTY8Qlp7cC9Xc3NdSH0jaGpETXJZL3UiLCJVU0VSSUQiOiI3IiwiT1JHSUQiOiIxIiwibmJmIjoxNTgxNjY2NDYxLCJleHAiOjE1ODQyNTg0NjEsImlzcyI6Imh0dHA6Ly8xODIuNzMuMjkuMjk6OTAwMyIsImF1ZCI6Imh0dHA6Ly8xODIuNzMuMjkuMjk6OTAwMSJ9.8sCjQl0Sn85_5am5GgkMp43Vqj0wPvAQIDHkP8Cruhg",
                expiresIn: 2592000,
              },
              refreshToken: "8VIBfpKf+R7niYr0VLi47z+FO/IAQQ5K6qCq4zaFUiQ=",
              userInformation: {
                userID: user.id,
                userName: user.username,
                firstName: user.firstName,
                lastName: user.lastName,
                email: user.email,
                contactNo: user.phone,
                role: user.lastName,
                accountConfirmed: true,
              },
            };
            resolve(responseJson);
          } else {
            reject(Error("Username or password is incorrect."));
          }
          return;
        }

        if (url.endsWith("/forgot-password")) {
          let responseJson = {
            success: true,
            message: "Email sent",
            status: 200,
          };
          resolve(responseJson);
          return;
        }

        if (url.endsWith("/reset-password")) {
          let responseJson = {
            success: true,
            message:
              "Password updated successfully. You can login with your newly set password!",
            status: 200,
          };
          resolve(responseJson);
          return;
        }

        if (
          entity === "users" ||
          entity === "travelers" ||
          entity === "roles" ||
          entity === "organizations" ||
          entity === "organization-types" ||
          entity === "country" ||
          entity === "regions" ||
          entity === "divisions" ||
          entity === "currencies" ||
          entity === "languages" ||
          entity === "sic-codes" ||
          entity === "product-types" ||
          entity === "categories" ||
          entity === "products" ||
          entity === "products-order-status" ||
          entity === "external-account-types" ||
          entity === "product-packages" ||
          entity === "promo-codes" ||
          entity === "taxes" ||
          entity === "modules" ||
          entity === "permissions" ||
          entity === "fields" ||
          entity === "inputtypes" ||
          entity === "field-categories" ||
          entity === "lookup" ||
          entity === "lookup-options" ||
          entity === "patients"
        ) {
          if (url.indexOf("/" + entity + "/") !== -1 && opts.method === "GET") {
            console.log("test");
            let parts = url.split("/" + entity + "/");
            console.log(entity);
            console.log("[fake:fetch] GET idx :" + parts[1]);
            //let data = getItemsByPage(page, pageSize);
            console.log("[fake:fetch] GET request " + JSON.stringify(opts));
            resolve(getDataByEntity(entity)[parts[1] - 1]);
            //resolve(data);
            return;
          } else if (
            url.indexOf("/" + entity) !== -1 &&
            opts.method === "GET"
          ) {
            let request = {};
            try {
              request = JSON.parse(opts.body);
            } catch (e) {}
            let page =
              request && request.pagination && request.pagination.page
                ? request.pagination.page
                : 1;

            let pageSize =
              request && request.pagination && request.pagination.pageSize
                ? request.pagination.pageSize
                : recordCountPerPage;

            console.log("[fake:fetch] GET page :" + page);
            let data = getItemsByPage(page, pageSize, entity);
            console.log("[fake:fetch] GET request " + JSON.stringify(opts));
            console.log("[fake:fetch] GET response " + data);
            resolve({
              dataItems: data,
              pagination: {
                totalRecords: getDataByEntity(entity).length,
                currentPage: page,
                pageSize: pageSize,
              },
            });
            //resolve(data);
            return;
          }
          if (url.indexOf("/" + entity) !== -1 && opts.method === "POST") {
            console.log("[fake:fetch] POST" + JSON.stringify(opts));
            let item = JSON.parse(opts.body);
            item.id = getDataByEntity(entity).length + 1;
            getDataByEntity(entity).unshift(item);
            // items.push[opts.body];
            resolve([item]);
            return;
          }
          if (url.indexOf("/" + entity) !== -1 && opts.method === "PUT") {
            console.log("[fake:fetch] PUT" + JSON.stringify(opts.body));
            let tempItem = JSON.parse(opts.body);
            console.log(tempItem);
            if (tempItem.id && tempItem.id > 0) {
              getDataByEntity(entity)[tempItem.id - 1] = tempItem;
            } else {
              tempItem.id = getDataByEntity(entity).length + 1;
              getDataByEntity(entity).push(tempItem);
            }
            resolve([JSON.parse(opts.body)]);
            return;
          }
          if (url.indexOf("/" + entity + "/") && opts.method === "DELETE") {
            console.log("[fake:fetch] DELETE" + JSON.stringify(opts));

            let parts = url.split("/" + entity + "/");

            //if (parts.length > 1 && parts[1] && parts[1] > 0) {
            console.log("[fake:fetch] GET idx :" + parts[0]);
            getDataByEntity(entity).splice(parts[0] - 1, 1);
            //}

            resolve(parts[0]);
            return;
          }
          // authenticate

          // get users
          if (url.endsWith("/users") && opts.method === "GET") {
            // check for fake auth token in header and return users if valid, this security is implemented server side in a real application
            if (
              opts.headers &&
              opts.headers.Authorization === "Bearer fake-jwt-token"
            ) {
              resolve({
                ok: true,
                text: () => Promise.resolve(JSON.stringify(users)),
              });
            } else {
              // return 401 not authorised if token is null or invalid
              reject("Unauthorised");
            }

            return;
          }
        }

        if (url.endsWith("/validateToken")) {
          // get parameters from post request
          let params = JSON.parse(opts.body);

          // find if any user matches login credentials
          let filteredUsers = users.filter((user) => {
            return user.lastName === params.token;
          });

          if (filteredUsers.length) {
            // if login details are valid return user details and fake jwt token
            let user = filteredUsers[0];
            let responseJson = {
              id: user.id,
              username: user.username,
              firstName: user.firstName,
              lastName: user.lastName,
              role: user.lastName,
              token: user.lastName,
              result: true,
              redirect: "/" + user.username + "/",
            };
            resolve(responseJson);
          } else {
            // else return error
            reject("Username or password is incorrect");
          }

          return;
        }

        console.log("Calloing realfetch for " + url);
        //if(opts.method == "GET" || opts.method == "HEAD") delete opts.body
        realfetch(url, opts).then((response) => resolve(response));
      }, 500);
    });
  };
}
