import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Row, Col, Form, Input, Button, Spin, Select, Card, Layout } from "antd";
import SideBar from "../../layout/sidebar/sidebar.jsx";
import * as AdminActions from "../../../redux/actions/admin-actions";
import { notifyUser } from "../../../services/notification-service";
import options from "../../layout/sidebar/dashboard-options";
import UserHeader from "../../layout/header.jsx";
import Config from "../../../config";
import * as StudyActions from "../../../redux/actions/study-actions";

const { Option } = Select;
const { Sider, Header, Content } = Layout;

class EditUser extends Component {
    formRef = React.createRef();
    constructor(props) {
        super(props);
        this.state = {
            submitted: false,
            user: undefined,
            userId: 0,
            studyList: [],
        };
    }

    componentDidMount = () => {
        this.setState({submitted: true});
        this.initialze();
        this.props
            .getStudies()
            .then((resp) => {
                this.setState({
                    loading: false,
                    studyList: resp.data,
                });
            })
            .catch((ex) => {
                this.setState({ loading: false });
            });
    };

    async initialze() {
        let id = this.props.match.params.userId;
        await this.props.getUserById(id);
        this.setState({ userId: id });
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.currentLoggedInUser) {
            var user = nextProps.currentLoggedInUser;
            this.setState({ user: user });
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.currentLoggedInUser !== this.props.currentLoggedInUser) {
            var user = this.props.currentLoggedInUser;
            this.setState({ user: user }, () => {
                if (this.formRef.current) {
                    this.formRef.current.setFieldsValue({
                        firstName: user.firstName || "",
                        lastName: user.lastName || "",
                        email: user.email || "",
                        phone: user.phoneNumber || "",
                        prefix: user.phoneCode || "+1",
                        userRoles: (user.roles && user.roles.length > 0) ? user.roles[0].name : "",
                        studyIds: user.study.map(item => item.id) || [],
                    });
                }
            });
            this.setState({submitted: false});
        }
    }

    handleSubmit = async (values) => {
        let self = this;
        let data = {
            firstName: values.firstName,
            lastName: values.lastName,
            email: values.email,
            phone: values.phone,
            userRoles: values.userRoles,
            phoneCode: values.prefix,
            studyIds: [],
        };
        if (values.studyIds && values.studyIds.length) data.studyIds = values.studyIds;
        this.setState({ submitted: true });
        await self.props.editUser(this.state.userId, data).then((response) => {
            if (response.error && response.error !== "") {
                notifyUser(response.error.message, "error");
                self.setState({ submitted: false });
            } else {
                if (response.data && response.data !== "") {
                    notifyUser("User updated Successfully", "success");
                    self.props.history.push("/user-list/");
                    self.setState({ submitted: false });
                }
            }
        });
    };

    onClick = () => {
        this.props.history.push("/user-list/");
    };

    render() {
        const { submitted, user } = this.state;
        const userRoleOptions = Config.systemUserRoles.map((role) => (
            <Option key={role.name} value={role.name}>
                {role.name}
            </Option>
        ));

        const prefixSelector = (
            <Form.Item name="prefix" noStyle>
                <Select style={{ width: 90 }}>
                    <Option value="+91">+91</Option>
                    <Option value="+1">+1</Option>
                </Select>
            </Form.Item>
        );

        const formLayout = "vertical";
        return (
            <Layout>
                <Sider
                    breakpoint="xl"
                    collapsedWidth="0"
                    width="260px"
                    className="sidebar"
                    onBreakpoint={(broken) => {
                        console.log(broken);
                    }}
                    onCollapse={(collapsed, type) => {
                        console.log(collapsed, type);
                    }}
                >
                    <SideBar {...this.props} options={options.dashBoardOptions} />
                </Sider>
                <Layout className="site-layout">
                    <Header
                        className="site-layout-background"
                        style={{
                            background: "#f2f5f9",
                            zIndex: 100,
                            top: 0,
                            width: "100%",
                            padding: 0,
                        }}
                    >
                        <UserHeader
                            {...this.props}
                            isCollapsed={this.state.collapsed}
                            toggleSidebar={this.toggleSidebar}
                        />
                    </Header>
                    <Content className="site-layout-background">
                        {user && user.roles ? (
                            <Card title={"Edit User"} extra={<Button onClick={() => this.onClick()}>Back</Button>}>
                                <Spin spinning={submitted}>
                                    <Form
                                        layout={formLayout}
                                        onFinish={this.handleSubmit}
                                        className="add-user"
                                        ref={this.formRef}
                                    >
                                        <Row gutter={16}>
                                            <Col xs={24} sm={24} md={12}>
                                                <Form.Item
                                                    name="firstName"
                                                    label="First Name:"
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: "Please enter first name!",
                                                            whitespace: true,
                                                        },
                                                    ]}
                                                >
                                                    <Input placeholder="FirstName" autoComplete="off" />
                                                </Form.Item>
                                            </Col>
                                            <Col xs={24} sm={24} md={12}>
                                                <Form.Item
                                                    name="lastName"
                                                    label="Last Name:"
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: "Please enter your last name!",
                                                            whitespace: true,
                                                        },
                                                    ]}
                                                >
                                                    <Input placeholder="LastName" autoComplete="off" />
                                                </Form.Item>
                                            </Col>
                                            <Col xs={24} sm={24} md={12}>
                                                <Form.Item
                                                    name="email"
                                                    label="Email"
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: "Please enter email!",
                                                            whitespace: true,
                                                        },
                                                        {
                                                            type: "email",
                                                            message: "The input is not valid E-mail!",
                                                        },
                                                    ]}
                                                >
                                                    <Input placeholder="Email" autoComplete="off" />
                                                </Form.Item>
                                            </Col>
                                            <Col xs={24} sm={24} md={12}>
                                                <Form.Item
                                                    name="phone"
                                                    label="Phone Number"
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: "Please enter phone!",
                                                        },
                                                        () => ({
                                                            validator(rule, value) {
                                                                if (!value || new RegExp(/^[0-9\-\+]{9,15}$/).test(value)) {
                                                                    return Promise.resolve();
                                                                }
                                                                return Promise.reject("Empty spaces are not allowed!");
                                                            },
                                                        }),
                                                    ]}
                                                >
                                                    <Input addonBefore={prefixSelector} placeholder="Phone Number" autoComplete="off" />
                                                </Form.Item>
                                            </Col>
                                            <Col xs={24} sm={24} md={12}>
                                                <Form.Item
                                                    name="userRoles"
                                                    label="Select Roles"
                                                    hasFeedback
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: "Please select role!",
                                                        },
                                                    ]}
                                                >
                                                    <Select placeholder="Please select a role">{userRoleOptions}</Select>
                                                </Form.Item>
                                            </Col>
                                            {this.props.userDetail &&
                                                this.props.userDetail.roles.every((item) => {
                                                    return item.name === "SUPERADMIN";
                                                }) && (
                                                <Col xs={24} sm={24} md={12}>
                                                    <Form.Item
                                                        name="studyIds"
                                                        label="Select Studies"
                                                        hasFeedback
                                                        rules={[
                                                            {
                                                                required: true,
                                                                message: "Please select studies!",
                                                            },
                                                        ]}
                                                    >
                                                        <Select mode="multiple" placeholder="Please select a studies">
                                                            {this.state.studyList.map((item, i) => {
                                                                return (
                                                                    <Option key={item.study.id} value={item.study.id}>
                                                                        {item.study.title}
                                                                    </Option>
                                                                );
                                                            })}
                                                        </Select>
                                                    </Form.Item>
                                                </Col>
                                            )}
                                            <Col xs={24} sm={24} md={24} className="form-button-group">
                                                <Form.Item>
                                                    <Button type="primary" htmlType="submit">
                                                        Update
                                                    </Button>
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                    </Form>
                                </Spin>
                            </Card>
                        ) : (
                            ""
                        )}
                    </Content>
                </Layout>
            </Layout>
        );
    }
}

function mapStateToProps(state) {
    return { ...state.user, userDetail:state.auth.userProfile };
}
function mapDispatchToProps(dispatch) {
    return bindActionCreators({ ...AdminActions , ...StudyActions}, dispatch);
}
export default withRouter(
    connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(EditUser)
);
