import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import SideBar from "./../layout/sidebar/sidebar.jsx";
import DashboardRouter from "../../routes/dashboard-router";
import DashboardHeader from "./../layout/header.jsx";
import options from "./../layout/sidebar/dashboard-options";
import * as AuthActions from "../../redux/actions/auth-actions";
import { Layout } from "antd";
import * as AdminActions from "../../redux/actions/admin-actions";

const { Header, Sider, Content } = Layout;

class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.state = { collapsed: false };
  }

  toggleSidebar = () => {
    this.setState({
      collapsed: !this.state.collapsed,
    });
  };

  render() {
    const { url } = this.props.match;
    const { params } = this.props.match.params
    return (
      <Layout>
        <Sider
          breakpoint="xl"
          collapsedWidth="0"
          width="260px"
          className="sidebar"
          // trigger={null}
          onBreakpoint={(broken) => {
            console.log(broken);
          }}
          // collapsible
          onCollapse={(collapsed, type) => {
            console.log(collapsed, type);
          }}
        // collapsed={this.state.collapsed}
        >
          <SideBar {...this.props} options={options.dashBoardOptions} />
        </Sider>
        <Layout className="site-layout">
          <Header
            className="site-layout-background"
            style={{
              background: "#f2f5f9",
              zIndex: 100,
              top: 0,
              width: "100%",
              padding: 0,
            }}
          >
            <DashboardHeader url={url} params={params}
              {...this.props}
              isCollapsed={this.state.collapsed}
              toggleSidebar={this.toggleSidebar}
              isDashboard={true}
            />
          </Header>
          <Content className="site-layout-background" >
            <DashboardRouter url={url} params={params} />
          </Content>
        </Layout>
      </Layout>
    );
  }
}

function mapStateToProps(state) {
  return {
    userData: state.auth.userData,
  };
}
function mapDispatchToProps(dispatch) {
  return bindActionCreators({ ...AuthActions, ...AdminActions }, dispatch);
}
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(
    Dashboard
  )
);
