import React from "react";
import Icon from "@ant-design/icons";
import { HomeSvg } from "../../shared/svg/icons";
import { PatientSvg } from "../../shared/svg/patient";
import { AppointmentSvg } from "../../shared/svg/appointment";
import { ChatSvg } from "../../shared/svg/chat";
import { SettingsSvg } from "../../shared/svg/settings";
import { ProfileSvg } from "../../shared/svg/profile";
import { AdverseEventSvg} from "../../shared/svg/adverseevents";

const HomeIcon = (props) => <Icon component={HomeSvg} {...props} />;
const PatientIcon = (props) => <Icon component={PatientSvg} {...props} />;
const AppointmentIcon = (props) => <Icon component={AppointmentSvg} {...props} />;
const AdverseEventIcon = (props) => <Icon component={AdverseEventSvg} {...props} />;
const ChatIcon = (props) => <Icon component={ChatSvg} {...props} />;
const SettingsIcon = (props) => <Icon component={SettingsSvg} {...props} />;
const ProfileIcon = (props) => <Icon component={ProfileSvg} {...props} />;

const dashBoardOptions = [
  {
    key: "/dashboard/",
    label: "Dashboard",
    leftIcon: <HomeIcon />,
  },
  {
    key: "/user-list/",
    label: "Study Team",
    leftIcon: <HomeIcon />,
  },
  {
    key: "/patients/1",
    label: "Patient",
    leftIcon: <PatientIcon />,
  },
  {
    key: "/appointments/",
    label: "Appointment",
    leftIcon: <AppointmentIcon />,
  },
  {
    key: "/chatList/",
    label: "Chat",
    leftIcon: <ChatIcon />,
  },
  {
    key: "/adverse-events/",
    label: "Adverse Events",
    leftIcon: <SettingsIcon />,
  },
  {
    key: "/tickets/",
    label: "Tickets",
    leftIcon: <SettingsIcon />,
  },
  {
    key: "/profile/",
    label: "Profile",
    leftIcon: <ProfileIcon />,
  },
];

export default {
  dashBoardOptions,
};
