import API from "../api/adverse-events-api";
import { Types } from "../constants/adverse-event-types";


export function listAdverseEvents(patientId, studyId) {

  return async function (dispatch, _getState) {
    try {
      let resp = await API.listAdverseEvents(patientId, studyId);
      if (resp && resp.data && resp.data !== "no data found") {
        dispatch({
          type: Types.LIST_ADVERSE_EVENTS,
          payload: resp.data
        });
      }
      else{
        dispatch({
          type: Types.LIST_ADVERSE_EVENTS,
          payload: undefined
        });
      }
      return resp;
    } catch (e) {
      return e;;
    }
  };
}

export function reviewAdverseEvents(studyId, adverseEventId, patientId) {
  return async function (dispatch, _getState) {
    try {
      let data = {
        studyId: studyId,
        adverseEventId: adverseEventId,
        patientId: patientId
      };
      await API.reviewAdverseEvents(data)
        .then((response) => {
          if (response && response.data) {
            dispatch({
              type: Types.ADVERSE_EVENT_DETAILS,
              payload: response.data.quesAnsList
            });
            if (response.data.imageList) {
              var imageData = [];
              getImageData(response.data.imageList, imageData, dispatch);
            }
          } else if (response && (response.error || (response.data && response.data.length === 0))) {
            dispatch({
              type: Types.ADVERSE_EVENT_DETAILS_NOT_FOUND,
              payload: response
            });
          }
          return response;
        })
    } catch (e) {
      return { error: true };
    }
  };
}

const getImageData = async (studyImages, imageData, dispatch) => {
  let counter = 1;
  var i;
  for (i = 0; i < studyImages.length; i++) {
    let resp = await API.getAdverseEventImage(studyImages[i].filePath+"/"+studyImages[i].fileName);
    if (resp) {
      // let data = URL.createObjectURL(resp);
      imageData.push({ desc: "Adverse_image_"+counter, url: resp });
      counter++;
    }
  }
  dispatch({
    type: Types.GET_IMAGE_URL,
    payload: imageData,
  });
  return imageData;
}


export function listAllAdverseEvents(studyId) {

  return async function (dispatch, _getState) {
    try {
      let resp = await API.listAllAdverseEvents(studyId);
      if (resp && resp.data) {
        dispatch({
          type: Types.LIST_ALL_ADVERSE_EVENTS,
          payload: resp.data
        });
      }
      
      return resp;
    } catch (e) {
      return e;;
    }
  };
}
export function getAdverseEventLogs(studyId) {

  return async function (dispatch, _getState) {
    try {
      let resp = await API.getAdverseEventLogs(studyId);
      if (resp && resp.data && resp.data !=="No Data Found") {
        dispatch({
          type: Types.LIST_ADVERSE_EVENT_LOGS,
          payload: resp.data
        });
      }
      else{
        dispatch({
          type: Types.LIST_ADVERSE_EVENT_LOGS,
          payload: undefined
        });
      }
      
      return resp;
    } catch (e) {
      return e;;
    }
  };
}

export function listPIAdverseEvents(studyId) {

  return async function (dispatch, _getState) {
    try {
      let resp = await API.listPIAdverseEvents(studyId);
      if (resp && resp.data && resp.data !== "No Data Found") {
        dispatch({
          type: Types.LIST_PI_ADVERSE_EVENTS,
          payload: resp.data
        });
      }
      else{
        dispatch({
          type: Types.LIST_PI_ADVERSE_EVENTS,
          payload: undefined
        });
      }
      return resp;
    } catch (e) {
      return e;;
    }
  };
}


export function getPIUsers() {

  return async function (dispatch, _getState) {
    try {
      let resp = await API.getPIUsers();
      if (resp && resp.data) {
        dispatch({
          type: Types.PI_USERS,
          payload: resp.data
        });
      }
      if (resp && resp.data ==="") {
        dispatch({
          type: Types.PI_USERS,
          payload: "No data found"
        });
      }
      return resp;
    } catch (e) {
      return e;;
    }
  };
}
export function getSignOffInfo(patientId,eventId) {

  return async function (dispatch, _getState) {
    try {
      let resp = await API.getSignOffInfo(patientId,eventId);
      if (resp && resp.data) {
        dispatch({
          type: Types.SIGN_OFF_INFO,
          payload: resp.data
        });
      }
      if (resp && resp.data ==="") {
        dispatch({
          type: Types.SIGN_OFF_INFO,
          payload: "No data found"
        });
      }
      return resp;
    } catch (e) {
      return e;;
    }
  };
}

export function assignToPIForSignOff(data) {
  return async function (dispatch, getState) {
    try {
      let resp = await API.assignToPIForSignOff(data);
      return resp;
    } catch (e) {
      return { error: true };
    }
  };
}



export function getAdverseEventDetails(eventId) {

  return async function (dispatch, _getState) {
    try {
      let resp = await API.getAdverseEventDetails(eventId);
      if (resp && resp.data) {
        dispatch({
          type: Types.ADVERSE_EVENT_DETAILS,
          payload: resp.data
        });
      }
      return resp;
    } catch (e) {
      return e;;
    }
  };
}

export function addDoctorNotesOnAdverseEvents(data) {
  return async function (dispatch, _getState) {
    try {
      let resp = await API.addDoctorNotesOnAdverseEvents(data);
      return resp;
    } catch (e) {
      return { error: true };
    }
  };
}

export function getDoctorCommentsOnAdverseEvents(eventId) {
  return async function (dispatch, getState) {
    try {
      let resp = await API.getDoctorCommentsOnAdverseEvents(eventId);
      if (resp && resp.data) {
        dispatch({
          type: Types.GET_DOCTOR_NOTES,
          payload: resp.data
        });
      } else {
        dispatch({
          type: Types.DOCTOR_NOTES_NOT_FOUND,
          payload: "Doctor notes not found"
        });
      }
      return resp;
    } catch (e) {
      return { error: true };
    }
  };
}
export function getAdverseEventImage(imagePath) {
  return async function (dispatch, _getState) {
    try {
      var imageData = [];
      let resp = await API.getAdverseEventImage(imagePath);
      if (resp) {
        let data = URL.createObjectURL(resp);
        imageData.push({ url: data });
        dispatch({
          type: Types.GET_IMAGE_URL,
          payload: imageData
        });
      } else {
        dispatch({
          type: Types.IMAGE_URL_NOT_FOUND,
          payload: imageData
        });
      }
      return imageData;
    } catch (e) {
      return { error: true };
    }
  };
}