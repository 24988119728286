import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { notifyUser } from "../../services/notification-service";
import { Row, Col, Form, Input, Button, Typography, Spin } from "antd";
import Logo from "../../assets/images/logo.png";

import queryString from "query-string";
import {
  CheckCircleTwoTone,
  ExclamationCircleTwoTone
} from "@ant-design/icons";
import * as AuthActions from "../../redux/actions/auth-actions";
const validColor = "#52c41a",
  invalidColor = "#cb0019";
const validIcon = <CheckCircleTwoTone twoToneColor="#52c41a" />,
  invalidIcon = <ExclamationCircleTwoTone twoToneColor="#cb0019" />;
class ResetPassword extends Component {
  state = {
    token: "",
    npassword: "",
    rpassword: "",
    confirmPassword: "",
    message: "",
    link: "",
    success: false,
    submitted: false,
    validated: false,
    submitButtonText: "Update Password",
    password: {
      isValid: false,
      visibleHint: false,
      uppercase: {
        hasUppercase: false,
        iconColor: invalidColor,
        iconType: invalidIcon
      },
      lowercase: {
        hasLowercase: false,
        iconColor: invalidColor,
        iconType: invalidIcon
      },
      numeric: {
        hasNumer: false,
        iconColor: invalidColor,
        iconType: invalidIcon
      },
      specialCharacter: {
        hasSpecialChar: false,
        iconColor: invalidColor,
        iconType: invalidIcon
      },
      characters: {
        minLength: 8,
        iconColor: invalidColor,
        iconType: invalidIcon
      }
    }
  };

  async componentDidMount() {
    const queryStringParams = queryString.parse(this.props.location.search);
    var queryToken = (queryStringParams.token).toString();
    var qt = queryToken.replaceAll(" ", "+");
    this.setState({ token: qt });
  }

  matchPasswords() {
    const { npassword, rpassword } = this.state;
    let _password = this.state.password;
    if (npassword === "" && rpassword === "") {
      this.setState({ validated: false });
    } else {
      if (npassword !== rpassword) {
        this.setState({ validated: false });
      } else {
        _password.isValid = true;
        this.setState({ validated: true });
      }
      this.setState({ password: _password });
    }
  }

  handleChange = e => {
    const { name, value } = e.target;
    this.setState({ [name]: value }, function () {
      this.matchPasswords();
    });
  };

  onPasswordChange = e => {
    const { name, value } = e.target;
    this.setState({ [name]: value }, function () {
      // this.matchPasswords();
    });
    let _password = this.state.password;
    let regSpecialChars = /^[A-Za-z0-9 ]+$/,
      regNumbers = /\d/,
      regUppercase = /[A-Z]/,
      regLowercase = /[a-z]/,
      _value = e.target.value;

    if (_value !== "" && regSpecialChars.test(_value) === false) {
      _password.specialCharacter.hasSpecialChar = true;
      _password.specialCharacter.iconColor = validColor;
      _password.specialCharacter.iconType = validIcon;
    } else {
      _password.specialCharacter.hasSpecialChar = false;
      _password.specialCharacter.iconColor = invalidColor;
      _password.specialCharacter.iconType = invalidIcon;
    }

    if (regNumbers.test(_value) === true) {
      _password.numeric.hasNumer = true;
      _password.numeric.iconColor = validColor;
      _password.numeric.iconType = validIcon;
    } else {
      _password.numeric.hasNumer = false;
      _password.numeric.iconColor = invalidColor;
      _password.numeric.iconType = invalidIcon;
    }

    if (regUppercase.test(_value) === true) {
      _password.uppercase.hasUppercase = true;
      _password.uppercase.iconColor = validColor;
      _password.uppercase.iconType = validIcon;
    } else {
      _password.uppercase.hasUppercase = false;
      _password.uppercase.iconColor = invalidColor;
      _password.uppercase.iconType = invalidIcon;
    }

    if (regLowercase.test(_value) === true) {
      _password.lowercase.hasLowercase = true;
      _password.lowercase.iconColor = validColor;
      _password.lowercase.iconType = validIcon;
    } else {
      _password.lowercase.hasLowercase = false;
      _password.lowercase.iconColor = invalidColor;
      _password.lowercase.iconType = invalidIcon;
    }

    if (_value.length >= _password.characters.minLength) {
      _password.characters.iconColor = validColor;
      _password.characters.iconType = validIcon;
    } else {
      _password.characters.iconColor = invalidColor;
      _password.characters.iconType = invalidIcon;
    }

    if (
      _password.specialCharacter.hasSpecialChar === false ||
      _password.lowercase.hasLowercase === false ||
      _password.uppercase.hasUppercase === false ||
      _password.numeric.hasNumer === false ||
      _value.length < _password.characters.minLength
    ) {
      _password.isValid = false;
      this.setState({ message: "Password should contain special character, lowercase, uppercase, number and length should be atleast 8 characters" });
    } else {
      _password.isValid = true;
    }
    this.setState({ password: _password });
  };

  togglePasswordHint = status => {
    let _password = this.state.password;
    _password.visibleHint = status;
    this.setState({ password: _password });
  };

  handleSubmit = async () => {
    const { npassword, rpassword } = this.state;
    this.setState({ submitted: true, submitButtonText: "Please wait..." });
    if (this.state.password.isValid === false) {

      notifyUser(this.state.message, "error");
      this.setState({ submitButtonText: "Update Password", submitted: false });
      this.passwordInput.focus();
    } if (npassword !== rpassword) {
      this.setState({
        validated: false,
        submitted: false,
        submitButtonText: "Update Password"
      });
      notifyUser("Passwords did not match!", "error");
    } if (this.state.password.isValid === true) {
      try {
        let data = {
          password: this.state.npassword,
          link: this.state.token,
          confirmPassword: this.state.rpassword

        };
        this.props.resetPassword(data).then(response => {
          if (response.data.isSuccess === true) {
            this.setState(response);
            this.setState({
              npassword: "",
              rpassword: ""
            });
            this.props.history.push("./login");
            notifyUser(
              "Your password has been reset successfully. Please login to your account with new credentials.",
              "success"
            );
          } else {
            let errorStr = response.errors ? response.errors : "Unknown error";
            notifyUser(errorStr, "error");
            this.setState({
              submitted: false,
              validated: false,
              submitButtonText: "Update Password"
            });
          }
        });
      } catch (e) {
        if (e.response.data.error && e.response.data.error !== "") {
          notifyUser(e.response.data.error, "error");
        } else if (
          e.response.data.errors &&
          e.response.data.errors.length > 0
        ) {
          notifyUser(e.response.data.errors[0], "error");
        } else {
          notifyUser(
            "Token expired. Please use Forgot Password link again to generate a new password reset request.",
            "error"
          );
        }
        this.setState({
          submitted: false,
          validated: false,
          submitButtonText: "Update Password"
        });
      }
    } else {
      // notifyUser("Please enter the valid details!", "error");
      this.setState({
        submitted: false,
        validated: false,
        submitButtonText: "Update Password"
      });
    }
  };
  /* section skin */
  render() {
    return (
      <div className="login">
        <div className="login-in">
          <section className="wrap flexbox">
            <Row gutter={24} style={{ width: "100%" }}>
              <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                <Row className="logo" gutter={24}>
                  <Col xs={24}>
                    <img src={Logo} alt="UCSF" />
                  </Col>
                </Row>
                <Row gutter={24}>
                  <Col xs={24}>
                    <Typography.Title level={4} className="title">
                      Reset Password
                    </Typography.Title>
                  </Col>
                </Row>
                <hr className="title-hr" />
                <Spin spinning={this.state.submitted}>
                  <Form onFinish={this.handleSubmit} className="login-form">
                    <Form.Item

                      rules={[
                        {
                          required: true,
                          message: "Please enter New Password!",
                        }
                      ]}
                    >
                      <Input
                        name="npassword"
                        autoComplete="false"
                        type="password"
                        placeholder="Enter New Password"
                        onChange={this.onPasswordChange}
                        onFocus={() => this.togglePasswordHint(true)}
                        onBlur={() => this.togglePasswordHint(false)}
                        ref={input => {
                          this.passwordInput = input;
                        }}
                      />
                    </Form.Item>
                    <Form.Item

                      rules={[
                        {
                          required: true,
                          message: "Please confirm your Password!"
                        }
                      ]}
                    >
                      <Input
                        name="rpassword"
                        type="password"
                        placeholder="Re-enter New Password"
                        value={this.state.rpassword}
                        onChange={this.onPasswordChange}
                      />
                    </Form.Item>
                    <Form.Item>
                      <Button
                        value="large"
                        block
                        type="primary"
                        htmlType="submit"
                        className="login-form-button"
                      >
                        {this.state.submitButtonText}
                      </Button>
                    </Form.Item>
                  </Form>
                </Spin>
              </Col>
            </Row>
          </section>
        </div>
      </div>
    );
  }
}
function mapDispatchToProps(dispatch) {
  return bindActionCreators({ ...AuthActions }, dispatch);
}
export default withRouter(
  connect(null, mapDispatchToProps, null, { forwardRef: true })(ResetPassword)
);
