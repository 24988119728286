import { setCookie, removeCookie, getCookie } from "./cookies";

export function logOut() {
  // Get all keys from localStorage
  const allKeys = Object.keys(localStorage);

  // Terms to check for
  const termsToRemove = ["localErythma", "localExcoriation", "localInduration", "localLichenification", "localScaling"];

  // Iterate through keys and remove those containing the specified terms
  allKeys.forEach(key => {
      if (termsToRemove.some(term => key.includes(term))) {
          localStorage.removeItem(key);
      }
  });
  localStorage.removeItem("tokenData");
  localStorage.removeItem("studyId");
  localStorage.removeItem("studyTitle");
  localStorage.removeItem("roleName");
  localStorage.removeItem("localErythmaValueFull");
  localStorage.removeItem("localIndurationValueFull");
  localStorage.removeItem("localExcoriationValueFull");
  localStorage.removeItem("localLichenificationValueFull");
  localStorage.removeItem("localScalingValueFull");
  removeCookie("tokenData");
}


export function setUserData(user, expiration) {
	localStorage.setItem("tokenData", user.authToken);
	setCookie('tokenData', user.authToken, expiration);
}
