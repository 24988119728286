import API from "../api/notification-api";

export function getNotificationsList() {
  return async function(dispatch, getState) {
    try {
      let resp = await API.getNotificationsList();
      return resp;
    } catch (e) {
      return { error: true };
    }
  };
}

export function listUnReadNotifications() {
  return async function(dispatch, getState) {
    try {
      let resp = await API.listUnReadNotifications();
      return resp;
    } catch (e) {
      return { error: true };
    }
  };
}

export function updateNotificationStatus(id,isRead) {
  return async function(dispatch, getState) {
    try {
      let resp = await API.updateNotificationStatus(id,isRead);
      return resp;
    } catch (e) {
      return { error: true };
    }
  };
}

export function updateAll(isRead) {
  
  return async function(dispatch, getState) {
    try {
      let resp = await API.updateAll(isRead);
      return resp;
    } catch (e) {
      return { error: true };
    }
  };
}
