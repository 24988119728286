import { Types } from "../constants/patient-types";
const initialState = {
  quesAnsData: undefined,
  isLoggedIn: false,
  token: "",
  redirect: "",
  patientData: undefined,
  studyImages: undefined,
  approvedPatientsList: undefined,
  approvedPatientsListJansssen: undefined,
  easiScores:undefined,
  photoGalleryByWeek:undefined,
  photoGallery:undefined,
  photoGalleryByWeekError:undefined,
  patientSelfEstimation:undefined,
  patientSelfEstimationJanssen: undefined,
  updatePatientSelfEstimation:undefined,
  jansennPhotoGalleryByWeek:undefined,
  janssenImagesScore: undefined,
  janssenPhotoGalleryNew: undefined,
  janssenPhotoGalleryWeek: undefined,
  weekByParts: undefined,
  nihPhotoGallery: undefined,
  nihPhotoGalleryError: undefined,
  registerUserSuccess: undefined,
  registerUserFailure: undefined,
  bulkUserImport: undefined,
  uploadPhoto: undefined,
};
export default function patientReducer(state = initialState, action) {
  switch (action.type) {
    case Types.SCREENING_QUES_ANS_LISTING:
      return { ...state, quesAnsData: action.payload };
    case Types.PATIENT_SELF_ESTIMATION:
        return { ...state, patientSelfEstimation: action.payload };
    case Types.PATIENT_SELF_ESTIMATION_JANSSEN:
        return { ...state, patientSelfEstimationJanssen: action.payload };
    case Types.PHOTO_GALLERY_BY_WEEK:
      return { ...state, photoGalleryByWeek: action.payload };
    case Types.RESET_PHOTO_GALLERY_BY_WEEK:
      return { ...state, photoGalleryByWeek: [] };
    case Types.EASI_SCORES:
      return { ...state, easiScores: action.payload };
    case Types.PHOTO_GALLERY_BY_WEEK_ERROR:
      return { ...state, photoGalleryByWeekError: action.payload };
    case Types.NIH_PHOTO_GALLERY_ERROR:
      return { ...state, nihPhotoGalleryError: action.payload };
    case Types.NIH_PHOTO_GALLERY:
      return { ...state, nihPhotoGallery: action.payload };
    case Types.PHOTO_GALLERY:
      return { ...state, photoGallery: action.payload };
    case Types.GET_APPROVED_PATIENTS:
      return { ...state, approvedPatientsList: action.payload };
      case Types.GET_APPROVED_PATIENTS_JANSSEN:
      return { ...state, approvedPatientsListJansssen: action.payload };
    case Types.SCREENING_QUES_ANS_LISTING_ERROR:
      return { ...state, quesAnsListingError: action.payload };
    case Types.STUDY_IMAGES:
      return { ...state, studyImages: action.payload };
    case Types.PATIENT_DATA:
      return { ...state, patientData: action.payload };
      case Types.UPDATE_PATIENT_SELF_ESTIMATION:
      return { ...state, updatePatientSelfEstimation: action.payload };
    case Types.PATIENT_DATA_NOT_FOUND:
      return { ...state, patientDataError: action.payload };
    case Types.JANSENN_PHOTO_GALLERY_BY_WEEK:
      return { ...state, jansennPhotoGalleryByWeek: action.payload };
    case Types.JANSENN_IMAGES_SCORES:
      return { ...state, janssenImagesScore: action.payload };
    case Types.JANSSEN_PHOTO_GALLERY_NEW:
      return { ...state, janssenPhotoGalleryNew: action.payload };
    case Types.JANSENN_PHOTO_GALLERY_WEEK:
      return { ...state, janssenPhotoGalleryWeek: action.payload };
    case Types.WEEK_BY_PARTS:
      return { ...state, weekByParts: action.payload };
    case Types.REGISTER_USER_SUCCESS:
      return { ...state, registerUserSuccess: action.payload };
    case Types.REGISTER_USER_FAILURE:
      return { ...state, registerUserFailure: action.payload };
    case Types.BULK_USER_IMPORT:
      return { ...state, bulkUserImport: action.payload };
    case Types.UPLOAD_PHOTO:
      return { ...state, uploadPhoto: action.payload };
    default:
      return state;
  }
}
