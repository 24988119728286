import { Types } from "../constants/chat-types";

const initialState = {
    recentMessages: [],
};

export default function messageReducer(state = initialState, action) {
    switch (action.type) {
        case Types.RECENT_MESSAGES:
            return { ...state, recentMessages: action.payload };
        default:
            return state;
    }
}
