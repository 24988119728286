import AxiosAuth from "../../services/axios-service";
import AxiosAuth2 from "../../services/axios-service-auth";

const login = (data) => {
  return AxiosAuth.post("/api/auth/authenticate", data, undefined);
};

const refreshLogin = (data) => {
  return AxiosAuth.post("auth/refresh", data, undefined);
};

const forgotPassword = (data) => {
  
  return AxiosAuth.post("/api/password/forget-password", data,undefined);
};

const resetPassword = (data) => {
  return AxiosAuth.post("/api/password/reset-password", data, undefined);
};

const getLoggedInUser = () => {
  return AxiosAuth2.get("/api/admin/getLoggedInUser", undefined, undefined);
};

export default {
  login,
  refreshLogin,
  forgotPassword,
  resetPassword,
  getLoggedInUser
};
