import React, { Component } from "react";
import { withRouter, } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Row, Col, Form, Input, Button, Spin, Select, Card, Layout } from "antd";
import SideBar from "../layout/sidebar/sidebar.jsx";
import * as AdminActions from "../../redux/actions/admin-actions";
import * as AuthActions from "../../redux/actions/auth-actions";
import { notifyUser } from "../../services/notification-service";
import options from "../layout/sidebar/dashboard-options";
import UserHeader from "../layout/header.jsx";
// import Config from "../../config";

const { Option } = Select;
const { Sider, Header, Content } = Layout;

class UserProfile extends Component {
    constructor(props) {
        super(props);
        this.state = {
            submitted: false,
            user: undefined,
            userId:0
        };
    }

    componentDidMount() {
        this.initialze()
    }

    async initialze() {
      //  let id = this.props.match.params.userId;
        await this.props.getLoggedInUser();
        if(this.props.userProfile){
            this.setState({ user: this.props.userProfile })
            this.setState({ userId: this.props.userProfile.id })
        }
       // this.setState({userId:id})
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.userProfile) {
            var user = nextProps.userProfile
            this.setState({ user: user })
        }
    }

    handleSubmit = async (values) => {
        let self = this;
        let data = {
            firstName: values.firstName,
            lastName: values.lastName,
            email: values.email,
            phone: values.phone,
            userRoles: values.select,
            phoneCode:values.prefix
        };
        this.setState({ submitted: true });
        await self.props
            .updateUser(this.state.userId, data)
            .then((response) => {
                if (response.error && response.error !== "") {
                    notifyUser("Something went wrong", "error");
                    self.setState({ submitted: false });
                } else {
                    if (response.data && response.data !== "") {
                        notifyUser("User profile updated Successfully", "success");
                        self.setState({ submitted: false });
                        //self.props.history.push('/user-list');
                    }
                }
            });
    };

    onClick = () => {
        this.props.history.push("/user-list");
    }

    render() {
        const { submitted, user } = this.state;

        const prefixSelector = (
            <Form.Item name="prefix" noStyle initialValue = {user && user.phoneCode?user.phoneCode:"+1"}>
              <Select style={{ width: 90 }}>
                <Option value="+91">+91</Option>
                <Option value="+1">+1</Option>
              </Select>
            </Form.Item>
          );

        const formLayout = "vertical";
        return (
            <Layout>
                <Sider
                    breakpoint="xl"
                    collapsedWidth="0"
                    width="260px"
                    className="sidebar"
                    onBreakpoint={(broken) => {
                        console.log(broken);
                    }}
                    onCollapse={(collapsed, type) => {
                        console.log(collapsed, type);
                    }}
                >
                    <SideBar {...this.props} options={options.dashBoardOptions} />
                </Sider>
                <Layout className="site-layout">
                    <Header
                        className="site-layout-background"
                        style={{
                            background: "#f2f5f9",
                            zIndex: 100,
                            top: 0,
                            width: "100%",
                            padding: 0,
                        }}
                    >
                        <UserHeader
                            {...this.props}
                            isCollapsed={this.state.collapsed}
                            toggleSidebar={this.toggleSidebar}
                        />
                    </Header>
                    <Content className="site-layout-background" >
                        {user && user.roles ? <Card title={"User Profile"} >
                            <Spin spinning={submitted}>
                                <Form
                                    layout={formLayout}
                                    onFinish={this.handleSubmit}
                                    className="add-user"
                                >
                                    <Row gutter={16}>
                                        <Col xs={24} sm={24} md={12}>
                                            <Form.Item
                                                name="firstName"
                                                label="First Name:"
                                                initialValue=
                                                {user.firstName === null
                                                    ? ""
                                                    : user.firstName}

                                                rules={[
                                                    {
                                                        required: true,
                                                        message: "Please enter first name!",
                                                        whitespace:true
                                                    }
                                                ]}
                                            >
                                                <Input placeholder="FirstName" autoComplete="off" />
                                            </Form.Item>
                                        </Col>
                                        <Col xs={24} sm={24} md={12}>
                                            <Form.Item
                                                name="lastName"
                                                label="Last Name:"
                                                initialValue=
                                                {user.lastName === null
                                                    ? ""
                                                    : user.lastName}
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: "Please enter your last name!",
                                                        whitespace:true
                                                    }
                                                ]}
                                            >
                                                <Input placeholder="LastName" autoComplete="off" />
                                            </Form.Item>
                                        </Col>
                                        <Col xs={24} sm={24} md={12}>
                                            <Form.Item
                                                name="email"
                                                label="Email"
                                                initialValue=
                                                {user.email === null
                                                    ? ""
                                                    : user.email}
                                              
                                            >
                                                <Input disabled  />
                                            </Form.Item>
                                        </Col>
                                        <Col xs={24} sm={24} md={12}>
                                            <Form.Item
                                                name="phone"
                                                label="Phone Number"
                                                initialValue=
                                                {user.phoneNumber === null
                                                    ? ""
                                                    : user.phoneNumber}
                                               
                                            >
                                                <Input addonBefore = {prefixSelector} disabled placeholder = "Phone Number" autoComplete="off" />
                                            </Form.Item>
                                        </Col>
                                        <Col xs={24} sm={24} md={12}>
                                   
                                            <Form.Item
                                                name="select"
                                                label="User Role"
                                                initialValue={user.roles[0].name}
                                            >
                                                 <Input disabled />
                                            </Form.Item>
                                        </Col>
                                        <Col xs={24} sm={24} md={24} className="form-button-group">
                                            <Form.Item>
                                                <Button type="primary" htmlType="submit">Update</Button>
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                </Form>
                            </Spin>
                        </Card> : ""}
                    </Content>
                </Layout>
            </Layout>
        );
    }
}


function mapStateToProps(state) {
    return { userProfile:state.auth.userProfile};
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({ ...AdminActions,...AuthActions }, dispatch);
}
export default withRouter(
    connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(UserProfile)
);
