import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import { CalendarComponent } from '@syncfusion/ej2-react-calendars';
import * as AppointmentActions from "../../../redux/actions/appointment-actions";

class ShowCalendar extends Component {
  state = {appointments: []};
  componentDidMount = () => {
    this.props
    .getAppointments()
    .then((resp) => {
      this.setState({
        appointments: resp.data
      });
    })
    .catch((ex) => {
      this.setState({ loading: false });
    });
   };
  
  render() {
    const { appointments } = this.state
    let scheduleEvents = []
    if (appointments && appointments.length > 0) {
      for (let i = 0; i < appointments.length; i++) {
        let event = appointments[i];
          scheduleEvents.push({
          Id: event.id,
          Subject: event.title,
          StartTime: event.startTime,
          EndTime: event.endTime,
          Title: event.title,
          Description: event.description,
          Patient:event.patient
          // IsAllDay: !event.start.dateTime
        });
      }
    }
    return (
      <div className="calendar">
        {/* <Calendar /> */}

        <CalendarComponent id="calendar" dayHeaderFormat="Narrow" isMultiSelection={true}></CalendarComponent>
       { /* <ScheduleComponent height='650px' selectedDate={new Date()} timezone='PST' eventSettings={{ dataSource: scheduleEvents }}>
                    
                    <Inject services={[Day]} />
                  </ScheduleComponent> */}
        <p className="view-link-right">
          <a onClick={() => this.props.history.push("/appointments/")} className="ant-link">Full View</a>
        </p>
      </div>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({...AppointmentActions}, dispatch);
}
export default withRouter(
  connect(null, mapDispatchToProps, null, { forwardRef: true })(ShowCalendar)
);
