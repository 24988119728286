import React, { Component } from "react";
import { withRouter, Link } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import SideBar from "../layout/sidebar/sidebar.jsx";
import UserHeader from "../layout/header.jsx";
import options from "../layout/sidebar/dashboard-options.js";
import * as PatientActions from "../../redux/actions/patient-actions.js";
import * as StudyActions from "../../redux/actions/study-actions.js";
import * as PatientDiaryActions from "../../redux/actions/patient-diary-actions.js";
import "react-h5-audio-player/lib/styles.css";
import {
  Layout,
  Row,
  Col,
  Avatar,
  Typography,
  List,
  Spin,
  Card,
  Tabs,
  Modal,
  Carousel,
  Button,
  Form,
  Input,
  PageHeader,
  Upload,
  Tooltip,
  DatePicker
} from "antd";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import {
  PlusCircleFilled,
  MinusCircleFilled,
  LeftOutlined,
  RightOutlined,
  RotateLeftOutlined,
  RotateRightOutlined,
  UploadOutlined,
  DownloadOutlined,
  AuditOutlined,
  SisternodeOutlined
} from "@ant-design/icons";
import moment from "moment-timezone";
import { notifyUser } from "../../services/notification-service.js";
import firebase from "../../../config/firebase.js";
import Config from "../../config.js";
import { ChatSvg } from "../shared/svg/chatlg.jsx";
import { ServeySvg } from "../shared/svg/servey.jsx";
import { DataSvg } from "../shared/svg/dataicon.jsx";
import { DairySvg } from "../shared/svg/dairyicon.jsx";
import { AdverseEventSvg } from "../shared/svg/adverseevents.jsx";
import { PhotogallerySvg } from "../shared/svg/photogallery.jsx";
import Icon from "@ant-design/icons";
import { StudyDrugLogSvg } from "../shared/svg/studydruglog.jsx";
import { LabSvg } from "../shared/svg/labicon.jsx";
import errorImage from '../../assets/images/error.png';
import { loadCldr } from "@syncfusion/ej2-base";

const LabIcon = (props) => <Icon component={LabSvg} {...props} />;
const dateFormathh = "MM/DD/YYYY";
const { Header, Sider, Content } = Layout;
const ChatIcon = (props) => <Icon component={ChatSvg} {...props} />;
const ServeyIcon = (props) => <Icon component={ServeySvg} {...props} />;
const DataIcon = (props) => <Icon component={DataSvg} {...props} />;
const DairyIcon = (props) => <Icon component={DairySvg} {...props} />;
const AdverseEventIcon = (props) => (
  <Icon component={AdverseEventSvg} {...props} />
);
const PhotogalleryIcon = (props) => (
  <Icon component={PhotogallerySvg} {...props} />
);
const StudyDrugLogIcon = (props) => (
  <Icon component={StudyDrugLogSvg} {...props} />
);

const { TabPane } = Tabs;
const ButtonGroup = Button.Group;

class NewRashPhotoGallery extends Component {
  formRef = React.createRef();
  _isMounted = false;
  constructor(props) {
    super(props);
    this.state = {
      collapsed: false,
      patientData: undefined,
      loading: true,
      image: {},
      isModalVisible: false,
      submitted: false,
      selectedCol: "gallery",
      bodypartType: "HEAD",
      bodypartTypeByWeek: "FULL_BODY",
      partTypeNih: "FULL_BODY",
      partType: "Head Image",
      partTypeByWeek: "Full Body Image",
      month: 1,
      week: 1,
      monthnih: 1,
      photoGallery: [],
      photoGalleryByWeek: undefined,
      easiScores: undefined,
      selectedMonth: 1,
      selectedWeek: 1,
      selectedMonthNih: 1,
      section: "section1",
      sectionWeek: "section1",
      sectionPart: "Section 1 images",
      imageName: "rightElbowCrease",
      quatar: 1,
      paradeUser: undefined,
      currentImageIndex: 0,
      visibleModal: false,
      fileList: [],
      imageBase64: '',
      selectedFile: null,
      selectedPhotoDate: undefined,
      isDisable: false,
      selection: null,
      shape: 'box', // Can be 'box' or 'circle'
      shapes: [],
      imgWidth: 400, // Set the desired canvas width
      imgHeight: 300,
      selectedImage: null,
      isImageLoading: false,
      newRashTasks: "",
      selectItemId: "",
      newRashDate: "",
      dataLoading: false,
      uploadTimes: "",
      activeDateTab: null, // Track the active date tab
      activeTimeTab: null,
      allDataLoading: true,
      rotationAngles: (props.photoGalleryByWeek && props.photoGalleryByWeek.length) ? props.photoGalleryByWeek.map(() => 0) : [],
    };
    this.canvasRef = React.createRef();

  }

  async componentDidMount() {
    this._isMounted = true;
    try {
      let studyId = localStorage.getItem("studyId");
      this.setState({ sectionPart: "Right elbow crease"});
      let section = this.state.section
      let imageName = this.state.imageName;
      let quatar = this.state.quatar;
      this.props
        .getStudyById(studyId)
        .then((resp) => {
          this.setState({
            //  loading: false,
            data: resp.data,
          });
        })
        .catch((ex) => {
          this.setState({ loading: false });
        });
      let patientId = this.props.match.params.id;
      // await this.props.getPatientById(patientId);
    
      await this.props.getPatientByIdParade(patientId);
      this.setState({paradeUser: this.props.patientData});
      if (
        this.props.patientData &&
        this.props.patientData.length > 0 &&
        this.props.patientData[0]
      ) {
        //this.setState({ loading: false })
      }

      let newRashTAsk = await this.props.getNewRashTask(patientId, quatar);
      console.log('newRashTAsk',newRashTAsk);
      let date = "";
      let time = "";
      let newDate = "";
      if (newRashTAsk && newRashTAsk.length > 0) {
        this.setState({newRashTasks: newRashTAsk});

        date = newRashTAsk[0].completeDate;
        console.log('date',date);
        

        time = newRashTAsk[0].uploadTimes[0];
        console.log('time',time);
        newDate = moment(date, 'YYYY-MM-DD HH:mm:ss').format('YYYY-MM-DD')

        
      }
      
      

      
      this.setState({newRashDate: newDate, imageName: time,activeDateTab: newDate, activeTimeTab: time});
      

      let dataByQuater = await this.props.getNewRashImages(patientId, newDate+" "+time, quatar);
      
      if (dataByQuater && dataByQuater.length > 0) {
          this.setState({ photoGallery: this.props.photoGalleryByWeek});
      } else if (this.props.patientDataError) {
          notifyUser(this.props.patientDataError, "error");
          this.props.history.push("/patients/1");
      }
    } catch (error) {
        console.error('Error fetching data:', error);
    }
    this.setState({ loading: false, allDataLoading: false });
       
  }

  formatDate = (timestamp) => {
    const date = new Date(parseInt(timestamp));
    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();
    
    // Pad single-digit day/month with leading zero
    const formattedDay = day < 10 ? '0' + day : day;
    const formattedMonth = month < 10 ? '0' + month : month;

    return `${formattedMonth}-${formattedDay}-${year}`;
  };

  componentWillUnmount() {
    // Set a flag to indicate that the component is unmounted
    this._isMounted = false;
  }

  async componentDidUpdate(prevProps, prevState) {

    
    // Check if the relevant state or props have changed
    if (this.state.photoGallery !== prevState.photoGallery) {
      // Perform any actions or side-effects here
    }
    if (prevProps.photoGalleryByWeek !== this.props.photoGalleryByWeek) {
      this.setState({
        rotationAngles: this.props.photoGalleryByWeek && this.props.photoGalleryByWeek.length ? this.props.photoGalleryByWeek.map(() => 0) : [],
      });
    }
    if (
      this.state.isModalVisible !== prevState.isModalVisible ||
      this.state.currentImageIndex !== prevState.currentImageIndex
    ) {
      this.redrawCanvas();
    }
  }

  toggleSidebar = () => {
    this.setState({
      collapsed: !this.state.collapsed,
    });
  };

  goToAdverseEvents = (id) => {
    this.setState({ selectedCol: id });
    var patientId = this.props.match.params.id;
    this.props.history.push("/adverse-events/" + patientId);
  };

  goToDiary = () => {
    var patientId = this.props.match.params.id;
    this.props.history.push("/patient-diary/" + patientId);
  };

  goToPatientProgress = () => {
    var patientId = this.props.match.params.id;
    let studyId = localStorage.getItem("studyId");
    this.props.history.push("/patients/1/");
  };

  async getPhotosParade(quatarOhsu) {
    await this.setState({ quatar: quatarOhsu, selectedWeek: quatarOhsu, loading: true, allDataLoading: true });
    let studyId = localStorage.getItem("studyId");
    var patientId = this.props.match.params.id;
    let imageName = this.state.imageName;
    let quatar = this.state.quatar;
    let newRashTAsk = await this.props.getNewRashTask(patientId, quatarOhsu);
    console.log('newRashTAsk',newRashTAsk);
    this.setState({newRashTasks: newRashTAsk});

    let date = "";
      let time = "";
      let newDate = "";
      if (newRashTAsk && newRashTAsk.length > 0) {
        this.setState({newRashTasks: newRashTAsk});

        date = newRashTAsk[0].completeDate;
        console.log('date',date);
        

        time = newRashTAsk[0].uploadTimes[0];
        console.log('time',time);
        newDate = moment(date, 'YYYY-MM-DD HH:mm:ss').format('YYYY-MM-DD')

        
      }
      this.setState({newRashDate: newDate, imageName: time,activeDateTab: newDate, activeTimeTab: time});
      await this.props.getNewRashImages(patientId, newDate+" "+time, quatar);

    if (this.props.photoGalleryByWeek) {
      this.setState({ photoGallery: this.props.photoGalleryByWeek, loading: false, allDataLoading: false});
      // console.log(this.props.photoGalleryByWeek)
    }
  }

  goToActivityData = (id) => {
    this.setState({ selectedCol: id });
    var patientId = this.props.match.params.id;
    this.props.history.push("/activity-data/" + patientId);
  };

  goToEASiScoring = () => {
    var patientId = this.props.match.params.id;
    this.props.history.push("/EASI-head/" + patientId);
  };

  // showModal = (img, index) => {
  //   this.setState({
  //     isModalVisible: true,
  //     currentImageIndex: index,
  //     image: img,
  //   });
  // };

  showModal = async (img, index) => {
    console.log('img',img);
    
    
    await this.setState(
      { isModalVisible: true,
        currentImageIndex: index,
        image: img },
      () => {
        // Callback to draw the image after state is set
        if (this.canvasRef.current) {
          this.drawImageOnCanvas();
        }
      }
    );
  };

  handleCancel = () => {
    this.setState({ isModalVisible: false, image: {}, visibleModal: false, fileList: [], imageBase64: '', selectedFile: null});
    this.resetImage();
  };

  downloadImageOhsu = async () => {
    notifyUser("Download images in progress, it might take a little while so be patient..", "loading");
    let studyId = localStorage.getItem("studyId");
    var patientId = this.props.match.params.id;
    let imageName = this.state.imageName;
    let quatar = this.state.quatar;
    let rashDate = this.state.newRashDate;
    var response = await this.props.downloadNewRashImages("allImages", studyId, quatar, patientId, rashDate);
    var a = window.document.createElement('a');
    a.href = window.URL.createObjectURL(new Blob([response], { type: 'application/octet_stream' }));
    a.download = "images.zip";

    document.body.appendChild(a)
    a.click();
    document.body.removeChild(a)
    // let deleteZip = await this.props.deleteZipFile();
    notifyUser("Images downloaded successfully", "success");      
  };

  handleTimeChange = async (key, activeTimeTab) => {
    console.log('key',key, activeTimeTab);
    this.setState({imageName: key, activeTimeTab: activeTimeTab, dataLoading: true});
    
    var patientId = this.props.match.params.id;
    let quatar = this.state.quatar;
    
    let newRashDate = this.state.newRashDate;
    let newDate = moment(newRashDate).format('YYYY-MM-DD');
    this.setState({ loading: true });

    this.setState({ imageName: key });
    await this.props.getNewRashImages(patientId, newDate+" "+key, quatar);

    // let dataByQuater = await this.props.getNewRashImages(newDate, key, quatar, studyId, patientId);
    if (this.props.photoGalleryByWeek) {
      await this.setState({ photoGallery: this.props.photoGalleryByWeek });
      this.setState({ loading: false, dataLoading: false });
    }
     this.setState({loading: false, dataLoading: false })
  };

 rotateImage = (index, direction) => {
    this.setState((prevState) => {
      const newAngles = [...prevState.rotationAngles];
      newAngles[index] += direction === 'left' ? -90 : 90;
      return { rotationAngles: newAngles };
    });
  };

  photoUpload = () => {
    this.setState({visibleModal: true});
  }

  goToFlares = (id) => {
    this.setState({ selectedCol: id });
    var patientId = this.props.match.params.id;
    this.props.history.push("/flares/" + patientId);
  }

  onDateChange = (date, dateString) => {
    if (date !== null) {
      const formattedDate = moment(date._d).format('MM-DD-YYYY');
      this.setState({ selectedPhotoDate: formattedDate });   
    }
  };

  disabledFutureDates = (current) => {
    // Disable dates after today
    return current && current > Date.now();
  };

  goToPhotoGallery = () => {
    var patientId = this.props.match.params.id;
    this.props.history.push("/photo-gallery/" + patientId);
  }

  redrawCanvas = () => {
    const canvas = this.canvasRef.current;
    
    if (!canvas) {
      console.error("Canvas ref is not set or is null");
      return;
    }
    const ctx = canvas.getContext('2d');
    const img = new Image();
  
    // Set the crossOrigin property to 'anonymous' to avoid CORS issues
    img.crossOrigin = 'anonymous';
  
    img.src = this.props.photoGalleryByWeek && this.props.photoGalleryByWeek[this.state.currentImageIndex].url;
  
    img.onload = () => {
      // Clear the canvas before drawing the image
      ctx.clearRect(0, 0, canvas.width, canvas.height);
  
      // Set the canvas dimensions to match the image aspect ratio if needed
      const aspectRatio = img.width / img.height;
      const canvasHeight = canvas.width / aspectRatio;
      canvas.height = canvasHeight; // Adjust canvas height to match image
  
      // Draw the image on the canvas
      ctx.drawImage(img, 0, 0, canvas.width, canvas.height);
  
      // Draw any existing shapes on top of the image
      this.redrawShapes();
    };
  
    img.onerror = () => {
      console.error("Failed to load the image");
    };
  };
  

  redrawShapes = () => {
    this.setState({isImageLoading: true});
    if (!this.canvasRef.current) {
      console.error("Canvas ref is not set or is null");
      return;
    }
    const ctx = this.canvasRef.current.getContext('2d');
    const { shapes } = this.state;

    shapes.forEach(({ startX, startY, endX, endY, shapeType }) => {
      ctx.fillStyle = 'black';
      if (shapeType === 'box') {
        ctx.fillRect(startX, startY, endX - startX, endY - startY);
      } else if (shapeType === 'circle') {
        const radius = Math.sqrt(Math.pow(endX - startX, 2) + Math.pow(endY - startY, 2)) / 2;
        ctx.beginPath();
        ctx.arc((startX + endX) / 2, (startY + endY) / 2, radius, 0, 2 * Math.PI);
        ctx.fill();
      }
    });
    this.setState({isImageLoading: false});
  };
  
  resetImage = () => {
    this.setState({ shapes: [] }, () => {
      this.redrawCanvas();
    });
  };

  drawImageOnCanvas = () => {
    this.setState({isImageLoading: true});
    const { selectedImage } = this.state;
    if (!selectedImage) return;

    const canvas = this.canvasRef.current;
    if (!canvas) {
      console.error("Canvas ref is not set");
      return;
    }

    const ctx = canvas.getContext("2d");

    const image = new Image();
    
    image.src = selectedImage.url;
    image.onload = () => {
      canvas.width = image.width;
      canvas.height = image.height;
      ctx.clearRect(0, 0, canvas.width, canvas.height); // Clear previous drawings
      ctx.drawImage(image, 0, 0, canvas.width, canvas.height); // Draw the new image
    };
    this.setState({isImageLoading: false});

  };

  handleMouseDown = (e) => {
    const rect = this.canvasRef.current.getBoundingClientRect();
    this.setState({
      selection: {
        startX: e.clientX - rect.left,
        startY: e.clientY - rect.top,
        endX: null,
        endY: null,
      },
    });
  };

  handleMouseMove = (e) => {
    const { selection } = this.state;
    if (selection) {
      const rect = this.canvasRef.current.getBoundingClientRect();
      this.setState({
        selection: {
          ...selection,
          endX: e.clientX - rect.left,
          endY: e.clientY - rect.top,
        },
      });
      this.redrawCanvas();
    }
  };

  handleMouseUp = () => {
    const { selection, shape, shapes } = this.state;
    if (selection) {
      const newShape = { ...selection, shapeType: shape };
      this.setState(
        {
          shapes: [...shapes, newShape],
          selection: null,
        },
        () => this.redrawCanvas()
      );
    }
  };

  deleteImage = async () => {  
    this.setState({isImageLoading: true});
    let imageDetail = this.state.image;
    let image = imageDetail.title ;
    let userId = this.props.match.params.id;
    let quatar = this.state.quatar;
    let studyId = localStorage.getItem("studyId");
    let deleteImage = await this.props.deleteRashParadeImage(image, studyId, quatar, userId);
    
    if (deleteImage && deleteImage.data) {
      this.setState({isImageLoading: false});
      this.handleCancel();
      notifyUser("Image deleted successfully", "success");
      await this.handleTimeChange(this.state.imageName);
    } else {
      notifyUser("Something went wrong", "error");
      this.setState({isImageLoading: false});
    }
  }

  saveImage = async ()=>{
    this.setState({isImageLoading: true});
    
    const img = this.state.image;
    // let img = this.state.image;
  
    const patientId = this.props.match.params.id;
    const canvas = this.canvasRef.current;
  
    if (!canvas) {
      console.error("Canvas ref is not set");
      return;
    }
  
    // toBlob is asynchronous, use a promise to handle it
    const blobToBase64 = (blob) => {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onloadend = () => resolve(reader.result);
        reader.onerror = reject;
        reader.readAsDataURL(blob);
      });
    };
  
    // Wrapping canvas.toBlob in a Promise to handle asynchronously
    const getCanvasBlob = () => {
      return new Promise((resolve, reject) => {
        canvas.toBlob((blob) => {
          if (blob) {
            resolve(blob);
          } else {
            reject(new Error("Failed to convert canvas to blob"));
          }
        }, 'image/png', 1); // 'image/png' format and quality of 1 (highest)
      });
    };
  
    try {
      // Get the blob from the canvas
      const blob = await getCanvasBlob();
  
      // Convert the blob to base64
      const blobData = await blobToBase64(blob);
  
      // Clean the base64 string
      const cleanBase64 = blobData.replace(/^data:image\/png;base64,/, '');
      
      const savedImage = await this.props.saveRashImage(cleanBase64, patientId, img.description, img.imgName, img.title, this.state.quatar);
      if (savedImage && savedImage.data) {
      this.setState({isImageLoading: false});
        notifyUser(savedImage.data,"success");
        this.handleCancel();
        await this.handleTimeChange(this.state.imageName);
        // Reload the page after 3 seconds
        // setTimeout(() => {
        //   window.location.reload();
        // }, 2000);

      } else {
        this.setState({isImageLoading: false});
        notifyUser("Something went wrong", "error");
      }
  
    } catch (error) {
      console.error("Error processing the image:", error);
      this.setState({isImageLoading: false});
    }
  };
  handleDateTabChange = async (key, activeDateTab) => {
    console.log('key',key, activeDateTab);
    
    let newRashDates = this.state.newRashTasks;
    let rashDates = newRashDates.filter((item, index)=> item.completeDate.includes(key));
    console.log('rashDates',rashDates);
    
    let time = rashDates[0].uploadTimes[0];
    this.setState({imageName: time,activeDateTab: activeDateTab,
      activeTimeTab: time});
    console.log('time',time);
    
    let newDate = moment(key).format('YYYY-MM-DD');
    await this.setState({newRashDate: key, dataLoading: true, loading: true});
    let quatar = this.state.quatar;
    let patientId = this.props.match.params.id;
    await this.props.getNewRashImages(patientId, newDate+" "+time, quatar);
    if (this.props.photoGalleryByWeek) {
        this.setState({ photoGallery: this.props.photoGalleryByWeek, dataLoading: false, loading: false });
    } else if (this.props.patientDataError) {
        notifyUser(this.props.patientDataError, "error");
        this.props.history.push("/patients/1");
        this.setState({ dataLoading: false, loading: false });
    }
  }

  render() {
    const {
      activeDateTab, 
      activeTimeTab,
      imageName,
      isImageLoading,
      selectedImage,
      visibleModal,
      fileList,
      submitted,
      selectedCol,
      photoGallery,
      easiScores,
      selectedMonth,
      selectedMonthNih,
      loading,
      selectedWeek,
      paradeUser,
      currentImageIndex,
      isModalVisible,
      image,
      rotationAngles,
      monthnih,
      isDisable,
      imgWidth,
      imgHeight,
      newRashTasks,
      selectItemId,
      newRashDate,
      dataLoading,
      allDataLoading
    } = this.state;

    let monthnu = this.state.monthnih;

    const patientData = this.props.patientData && this.props.patientData[0];

    let quatar = this.state.quatar;


    const { photoGalleryByWeek} = this.props;

    

    const studyId = localStorage.getItem("studyId");

    const oldDate = this.props.patientData && this.props.patientData[0] && this.props.patientData[0].dateOfBirth
    const dateType = !isNaN(Date.parse(oldDate))
    let finalDate = "";
    if (oldDate && dateType == true) {
      finalDate = moment(oldDate).format("MM/DD/YYYY")
    } else if (oldDate && dateType == false) {
      finalDate = moment(new Date(Number(oldDate))).format("DD-MM-YYYY")
    } else {
      finalDate = "N/A"
    }

    // Patient Start End time
    const notificationEndDate = this.props.patientData && this.props.patientData[0] && this.props.patientData[0].notificationEndDate;
    const notificationTimezone = this.props.patientData && this.props.patientData[0] && this.props.patientData[0].notificationTimezone;
    const endDateInTimezone = moment.tz(notificationEndDate, notificationTimezone);
    let formattedTimeEnd = "";
    if (this.props.patientData && this.props.patientData[0] && this.props.patientData[0].notificationEndDate) {
      formattedTimeEnd = endDateInTimezone.format('hh:mm A');
    } else {
      formattedTimeEnd = "";
    }
    const formattedTimeEndData = formattedTimeEnd;

    const notificationStartDate = this.props.patientData && this.props.patientData[0] && this.props.patientData[0].notificationStartDate;
    const startDateInTimezone = moment.tz(notificationStartDate, notificationTimezone);
    let formattedTimeStart = "";
    if (this.props.patientData && this.props.patientData[0] && this.props.patientData[0].notificationStartDate) {
      formattedTimeStart = startDateInTimezone.format('hh:mm A');
    } else {
      formattedTimeStart = "";
    }
    const formattedTimeStartData = formattedTimeStart;
    // Patient Start End time end

    return (
      <Layout>
        <Sider
          breakpoint="xl"
          collapsedWidth="0"
          width="260px"
          className="sidebar"
          // trigger={null}
          onBreakpoint={(broken) => {
            console.log(broken);
          }}
          // collapsible
          onCollapse={(collapsed, type) => {
            console.log(collapsed, type);
          }}
        // collapsed={this.state.collapsed}
        >
          <SideBar {...this.props} options={options.dashBoardOptions} />
        </Sider>
        <Layout className="site-layout">
          <Header
            className="site-layout-background"
            style={{
              background: "#f2f5f9",
              zIndex: 100,
              top: 0,
              width: "100%",
              padding: 0,
            }}
          >
            <UserHeader
              {...this.props}
              isCollapsed={this.state.collapsed}
              toggleSidebar={this.toggleSidebar}
            />
          </Header>
          <Content className="site-layout-background">

            <PageHeader
              className="site-header-title"
              title={
                this.state.data && paradeUser && studyId === "3" ? (
                  <>
                    {this.state.data.title} - <span className="patid">{paradeUser[0].patientId}</span> <span className="patid">Q {paradeUser[0].rashQuarter}</span>
                  </>
                ) : (
                  this.state.data && this.state.data.title
                )
              }
              extra={[
                <Button onClick={() => this.goToPatientProgress()}>
                    Back
                  </Button> ,]}
            />
            <Row gutter={16}>
              

              <Col xs={24} sm={studyId == "3" ? 24 : 12} md={studyId == "3" ? 24 :18} xl={studyId == "3" ? 24 :18}>
        
                <div className="parade new">
                  <ButtonGroup className="horizontal-button-tabs flare-photos">
                    <Button
                    className={"inactive"}
                    onClick={() => this.goToPhotoGallery()}
                    >
                      Photogallery
                    </Button>
                    <Button
                    className={"active"}
                    // onClick={() => this.goToPhotoGallery()}
                    >
                      New Rash
                    </Button>
                </ButtonGroup>
                </div>
                {/* PHOTOGALLERY START */}
                <ButtonGroup className="horizontal-button-tabs spaces">
                    <Button
                    className={selectedWeek === 1 ? "active" : ""}
                    onClick={() => this.getPhotosParade(1)}
                    >
                    Quarter 1
                    </Button>  
                    <Button
                    className={selectedWeek === 2 ? "active" : ""}
                    onClick={() => this.getPhotosParade(2)}
                    >
                    Quarter 2
                    </Button>  
                    <Button
                    className={selectedWeek === 3 ? "active" : ""}
                    onClick={() => this.getPhotosParade(3)}
                    >
                    Quarter 3
                    </Button>  
                    <Button
                    className={selectedWeek === 4 ? "active" : ""}
                    onClick={() => this.getPhotosParade(4)}
                    >
                    Quarter 4
                    </Button>
                </ButtonGroup>
                {/* VERTICAL TAB FOR STUDY 3 */}
                <Spin spinning={allDataLoading}>
                { 
                    newRashTasks && newRashTasks.length > 0 ?
                      <Tabs 
                        defaultActiveKey="mainTab1" 
                        onChange={this.handleDateTabChange}
                        tabPosition="left"
                        className="vertical-data-tabs newrash"
                        activeKey={activeDateTab}
                      >
                        {/* Main Tabs Mapping - Each Tab corresponds to a completeDate */}
                        {newRashTasks && newRashTasks.length > 0 && newRashTasks.map((task, index) => (
                          <TabPane 
                            tab={moment(task.completeDate, 'YYYY-MM-DD HH:mm:ss').format('YYYY-MM-DD')} 
                            key={moment(task.completeDate, 'YYYY-MM-DD HH:mm:ss').format('YYYY-MM-DD')} 
                            className="tabpane newrash"
                          >
                            <Spin spinning={dataLoading}>
                              {/* {task.uploadTimes && task.uploadTimes.length > 0 && ( */}
                                <Tabs
                                  onChange={this.handleTimeChange}
                                  tabPosition="left"
                                  className="vertical-data-tabs new-tabs-rash"
                                  activeKey={activeTimeTab}
                                >
                                  {/* Sub Tabs Mapping - Each Tab corresponds to an uploadTime */}
                                  {task.uploadTimes.map((uploadTime) => (
                                    <TabPane 
                                      tab={uploadTime}
                                      key={uploadTime}
                                      type="card"
                                    >
                                      {/* <Spin spinning={loading}> */}
                                        {!this.state.loading && (
                                          <Card
                                            // title={`Quarter ${quatar} ${this.state.sectionPart}`}
                                            style={{ marginBottom: "8px", padding: "0 16px" }}
                                            className="week-section"
                                            extra={photoGallery && photoGallery.length > 0 ?
                                              <Button onClick={() => this.downloadImageOhsu()} className="leftSpace">Download All Images</Button>
                                              : ""
                                            }
                                          >
                                            <div className="images-container">
                                              <Carousel
                                                arrows={true}
                                                prevArrow={<LeftOutlined />}
                                                nextArrow={<RightOutlined />}
                                                className="images-slider"
                                                slidesPerRow={3}
                                                swipeToSlide={true}
                                                touchThreshold={20}
                                                focusOnSelect={true}
                                                centerMode={true}
                                                ref={(carousel) => (this.nav = carousel)}
                                                draggable={true}
                                                autoplay={false}
                                                vertical
                                                effect="fade"
                                              >
                                                {photoGallery && photoGallery.length > 0 && photoGallery.map((img, imgIndex) => (
                                                  <div className="carDiv" key={img.id}>
                                                    {img.imageStatus === "Deactive" ? (
                                                      <div className="noImage">
                                                        <img src={errorImage} alt="error" />
                                                      </div>
                                                    ) : (
                                                      <div style={{ height: '100%' }}>
                                                        <Typography.Text>{img.desc}</Typography.Text>
                                                        <img
                                                          src={img.url}
                                                          alt={img.desc}
                                                          onClick={() => this.showModal(img, imgIndex)}
                                                          loading="lazy"
                                                        />
                                                        <Modal
                                                          centered
                                                          width={450}
                                                          className="photo-zoom-popup"
                                                          title={<><span className="patid">{patientData.patientId}</span><p className="new-r-desc hi">{`${img.desc}`}</p></>}
                                                          visible={isModalVisible}
                                                          onCancel={this.handleCancel}
                                                          footer={null}
                                                        >
                                                          <p style={{marginBottom: "5px", marginTop: "-15px"}}>
                                                            To add a black box to a specific part of the image click and drag the mouse diagonally over the area you want to hide.
                                                          </p>
                                                          <div className="image-edit">
                                                            <Button onClick={this.resetImage}>Reset Image</Button>
                                                            <Button onClick={this.saveImage}>Save Image</Button>
                                                            <Button onClick={this.deleteImage}>Delete Image</Button>
                                                          </div>
                                                          <Spin spinning={isImageLoading} tip="Loading image...">
                                                            <TransformWrapper defaultScale={1} defaultPositionX={0} defaultPositionY={0} pan={{ disabled: true }}>
                                                              {({ zoomIn, zoomOut }) => (
                                                                <>
                                                                  <div style={{ textAlign: 'center' }}>
                                                                    <PlusCircleFilled style={{ marginRight: 8 }} size={30} onClick={zoomIn} />
                                                                    <MinusCircleFilled style={{ marginRight: 8 }} size={20} onClick={zoomOut} />
                                                                  </div>
                                                                  <TransformComponent>
                                                                    <div>
                                                                      <canvas
                                                                        ref={this.canvasRef}
                                                                        width={imgWidth}
                                                                        height={imgHeight}
                                                                        onMouseDown={this.handleMouseDown}
                                                                        onMouseMove={this.handleMouseMove}
                                                                        onMouseUp={this.handleMouseUp}
                                                                      />
                                                                      {/* <Typography.Text>{img.desc}</Typography.Text> */}
                                                                    </div>
                                                                  </TransformComponent>
                                                                </>
                                                              )}
                                                            </TransformWrapper>
                                                          </Spin>
                                                        </Modal>
                                                      </div>
                                                    )}
                                                  </div>
                                                ))}
                                              </Carousel>
                                            </div>
                                          </Card>
                                        )}
                                      {/* </Spin> */}
                                    </TabPane>
                                  ))}
                                </Tabs>
                              {/* )} */}
                            </Spin>
                          </TabPane>
                        ))}
                      </Tabs>
                    

                    : 
                    <div className="no-newrash" style={{textAlign: "center"}}>
                      <Card size="large" title={"No new rash photos for quarter " + this.state.quatar}/>
                    </div>
                  }
                </Spin>
                {
                  studyId == "3" ? "" :
                  <ul className="link-list-box" style={{ display: "block" }}>
                  <li>
                    <div
                      onClick={() => this.goToEASiScoring()}
                      className={
                        selectedCol === "survey"
                          ? "link-box selected-item-list"
                          : "link-box"
                      }
                    >
                      <ServeyIcon />
                      <h4>Surveys &amp; Scores</h4>
                    </div>
                  </li>
                  <li>
                    <div onClick={() => this.goToDiary()} className="link-box">
                      <DairyIcon />
                      <h4>Diary</h4>
                    </div>
                  </li>
                  <li>
                    <div
                      className={
                        selectedCol === "gallery"
                          ? "link-box selected-item-list"
                          : "link-box"
                      }
                    >
                      <PhotogalleryIcon />
                      <h4>Photo Gallery</h4>
                    </div>
                  </li>
                  <li>
                    <div
                      onClick={() => this.goToAdverseEvents()}
                      class="link-box"
                    >
                      <AdverseEventIcon />
                      <h4>Adverse Events</h4>
                    </div>
                  </li>
                  <li>
                    <div
                      onClick={() => this.goToActivityData()}
                      class="link-box"
                    >
                      <DataIcon />
                      <h4>Activity Data</h4>
                    </div>
                  </li>
                </ul>
                }
              </Col>
            </Row>
          </Content>
        </Layout>
      </Layout>
    );
  }
}

function mapStateToProps(state) {
  return {
    photoGalleryByWeek: state.patient.photoGalleryByWeek,
    nihPhotoGallery: state.patient.nihPhotoGallery,
    photoGallery: state.patient.photoGallery,
    patientData: state.patient.patientData,
    easiScores: state.patient.easiScores,
    janssenPhotoGalleryNew: state.patient.janssenPhotoGalleryNew,
    janssenPhotoGalleryWeek: state.patient.janssenPhotoGalleryWeek,
    uploadPhoto: state.patient.uploadPhoto,
  };
}
function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    { ...PatientActions, ...PatientDiaryActions, ...StudyActions },
    dispatch
  );
}
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(
    NewRashPhotoGallery
  )
);
