import AxiosAuth2 from "../../services/axios-service-auth";

const getPhysicalActivities = (patientId) => {
  return AxiosAuth2.get("/api/appleHealth/getAppleHeathInfo/"+patientId, undefined, undefined);
};

const getPhysicalActivitiesNih = (patientId, studyId) => {
  return AxiosAuth2.get("/api/appleHealth/getPatientAppleHeathInfo/"+patientId+"/"+studyId, undefined, undefined);
};

const getSleepMeasures = (patientId) => {
  return AxiosAuth2.get("/api/patientSleep/getSleepInfo/"+patientId, undefined, undefined);
};

const getSleepInfo = (patientId, studyId) => {
  return AxiosAuth2.get("/api/patientSleep/getPatientSleepInfo/"+patientId+"/"+studyId, undefined, undefined);
};

const getEnvironmentFactors = (data) => {
  return AxiosAuth2.post("/api/environmentFactor/getDateWiseEnvironmentFactors", data, undefined);
};

const getEnvironmentFactorsNew = (data) => {
  return AxiosAuth2.post("/api/environmentFactor/getDateWiseEnvironmentFactorData", data, undefined);
};

export default {
  getPhysicalActivities,
    getSleepMeasures,
    getEnvironmentFactors,
    getPhysicalActivitiesNih,
    getEnvironmentFactorsNew,
    getSleepInfo,
};
