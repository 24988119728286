import { Types } from "../constants/score-types";

const initialState = {
    easiHeadScores: undefined,
    easiTotal:undefined,
    globalAssessment:undefined,
    saveGlobalAssessment:undefined,
    pasiScoreNih: undefined
};

export default function easiScoreReducer(state = initialState, action) {
    switch (action.type) {
        case Types.SAVE_EASI_HEAD_SCORE:
            return { ...state, easiHeadScores: action.payload };
        case Types.GET_EASI_TOTAL:
            return { ...state, easiTotal: action.payload };
        case Types.GET_GLOBAL_ASSESSMENT:
            return { ...state, globalAssessment: action.payload };
        case Types.SAVE_GLOBAL_ASSESSMENT:
            return { ...state, saveGlobalAssessment: action.payload };
        case Types.PASI_SCORE_NIH:
            return { ...state, pasiScoreNih: action.payload };
        default:
            return state;
    }
}
