import React, { Component } from "react";
import {  Layout } from "antd";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { ScheduleComponent, ViewsDirective, ViewDirective, Day, Week, WorkWeek, Month, Agenda, Inject} from '@syncfusion/ej2-react-schedule';
import { L10n } from '@syncfusion/ej2-base';
import { DateTimePickerComponent } from '@syncfusion/ej2-react-calendars';
import { Spin } from "antd";
import { DropDownListComponent } from '@syncfusion/ej2-react-dropdowns';
import * as PatientActions from "../../redux/actions/patient-actions";
import * as AppointmentActions from "../../redux/actions/appointment-actions";
import { notifyUser } from "../../services/notification-service";
import SideBar from "../layout/sidebar/sidebar.jsx";
import options from "../layout/sidebar/dashboard-options";
import UserHeader from "../layout/header";
const { Sider, Header, Content } = Layout;
const currentDate = new Date();
L10n.load({
  'en-US': {
    'schedule': {
      'saveButton': 'Save',
      'cancelButton': 'Cancel'
    }
  }
})

class Appointment extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [], submitted: false, patient: [], appointments: [],delete:[]
    };
  }

  componentDidMount = () => {
    let self = this;
    let studyId = localStorage.getItem("studyId")
    self.props
      .getApprovedPatientsList(studyId)
      .then((resp) => {
        self.setState({
          data: resp.data
        });
      })
      .catch((ex) => {
        this.setState({ loading: false });
      });

      self.props
      .getAppointments()
      .then((resp) => {
        self.setState({
          appointments: resp.data
        });
      })
      .catch((ex) => {
        this.setState({ loading: false });
      });
  };

  change(args) {
    this.scheduleObj.selectedDate = args.value;
    this.scheduleObj.dataBind();
  }

  addEvent = async (values) => {
    let self = this;
    this.setState({ submitted: true });
    if(values.StartTime<currentDate){
      notifyUser("You Cannot schedule an appointment for past dates","error")
      self.setState({ submitted: false });
    }
   else if(values.StartTime>=values.EndTime){
        notifyUser("End time cannot be greater than or equal to Start time","error")
        self.setState({ submitted: false });
    }
    else{
    let data = {
      email: values.Patient,
      title: values.Title,
      description: values.Description,
      startDate:values.StartTime,
      endDate:values.EndTime,
      madeBy:"physician"
    }
    self.props
      .addEvent(data)
      .then((resp) => {
        self.setState({
          patient: resp.data
        });
        if (resp.error && resp.error !== "") {
          notifyUser(resp.error.message, "error");
         
          self.setState({ submitted: false });
        } else {
          if (resp.data && resp.data !== "") {
            notifyUser("Appointment scheduled  Successfully", "success");
            window.location.reload();
            self.setState({ submitted: false });
          }
        }
      })
      .catch((ex) => {
        this.setState({ loading: false });
      });
    }
  };

  editEvent = async (values) => {
    let self = this;
    if(values.StartTime<currentDate){
      notifyUser("You Cannot schedule an appointment for past dates","error")
      self.setState({ submitted: false });
    }
   else if(values.StartTime>=values.EndTime){
        notifyUser("End time cannot be greater than or equal to Start time","error")
        self.setState({ submitted: false });
    }
    else{
      this.setState({ submitted: true });
      let data = {
        email: values.Patient,
        title: values.Title,
        description: values.Description,
        startDate:values.StartTime,
        endDate: values.EndTime,
        guid: values.Guid,
        id: values.Id,
        madeBy:"physician"
      }
      self.props
        .addEvent(data)
        .then((resp) => {
          self.setState({
            patient: resp.data
          });
          if (resp.error && resp.error !== "") {
            notifyUser(resp.error.message, "error");
            self.setState({ submitted: false });
          } else {
            if (resp.data && resp.data !== "") {
              notifyUser("Appointment updated  Successfully", "success");
              window.location.reload();
              self.setState({ submitted: false });
            }
          }
        })
        .catch((ex) => {
          this.setState({ loading: false });
        });
    }
    
  };

  deleteEvent = async (values) => {
    let self = this;
    this.setState({ submitted: true });
    self.props
      .deleteEvent(values.Id)
      .then((resp) => {
        self.setState({
          delete: resp.data
        });
        if (resp.error && resp.error !== "") {
          notifyUser(resp.error.message, "error");
          self.setState({ submitted: false });
        } else {
          if (resp.data && resp.data !== "") {
            notifyUser("Appointment deleted  Successfully", "success");
            window.location.reload();
            self.setState({ submitted: false });
          }
        }
      })
      .catch((ex) => {
        this.setState({ loading: false });
      });
  };

  onActionBegin(args) {
    
    if (args.requestType === 'eventCreate') {
      let data = args.data instanceof Array ? args.data[0] : args.data;
      if(data.Patient === "" || data.Patient === null){
        args.cancel = true;
       notifyUser("Please select patient", "error");
      }
      else{
      args.cancel = true;
      this.addEvent(data);
      }
    }
    if (args.requestType === 'eventChange') {
      let data = args.data instanceof Array ? args.data[0] : args.data;
      args.cancel = true;
      this.editEvent(data);
    }
    if (args.requestType === 'eventRemove') {
      let data = args.data instanceof Array ? args.data[0] : args.data;
      args.cancel = true;
      this.deleteEvent(data);
    }
  }

  editorTemplate(props) {
    
    const { data } = this.state
    let patients = [];
    if (data && data.length > 0) {
     // patients = data.map((patient) => patient.email);
      patients = data.map((patient) => patient.email +"(" + patient.firstName+")");
    }
    return (
      props !== undefined ? <table className="custom-event-editor" style={{ width: '100%', cellpadding: '5' }}><tbody>
        <tr><td className="e-textlabel">Title</td><td colSpan={4}>
          <input id="Summary" required="true" className="e-field e-input" type="text" name="Title" style={{ width: '100%' }} />
        </td></tr>
        <tr><td className="e-textlabel">Select Patient</td><td colSpan={4}>
          <DropDownListComponent id="EventType" placeholder='Choose patient' required="true" name="Patient" className="e-field e-input" style={{ width: '100%' }} dataSource={patients} ></DropDownListComponent>
        </td></tr>
        <tr><td className="e-textlabel">StartTime</td><td colSpan={4}>
          <DateTimePickerComponent id="StartTime" name="StartTime" className="e-field"></DateTimePickerComponent>
        </td></tr>
        <tr><td className="e-textlabel">EndTime</td><td colSpan={4}>
          <DateTimePickerComponent id="EndTime" data-name="EndTime" className="e-field"></DateTimePickerComponent>
        </td></tr>
        <tr><td className="e-textlabel">Description</td><td colSpan={4}>
          <textarea id="Description" required="true" className="e-field e-input" name="Description" rows={3} cols={50} style={{ width: '100%', height: '60px !important', resize: 'vertical' }}></textarea>
        </td></tr></tbody></table> : <div></div>);
  }

  render() {
    const { submitted,appointments } = this.state
    let scheduleEvents = []
    if (appointments && appointments.length > 0) {
      for (let i = 0; i < appointments.length; i++) {
        let event = appointments[i];
       // let recurrenceRule = "";
       // if(event.frequency && event.frequency !== null && event.frequency !=="" && event.frequency ==="Daily"){
         // recurrenceRule = "FREQ=DAILY;INTERVAL=1;COUNT=184"
        //}
        //else if(event.frequency && event.frequency !== null && event.frequency !=="" && event.frequency ==="weekly"){
       //   recurrenceRule = "FREQ=WEEKLY;INTERVAL=1;COUNT=26"
       // }
          scheduleEvents.push({
          Id: event.id,
          Subject: event.title,
          StartTime: event.startTime,
          EndTime: event.endTime,
          Title: event.title,
          Description: event.description,
          Patient:event.patient
          // IsAllDay: true
        });
      }
    }
    return (
      <Layout>
        <Sider
          breakpoint="xl"
          collapsedWidth="0"
          width="260px"
          className="sidebar"
          onBreakpoint={(broken) => {
            console.log(broken);
          }}
          onCollapse={(collapsed, type) => {
            console.log(collapsed, type);
          }}
        >
          <SideBar {...this.props} options={options.dashBoardOptions} />
        </Sider>
        <Layout className="site-layout">
          <Header
            className="site-layout-background"
            style={{
              background: "#f2f5f9",
              zIndex: 100,
              top: 0,
              width: "100%",
              padding: 0,
            }}
          >
            <UserHeader
              {...this.props}
              isCollapsed={this.state.collapsed}
              toggleSidebar={this.toggleSidebar}
            />
          </Header>
          <Content className="site-layout-background" >
          <div className='schedule-control-section'>
            <div className='col-lg-9 control-section'>
              <div className='control-wrapper'>
                <Spin spinning={submitted}>
                  <ScheduleComponent selectedDate={new Date()} timezone='PST' eventSettings={{ dataSource: scheduleEvents }} editorTemplate={this.editorTemplate.bind(this)}showQuickInfo={false} actionBegin={this.onActionBegin.bind(this)}>
                    <ViewsDirective>
                      <ViewDirective option='Day' />
                      <ViewDirective option='Week' />
                      <ViewDirective option='WorkWeek' />
                      <ViewDirective option='Month' />
                      <ViewDirective option='Agenda' />
                    </ViewsDirective>
                    <Inject services={[Day, Week, WorkWeek, Month, Agenda]} />
                  </ScheduleComponent>
                </Spin>
              </div>
            </div>
           
          </div>
          </Content>
        </Layout>
      </Layout>);
  }
}

function mapStateToProps(state) {
  return { approvedPatients: state.patient.approvedPatientsList };
}
function mapDispatchToProps(dispatch) {
  return bindActionCreators({ ...AppointmentActions, ...PatientActions }, dispatch);
}
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(Appointment)
);
