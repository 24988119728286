import { Types } from "../constants/appointment-types";
const initialState = {
 appointment: undefined,
};
export default function appointmentReducer(state = initialState, action) {
  switch (action.type) {
    case Types.CREATE_APPOINTMENT:
      return { ...state, appointment: action.payload };
    
    default:
      return state;
  }
}
