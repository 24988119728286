import React from "react";
export const DoubleCheckSvg = () => (
    <svg
        version="1.1"
        id="Layer_1"
        x="0px"
        y="0px"
        width="1.1em"
        height="1.1em"
        fill="currentColor"
        viewBox="0 0 384 219.4"
    >
        <path fill="#73C053" d="M384,37.2c-0.9,0.8-1.9,1.6-2.8,2.5c-58.5,58.5-117,117-175.5,175.5c-1,1-1.8,2.4-2.7,3.7
	c-15.6-15.6-30.5-30.5-45.5-45.5c-15.3,15.3-30.2,30.2-45.9,46c-1.1-1.6-1.7-3-2.8-4C73.6,180.1,38.3,144.8,3.1,109.6
	c-1-1-2-1.8-3.1-2.6c0-0.2,0-0.5,0-0.8C8.7,97.6,17.4,89,26.1,80.4c1-0.9,2-1.9,3.1-2.9c27.7,27.7,55.2,55.2,82.5,82.5
	c5.4-5.4,10.6-10.5,15.8-15.7c-15-15-30.2-30.1-45.2-45c10.1-10.1,19.7-19.7,29.4-29.4c14.9,14.9,29.9,30,45,45.1
	C195.3,76.3,233.6,38,271.6,0c10.1,10.1,19.7,19.7,29.2,29.2C263,67,224.7,105.4,186.6,143.4c5.7,5.7,10.9,10.8,16.3,16.2
	c50.3-50.3,100.9-101,151.9-152c6.8,6.8,13.3,13.3,19.8,19.8c3.1,3.1,6.3,6,9.4,9C384,36.7,384,36.9,384,37.2z"/>
    </svg>
);
