import AxiosAuth2 from "../../services/axios-service-auth";

const getVoiceDiaries = (id) => {
  return AxiosAuth2.get("/api/userVoiceDiary/getUserVoiceDiaries/"+id, undefined, undefined);
};

const getFlareVoiceDiaries = (id, uploadDate) => {
  return AxiosAuth2.get("/api/flareTask/getUserFlareVoiceDiaries/"+id+"/"+uploadDate, undefined, undefined);
};

const getTextDiaries = (id) => {
  return AxiosAuth2.get("/api/userTextDiary/getUserTextDiaries/"+id, undefined, undefined);
};

const getPhotoDiaries = (id) => {
  return AxiosAuth2.get("/api/userPhotoDiary/getUserPhotoDiaries?patientId="+id, undefined, undefined);
};

const addDoctorNotesOnVoiceDiary = (data) => {
  return AxiosAuth2.post("/api/doctorNote/saveDoctorNote", data, undefined);
};
const addDoctorNotesOnTextDiary = (data) => {
  return AxiosAuth2.post("/api/doctorTextNote/saveDoctorTextNote", data, undefined);
};
const addDoctorNotesOnPhotoDiary = (data) => {
  return AxiosAuth2.post("/api/doctorPhotoDiaryNotes/saveDoctorPhotoDiaryNotes", data, undefined);
};
const getDoctorCommentsOnVoiceDiary = (voiceDiaryId) => {
  return AxiosAuth2.get("/api/doctorNote/getDoctorNoteByVoiceDiaryId/"+voiceDiaryId, undefined, undefined);
};

const getDoctorCommentsOnTextDiary = (textDiaryId) => {
  return AxiosAuth2.get("/api/doctorTextNote/getDoctorTextNoteByTextDiaryId/"+textDiaryId, undefined, undefined);
};
const getDoctorCommentsOnPhotoDiary = (photoDiaryId) => {
  return AxiosAuth2.get("/api/doctorPhotoDiaryNotes/getDoctorPhotoDiaryNotes/"+photoDiaryId, undefined, undefined);
};
const getPhotoDiaryImage = (data) => {
  return AxiosAuth2.get("/api/userPhotoDiary/getPhoto?imagePath="+data, undefined, undefined, false, true);
};

const getAllFlareImages = (userId, uploadDate) => {
  return AxiosAuth2.get("/api/flareTask/getAllFlareImages/?userId="+userId+"&uploadDate="+uploadDate, undefined, undefined);
};

export default {
    getVoiceDiaries,
    getTextDiaries,
    addDoctorNotesOnVoiceDiary,
    addDoctorNotesOnTextDiary,
    getDoctorCommentsOnVoiceDiary,
    getDoctorCommentsOnTextDiary,
    getPhotoDiaries,
    getPhotoDiaryImage,
    addDoctorNotesOnPhotoDiary,
    getDoctorCommentsOnPhotoDiary,
    getFlareVoiceDiaries,
    getAllFlareImages
};
