import AxiosLib from "./axios-service-lib";
import { initFakeBackend } from "./fake-backend";
import Config from "../config";
import {  removeCookie } from "./cookies";

initFakeBackend();
const _request = async (method, url, data, token, hitCount) => {
  if (hitCount > 2) {
    window.location.href = "/login";
  }
  let options = {
    method: method,
    url: url,
    responseType: "json",
  };
  if (data && method === "GET") {
    options.params = data;
  } else if (data) {
    options.data = data;
    options.headers = {
      "Content-Type": "application/json;charset=UTF-8",
    };
  }

  return new Promise(async (resolve, reject) => {
    AxiosLib.request(options, token)
      .then(async (response) => {
        let data = response.data;
        if (typeof data != "object") data = JSON.parse(data);
        resolve(data);
      })
      .catch((error) => {
        if (error.response && error.response.status === 401) {
          refreshToken(method, url, data, hitCount, reject);
        } /* else {
          if(window.location.pathname.indexOf('404') <= -1){
            window.location = "/404";
          }
        } */
        reject(error);
      });
  });
};

const refreshToken = async (method, url, data, hitCount, reject) => {
  var tokenData = JSON.parse(localStorage.getItem("tokenData"));
  if (tokenData && tokenData !== null) {
    var objTokenData = {
      accessToken: tokenData.token,
      refreshToken: tokenData.refreshToken,
    };
    let optionsToken = {
      method: "POST",
      url: Config.API + "/api/refresh-token",
      responseType: "json",
      data: objTokenData,
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
      },
    };

    AxiosLib.request(optionsToken, undefined)
      .then(async (responseToken) => {
        
        if (responseToken.status === 200) {
          var tokenResponseData = responseToken.data;
          var expiry = new Date();
          expiry.setSeconds(
            expiry.getSeconds() + tokenResponseData.accessToken.expiresIn
          );
          var newTokenData = {
            token_expiry: expiry,
            token: tokenResponseData.accessToken.token,
            refreshToken: tokenResponseData.refreshToken,
          };
          localStorage.setItem("tokenData", JSON.stringify(newTokenData));
          _request(
            method,
            url,
            data,
            tokenResponseData.accessToken.token,
            hitCount + 1
          );
        }
      })
      .catch((error) => {
        // Get all keys from localStorage
        const allKeys = Object.keys(localStorage);

        // Terms to check for
        const termsToRemove = ["localErythma", "localExcoriation", "localInduration", "localLichenification", "localScaling"];

        // Iterate through keys and remove those containing the specified terms
        allKeys.forEach(key => {
            if (termsToRemove.some(term => key.includes(term))) {
                localStorage.removeItem(key);
            }
        });
        localStorage.removeItem("tokenData");
        localStorage.removeItem("studyId");
        localStorage.removeItem("studyTitle");
        localStorage.removeItem("roleName");
        localStorage.removeItem("localErythmaValueFull");
        localStorage.removeItem("localIndurationValueFull");
        localStorage.removeItem("localExcoriationValueFull");
        localStorage.removeItem("localLichenificationValueFull");
        localStorage.removeItem("localScalingValueFull");
        removeCookie("tokenData");
        window.location.href = "/login";
        reject(error);
      });
  }
};

const DataProvider = {
  get(url, data, token) {
    return _request("GET", url, data, token, 1);
  },
  post(url, data, token) {
    return _request("POST", url, data, token, 1);
  },
  delete(url, token) {
    return _request("DELETE", url, undefined, token, 1);
  },
  put(url, data, token) {
    return _request("PUT", url, data, token, 1);
  },
  patch(url, data, token) {
    return _request("PATCH", url, data, token, 1);
  },
};

const DataAccessService = {
  get(url, data, token) {
    const requestOptions = {
      method: "GET",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(data),
    };
    return getFetchMethod(url) === "fake"
      ? fetch(`${Config.API}` + url, requestOptions)
      : DataProvider.get(url, data, token);
  },
  post(url, data, token) {
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(data),
    };
    return getFetchMethod(url) === "fake"
      ? fetch(`${Config.API}` + url, requestOptions)
      : DataProvider.post(url, data, token);
  },
  delete(url, data, token) {
    const requestOptions = {
      method: "DELETE",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(data),
    };
    return getFetchMethod(url) === "fake"
      ? fetch(`${Config.API}` + url, requestOptions)
      : DataProvider.delete(url, undefined, token);
  },
  put(url, data, token) {
    const requestOptions = {
      method: "PUT",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(data),
    };
    return getFetchMethod(url) === "fake"
      ? fetch(`${Config.API}` + url, requestOptions)
      : DataProvider.put(url, data, token);
  },
  patch(url, data, token) {
    const requestOptions = {
      method: "PATCH",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(data),
    };
    return getFetchMethod(url) === "fake"
      ? fetch(`${Config.API}` + url, requestOptions)
      : DataProvider.patch(url, data, token);
  },
};

function getFetchMethod(url) {
 
  if (
    url.indexOf("/api/auth/authenticate") > -1 ||
    url.indexOf("/api/password/forget-password") !== -1 ||
    url.indexOf("/api/password") !== -1 ||
    url.indexOf("/api/category") !== -1 ||
    url.indexOf("/api/SICCode") !== -1 ||
    url.indexOf("/api/producttype") !== -1 ||
    url.indexOf("/api/UpdateSequence") !== -1 ||
    url.indexOf("/api/division") !== -1 ||
    url.indexOf("/api/fieldlookup") !== -1 ||
    url.indexOf("/api/FieldLookUpValues") !== -1 ||
    url.indexOf("/api/ActiveFieldTypes") !== -1 ||
    url.indexOf("/api/UpdateSequenceNo") !== -1 ||
    url.indexOf("/api/ActiveCountries") !== -1 ||
    url.indexOf("/api/productpackage") !== -1 ||
    url.indexOf("/api/Products") !== -1 ||
    url.indexOf("/api/EntityFields") !== -1 ||
    url.indexOf("/api/EntityField") !== -1 ||
    url.indexOf("/api/FieldHelperData") !== -1 ||
    url.indexOf("/api/Schema") !== -1 ||
    url.indexOf("/api/language") !== -1 ||
    url.indexOf("/api/tax") !== -1 ||
    url.indexOf("/api/FieldCategory") !== -1 ||
    url.indexOf("/api/FieldHelperData") !== -1 ||
    url.indexOf("/api/Schema") !== -1 ||
    url.indexOf("/api/AccountType") !== -1 ||
    url.indexOf("/api/ActiveAccountType") !== -1 ||
    url.indexOf("/api/PromoOffer") !== -1 ||
    url.indexOf("/api/Translation") !== -1 ||
    url.indexOf("/api/Queue") !== -1 ||
    url.indexOf("/api/PaymentType") !== -1 ||
    url.indexOf("/api/Certificate") !== -1 ||
    url.indexOf("/api/Translation") !== -1 ||
    url.indexOf("/api/ProductSchemaDetail") !== -1 ||
    url.indexOf("/api/Page") !== -1 ||
    url.indexOf("/api/PageLabel") !== -1 ||
    url.indexOf("/api/GetByPageId") !== -1
  ) {
    return "real";
  } else {
    return "fake";
  }
}

export default DataAccessService;
