export const Types = {
  LOG_OUT: "LOG_OUT",
  USER_LISTING: "USER_LISTING",
  USER_LISTING_ERROR: "USER_LISTING_ERROR",
  FORGOT_PASSWORD_RESPONSE: "FORGOT_PASSWORD_RESPONSE",
  SAVE_ALREADY_VERIFIED: "SAVE_ALREADY_VERIFIED",
  TOKEN_EXPIRY_DATE:"TOKEN_EXPIRY_DATE",
  FLUSH_TOKEN_DATA:"FLUSH_TOKEN_DATA",
  UPDATE_MY_ACCOUNT:"UPDATE_MY_ACCOUNT",
  SAVE_LOGO_URL:"SAVE_LOGO_URL",
  UPDATE_NOTIFICATION_COUNT:"UPDATE_NOTIFICATION_COUNT",
  DEC_NOTIFICATION_COUNT : "DEC_NOTIFICATION_COUNT",
  INC_NOTIFICATION_COUNT: "INC_NOTIFICATION_COUNT",
  GET_LOGGED_IN_USER:"GET_LOGGED_IN_USER"
};
