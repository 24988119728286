import  firebase from "firebase/app";

// Add the Firebase services that you want to use
// We only want to use Firebase Auth here
import "firebase/firestore";
import "firebase/auth"
import "firebase/storage";

var firebaseConfig = {
    apiKey: "AIzaSyBgfjEIbjdoJANsFXpJhExMI8aL4f-tsow",
    authDomain: "skin-tracker-3815b.firebaseapp.com",
    projectId: "skin-tracker-3815b",
    storageBucket: "skin-tracker-3815b.appspot.com",
    databaseURL: "https://skin-tracker-3815b.firebaseio.com",
    messagingSenderId: "519349362661",
    appId: "1:519349362661:ios:8c6beb0cb4d02fdb1dd90c"
  };

// Initialize Firebase
firebase.initializeApp(firebaseConfig);

// Finally, export it to use it throughout your app
export default firebase;
