import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import SideBar from "../../layout/sidebar/sidebar.jsx";
import { Table, Card, Button, Layout } from "antd";
import options from "../../layout/sidebar/dashboard-options";
import UserHeader from "../../layout/header.jsx";
import * as PatientActions from "../../../redux/actions/patient-actions";

const { Sider, Header, Content } = Layout;

class ActivityLogs extends Component {
  state = {};
  componentDidMount = () => {
    
    let id = this.props.match.params.id;
    this.props
      .getAuditLogs(id)
      .then((resp) => {
        this.setState({
          loading: false,
          data: resp.data
        });
      })
      .catch((ex) => {
        this.setState({ loading: false });
      });
  };

  onClick = () => {
    this.props.history.push("/user-list");
  }

  render() {
    const columns = [
      {
        title: 'Date',
        dataIndex: 'modifiedDate',
        key: 'date',
        width: '30%',
      },
      {
        title: 'Updated By',
        dataIndex: 'modifiedBy',
        key: 'updatedBy',
        width: '20%',
      },
      {
        title: 'Activity',
        render: (_,record) => {
          let _allContent = [];
          if( record.changedContent && record.changedContent.firstName){
            _allContent.push("FirstName: " +record.changedContent.firstName)
          }
          if( record.changedContent && record.changedContent.lastName){
            _allContent.push("LastName: " +record.changedContent.lastName)
          }
          if(record.changedContent && record.changedContent.phone){
            _allContent.push("Phone: " +record.changedContent.phone)
          }
          if(record.changedContent && record.changedContent.phoneCode){
            _allContent.push("phoneCode: " +record.changedContent.phoneCode)
          }
          if(record.changedContent && record.changedContent.email){
            _allContent.push("Email: " +record.changedContent.email)
          }
         if(record.changedContent && record.changedContent.roles){
          _allContent.push("Role: " +record.changedContent.roles)
          }
          return _allContent.join(", ");
        }
      }
    ];
    return (
      <Layout>
        <Sider
          breakpoint="xl"
          collapsedWidth="0"
          width="260px"
          className="sidebar"
          onBreakpoint={(broken) => {
            console.log(broken);
          }}
          onCollapse={(collapsed, type) => {
            console.log(collapsed, type);
          }}
        >
          <SideBar {...this.props} options={options.dashBoardOptions} />
        </Sider>
        <Layout className="site-layout">
          <Header
            className="site-layout-background"
            style={{
              background: "#f2f5f9",
              zIndex: 100,
              top: 0,
              width: "100%",
              padding: 0,
            }}
          >
            <UserHeader
              {...this.props}
              isCollapsed={this.state.collapsed}
              toggleSidebar={this.toggleSidebar}
            />
          </Header>
          <Content className="site-layout-background" >
            <Card title={"Activity Logs"} extra={<Button onClick={() => this.onClick()}>Back</Button>}>
              <Table dataSource={this.state.data} columns={columns} />
            </Card>
          </Content>
        </Layout>
      </Layout>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({...PatientActions}, dispatch);
}
export default withRouter(
  connect(null, mapDispatchToProps, null, { forwardRef: true })(ActivityLogs)
);
