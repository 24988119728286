import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Layout } from "antd";
import 'antd/dist/antd.css';
import { Table, Input, Button, Space, Card, Spin, Switch } from 'antd';
import Highlighter from 'react-highlight-words';
import { SearchOutlined } from '@ant-design/icons';
import * as PatientActions from "../../redux/actions/patient-actions";
import * as AdminActtions from "../../redux/actions/admin-actions";
import { notifyUser } from "../../services/notification-service"


class DisQualifiedPatients extends Component {
  state = {
    searchText: '',
    searchedColumn: '',
    loading: true, 
    data: [],
    type: "",
  };

  componentDidMount = async () => {
    this.setState({data: []});
    let studyId = localStorage.getItem("studyId")
    // this.props
    //   .getInActivePatientsList(studyId)
    //   .then((resp) => {
    //     this.setState({
    //       loading: false,
    //       data: resp.data
    //     });
    //   })
    //   .catch((ex) => {
    //     this.setState({ loading: false });
    //   });

    if (studyId == "1") {
      if (this.props.inActivePatients) {
        this.setState({
          data: this.props.inActivePatients,
          loading: false
        });
      }
    } else if (studyId == "2" || studyId == "4") {
      await this.props
      .getInActivePatientsList(studyId)
      .then((resp) => {
        this.setState({
          data: resp.data,
          loading: false,
        });
      })
      .catch((ex) => {
        this.setState({ loading: false });
      });
    } else {
      await this.props
      .getInactivePatientsForOhsu(studyId)
      .then((resp) => {
        this.setState({
          data: resp.data,
          loading: false,
        });
      })
      .catch((ex) => {
        this.setState({ loading: false });
      });
    }
  };

  componentWillReceiveProps = (props) => {
    if (props.inActivePatients) {
      this.setState({
        data: props.inActivePatients,
        loading: false
      });
    }
  }

  getColumnSearchProps = dataIndex => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={node => {
            this.searchInput = node;
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button onClick={() => this.handleReset(clearFilters)} size="small" style={{ width: 90 }}>
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({ closeDropdown: false });
              this.setState({
                searchText: selectedKeys[0],
                searchedColumn: dataIndex,
              });
            }}
          >
            Filter
          </Button>
        </Space>
      </div>
    ),
    filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
        : '',
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        setTimeout(() => this.searchInput.select(), 100);
      }
    },
    render: text =>
      this.state.searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[this.state.searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  });

  handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    this.setState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex,
    });
  };

  handleReset = clearFilters => {
    clearFilters();
    this.setState({ searchText: '' });
  };
  
  handleStatus = async (record, value) => {
    let self = this;
    this.setState({ submitted: true });
    this.setState({ loading: true });
    this.setState({checked: value});
    
    await self.props
      .updateUserStatus(record.id, value)
      .then((response) => {
        if (response.error && response.error !== "") {
          notifyUser("Failed Updating status", "error");
          self.setState({ submitted: false, loading: false });
        } else {
          if (response.data && response.data !== "" && response.data.isSuccess === true) {
            let studyId = localStorage.getItem("studyId"); 
            this.props.getInactivePatientsForOhsu(studyId)
            .then((resp) => {
              // Filter the data to exclude users with userStatus equal to "DEACTIVE"
              const filteredData = resp.data.filter(item => item.userStatus !== "ACTIVATE");

              this.setState({
                // loading: false,
                data: filteredData,
              });
            })
            .catch((ex) => {
              this.setState({ loading: false });
            });
            self.setState({ submitted: false, loading: false });
            notifyUser("Status Updated Successfully", "success");
          }
        }
      });
      await this.props.getApprovedPatientsListJanssen()
  };


  render() {
    const studyId = localStorage.getItem("studyId");
    let columns = []
      if (studyId == "3") {
        columns = [
          {
            title: 'Participant Id',
            dataIndex: 'patientId',
            key: 'patientId',
            key: 'email',
            sorter: (a, b) => a.patientId.localeCompare(b.patientId),
            ...this.getColumnSearchProps('patientId'),
          },
          {
            title: "Status",
            dataIndex: "status",
            key: "status",
            // width: "25%",
            render: (_, record) => (
              <Switch
                defaultChecked
                checked={record.userStatus === "ACTIVE" ? true : false}
                checkedChildren="Active"
                unCheckedChildren="InActive"
                onChange={(value) => this.handleStatus(record, value)}
              />
            ),
          },
          {
            title: 'Updated At',
            dataIndex: 'updatedAt',
            key: 'updatedAt',
            sorter: (a, b) => a.updatedAt.localeCompare(b.updatedAt),
            ...this.getColumnSearchProps('updatedAt'),
          },
        ];
      // } else if (studyId == "4") {
      //   columns = [
      //     {
      //       title: 'First Name',
      //       dataIndex: 'firstName',
      //       key: 'firstName',
      //       width: '30%',
      //       sorter: (a, b) => a.firstName.localeCompare(b.firstName),
      //       ...this.getColumnSearchProps('firstName'),
      //     },
      //     {
      //       title: 'Email',
      //       dataIndex: 'email',
      //       key: 'email',
      //       sorter: (a, b) => a.email.localeCompare(b.email),
      //       ...this.getColumnSearchProps('email'),
      //     },
      //     {
      //       title: 'Phone',
      //       dataIndex: 'phoneNumber',
      //       key: 'phoneNumber',
      //       sorter: (a, b) => a.phoneNumber.localeCompare(b.phoneNumber),
      //       ...this.getColumnSearchProps('phoneNumber'),
      //     },
      //   ];
      } else {
        columns = [
          {
            title: 'Last Name',
            dataIndex: 'lastName',
            key: 'lastName',
            sorter: (a, b) => a.lastName.localeCompare(b.lastName),
            width: '20%',
            ...this.getColumnSearchProps('lastName'),
          },
          {
            title: 'First Name',
            dataIndex: 'firstName',
            key: 'firstName',
            width: '30%',
            sorter: (a, b) => a.firstName.localeCompare(b.firstName),
            ...this.getColumnSearchProps('firstName'),
          },
          {
            title: 'Email',
            dataIndex: 'email',
            key: 'email',
            sorter: (a, b) => a.email.localeCompare(b.email),
            ...this.getColumnSearchProps('email'),
          },
          {
            title: 'Phone',
            dataIndex: 'phoneNumber',
            key: 'phoneNumber',
            sorter: (a, b) => a.phoneNumber.localeCompare(b.phoneNumber),
            ...this.getColumnSearchProps('phoneNumber'),
          },
        ];
      }
    
    return (

      <Layout>
        <Spin spinning={this.state.loading} >
          <Card title={studyId == 3 ? "Inactive Participants" : "Inactive Patients"} >
            <Table 
            className={studyId =="3" ? "inactive-patients-table centered-align" : "inactive-patients"}
            columns={columns} bordered dataSource={this.state.data} />
          </Card>
        </Spin>
      </Layout>

    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ ...PatientActions, ...AdminActtions }, dispatch);
}
export default withRouter(
  connect(null, mapDispatchToProps, null, { forwardRef: true })(DisQualifiedPatients)
);
