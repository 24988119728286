import React, { Component } from "react";
import { Row, Col, Space } from "antd";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import ShowCalender from "../calendar/show-calendar";
import ListPatients from "../patients/list-patients";
import ListTasks from "../tasks/list-tasks";
import ApprovedPatients from "../patients/approved-patients";
import * as PatientActions from "../../../redux/actions/patient-actions";
import ListMessages from "../messages/list-messages";
import ListAdverseEvents from "../adverseEvents/adverseEvents";
import * as StudyActions from "../../../redux/actions/study-actions";
import { notifyUser } from "../../../services/notification-service"
import { Card } from "antd";
import Icon from "@ant-design/icons";
// import Icons from "../../../assets/images/icon.png";
import { AddPatientSvg } from "../../shared/svg/addpatient";
import { TaskDueSvg } from "../../shared/svg/taskdue";
import { MessagesSvg } from "../../shared/svg/messages";
// import AxiosAuth2 from "../../../services/axios-service-auth";
import Config from "../../../../app/config"
import { DownloadOutlined } from "@ant-design/icons"

const AddPatientIcon = (props) => <Icon component={AddPatientSvg} {...props} />;
const TaskDueIcon = (props) => <Icon component={TaskDueSvg} {...props} />;


class DashboardHome extends Component {
  state = { data: {} , dataId: "", dTtile: {}};
  componentDidMount = () => {
    // let studyId = this.props.defaultStudyId;
    let studyId = localStorage.getItem("studyId")
    this.props
      .getStudyById(studyId)
      .then((resp) => {
        this.setState({
          loading: false,
          data: resp.data,
          // dataTitle : resp.data.title,
          dataId: resp.data.id
        });
      })
      .catch((ex) => {
        this.setState({ loading: false });
      });
  };

  getPatientsTab = () => {
    this.props.history.push("/patients/1");
  }

  getAdverseEventsTab = () => {
    this.props.history.push("/adverse-events");
  }

  getApprovedPatientsTab = () => {
    this.props.history.push("/patients/2");
  }

  async downloadAppleData() {
    await this.setState({isDisabled: true});
    notifyUser("Please wait while file is being downloaded","loading");
    let fileName = "apple_data" + new Date().getTime() + ".csv"
    var response = await this.props.downloadHealthDataNih(fileName);
    console.log('response',response);    
    if (response) {
      var a = window.document.createElement('a');
      a.href = window.URL.createObjectURL(new Blob([response], { type: 'application/octet_stream' }));
      a.download = fileName;
      document.body.appendChild(a)
      a.click();
      document.body.removeChild(a)
      notifyUser("File successfully downloaded","success");
    } else {
      notifyUser("Something went wrong","error");
    }
    await this.setState({isDisabled: false});
  }

  async downloadPatientData() {
    await this.setState({isDisabled: true});
    notifyUser("Please wait while file is being downloaded","loading");
    let fileName = "patientData" + new Date().getTime() + ".csv"
    var response = await this.props.downloadPatientDataNih(fileName);
    console.log('response',response);    
    if (response) {
      var a = window.document.createElement('a');
      a.href = window.URL.createObjectURL(new Blob([response], { type: 'application/octet_stream' }));
      a.download = fileName;
      document.body.appendChild(a)
      a.click();
      document.body.removeChild(a)
      notifyUser("File successfully downloaded","success");
    } else {
      notifyUser("Something went wrong","error");
    }
    await this.setState({isDisabled: false});
  }

 
  render() {

    const isHrefDisabled = true;
    let url = Config.downloadCsv.url;
    // Dev
    // let janssenUrl = "https://ucsfdev.test2.redblink.net/api/download/patientData/janssen/";
    // Prod
    let janssenUrl = "https://skintracker-be.ucsf.edu/api/download/patientData/janssen/";
    const studyId = localStorage.getItem("studyId");
    const dTtile = localStorage.getItem("studyTitle");

    return (
      <div className="dashboard">

        <Card className={studyId == 2 ? "janssen-css" : "eczema-css"}
          extra={
            <Space>
              {studyId == 1 ?<>
                <a href={url + "patient_" + new Date().getTime() + ".csv"}><DownloadOutlined /> Patient Data</a>
                <a href={url + "adverse_event_" + new Date().getTime() + ".csv"}><DownloadOutlined /> Adverse Event</a></>
                : studyId == 4 ?<>
                <a 
                  // href={janssenUrl + "patient_" + new Date().getTime() + ".csv"}
                  onClick={() => this.downloadPatientData()}
                  >
                    <DownloadOutlined /> Patient Data</a>
                <a 
                  onClick={() => this.downloadAppleData()}
                  // href={url + "adverse_event_" + new Date().getTime() + ".csv"}
                  >
                    <DownloadOutlined /> Apple Data</a></>
                :
                <>
                <a href={janssenUrl + "patient_" + new Date().getTime() + ".csv"}><DownloadOutlined /> Patient Data</a>
                <a href={url + "adverse_event_" + new Date().getTime() + ".csv"}><DownloadOutlined /> Adverse Event</a></>
              }
              
            </Space>
          }
          // title={this.state.data && this.state.data != null && this.state.data.title ? this.state.data.title : "Eczema Tracking Study"}
          title={dTtile}
          >
          <Row gutter={16}>
            <Col xs={24} sm={24} md={12} lg={8} xl={9}>
              <div className="white-box">
                <div className="title">
                  <h2>Patients
                    <span className="title-icon">
                      <AddPatientIcon />
                    </span>

                  </h2>
                </div>
                <ListPatients />
                <p className="view-link-right"><a onClick={() => this.getApprovedPatientsTab()} className="ant-link">View More</a></p>
              </div>
              {studyId == "1" ?
              <div className="white-box" style={{ marginTop: "20px" }}>
              <div className="title">
                <h2>Approve Patients <span className="title-icon"><AddPatientIcon /></span></h2>
              </div>
              <ApprovedPatients />
              <p className="view-link-right"><a onClick={() => this.getPatientsTab()} className="ant-link">View More</a></p>
            </div>: null }
              
            </Col>
            <Col xs={24} sm={24} md={12} lg={8} xl={6}>
              { studyId == "1" ?
                <div className="white-box">
                  <div className="title" style={{ display: 'none' }}>
                    <h2>Appointments <span className="title-icon"><AddPatientIcon /></span></h2>
                  </div>
                  
                  <div className="responsive-calendar"><ShowCalender /></div> 
                  
                </div>
              : null }
              <div className="white-box" style={{ marginTop: studyId === 1 ? "20px" : "unset" }}>
                <div className="title">
                  <h2>Adverse Events <span className="title-icon"><MessagesSvg /></span></h2>
                </div>
                <ListAdverseEvents studyId={studyId}/>
                <p className="view-link-right"><a onClick={() => this.getAdverseEventsTab()} className="ant-link">View More</a></p>
              </div>
            </Col>
            <Col xs={24} sm={24} md={12} lg={8} xl={9}>
              <div className="white-box">
                <div className="title">
                  <h2>Overdue Tasks <span className="title-icon"><TaskDueIcon /></span></h2>
                </div>
                <ListTasks />
              </div>
              {studyId == "1" ?
              <div className="white-box" style={{ marginTop: "20px" }}>
                <div className="title">
                  <h2>Recent Messages <span className="title-icon"><MessagesSvg /></span></h2>
                </div>
                <ListMessages />
              </div>
              : null}
            </Col>
          </Row>
        </Card>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return { defaultStudyId: state.study.defaultStudy };
}
function mapDispatchToProps(dispatch) {
  return bindActionCreators({ ...StudyActions, ...PatientActions }, dispatch);
}
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(DashboardHome)
);
