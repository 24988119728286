import API from "../api/medical-history-api";
import { Types } from "../constants/medical-history-types";


export function listAllMedicalHistory(patientId, studyId) {

  return async function (dispatch, _getState) {
    try {
      let resp = await API.listAllMedicalHistory(patientId, studyId);
      if (resp && resp.data && resp.data.length > 0) {
        dispatch({
          type: Types.LIST_ALL_MEDICAL_HISTORY,
          payload: resp.data
        });
      } else {
        dispatch({
          type: Types.MEDICAL_HISTORY_NOT_FOUND,
          payload: "medical history not found"
        });
      }

      return resp;
    } catch (e) {
      return e;;
    }
  };
}

export function listAllMedicalHistoryNih(patientId, studyId) {

  return async function (dispatch, _getState) {
    try {
      let resp = await API.listAllMedicalHistoryNih(patientId, studyId);
      if (resp && resp.data && resp.data.length > 0) {
        dispatch({
          type: Types.LIST_ALL_MEDICAL_HISTORY,
          payload: resp.data
        });
      } else {
        dispatch({
          type: Types.MEDICAL_HISTORY_NOT_FOUND,
          payload: "medical history not found"
        });
      }

      return resp;
    } catch (e) {
      return e;;
    }
  };
}

export function listAdverseEvents(patientId, studyId) {

  return async function (dispatch, _getState) {
    try {
      let resp = await API.listAdverseEvents(patientId, studyId);
      if (resp && resp.data) {
        dispatch({
          type: Types.LIST_ADVERSE_EVENTS,
          payload: resp.data
        });
      }
      return resp;
    } catch (e) {
      return e;;
    }
  };
}

export function getDoctorCommentsOnAdverseEvents(eventId) {
  return async function (dispatch, getState) {
    try {
      let resp = await API.getDoctorCommentsOnAdverseEvents(eventId);
      if (resp && resp.data) {
        dispatch({
          type: Types.GET_DOCTOR_NOTES,
          payload: resp.data
        });
      } else {
        dispatch({
          type: Types.DOCTOR_NOTES_NOT_FOUND,
          payload: "Doctor notes not found"
        });
      }
      return resp;
    } catch (e) {
      return { error: true };
    }
  };
}
