import React from "react";
export const PatientSvg = () => (
  <svg
    version="1.1"
    id="Layer_1"
    x="0px"
    y="0px"
    width="1.2em"
    height="1.2em"
    fill="currentColor"
    viewBox="0 0 64 64"
  >
    <path d="m54.712 36.093-7.8-3.033c-1.161-.452-1.912-1.549-1.912-2.796v-.682c.834-.623 1.602-1.32 2.282-2.093 4.696-2.368 7.718-7.234 7.718-12.489v-1.917c0-3.925-2.748-7.257-6.572-8.021l-1.355-1.085c-2.401-1.92-5.415-2.977-8.488-2.977-7.491 0-13.585 6.094-13.585 13.585v.415c0 5.255 3.023 10.122 7.718 12.489.68.773 1.448 1.47 2.282 2.092v.682c0 .842-.348 1.626-.941 2.184-2.563-3.306-6.561-5.447-11.059-5.447-7.72 0-14 6.28-14 14 0 2.397.607 4.654 1.673 6.628l-7.809 6.375c-1.184.968-1.864 2.399-1.864 3.928 0 2.795 2.274 5.069 5.069 5.069 1.528 0 2.959-.679 3.926-1.863l6.376-7.81c1.975 1.066 4.232 1.673 6.629 1.673h40v-6.791c0-5.402-3.253-10.158-8.288-12.116zm-27.712-21.093v-.415c0-6.388 5.197-11.585 11.585-11.585 2.621 0 5.191.901 7.237 2.539l1.757 1.405.257.043c2.992.498 5.164 3.062 5.164 6.096v1.917c0 2.923-1.093 5.705-2.959 7.855.616-1.655.959-3.438.959-5.295v-4.56h-3.789c-4.52 0-8.896-1.325-12.656-3.832l-.482-.322-.52.259c-2.809 1.405-4.553 4.228-4.553 7.367v1.087c0 1.857.342 3.64.959 5.294-1.866-2.149-2.959-4.93-2.959-7.853zm4 2.56v-1.087c0-2.19 1.121-4.176 2.959-5.307 3.973 2.51 8.541 3.834 13.252 3.834h1.789v2.56c0 5.699-3.604 10.642-9 12.393-5.396-1.752-9-6.694-9-12.393zm-22.554 42.312c-.585.717-1.452 1.128-2.377 1.128-1.692 0-3.069-1.376-3.069-3.069 0-.926.411-1.792 1.129-2.378l7.637-6.235c.824 1.11 1.806 2.092 2.916 2.916zm2.554-18.872c0-6.617 5.383-12 12-12s12 5.383 12 12-5.383 12-12 12-12-5.383-12-12zm50 12h-6v-10h-2v10h-22.826c4.081-2.449 6.826-6.904 6.826-12 0-2.504-.669-4.851-1.825-6.886.999-.821 1.643-1.993 1.787-3.294.866.458 1.781.847 2.751 1.138l.287.086.287-.086c.971-.291 1.887-.681 2.755-1.14.202 1.843 1.385 3.421 3.146 4.106l7.8 3.033c4.259 1.656 7.012 5.681 7.012 10.252z" /><path d="m26 38v-3h-6v3h-3v6h3v3h6v-3h3v-6zm1 4h-3v3h-2v-3h-3v-2h3v-3h2v3h3z" />
    <path d="m23 31c-5.514 0-10 4.486-10 10s4.486 10 10 10 10-4.486 10-10-4.486-10-10-10zm0 18c-4.411 0-8-3.589-8-8s3.589-8 8-8 8 3.589 8 8-3.589 8-8 8z" />
    <path d="m42 41c-1.654 0-3 1.346-3 3s1.346 3 3 3 3-1.346 3-3-1.346-3-3-3zm0 4c-.552 0-1-.449-1-1s.448-1 1-1 1 .449 1 1-.448 1-1 1z" />
    <circle cx="37" cy="50" r="1" />
    <circle cx="40" cy="38" r="1" />
    <circle cx="45" cy="39" r="1" />
    <circle cx="43" cy="50" r="1" />
    <circle cx="47" cy="48" r="1" />
    <circle cx="38" cy="35" r="1" />
    <path d="m42 26h2c0-1.682-1.757-3-4-3s-4 1.318-4 3h2c0-.408.779-1 2-1s2 .592 2 1z" />
    <circle cx="6" cy="58" r="1" />
  </svg>
);
