import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter, Link } from "react-router-dom";
import {Spin, Menu} from "antd";
import { Avatar } from "antd";
import Icon from "@ant-design/icons";
import Logo from "../../../assets/images/logo.png";
import * as AuthActions from "../../../redux/actions/auth-actions";

import { LogoutSvg } from "../../shared/svg/logout-icon";
const LogoutIcon = (props) => <Icon component={LogoutSvg} {...props} />;

const { SubMenu } = Menu;

class SideBar extends Component {
  state = { loading: true, data: {}, 
            firstName: "", 
            email: "", 
            avatar: "" ,
            roleName: "",
            submitted: false,
          };

  constructor(props) {
    super(props);
    this.state = {
      
      openKeys: [
        /* "/menu-archive/", "/community-engagement/" */
      ],
    };
  }

  componentDidMount = async () => {
    this.setState({ submitted: true });
    this.props
      .getLoggedInUser()
      .then((resp) => {
        this.setState({
          loading: false,
          firstName: resp.data.firstName,
          email: resp.data.email,
          roleName:resp.data.roles[0].name,
          avatar: resp.data.firstName.toString().substring(0, 1) + resp.data.lastName.toString().substring(0, 1)
        });
       
      })
      .catch((ex) => {
        this.setState({ loading: false });
        this.setState({ submitted: false });
      }).finally(()=> this.setState({ submitted: false }));
  };

  logout = () => {
    this.props.logout();
    this.props.history.push("/login");
  };

  getMenuItem = ({ singleOption }) => {
    const { key, label, leftIcon, children } = singleOption;
    const studyId = localStorage.getItem("studyId");
    if (children) {
      return (
        <SubMenu
          key={key}
          title={
            <span className="isoMenuHolder">
              {leftIcon}
              <span className="nav-text">{label}</span>
            </span>
          }
        >
          {children.map((child) => {
            console.log('child',child);
            return (
              child.roles &&
              child.roles.indexOf(this.state.roleName) !== -1 && (
                <Menu.Item key={child.key}>
                  <Link to={child.key}>
                    {child.leftIcon}
                    {child.label}
                    {child.alertsCount}
                  </Link>
                </Menu.Item>
              )
            );
          })}
        </SubMenu>
      );
    }
    return (
      <Menu.Item key={key}>
        <Link to={`${key}`}>
          <span className="isoMenuHolder">
            {leftIcon}
            <span className="nav-text">{studyId == "3" && label === "Patient" ? "Participants" : label}</span>
          </span>
        </Link>
      </Menu.Item>
    );
  };

  render() {
    const { submitted } = this.state;
    const studyId = localStorage.getItem("studyId");
    return (
      <React.Fragment>
        <div className="sidebar-menu">
          <div className="logo">
            <img src={Logo} alt="" />
          </div>
          <div>
            <div className="doctor-avtar">
              <Avatar style={{backgroundColor: "#514D4D"}}>{this.state.avatar}</Avatar>
              <div className="doctor-detail">
                <div className="name-icon"> {this.state.firstName}</div>
                <div className="email"> {this.state.email}</div>
              </div>
            </div>
            <Spin spinning={submitted}>
              <Menu
                theme="light"
                mode="inline"
                defaultSelectedKeys={studyId === 3 ? "/patients/1" : "/dashboard/"}
                selectedKeys={[this.props.history.location.pathname]}
              >

                {/* {["SUPERADMIN"].includes(this.state.roleName)? 
                  this.props.options &&
                  this.props.options.filter((item) => item.key == "/user-list/" || item.key == "/profile/").map((singleOption) =>
                    this.getMenuItem({ singleOption })
                  ):this.props.options &&
                    this.props.options.filter((item) => item.key !== "/user-list/").map((singleOption) =>
                    this.getMenuItem({ singleOption })
                  )} */}
                {(this.state.roleName == "SUPERADMIN") ? 
                    (this.props.options &&
                      this.props.options.filter((item) => item.key == "/user-list/" || item.key == "/profile/").map((singleOption) =>
                        this.getMenuItem({ singleOption })
                      )
                    ) : (studyId == "3") ?
                    (this.props.options &&
                      this.props.options.filter((item) => item.key !== "/user-list/" && item.key !== "/appointments/" && item.key !== "/chatList/" && item.key !== "/dashboard/" && item.key !== "/adverse-events/" && item.key !== "/tickets/") .map((singleOption) =>
                        this.getMenuItem({ singleOption })
                      )
                    ) : (studyId == "2") ?
                      (this.props.options &&
                        this.props.options.filter((item) => item.key !== "/user-list/" && item.key !== "/appointments/" && item.key !== "/chatList/" ).map((singleOption) =>
                          this.getMenuItem({ singleOption })
                        )
                      ) : (studyId == "4") ?
                      (this.props.options &&
                        this.props.options.filter((item) => item.key !== "/user-list/" && item.key !== "/appointments/" && item.key !== "/chatList/" && item.key !== "/tickets/").map((singleOption) =>
                          this.getMenuItem({ singleOption })
                        )
                      ) : (studyId == "1") ?
                      (this.props.options &&
                        this.props.options.filter((item) => item.key !== "/user-list/").map((singleOption) =>
                          this.getMenuItem({ singleOption })
                        )
                      ) : (this.props.options &&
                        this.props.options.map((singleOption) =>
                          this.getMenuItem({ singleOption })
                        )
                      )
                  }
                <Menu.Item
                  key="logout"
                  onClick={this.logout}
                  icon={<LogoutIcon />}
                >
                  <span>Logout</span>
                </Menu.Item>
              </Menu>
            </Spin>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
function mapDispatchToProps(dispatch) {
  return bindActionCreators({ ...AuthActions }, dispatch);
}
export default withRouter(
  connect(null, mapDispatchToProps, null, { forwardRef: true })(SideBar)
);
