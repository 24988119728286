import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { List, Avatar, Card, Spin } from "antd";
import * as PatientActions from "../../../redux/actions/patient-actions";
import * as StudyActions from "../../../redux/actions/study-actions";
import Icon from "@ant-design/icons";
import { RightCaretSvg } from "../../shared/svg/rightcaret";

const RightCaretIcon = (props) => <Icon component={RightCaretSvg} {...props} />;

class ListPatients extends Component {
  state = { loading: false, data: [], dataItem: []};

  componentDidMount = () => {
    this.setState({loading: true})
    let studyId = localStorage.getItem("studyId");   
    
    if (studyId == 1) {
      this.props
      .getApprovedPatientsList(1)
      .then((resp) => {
        this.setState({
          loading: false,
          data: resp.data,
          dataItem: resp.data.slice(0, 5)
        });
        this.setState({ loading: false });
      })
      .catch((ex) => {
        this.setState({ loading: false });
      });
    } else if (studyId == 4) {
      this.props.getApprovedPatientsListNih()
      
        .then((resp) => {
          this.setState({
            data: resp.data,
            dataItem: resp.data.slice(0, 5)
          });
          this.setState({ loading: false });
        })
        .catch((ex) => {
          this.setState({ loading: false });
        });
    } else {
      this.props
        .getApprovedPatientsListJanssen()
        .then((resp) => {
          this.setState({
            data: resp.data,
            dataItem: resp.data.slice(0, 5)
          });
          this.setState({ loading: false });
        })
        .catch((ex) => {
          this.setState({ loading: false });
        });
    }
  };

  viewProgress = (id) => {
    this.props.history.push("/patient-progress/"+id);
  }

  render() {
    const studyId = localStorage.getItem('studyId');
    return (
      <div className="list-patients" style={{ height: "300px", overflow: "auto" }} >
        <Spin spinning={this.state.loading} >
          <Card bordered={false}>
            <List
              itemLayout="horizontal"
              dataSource={this.state.dataItem}
              renderItem={(item) => (
                <List.Item onClick={() => this.viewProgress(item.id)}>
                  <List.Item.Meta
                    avatar={<Avatar style={{backgroundColor: "#4B4848"}}>{item.firstName && item.firstName.charAt(0).toUpperCase() + item && item.lastName && item.lastName.charAt(0).toUpperCase()}</Avatar>}
                    title={<h3 style={{color: "black"}}>{item.firstName}</h3>}
                  />
                  {studyId == 1 ? 
                    <div className="week-count">{item.studyWeek != null ? "Week " + item.studyWeek + "/26" : "Week 1/54"}</div>
                    : studyId == 2 ?
                    <div className="week-count">{item.studyWeek != null ? "Week " + item.studyWeek + "/8" : "Week 1/54"}</div> :
                    <div className="week-count">{item.studyWeek != null ? "Month " + item.studyWeek + "/6" : "Month 1/6"}</div>
                  }
                  <RightCaretIcon  />
                </List.Item>
              )}
            />
          </Card>
        </Spin>
      </div>
    );
  }
}
function mapStateToProps(state) {
  return { defaultStudyId: state.study.defaultStudy};
}
function mapDispatchToProps(dispatch) {
  return bindActionCreators({ ...PatientActions ,...StudyActions}, dispatch);
}
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(ListPatients)
);
