import AxiosAuth2 from "../../services/axios-service-auth";

const addEvent = (data) => {

  return AxiosAuth2.post("/api/studyDrugLog/saveStudyDrugLog", data, undefined);
};

const getStudyLogsByPatientId = (patientId) => {
  return AxiosAuth2.get("/api/studyDrugLog/getStudyDrugLogByPatientId?patientId=" + patientId, undefined, undefined);
};

export default {
  addEvent,
  getStudyLogsByPatientId,
};
