export const Types = {
    GET_VOICE_DIARIES: "GET_VOICE_DIARIES",
    VOICE_DIARIES_NOT_FOUND:"VOICE_DIARIES_NOT_FOUND",
    GET_TEXT_DIARIES: "GET_TEXT_DIARIES",
    GET_PHOTO_DIARIES: "GET_PHOTO_DIARIES",
    GET_IMAGE_URL:"GET_IMAGE_URL",
    IMAGE_URL_NOT_FOUND:"IMAGE_URL_NOT_FOUND",
    PHOTO_DIARIES_NOT_FOUND:"PHOTO_DIARIES_NOT_FOUND",
    TEXT_DIARIES_NOT_FOUND:"TEXT_DIARIES_NOT_FOUND",
    GET_DOCTOR_VOICE_NOTES: "GET_DoCTOR_VOICE_NOTES",
    DOCTOR_VOICE_NOTES_NOT_FOUND:"DOCTOR_VOICE_NOTES_NOT_FOUND",
    GET_DOCTOR_TEXT_NOTES: "GET_DOCTOR_TEXT_NOTES",
    DOCTOR_TEXT_NOTES_NOT_FOUND:"DOCTOR_TEXT_NOTES_NOT_FOUND",
    GET_DOCTOR_PHOTO_NOTES: "GET_DOCTOR_PHOTO_NOTES",
    DOCTOR_PHOTO_NOTES_NOT_FOUND:"DOCTOR_PHOTO_NOTES_NOT_FOUND",
    FLARE_PHOTO: "FLARE_PHOTO",
    FLARE_PHOTO_NOT_FOUND: "FLARE_PHOTO_NOT_FOUND"
  };
