import { Types } from "../constants/medical-history-types";

const initialState = {
    medicalHistoryList: undefined,
    medicalHistoryListError: undefined
};

//Change function name HERE
export default function medicalHistoryReducer(state = initialState, action) {
    switch (action.type) {
        case Types.LIST_ALL_MEDICAL_HISTORY:
            return { ...state, medicalHistoryList: action.payload };
        case Types.MEDICAL_HISTORY_NOT_FOUND:
            return { ...state, medicalHistoryList: action.payload };
        default:
            return state;
    }
}
