import React from "react";
export const DataSvg = () => (
  <svg 
    version="1.1"
    id="Layer_1"
    x="0px"
    y="0px"
    width="3em"
    height="3em" 
    fill="currentColor"
    viewBox="0 0 496 496">
        <path d="m345.782 102.36c-2.454-1.186-4.079-3.606-4.361-6.317l-8.511-81.703c-.85-8.15-7.72-14.34-15.91-14.34h-138c-8.19 0-15.06 6.19-15.91 14.34l-8.511 81.703c-.282 2.711-1.907 5.13-4.361 6.317-21.417 10.354-36.218 32.304-36.218 57.64v176c0 25.336 14.801 47.286 36.218 57.641 2.454 1.186 4.079 3.606 4.361 6.317l8.511 81.703c.85 8.149 7.72 14.339 15.91 14.339h138c8.19 0 15.06-6.19 15.91-14.34l8.511-81.703c.282-2.711 1.907-5.13 4.361-6.317 21.417-10.354 36.218-32.304 36.218-57.64v-176c0-25.336-14.801-47.286-36.218-57.64zm4.218 233.64c0 17.64-14.36 32-32 32h-140c-17.64 0-32-14.36-32-32v-176c0-17.64 14.36-32 32-32h140c17.64 0 32 14.36 32 32zm-88.903-9.771-33.178-95.927-8.161 22.909c-2.271 6.373-8.306 10.63-15.072 10.63h-20.999c-8.837 0-16-7.163-16-16s7.163-16 16-16h9.714l19.67-55.211c5.059-14.2 25.262-14.117 30.193.14l32.032 92.615 8.036-26.23c2.06-6.723 8.267-11.313 15.298-11.313h13.684c8.837 0 16 7.163 16 16s-7.163 16-16 16h-1.852l-18.946 61.845c-4.519 14.752-25.371 15.141-30.419.542z"/>
    </svg>
);
