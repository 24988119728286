import AxiosAuth2 from "../../services/axios-service-auth";

const addEvent = (data) => {
  return AxiosAuth2.post("/api/appointment/saveAppointment", data, undefined);
};

const getAppointments = () =>{
  return AxiosAuth2.get("/api/appointment/getAppointments", undefined, undefined);
};

const deleteEvent = (id) =>{
  return AxiosAuth2.delete("/api/appointment/deleteAppointment/"+id, undefined, undefined);
};

export default {
    addEvent,
    getAppointments,
    deleteEvent
};
