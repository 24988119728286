import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { List, Avatar, Card, Spin } from "antd";
import * as PatientActions from "../../../redux/actions/patient-actions";
import Icon from "@ant-design/icons";
import { RightCaretSvg } from "../../shared/svg/rightcaret";

const RightCaretIcon = (props) => <Icon component={RightCaretSvg} {...props} />;

class ListTasks extends Component {
  state = { loading: false, data: [] };

  componentDidMount = () => {
    this.setState({loading: true});
    let studyId = localStorage.getItem("studyId")
    this.props
      .getTasksList(studyId)
      .then((resp) => {
        
        this.setState({
          loading: false,
          data: resp.data,
        });
      })
      .catch((ex) => {
        this.setState({ loading: false });
      });
  };

  viewProgress = (id) => {
    this.props.history.push("/patient-progress/"+id);
  }

  render() {
    const {data} = this.state
    let dueTasks = []
    if(data &&  data.patientOverDueList> 0 && data.length > 0){
      dueTasks=  data.patientOverDueList.map((task)=>task.taskName)
    }
    return (
      <div className="list-patients" style={{ height: "300px", overflow: "auto" }}>
        <Spin spinning={this.state.loading}>
          <Card bordered={false}>
            <List
              itemLayout="horizontal"
              dataSource={this.state.data}
              renderItem={(patient) => (
                <List.Item onClick={() => this.viewProgress(patient.userId)}>
                  <List.Item.Meta
                    avatar={<Avatar style={{backgroundColor: "#4B4848"}}>{patient.patient.charAt(0).toUpperCase()}</Avatar>}
                    title={<h3 style={{color: "black"}}>{patient.patient}</h3>}
                  />
                  <div className="due-tasks" style={{paddingLeft: "95px"}}><span style = {{color : "#1569C7"}}>Pending: </span>{patient.patientOverDueList.map((task)=>task.taskName).slice(0,2).join(', ')}</div>
                  <RightCaretIcon  />
                  
                </List.Item>
              )}
            />
          </Card>
        </Spin>
      </div>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ ...PatientActions }, dispatch);
}
export default withRouter(
  connect(null, mapDispatchToProps, null, { forwardRef: true })(ListTasks)
);
