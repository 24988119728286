import React from "react";
import { Route, Redirect } from "react-router-dom";
import { removeCookie } from "./services/cookies";

// import Config from "./config";

export const PrivateRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={(props) =>{
      return localStorage.getItem("tokenData") ? (
        <Component {...props} />
      ) : (
        <Redirect to={{ pathname: "/login" }} />
      )
    }
      
    }
  />
);
