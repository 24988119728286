import API from "../api/survey-api";
import { Types } from "../constants/survey-types";

export function reviewSurvey(patientId,taskId) {
  return async function(dispatch, _getState) {
    try {
      let resp = await API.reviewSurvey(patientId,taskId);
      if (resp && resp.data) {
        dispatch({
          type: Types.QUESTION_ANSWER_LIST,
          payload: resp.data
        });
      }
      return resp;
    } catch (e) {
      return { error: true };
    }
  };
}

export function reviewSurveyJanssen(patientId,taskId) {
  return async function(dispatch, _getState) {
    try {
      let resp = await API.reviewSurveyJanssen(patientId,taskId);
      if (resp && resp.data) {
        dispatch({
          type: Types.QUESTION_ANSWER_LIST,
          payload: resp.data
        });
      }
      return resp;
    } catch (e) {
      return { error: true };
    }
  };
}

export function reviewSurveyNih(patientId,taskId) {
  return async function(dispatch, _getState) {
    try {
      let resp = await API.reviewSurveyNih(patientId,taskId);
      if (resp && resp.data) {
        dispatch({
          type: Types.QUESTION_ANSWER_LIST,
          payload: resp.data
        });
      }
      return resp;
    } catch (e) {
      return { error: true };
    }
  };
}

export function flaresTask(patientId,taskId) {
  return async function(dispatch, _getState) {
    try {
      let resp = await API.flaresTask(patientId,taskId);
      if (resp && resp.data) {
        dispatch({
          type: Types.QUESTION_ANSWER_LIST,
          payload: resp.data
        });
      }
      return resp;
    } catch (e) {
      return { error: true };
    }
  };
}