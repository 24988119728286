import React from "react";

export const MedicationIconSvg = () => (
    <svg width="33" height="33" viewBox="0 0 33 33" fill="currentColor">
    <g clip-path="url(#clip0_2064_66)">
      <path d="M8.10144 8.1207C7.74566 8.1207 7.45691 8.40945 7.45691 8.76524C7.45691 9.12102 7.74566 9.40977 8.10144 9.40977C8.45722 9.40977 8.74597 9.12102 8.74597 8.76524C8.74597 8.40945 8.45722 8.1207 8.10144 8.1207ZM13.9019 20.1728H11.9684V18.2393C11.9684 17.8834 11.6798 17.5948 11.3239 17.5948C10.968 17.5948 10.6794 17.8834 10.6794 18.2393V20.1728H8.74597C8.39006 20.1728 8.10144 20.4614 8.10144 20.8173C8.10144 21.1731 8.39006 21.4618 8.74597 21.4618H10.6794V23.3953C10.6794 23.7512 10.9681 24.0398 11.3239 24.0398C11.6797 24.0398 11.9684 23.7512 11.9684 23.3953V21.4618H13.9019C14.2578 21.4618 14.5464 21.1732 14.5464 20.8173C14.5464 20.4614 14.2579 20.1728 13.9019 20.1728Z" fill="currentColor"/>
      <path d="M31.297 20.7529C29.8723 18.2852 26.7221 17.4407 24.254 18.8658L21.636 20.3772V14.7803C21.636 14.0884 21.4072 13.4018 20.9915 12.8469L17.7691 8.55035V6.72079C18.5192 6.45472 19.0581 5.73839 19.0581 4.89812V1.93346C19.0579 0.867346 18.1906 0 17.1245 0H5.52349C4.45737 0 3.58996 0.867346 3.58996 1.93346V4.89818C3.58996 5.73833 4.12885 6.45479 4.87896 6.72085V8.55042L1.65624 12.8472C1.24077 13.4019 1.01196 14.0884 1.01196 14.7803V27.2623C1.01196 29.0392 2.45758 30.4848 4.23443 30.4848H14.5922C16.0373 32.9071 19.1533 33.7178 21.5959 32.3075L29.4099 27.7959C31.8563 26.3832 32.7289 23.234 31.297 20.7529ZM4.87896 1.93346C4.87896 1.57807 5.16809 1.28893 5.52342 1.28893H17.1244C17.4798 1.28893 17.7689 1.57807 17.7689 1.93346V4.89818C17.7689 5.25357 17.4798 5.54265 17.1244 5.54265H5.52349C5.16809 5.54265 4.87902 5.25351 4.87902 4.89818L4.87896 1.93346ZM2.68774 13.6202L6.03905 9.15189C6.12273 9.04033 6.16796 8.90463 6.16796 8.76517V6.83171H16.4799V8.12071H10.6795C10.3236 8.12071 10.0349 8.40933 10.0349 8.76524C10.0349 9.12115 10.3236 9.40977 10.6795 9.40977H16.8022L19.96 13.62C20.1663 13.8955 20.2945 14.2252 20.3333 14.5658H2.31469C2.35336 14.2251 2.48156 13.8954 2.68774 13.6202ZM4.23449 29.1958C3.16837 29.1958 2.30103 28.3284 2.30103 27.2623V26.6114H14.0105C13.8062 27.447 13.8075 28.3396 14.0415 29.1958H4.23449V29.1958ZM14.5161 25.3288H2.30096V15.8546H20.3469V21.1213L16.4398 23.3773C15.6252 23.8478 14.9668 24.5177 14.5161 25.3288ZM20.9515 31.1911C19.1019 32.2592 16.7384 31.6275 15.6696 29.7766C14.5958 27.9079 15.2466 25.5551 17.0845 24.4935L20.5828 22.4736L24.4498 29.1713L20.9515 31.1911ZM28.7654 26.6797L25.5662 28.5268L21.6991 21.829L24.8984 19.9821C26.7493 18.9136 29.1119 19.5463 30.1807 21.3973C31.2541 23.2575 30.6014 25.6195 28.7654 26.6797Z" fill="currentColor"/>
    </g>
    <defs>
      <clipPath id="clip0_2064_66">
        <rect width="33" height="33" fill="currentColor"/>
      </clipPath>
    </defs>
  </svg>
);
