import { Types } from "../constants/chat-types";

const initialState = {
    messages: [],
};

export default function chatReducer(state = initialState, action) {
    switch (action.type) {
        case Types.GET_MESSAGES:
            return { ...state, messages: action.payload };
        default:
            return state;
    }
}
