import React from "react";
export const UnFlagSvg = () => (
    <svg
        version="1.1"
        id="Layer_1"
        x="0px"
        y="0px"
        width="1.1em"
        height="1.1em"
        fill="currentColor"
        viewBox="0 0 307.3 409.6"
    >
        <path d="M20.9,0c10.4,3.8,14.2,11.6,13.4,22.2c-0.3,3.8,0,7.7,0,12c2.2,0,3.8,0,5.5,0c82.4,0,164.8,0,247.2,0
	c5.2,0,10.1,0.5,14.2,4.1c5.9,5.2,7.7,13.2,4.2,20.3c-0.9,1.8-1.9,3.5-3,5.1c-19.3,28.9-38.5,57.9-57.9,86.8c-1.6,2.4-1.5,3.9,0,6.2
	c19.6,29.2,39.1,58.5,58.6,87.8c5.4,8.1,5.6,15.3,0.6,21.8c-2.9,3.8-6.7,6.1-11.5,6.5c-2.1,0.2-4.3,0.2-6.4,0.2c-82,0-164,0-246,0
	c-1.7,0-3.4,0-5.5,0c0,2.1,0,3.6,0,5.2c0,37.2,0,74.4,0,111.6c0,5.7-1,10.9-5.4,14.9c-5.1,4.7-11.2,6.2-17.8,3.7
	c-6.6-2.5-10.2-7.5-11-14.5c-0.2-1.8-0.1-3.7-0.1-5.6C0.1,266.1,0.2,143.8,0,21.6C0,11,3.4,3.5,13.7,0C16.1,0,18.5,0,20.9,0z
	 M258.2,238.8c-1.2-1.8-1.9-3-2.7-4.1c-15.3-22.9-30.6-45.9-45.9-68.8c-6.2-9.4-6.2-15.2,0.1-24.6c15.3-22.9,30.6-45.9,45.9-68.8
	c0.8-1.2,1.5-2.3,2.5-4c-74.9,0-149.1,0-223.5,0c0,56.9,0,113.6,0,170.4C109.1,238.8,183.1,238.8,258.2,238.8z"/>
    </svg>
);
