import React, { Component } from "react";
import { Row, Col, Form, Input, Button, Typography, Spin } from "antd";
import { withRouter, Link } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as AuthActions from '../../redux/actions/auth-actions';
import { notifyUser } from "../../services/notification-service";
import { UserOutlined } from '@ant-design/icons';
import Logo from "../../assets/images/logo.png";


class ForgotPassword extends Component {
  constructor(props) {
    super(props);
    this.formRef = React.createRef();
    this.state = {
      success: false,
      email: "",
      message: "",
      submitted: false,
      submitButtonText: "Send"
    };
  }


  handleSubmit = (values) => {
    let self = this;
    this.setState({ submitted: true ,email:values.email});
    let data = {
      email: this.state.email,
    };

    self.props.forgotPassword(data).then(response => {
      if (response.data && response.data.isSuccess === true) {
        notifyUser("An email was sent to your registered email address. Kindly follow the instructions to reset your password!", "success");
        self.setState({ submitted: false }, () => {
          this.formRef.current.setFieldsValue({
            email: ''
          });
        });
      } else {
        notifyUser("Please enter valid email", "error");
        self.setState({ submitted: false });
      }
    });
  }

  render() {
    return (
      <div className="login">
        <div className="login-in">
          <section className="wrap flexbox">
            <Row gutter={24} style={{ width: "100%" }}>
              <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                <Row className="logo" gutter={24}>
                  <Col xs={24}>
                    <img src={Logo} alt="UCSF" />
                  </Col>
                </Row>
                <Row gutter={24}>
                  <Col xs={24}>
                    <Typography.Title level={4}>
                      Forgot Password
                    </Typography.Title>
                  </Col>
                </Row>
                <hr />
                <Spin spinning={this.state.submitted}>
                  <Form onFinish={this.handleSubmit} ref={this.formRef} className="login-form">
                    <Form.Item
                      name="email"
                      rules={[
                        {
                          required: true,
                          message: "This is a required field!"
                        }
                      ]}
                    >
                      <Input
                        prefix={<UserOutlined className="site-form-item-icon" />}
                        type="email"
                        placeholder="Enter Email Address..."

                      />
                    </Form.Item>
                    <Form.Item>
                      <Button
                        value="large"
                        block
                        type="primary"
                        htmlType="submit"
                        className="login-form-button"
                      >
                        {this.state.submitButtonText}
                      </Button>
                    </Form.Item>
                    <Row gutter={24} className="login-bottom-items">
                      <Col xl={24} style={{ textAlign: "right" }}>
                        <Link
                          className="login-form-forgot"
                          to="/login"
                        >
                          Back to Login
                        </Link>
                      </Col>
                    </Row>
                  </Form>
                </Spin>
              </Col>
            </Row>
          </section>
        </div>
      </div>
    );
  }
}
function mapDispatchToProps(dispatch) {
  return bindActionCreators({ ...AuthActions }, dispatch);
}
export default withRouter(
  connect(null, mapDispatchToProps, null, { forwardRef: true })(
    ForgotPassword
  )
);
