import React, { Component } from "react";
import { Row, Col, Typography, Spin } from "antd";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import DashboardHeader from "./../layout/header.jsx";
import options from "./../layout/sidebar/dashboard-options";
import { Layout, Card, List, Avatar } from "antd";
import SideBar from "../layout/sidebar/sidebar";
import * as PatientActions from "../../redux/actions/patient-actions";
import * as SurveyActions from "../../redux/actions/survey-actions";
import { notifyUser } from "../../services/notification-service";
import moment from "moment-timezone";
import firebase from '../../../../src/config/firebase';
import Config from "../../config";
const { Header, Sider, Content } = Layout;
const dateFormathh = "MM/DD/YYYY";

class SurveyReview extends Component {
  constructor(props) {
    super(props);
    this.state = {
      submitted: false,
      patientData: undefined,
      quesAnsListing: undefined,
      loading: true,
      qaLoading: true,
      isModalVisible: false,
      image: {}
    };
  }

  async componentDidMount() {
    let studyId = localStorage.getItem("studyId")
    // await this.props.getPatientById(this.props.match.params.id);
    if (studyId == 2) {
      await this.props.getPatientByIdJanssen(this.props.match.params.id);
    } else if (studyId == 4) {
      await this.props.getPatientByIdNih(this.props.match.params.id);
    }else{
      await this.props.getPatientById(this.props.match.params.id);
    }
    if (this.props.patientData && this.props.patientData.length > 0 && this.props.patientData[0]) {
      this.setState({ loading: false })
      let patientId = this.props.match.params.id;
      let taskId = this.props.match.params.taskId;
      if (studyId === 1){
        await this.props.reviewSurvey( patientId,taskId);
      } else if (studyId == 2) {
        await this.props.reviewSurveyJanssen( patientId,taskId);        
      } else {
        await this.props.reviewSurveyNih( patientId,taskId);
      }
      if (this.props.quesAnsData && this.props.quesAnsData.quesAnsList) {
        this.setState({ qaLoading: false })
      } else if (this.props.quesAnsLisError) {
        this.setState({ qaLoading: false })
      }
    } else if (this.props.patientDataError) {
      notifyUser(this.props.patientDataError, "error");
      this.props.history.push("/patients/1");
    }
  };

  async componentWillUnmount() {
    await  this.props.clearScreeningQuestionState();
  }

  goToChat = () => {
    var patientId = this.props.match.params.id;
     var ref = firebase.firestore().collection("chatrooms");
     var type =  Config.server.type;
     ref.where(`users.${patientId+type}`, '==',true).get().then(docs =>
      {
       let room ={};
       docs.forEach((snapshot)=> {
         room = snapshot.data();
         room.id = snapshot.id;
       })
         this.props.history.push("/chat/"+patientId);
      }
     )
  }

  showModal = (img) => {
    this.setState({ image: img, isModalVisible: true });
  };

  handleCancel = () => {
    this.setState({ isModalVisible: false });
  };

  setDate = (date) => {
    var month = new Date(date).toLocaleString('default', { month: 'short' })
    var day = new Date(date).getDate();
    var year = new Date(date).getFullYear();
    return month + " " + day + ", " + year;
  }

  render() {
    //console.log(this.props.quesAnsData.quesAnsList);
    const { submitted } = this.state;
    const studyId = localStorage.getItem("studyId");
    return (
      <Layout>
        <Sider
          breakpoint="lg"
          collapsedWidth="0"
          width="260px"
          className="sidebar"
          onBreakpoint={(broken) => {
            console.log(broken);
          }}
          onCollapse={(collapsed, type) => {
            console.log(collapsed, type);
          }}
        >
          <SideBar {...this.props} options={options.dashBoardOptions} />
        </Sider>
        <Layout className="site-layout">
          <Header
            className="site-layout-background"
            style={{
              background: "#f2f5f9",
              zIndex: 100,
              top: 0,
              width: "100%",
              padding: 0,
            }}
          >
            <DashboardHeader
              {...this.props}
              isCollapsed={this.state.collapsed}
              toggleSidebar={this.toggleSidebar}
            />
          </Header>
          <Content
            className="site-layout-background"
            style={{
              margin: "24px 10px",
              padding: 10,
              minHeight: 280,
            }}
          >
            <div className="dashboard">
              {this.state.loading && <Spin />}
              <Spin spinning={submitted}>
                {!this.state.loading &&
                  <Row gutter={16}>
                    <Col sm={24} md={12} lg={8} xl={6}>
                      {<div className="white-box user-section">
                        <div className="user-profile">
                          <Avatar size={84}>
                            {this.props.patientData[0].firstName.toUpperCase().charAt(0) + this.props.patientData[0].lastName.toUpperCase().charAt(0)}
                          </Avatar>
                          <div className="profile-details">
                            <h3>{this.props.patientData[0].firstName + " " + this.props.patientData[0].lastName}</h3>
                            {
                              studyId === "1" ?
                              <button  className="btn" onClick={() => this.goToChat()}>Contact</button>:""
                            }
                          </div>
                        </div>
                        <List style={{ marginTop: "40px" }}
                          itemLayout="horizontal"
                          dataSource={this.props.patientData}
                          renderItem={patient => (
                            <>
                              <List.Item>
                                <Typography.Text style={{ color: "#006297" }}>Study:</Typography.Text> {patient.title}
                              </List.Item>
                              <List.Item>
                                <Typography.Text style={{ color: "#006297" }}>Email:</Typography.Text> {patient.email}
                              </List.Item>
                              <List.Item>
                                <Typography.Text style={{ color: "#006297" }}>Date of Birth:</Typography.Text> {patient.dateOfBirth ?moment(new Date(Number(patient.dateOfBirth))).format(
                                    "MM/DD/YYYY"
                                  ) : "N/A"}
                              </List.Item>
                              <List.Item>
                                <Typography.Text style={{ color: "#006297" }}>Age:</Typography.Text> {patient.age ? patient.age + " years" : "N/A"}
                              </List.Item>
                              <List.Item>
                                <Typography.Text style={{ color: "#006297" }}>Phone Number:</Typography.Text> {"(" + patient.phoneCode + ") " + patient.phoneNumber}
                              </List.Item>
                              <List.Item>
                                <Typography.Text style={{ color: "#006297" }}>Member Status:</Typography.Text> {patient.userScreeningStatus.replace("_", " ")}
                              </List.Item>
                              <List.Item>
                                <Typography.Text style={{ color: "#006297" }}>Registered Date:</Typography.Text> {patient.createdDate ? moment(patient.createdDate).format(dateFormathh) : "N/A"}
                              </List.Item>
                            </>
                          )}
                        />
                      </div>}
                    </Col>
                    <Col sm={24} md={12} lg={8} xl={18}>
                      <div className="white-box">
                       
                        <Card
                      title={this.props.quesAnsData && this.props.quesAnsData.name?this.props.quesAnsData.name:""}
                      extra={this.props.quesAnsData && this.props.quesAnsData.obtScore?this.props.quesAnsData.obtScore+" Out of "+this.props.quesAnsData.maxScore:""}
                      style={{ marginBottom: '8px', padding: '0 16px' }}
                      className="patient-dairy-section"
                    >
                       {this.state.qaLoading && <Spin />}
                        {!this.state.qaLoading && this.props.quesAnsData &&  this.props.quesAnsData.quesAnsList && this.props.quesAnsData.quesAnsList.length > 0 && 
                          <>
                            <List
                              itemLayout="horizontal"
                              className="screening-ques"
                              dataSource={this.props.quesAnsData.quesAnsList}
                              renderItem={(quesAnsData, index) => (
                                <>
                                  <List.Item >
                                   
                                      <List.Item.Meta
                                        title={<span><b>{"Q" + (index + 1) + ". "}</b> {quesAnsData.question}</span>}
                                        description={<span><b>{"A" + (index + 1) + ". " }</b> { quesAnsData.answer}</span>}
                                      />
                                    
                                  </List.Item>
                                </>
                              )}
                            />

                          </>
                        }
                      </Card>
                     
                       
                      </div>
                    </Col>
                  </Row>
                }
              </Spin>
            </div>
          </Content>
        </Layout>
      </Layout>
    );
  }
}

function mapStateToProps(state) {
  return { patientData: state.patient.patientData ,
    quesAnsData: state.survey.listQuesAns,
    quesAnsListError:state.survey.quesAnsListError};
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ ...PatientActions,...SurveyActions}, dispatch);
}
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(SurveyReview)
);
