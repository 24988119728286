import { Types } from "../constants/task-types";

const initialState = {
    listTasks: undefined,
};

export default function taskReducer(state = initialState, action) {
    switch (action.type) {
        case Types.TASK_LIST:
            return { ...state, listTasks: action.payload };
        default:
            return state;
    }
}
