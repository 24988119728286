import API from "../api/tickets-api";
import { Types } from "../constants/ticket-types";

export function getAllTicketsList() {
  return async function(dispatch, _getState) {
    try {
      let resp = await API.getAllTicketsList();
      if (resp && resp.data) {
        dispatch({
          type: Types.TICKET_LIST,
          payload: resp.data
        });
      }
      return resp;
    } catch (e) {
      return { error: true };
    }
  };
}

export function updateTicketStatus(ticketId) {
  
  return async function (dispatch, getState) {
    try {
      let resp = await API.updateTicketStatus(ticketId);
      return resp;
    } catch (e) {
      return { error: true };
    }
  };
}

export function getAttachmentImage(imagePath) {
  return async function (dispatch, _getState) {
    try {
      var imageData = [];
      let resp = await API.getAttachmentImage(imagePath);
      if (resp) {
        let data = URL.createObjectURL(resp);
        imageData.push({url: data });
        dispatch({
          type: Types.GET_ATTACHMENT,
          payload: imageData
        });
      } else {
        dispatch({
          type: Types.ATTACHMENT_NOT_FOUND,
          payload: imageData
        });
      }
      return imageData;
    } catch (e) {
      return { error: true };
    }
  };
}
