import React, { Component } from "react";
import { withRouter, Link } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  Row,
  Col,
  Form,
  Input,
  Button,
  Checkbox,
  Typography,
  Spin,
} from "antd";

import Icon from "@ant-design/icons";
import * as AuthActions from "../../redux/actions/auth-actions";
import { notifyUser } from "../../services/notification-service";

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      submitted: false,
      email: "",
      password: "",
    };
  }

  handleSubmit = async (values) => {
    let self = this;
    this.setState({ submitted: true });
    await self.props
      .login(values.email, values.password)
      .then((response) => {        
        if (response.error && response.error !== "") {
          notifyUser("Invalid Credentials", "error");
          self.setState({ submitted: false });
        } else {
          if (response.redirect && response.redirect !== "") {
            self.props.history.push(response.redirect);
            localStorage.setItem("roleName", response.data.authority[0].authority)
          } else {
            notifyUser("InValid Credentials", "error");
            self.setState({ submitted: false });
          }
        }
      });
  };

  render() {
    const { submitted } = this.state;
    const formLayout = "vertical";
    return (
      <div className="login">
        <div className="login-in">
          <section className="wrap flexbox">
            <Row gutter={24} style={{ width: "100%" }}>
              <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                <Row gutter={24}>
                  <Col xs={24}>
                    <Typography.Title level={4}>Login</Typography.Title>
                  </Col>
                </Row>
                <hr />
                <Spin spinning={submitted}>
                  <Form
                    initialValues={{
                      remember_me: true,
                    }}
                    layout={formLayout}
                    onFinish={this.handleSubmit}
                    className="login-form"
                  >
                    <Form.Item
                      name="email"
                      label="Username"
                      rules={[
                        {
                          required: true,
                          message: "Please input your email!",
                        },
                      ]}
                    >
                      <Input placeholder="Email" autoComplete="off" />
                    </Form.Item>
                    <Form.Item
                      name="password"
                      label="Password"
                      rules={[
                        {
                          required: true,
                          message: "Please input your password!",
                        },
                      ]}
                    >
                      <Input
                        prefix={
                          <Icon
                            type="lock"
                            style={{ color: "rgba(0,0,0,.25)" }}
                          />
                        }
                        type="password"
                        placeholder="Password"
                        autoComplete="off"
                      />
                    </Form.Item>
                    <Row gutter={24}>
                      <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                        <Form.Item name="remember_me" valuePropName="checked">
                          <Checkbox>Remember me</Checkbox>
                        </Form.Item>
                      </Col>
                      <Col xs={12} sm={12} md={12} lg={12} xl={12}
                        style={{ textAlign: "right" }}
                      >
                        <Link
                          className="login-form-forgot"
                          to="/forgot-password"
                        >
                          Forgot password
                        </Link>
                      </Col>
                      <Col xs={24} >
                        <Button
                          value="large"
                          block
                          type="primary"
                          htmlType="submit"
                          className="login-form-button"
                        >
                          Log in
                        </Button>
                      </Col>
                    </Row>
                  </Form>
                </Spin>
              </Col>
            </Row>
          </section>
        </div>
      </div>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ ...AuthActions }, dispatch);
}
export default withRouter(
  connect(null, mapDispatchToProps, null, { forwardRef: true })(Login)
);
