import { Types } from "../constants/lab-types";

const initialState = {
    uploadFile: undefined,
    testList:undefined
};

export default function labReducer(state = initialState, action) {
    switch (action.type) {
        case Types.UPLOAD_FILE:
            return { ...state, uploadFile: action.payload };
            case Types.LIST_TESTS:
                return { ...state, testList: action.payload };
        default:
            return state;
    }
}
