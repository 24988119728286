import API from "../api/admin-api";
import { Types } from "../constants/users-types";

export function getActivityLogs(userId) {

  return async function (_dispatch, _getState) {
    try {
      let data = {
        userId: userId
      };
      return await API.getActivityLogs(data);
    } catch (e) {
      return e;
    }
  };
}

export function addUser(data) {
  return async function (dispatch, getState) {
    try {
      let resp = await API.addUser(data);
      return resp;
    } catch (e) {
      return { error: e.response.data.error };
    }
  };
}

export function editUser(userId, data) {
  return async function (dispatch, getState) {
    try {
      let resp = await API.editUser(userId, data);
      return resp;
    } catch (e) {
      return { error: true };
    }
  };
}

export function updateUser(userId, data) {
  return async function (dispatch, getState) {
    try {
      let resp = await API.updateUser(userId, data);
      return resp;
    } catch (e) {
      return { error: true };
    }
  };
}

export function updateUserStatus(userId, value) {

  return async function (dispatch, getState) {
    try {
      let resp = await API.updateUserStatus(userId, value);
      return resp;
    } catch (e) {
      return { error: true };
    }
  };
}


export function getStudyTeam() {
  return async function (dispatch, getState) {
    try {
      let resp = await API.getStudyTeam();
      return resp;
    } catch (e) {
      return { error: true };
    }
  };
}

export function getUserById(id) {
  return async function (dispatch, getState) {
    try {
      let resp = await API.getUserById(id);
      if (resp && resp.data) {
        dispatch({
          type: Types.GET_LOGGED_IN_USER,
          payload: resp.data
        });
      }

      return resp;
    } catch (e) {
      return { error: true };
    }
  };
}

export function searchByKeyword(data) {

  return async function (dispatch, getState) {
    try {
      let resp = await API.searchByKeyword(data);
      return resp;
    } catch (e) {
      return { error: true };
    }
  };
}

export function downloadAllPatientsData(studyId) {
  return async function (dispatch, getState) {
    try {
      return await API.downloadAllPatientsData(studyId);
    } catch (e) {
      return { error: true };
    }
  };
}

export function downloadAllPatientsDataJanssen(studyId) {
  return async function (dispatch, getState) {
    try {
      var response = await API.downloadAllPatientsDataJanssen(studyId);
      return response;
    } catch (e) {
      return { error: true };
    }
  };
}

export function deletePatientsZipFile() {
  return async function (dispatch, getState) {
    try {
      return await API.deletePatientsZipFile();
    } catch (e) {
      return { error: true };
    }
  }
}
