import { Button, Result } from 'antd';

import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import SideBar from "./../layout/sidebar/sidebar.jsx";
import DashboardRouter from "../../routes/dashboard-router";
import DashboardHeader from "./../layout/header.jsx";
import options from "./../layout/sidebar/dashboard-options";
import * as AuthActions from "../../redux/actions/auth-actions";
import { Layout } from "antd";
import * as AdminActions from "../../redux/actions/admin-actions";

const { Header, Sider, Content } = Layout;

class NotFound extends Component {
  state = { data: {} , dataId: "", dTtile: {}};
  componentDidMount = () => {
  };
 
  render() {

    return (

        <Layout>
        <Sider
          breakpoint="xl"
          collapsedWidth="0"
          width="260px"
          className="sidebar"
          // trigger={null}
          onBreakpoint={(broken) => {
            console.log(broken);
          }}
          // collapsible
          onCollapse={(collapsed, type) => {
            console.log(collapsed, type);
          }}
        // collapsed={this.state.collapsed}
        >
          <SideBar {...this.props} options={options.dashBoardOptions} />
        </Sider>
        <Layout className="site-layout">
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
            <Result
            status="404"
            title="404"
            subTitle="Sorry, the page you visited does not exist."
            extra={
                <Button type="primary" onClick={() => this.props.history.push('/dashboard')}>
                Back Home
                </Button>
            }
            />
        </div>
        </Layout>
      </Layout>
    );
  }
}

function mapStateToProps(state) {
    return {
      userData: state.auth.userData,
    };
}
function mapDispatchToProps(dispatch) {
return bindActionCreators({ ...AuthActions, ...AdminActions }, dispatch);
}

export default withRouter(
connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(
    NotFound
)
);
