import { Types } from "../constants/ticket-types";

const initialState = {
    listTickets: undefined,
    getTicketAttachment:undefined,
    ticketAttachmentNotFound:undefined
};

export default function ticketReducer(state = initialState, action) {
    switch (action.type) {
        case Types.TICKET_LIST:
            return { ...state, listTickets: action.payload };
            case Types.GET_ATTACHMENT:
                return { ...state, getTicketAttachment: action.payload };
                case Types.ATTACHMENT_NOT_FOUND:
                    return { ...state, ticketAttachmentNotFound: action.payload };
        default:
            return state;
    }
}
