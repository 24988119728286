import React, { Component } from "react";
import { withRouter, Link } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import SideBar from "../layout/sidebar/sidebar.jsx";
import UserHeader from "../layout/header.jsx";
import options from "../layout/sidebar/dashboard-options";
import * as PatientActions from "../../redux/actions/patient-actions";
import * as StudyActions from "../../redux/actions/study-actions";

import * as AdverseEventActions from "../../redux/actions/adverse-event-actions";
import "react-h5-audio-player/lib/styles.css";

import {
  Layout,
  Row,
  Col,
  Avatar,
  Typography,
  List,
  Spin,
  Card,
  Button,
  Modal,
  Form,
  Input,
  Comment,
  Tooltip,
  PageHeader,
} from "antd";
import { AuditOutlined } from "@ant-design/icons";
import moment from "moment-timezone";
import { notifyUser } from "../../services/notification-service";
import firebase from "../../../config/firebase";
import Config from "../../config";
import { ChatSvg } from "../shared/svg/chatlg";
import { ServeySvg } from "../shared/svg/servey";
import { DataSvg } from "../shared/svg/dataicon";
import { DairySvg } from "../shared/svg/dairyicon";
import { AdverseEventSvg } from "../shared/svg/adverseevents";
import { PhotogallerySvg } from "../shared/svg/photogallery";
import Icon from "@ant-design/icons";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import { PlusCircleFilled, MinusCircleFilled } from "@ant-design/icons";
import { StudyDrugLogSvg } from "../shared/svg/studydruglog.jsx";
import { LabSvg } from "../shared/svg/labicon";
import { MedicationIconSvg } from "../shared/svg/medication";
const LabIcon = (props) => <Icon component={LabSvg} {...props} />;

const dateFormathh = "MM/DD/YYYY";
const { Header, Sider, Content } = Layout;
const ChatIcon = (props) => <Icon component={ChatSvg} {...props} />;
const ServeyIcon = (props) => <Icon component={ServeySvg} {...props} />;
const DataIcon = (props) => <Icon component={DataSvg} {...props} />;
const DairyIcon = (props) => <Icon component={DairySvg} {...props} />;
const AdverseEventIcon = (props) => (
  <Icon component={AdverseEventSvg} {...props} />
);
const PhotogalleryIcon = (props) => (
  <Icon component={PhotogallerySvg} {...props} />
);
const StudyDrugLogIcon = (props) => (
  <Icon component={StudyDrugLogSvg} {...props} />
);

const MedicationIcon = (props) => (
  <Icon component={MedicationIconSvg} {...props} />
);

const dateFormat = "Do MMM";

class AdverseEvents extends Component {
  constructor(props) {
    super(props);
    this.state = {
      collapsed: false,
      patientData: undefined,
      loading: true,
      formKey: 0,
      isModalVisible: false,
      submitted: false,
      patientVoiceDiary: [],
      selectItemId: "",
      selectedCol: "adverseEvents",
      adverseEvent: undefined,
      imageUrl: undefined,
      detailLoading: false,
    };
  }

  async componentDidMount() {
    let studyId = localStorage.getItem("studyId");
    this.props
      .getStudyById(studyId)
      .then((resp) => {
        this.setState({
          loading: false,
          data: resp.data,
        });
      })
      .catch((ex) => {
        this.setState({ loading: false });
      });
    let patientId = this.props.match.params.id;
    // await this.props.getPatientById(patientId);
    if (studyId == 2) {
      await this.props.getPatientByIdJanssen(patientId);
    } else if (studyId ==4){
      await this.props.getPatientByIdNih(patientId);
    }else {
      await this.props.getPatientById(patientId);
    }
    if (
      this.props.patientData &&
      this.props.patientData.length > 0 &&
      this.props.patientData[0]
    ) {
      this.setState({ loading: false });
    } else if (this.props.patientDataError) {
      notifyUser(this.props.patientDataError, "error");
      this.props.history.push("/patients/1");
    }
    await this.props.listAdverseEvents(patientId, studyId);
  }

  componentDidUpdate(prevProps, prevState) {
    
    if (prevProps.doctorComments !== this.props.doctorComments) {
      // Perform actions when doctorComments prop changes
    }
  }

  toggleSidebar = () => {
    this.setState({
      collapsed: !this.state.collapsed,
    });
  };

  showModal = (img) => {
    this.setState({ image: img, isModalVisible: true });
  };

  handleCancel = () => {
    this.setState({ isModalVisible: false });
  };

  addDoctorNotesOnAdverseEvents = async (values) => {
    let studyId = localStorage.getItem("studyId");
    let patientId = this.props.match.params.id;
    this.setState({ formKey: (this.state.formKey || 0) + 1 });

    let self = this;
    if (self.state.eventId === "" || self.state.eventId === null) {
      notifyUser("Please select any event", "error");
    } else {
      let data = {
        doctorNote: values.doctorNotes,
        eventId: this.state.selectItemId,
      };

      this.setState({ submitted: true });
      await self.props.addDoctorNotesOnAdverseEvents(data).then((response) => {
        if (response.error && response.error !== "") {
          notifyUser(response.error.message, "error");
          self.setState({ submitted: false });
        } else {
          this.forceUpdate();
          if (response.data && response.data !== "") {
            this.props.getDoctorCommentsOnAdverseEvents(this.state.selectItemId);
            notifyUser("Doctor note added Successfully", "success");
            self.setState({ submitted: false });
          }
        }
      });
    }
  };

  goToChat = () => {
    var patientId = this.props.match.params.id;
    var ref = firebase.firestore().collection("chatrooms");
    var type = Config.server.type;
    ref
      .where(`users.${patientId + type}`, "==", true)
      .get()
      .then((docs) => {
        let room = {};
        docs.forEach((snapshot) => {
          room = snapshot.data();
          room.id = snapshot.id;
        });
        this.props.history.push("/chat/" + patientId);
      });
  };

  goToDiary = (colId) => {
    this.setState({ selectedCol: colId });
    var patientId = this.props.match.params.id;
    this.props.history.push("/patient-diary/" + patientId);
  };

  goToPhotoGallery = (id) => {
    this.setState({ selectedCol: id });
    var patientId = this.props.match.params.id;
    this.props.history.push("/photo-gallery/" + patientId);
  };

  goToPatientProgress = () => {
    var patientId = this.props.match.params.id;
    this.props.history.push("/patient-progress/" + patientId);
  };

  goToPISignOff = () => {
    var patientId = this.props.match.params.id;
    this.props.history.push(
      "/adverse-event-signOff/" + patientId + "/" + this.state.selectItemId
    );
  };

  goToAdverseEventLogs = () => {
    this.props.history.push("/event-logs/" + this.state.selectItemId);
  };

  goToActivityData = (id) => {
    this.setState({ selectedCol: id });
    var patientId = this.props.match.params.id;
    this.props.history.push("/activity-data/" + patientId);
  };

  goToStudyDrugLog = (id) => {
    this.setState({ selectedCol: id });
    var patientId = this.props.match.params.id;
    this.props.history.push("/study-drug-log/" + patientId);
  };
  goToLab = (id) => {
    var patientId = this.props.match.params.id;
    this.props.history.push("/lab/" + patientId);
  };

  async goToAdverseEvent(item) {
    var patientId = this.props.match.params.id;
    let studyId = localStorage.getItem("studyId");
    this.setState({ detailLoading: true, submitted: true });
    await this.props.reviewAdverseEvents(
      studyId,
      item.adverseEventId,
      patientId
    );
    this.setState({
      selectItemId: item.adverseEventId,
      adverseEvent: item,
      detailLoading: false,
      imageUrl: this.props.adversePhotoUrl
    });
    await this.props.getDoctorCommentsOnAdverseEvents(item.adverseEventId);
    this.setState({ submitted: false });
  }

  goToEASiScoring = () => {
    var patientId = this.props.match.params.id;
    this.props.history.push("/EASI-head/" + patientId);
  };

  goToFlares = (id) => {
    this.setState({ selectedCol: id });
    var patientId = this.props.match.params.id;
    this.props.history.push("/flares/" + patientId);
  }

  checkForLongNumbers = (inputString) => {
    const longNumberPattern = /\b\d{6,}\b/; // Matches sequences of 6 or more digits

    return longNumberPattern.test(inputString);
  };


  formatDate = (numericDateString) => {
    const timestamp = parseInt(numericDateString);
    const dateObject = new Date(timestamp);

    const day = dateObject.getDate().toString().padStart(2, '0');
    const month = (dateObject.getMonth() + 1).toString().padStart(2, '0'); // Months are zero-based
    const year = dateObject.getFullYear().toString();

    return `${month}/${day}/${year}`;
  };

  goToNewMed = (id) => {
    this.setState({ selectedCol: id });
    var patientId = this.props.match.params.id;
    this.props.history.push("/new-medication/" + patientId);
  }

  render() {
    const {
      submitted,
      selectItemId,
      selectedCol,
      adverseEvent,
      detailLoading,
      imageUrl
    } = this.state;
    const oldDate = this.props.patientData && this.props.patientData[0] && this.props.patientData[0].dateOfBirth
    const dateType = !isNaN(Date.parse(oldDate))
    let finalDate = "";
    if (oldDate && dateType == true) {
      finalDate = moment(oldDate).format("MM/DD/YYYY")
    } else if (oldDate && dateType == false) {
      finalDate = moment(new Date(Number(oldDate))).format("MM/DD/YYYY")
    } else {
      finalDate = "N/A"
    }
    const studyId = localStorage.getItem("studyId");

    const patientData = this.props.patientData && this.props.patientData[0];



    // Patient Start End time
    const notificationEndDate = this.props.patientData && this.props.patientData[0] && this.props.patientData[0].notificationEndDate;
    const notificationTimezone = this.props.patientData && this.props.patientData[0] && this.props.patientData[0].notificationTimezone;
    const endDateInTimezone = moment.tz(notificationEndDate, notificationTimezone);
    let formattedTimeEnd = "";
    if (this.props.patientData && this.props.patientData[0] && this.props.patientData[0].notificationEndDate) {
      formattedTimeEnd = endDateInTimezone.format('hh:mm A');
    } else {
      formattedTimeEnd = "";
    }
    const formattedTimeEndData = formattedTimeEnd;

    const notificationStartDate = this.props.patientData && this.props.patientData[0] && this.props.patientData[0].notificationStartDate;
    const startDateInTimezone = moment.tz(notificationStartDate, notificationTimezone);
    let formattedTimeStart = "";
    if (this.props.patientData && this.props.patientData[0] && this.props.patientData[0].notificationStartDate) {
      formattedTimeStart = startDateInTimezone.format('hh:mm A');
    } else {
      formattedTimeStart = "";
    }
    const formattedTimeStartData = formattedTimeStart;
    // Patient Start End time end

    return (
      <Layout>
        <Sider
          breakpoint="xl"
          collapsedWidth="0"
          width="260px"
          className="sidebar"
          // trigger={null}
          onBreakpoint={(broken) => {
            console.log(broken);
          }}
          // collapsible
          onCollapse={(collapsed, type) => {
            console.log(collapsed, type);
          }}
        // collapsed={this.state.collapsed}
        >
          <SideBar {...this.props} options={options.dashBoardOptions} />
        </Sider>
        <Layout className="site-layout">
          <Header
            className="site-layout-background"
            style={{
              background: "#f2f5f9",
              zIndex: 100,
              top: 0,
              width: "100%",
              padding: 0,
            }}
          >
            <UserHeader
              {...this.props}
              isCollapsed={this.state.collapsed}
              toggleSidebar={this.toggleSidebar}
            />
          </Header>
          <Content className="site-layout-background">
            <PageHeader
              className="site-header-title"
              title={
                studyId === "2" ? 
                "JANSSEN SKINTRACKER STUDY"
                  : studyId === "1" ? "Eczema Tracking Study" 
                  : studyId === "4" ? "NIH SkinTracker"
                  : "SkinTracker-PARADE"
              }
            ></PageHeader>

            <Spin spinning={submitted}>
              {!this.state.loading && (
                <Row gutter={16}>
                  <Col xs={24} sm={12} md={6} xl={6}>
                    <div className="white-box user-section">
                      <div className="user-profile">
                        <Link to={"/patient-progress/" + this.props.match.params.id} >
                          <Avatar size={84}>
                            {this.props.patientData &&
                              this.props.patientData[0].firstName
                                .toUpperCase()
                                .charAt(0) +
                              this.props.patientData[0].lastName
                                .toUpperCase()
                                .charAt(0)}
                          </Avatar>
                        </Link>
                        <div className="profile-details">
                          <Link to={"/patient-progress/" + this.props.match.params.id} >
                            <h3>
                              {this.props.patientData &&
                                this.props.patientData[0].firstName +
                                " " +
                                this.props.patientData[0].lastName}
                            </h3>
                          </Link>
                          {
                            studyId == 1 ?
                            <button
                              className="btn"
                              onClick={() => this.goToChat()}
                            >
                              Chat Now
                            </button>
                            : 
                            ""
                          }
                        </div>
                      </div>
                      <div className="user-details">
                        <List
                          style={{ marginTop: "40px" }}
                          itemLayout="horizontal"
                          dataSource={this.props.patientData}
                          renderItem={(patient) => (
                            <>
                              <List.Item>
                                <Typography.Text style={{ color: "#006297" }}>
                                  Study:
                                </Typography.Text>{" "}
                                {patient.title}
                              </List.Item>
                              <List.Item>
                                <Typography.Text style={{ color: "#006297" }}>
                                  Email:
                                </Typography.Text>{" "}
                                {patient.email}
                              </List.Item>
                              <List.Item>
                                <Typography.Text style={{ color: "#006297" }}>
                                  Date of Birth:
                                </Typography.Text>{" "}
                                {/* {patient.dateOfBirth
                                  ? moment(new Date(Number(patient.dateOfBirth))).format(
                                    "DD-MM-YYYY"
                                  )
                                  : "N/A"} */}
                                  {finalDate}
                              </List.Item>
                              <List.Item>
                                <Typography.Text style={{ color: "#006297" }}>
                                  Age:
                                </Typography.Text>{" "}
                                {patient.age ? patient.age + " years" : "N/A"}
                              </List.Item>
                              <List.Item>
                                <Typography.Text style={{ color: "#006297" }}>
                                  Phone Number:
                                </Typography.Text>{" "}
                                {"(" +
                                  patient.phoneCode +
                                  ") " +
                                  patient.phoneNumber}
                              </List.Item>
                              <List.Item>
                                <Typography.Text style={{ color: "#006297" }}>
                                  Member Status:
                                </Typography.Text>{" "}
                                {patient.userScreeningStatus.replace("_", " ")}
                              </List.Item>
                              {
                                studyId == 2 ?
                                <List.Item>
                                  <Typography.Text style={{ color: "#006297" }}>
                                    Patient's TimeZone:
                                  </Typography.Text>{" "}
                                    {patient.notificationTimezone}
                                </List.Item>
                                : ""
                              }
                              {
                                studyId == 2 ?
                                <List.Item>
                                  <Typography.Text style={{ color: "#006297" }}>
                                    Notifications Time:
                                  </Typography.Text>{" "}
                                    {formattedTimeStartData} {!formattedTimeStartData && !formattedTimeEndData ? "" : "to"} {formattedTimeEndData}
                                </List.Item>
                                : 
                                ""
                              }
                              <List.Item>
                                <Typography.Text style={{ color: "#006297" }}>
                                  Registered Date:
                                </Typography.Text>{" "}
                                {patient.createdDate
                                  ? moment(patient.createdDate)
                                    .format(dateFormathh)
                                  : "N/A"}
                              </List.Item>
                            </>
                          )}
                        />
                      </div>
                    </div>
                  </Col>
                  <Col xs={24} sm={12} md={18} xl={18}>
                    <Card
                      title={"Adverse Events"}
                      extra={
                        <Button onClick={() => this.goToPatientProgress()}>
                          Back
                        </Button>
                      }
                      style={{ marginBottom: "8px", padding: "0 16px" }}
                      className="patient-dairy-section"
                    >
                      <Row gutter={0}>
                        <Col xs={24} sm={12} md={8} xl={8}>
                          {/* Activity Tabs START */}
                          <div className="card-container">
                            <List
                              itemLayout="horizontal"
                              dataSource={this.props.adverseEventsList}
                              renderItem={(item) => (
                                <List.Item
                                  onClick={() => this.goToAdverseEvent(item)}
                                  className={
                                    selectItemId === item.adverseEventId
                                      ? "selected-item-list"
                                      : "item-list"
                                  }
                                >
                                  <List.Item.Meta
                                    avatar={
                                      <span className="date">
                                        {item.date
                                          ? moment(item.date).format(dateFormat)
                                          : "date"}
                                      </span>
                                    }
                                    title={
                                      item.patientName
                                        ? item.patientName
                                        : "name"
                                    }
                                  />
                                </List.Item>
                              )}
                            />
                          </div>
                          {/* /Activity Tabs END */}
                        </Col>
                        <Col xs={24} sm={12} md={16} xl={16}>
                          {/* Activity Content Section Start */}
                          <div className="dairy-content-section">
                            <div className="play-icon-container"></div>
                            <Spin spinning={detailLoading}>
                              {adverseEvent ? (
                                <Form
                                  layout="vertical"
                                  key={this.state.formKey}
                                  onFinish={this.addDoctorNotesOnAdverseEvents}
                                  initialValues={this.props.doctorComments}
                                >
                                  <List
                                    itemLayout="horizontal"
                                    className="screening-ques"
                                    dataSource={this.props.adverseEventDetail}
                                    renderItem={(quesAnsData, index) => (
                                      <List.Item>
                                        <List.Item.Meta
                                          title={
                                            <span>
                                              <b>{"Q" + (index + 1) + ". "}</b>
                                              {" "}
                                              {quesAnsData.question}
                                            </span>
                                          }
                                          description={
                                            <span>
                                              <b>{"A" + (index + 1) + ". "}</b>
                                              {" "}
                                              {(/^\d{12,}$/.test(quesAnsData.answer)) ? this.formatDate(quesAnsData.answer) : quesAnsData.answer}
                                            </span>
                                          }
                                        />
                                      </List.Item>
                                    )}
                                  />
                                  {
                                    imageUrl && imageUrl.length >0 ?
                                    <List
                                    grid={{ gutter: 8, column: 5 }}
                                    itemLayout="verticle"
                                    dataSource={imageUrl}
                                    renderItem={(img) => (
                                      <div>
                                        <List.Item>
                                          <img
                                            onClick={() => this.showModal(img)}
                                            width={130}
                                            src={img.url}
                                          />
                                          <Typography.Text>
                                            {img.desc}
                                          </Typography.Text>
                                        </List.Item>

                                        <Modal
                                          centered
                                          width={450}
                                          className="photo-zoom-popup"
                                          visible={this.state.isModalVisible}
                                          onCancel={this.handleCancel}
                                          footer={[<></>]}
                                        >
                                          <TransformWrapper
                                            defaultScale={1}
                                            defaultPositionX={1}
                                            defaultPositionY={1}
                                          >
                                            {({ zoomIn, zoomOut }) => (
                                              <>
                                                <PlusCircleFilled
                                                  size={30}
                                                  onClick={zoomIn}
                                                />
                                                <MinusCircleFilled
                                                  size={20}
                                                  onClick={zoomOut}
                                                />
                                                <TransformComponent>
                                                  <img
                                                    width={400}
                                                    src={this.state.image.url}
                                                    alt={img.desc}
                                                  />
                                                </TransformComponent>
                                              </>
                                            )}
                                          </TransformWrapper>
                                        </Modal>
                                      </div>
                                    )}
                                  /> : ""

                                  }
                                  {this.props.doctorComments ? (
                                    <div className="previous-comment-section">
                                      <Form.Item label="Previous Comments" >
                                        <div
                                          style={{
                                            maxHeight: "300px",
                                            overflow: "auto",
                                          }}
                                        >
                                          {this.props.doctorComments.map(
                                            (comment) => {
                                              return (
                                                <div>
                                                  <Comment
                                                    key={comment.id}
                                                    author={comment.doctorName}
                                                    content={comment.doctorNote}
                                                    datetime={
                                                      <Tooltip>
                                                        {moment(
                                                          comment.noteDate
                                                        ).format(dateFormathh)}
                                                      </Tooltip>
                                                    }
                                                  />
                                                </div>
                                              );
                                            }
                                          )}
                                        </div>
                                      </Form.Item>
                                    </div>
                                  ) : (
                                    ""
                                  )}

                                  <Form.Item
                                    name="doctorNotes"
                                    label="Doctor Notes"
                                    rules={[
                                      {
                                        required: true,
                                        message:
                                          "Please add comment!",
                                        whitespace: true,
                                      },
                                    ]}
                                  >
                                    <Input.TextArea placeholder="Add comment..." />
                                  </Form.Item>

                                  <Form.Item className="btn-form-container">
                                    <Button type="primary" htmlType="submit">
                                      Submit
                                    </Button>
                                    <Button
                                      type="primary"
                                      onClick={() => this.goToPISignOff()}
                                    >
                                      PI SignOff
                                    </Button>
                                    <Button
                                      type="primary"
                                      onClick={() =>
                                        this.goToAdverseEventLogs()
                                      }
                                    >
                                      Event Logs
                                    </Button>
                                  </Form.Item>
                                </Form>
                              ) : (
                                ""
                              )}
                            </Spin>
                          </div>

                          {/* /Activity Content Section End */}
                        </Col>
                      </Row>
                    </Card>
                    <ul className="link-list-box" style={{ display: "block" }}>
                      {
                        studyId == 1 ?
                        <li>
                          <div onClick={() => this.goToChat()} class="link-box">
                            <ChatIcon />
                            <h4>Chat</h4>
                          </div>
                        </li>
                        : 
                        ""
                      }
                      <li>
                        <div
                          onClick={() => this.goToEASiScoring()}
                          class="link-box"
                        >
                          <ServeyIcon />
                          <h4>Surveys &amp; Scores</h4>
                        </div>
                      </li>
                      <li>
                        <div
                          onClick={() => this.goToDiary("diary")}
                          className={
                            selectedCol === "diary"
                              ? "link-box selected-item-list"
                              : "link-box"
                          }
                        >
                          <DairyIcon />
                          <h4>Diary</h4>
                        </div>
                      </li>
                      <li>
                        <div
                          onClick={() => this.goToPhotoGallery("gallery")}
                          className={
                            selectedCol === "gallery"
                              ? "link-box selected-item-list"
                              : "link-box"
                          }
                        >
                          <PhotogalleryIcon />
                          <h4>Photo Gallery </h4>
                        </div>
                      </li>
                      <li>
                        <div
                          className={
                            selectedCol === "adverseEvents"
                              ? "link-box selected-item-list"
                              : "link-box"
                          }
                        >
                          <AdverseEventIcon />
                          <h4>Adverse Events</h4>
                        </div>
                      </li>
                      <li>
                        <div
                          onClick={() => this.goToActivityData("activity")}
                          class="link-box"
                        >
                          <DataIcon />
                          <h4>Activity Data</h4>
                        </div>
                      </li>
                      {
                        studyId == 1 ?
                        <li>
                          <div
                            onClick={() => this.goToStudyDrugLog("log")}
                            class="link-box"
                          >
                            <StudyDrugLogIcon />
                            <h4>Study Drug Log</h4>
                          </div>
                        </li>
                        :
                        ""
                      }
                      {
                        studyId == 1 ?
                        <li>
                          <div
                            onClick={() => this.goToLab("lab")}
                            class="link-box"
                          >
                            <LabIcon />
                            <h4>Lab</h4>
                          </div>
                        </li>
                        : 
                        ""
                      }
                      {
                        studyId == 4 && (patientData && patientData.healthCondition !== "HEALTHY_CONTROL") ?
                        <li>
                          <div
                            onClick={() => this.goToFlares()}
                            class="link-box extra"
                          >
                            <AuditOutlined style={{fontSize: "35px"}}/>
                            <h4>Flares</h4>
                          </div>
                        </li>
                        : ""
                      }
                      {
                        studyId === "4" ?
                        <li>
                          <div
                            onClick={() => this.goToNewMed()}
                            class="link-box extra"
                          >
                            <MedicationIcon/>
                            <h4>Medication</h4>
                          </div>
                        </li>
                        : ""
                      }
                    </ul>
                  </Col>
                </Row>
              )}
            </Spin>
          </Content>
        </Layout>
      </Layout>
    );
  }
}

function mapStateToProps(state) {
  return {
    patientData: state.patient.patientData,
    adverseEventsList: state.adverseEvents.adverseEventsList,
    doctorComments: state.adverseEvents.doctorNotesList,
    adversePhotoUrl: state.adverseEvents.adversePhotoUrl,
    adverseEventDetail: state.adverseEvents.adverseEventDetail,
  };
}
function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    { ...PatientActions, ...StudyActions, ...AdverseEventActions },
    dispatch
  );
}
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(
    AdverseEvents
  )
);
