import React from "react";
import {Route, Redirect, withRouter} from 'react-router-dom';
import {connect} from 'react-redux';

const PublicRoute = ({component: Component, ...rest}) => {
  return <Route
      {...rest}
      render={
          props => {
              return !localStorage.getItem(`tokenData`) ?
                    (
                        <Component {...props} />
                    )
                    :
                    rest.selectedStudy?
                    (
                        <Redirect
                            to={{
                                pathname: "/dashboard",
                                state: {from: props.location}
                            }}
                        />
                    )
                    : !rest.selectedStudy && rest.role === "ROLE_SUPERADMIN" ?
                    (
                        <Redirect
                            to={{
                                pathname: "/user-list/",
                                state: {from: props.location}
                            }}
                        />
                    ) 
                    :
                    (
                        <Redirect
                            to={{
                                pathname: "/studies",
                                state: {from: props.location}
                            }}
                        />
                    ) 
          }
      }
  />
};

const mapStateToProps = state => (
  {
  }
);

export default withRouter(connect(
  mapStateToProps, null, null, {pure: false}
)(PublicRoute));

