import React from "react";
export const AdverseEventSvg = () => (
  <svg
    version="1.1"
    id="Layer_1"
    x="0px"
    y="0px"
    width="3em"
    height="3em"
    viewBox="0 0 64 64"
    fill="currentColor"
  >
    <g>
      <path d="M39,53a1,1,0,0,0-1,1v7a1,1,0,0,0,2,0V54A1,1,0,0,0,39,53Z"></path>
      <circle cx="19" cy="16" r="1"></circle>
      <circle cx="25" cy="17" r="1"></circle>
      <circle cx="21" cy="23" r="1"></circle>
      <circle cx="15" cy="25" r="1"></circle>
      <circle cx="21" cy="31" r="1"></circle>
      <circle cx="13" cy="18" r="1"></circle>
      <path d="M19.707,20.707a1,1,0,0,0-1.414-1.414,1.831,1.831,0,0,1-2.586,0,1,1,0,0,0-1.414,1.414,3.831,3.831,0,0,0,5.414,0Z"></path><path d="M27.707,19.293a1,1,0,0,0-1.414,0,1.831,1.831,0,0,1-2.586,0,1,1,0,0,0-1.414,1.414,3.831,3.831,0,0,0,5.414,0A1,1,0,0,0,27.707,19.293Z"></path>
      <path d="M18.707,28.707a3.247,3.247,0,0,1,4.586,0,1,1,0,0,0,1.414-1.414,5.249,5.249,0,0,0-7.414,0,1,1,0,0,0,1.414,1.414Z"></path><path d="M11,55a1,1,0,0,0-1,1v5a1,1,0,0,0,2,0V56A1,1,0,0,0,11,55Z"></path>
      <circle cx="34" cy="55" r="1"></circle>
      <circle cx="36" cy="59" r="1"></circle>
      <circle cx="27" cy="25" r="1"></circle>
      <circle cx="5" cy="56" r="1"></circle><circle cx="7" cy="48" r="1"></circle>
      <circle cx="8" cy="59" r="1"></circle><circle cx="7" cy="52" r="1"></circle>
      <path d="M52.331,36.5a.988.988,0,0,0,.9.5,1,1,0,0,0,.864-.557L54.312,36a.939.939,0,0,1,.925-.534l.493.032a.988.988,0,0,0,1.062-.934,1,1,0,0,0-.934-1.062l-.492-.032a2.975,2.975,0,0,0-2.215.791,7,7,0,0,0-1.118-.976l.014-.242a.962.962,0,0,1,.712-.872,1,1,0,1,0-.518-1.931,2.952,2.952,0,0,0-2.09,2.1,7.008,7.008,0,0,0-1.6-.312.957.957,0,0,1,0-1.063l.274-.411a1,1,0,1,0-1.664-1.11l-.274.412a2.947,2.947,0,0,0-.414,2.314A6.959,6.959,0,0,0,45,32.686a.959.959,0,0,1-.531-.923l.032-.493a1,1,0,1,0-2-.128l-.032.492a2.946,2.946,0,0,0,.8,2.217q-.221.2-.423.424a2.957,2.957,0,0,0-2.216-.8l-.493.032a1,1,0,0,0,.128,2l.494-.032a.961.961,0,0,1,.922.531,7.014,7.014,0,0,0-.529,1.537,2.952,2.952,0,0,0-2.864.759,1,1,0,0,0,1.414,1.414.962.962,0,0,1,1.111-.18l.214.107a6.923,6.923,0,0,0,.31,1.517,2.951,2.951,0,0,0-2.1,2.09,1,1,0,1,0,1.931.518.962.962,0,0,1,.872-.712l.245-.014a7.073,7.073,0,0,0,.986,1.116,2.946,2.946,0,0,0-.8,2.216l.032.493a1,1,0,0,0,1,.936c.022,0,.043,0,.065,0A1,1,0,0,0,44.5,46.73l-.032-.494A.958.958,0,0,1,45,45.314a6.951,6.951,0,0,0,2.448.658.957.957,0,0,1,0,1.062l-.274.411a1,1,0,0,0,1.664,1.11l.274-.412a2.943,2.943,0,0,0,.414-2.316A6.905,6.905,0,0,0,51,45.315a.959.959,0,0,1,.53.922l-.032.493a1,1,0,0,0,.934,1.062c.022,0,.043,0,.065,0a1,1,0,0,0,1-.936l.032-.492a2.949,2.949,0,0,0-.8-2.213c.147-.135.287-.277.421-.423a2.947,2.947,0,0,0,2.02.806c.064,0,.129,0,.194-.006l.493-.032a1,1,0,1,0-.128-2l-.494.032a.952.952,0,0,1-.919-.527,6.955,6.955,0,0,0,.662-2.458.962.962,0,0,1,1.055.009l.411.274a1,1,0,1,0,1.11-1.664l-.412-.274a2.956,2.956,0,0,0-3.286,0l-.412.274A1,1,0,0,0,53,39a5,5,0,1,1-.669-2.5Z"></path>
      <path d="M47,38a1,1,0,0,0-2,0,4,4,0,0,0,4,4,1,1,0,0,0,0-2A2,2,0,0,1,47,38Z"></path>
      <circle cx="50" cy="38" r="1"></circle>
      <path d="M59.3,30.75a1,1,0,0,0-1.614,1.181A11.983,11.983,0,0,1,40.814,48.593a1,1,0,0,0-1.047-.094L36.5,50.133l1.135-4.54a1,1,0,0,0-.1-.732A11.986,11.986,0,0,1,55.069,29.319a1,1,0,0,0,1.181-1.614,13.967,13.967,0,0,0-21.24,6.1A10.977,10.977,0,0,0,29,32h-1.7a11.062,11.062,0,0,0,3.119-3.356A14.983,14.983,0,1,0,11.018,5.825a1,1,0,1,0,1.333,1.491A12.988,12.988,0,0,1,31.95,24c.029-.329.05-.66.05-1V19a1,1,0,0,0-2,0v4a9,9,0,0,1-18,0V15.954a11.074,11.074,0,0,0,7.238-3.666l1.925,1.1A19.856,19.856,0,0,0,31,16a1,1,0,0,0,0-2,17.851,17.851,0,0,1-8.845-2.349L19.5,10.132a1,1,0,0,0-1.3.268A9.044,9.044,0,0,1,11,14a1,1,0,0,0-1,1v8c0,.337.021.668.05,1a12.937,12.937,0,0,1-.09-13.835,1,1,0,1,0-1.7-1.055,14.931,14.931,0,0,0,3.315,19.537A11.062,11.062,0,0,0,14.7,32H13A11.013,11.013,0,0,0,2,43V61a1,1,0,0,0,2,0V47h6v5a1,1,0,0,0,2,0V43a1,1,0,0,0-2,0v2H4V43a9.011,9.011,0,0,1,9-9h2a6,6,0,0,0,12,0h2a8.977,8.977,0,0,1,5.382,1.8,13.674,13.674,0,0,0,1,9.2H32V43a1,1,0,0,0-2,0V61a1,1,0,0,0,2,0V51a1,1,0,0,0,0-2V47h3.219L34.03,51.757A1,1,0,0,0,35.447,52.9l4.675-2.338A13.849,13.849,0,0,0,48,53,13.984,13.984,0,0,0,59.3,30.75ZM21,38a4,4,0,0,1-4-4v-.764a10.853,10.853,0,0,0,8,0V34A4,4,0,0,1,21,38Z"></path>
      <path d="M27,50H25.816a2.994,2.994,0,0,0-.452-.825L25.8,48.6A1,1,0,0,0,24.2,47.4l-.522.689A3,3,0,0,0,23,48a1,1,0,0,1-1-1,2.953,2.953,0,0,0-.116-.78l.614-.353a1,1,0,0,0-1-1.734l-.758.436A2.984,2.984,0,0,0,20,44.184V43a1,1,0,0,0-2,0v1.184a3,3,0,0,0-.744.385l-.757-.436a1,1,0,0,0-1,1.734l.615.353A2.959,2.959,0,0,0,16,47a6.945,6.945,0,0,0,.036.71l-1.279.32A1,1,0,0,0,15,50a.976.976,0,0,0,.244-.03l1.281-.32a6.989,6.989,0,0,0,.878,1.534l-1.109,1.109a1,1,0,1,0,1.414,1.414L18.816,52.6a6.989,6.989,0,0,0,1.534.878l-.32,1.281a1,1,0,0,0,.727,1.213A.976.976,0,0,0,21,56a1,1,0,0,0,.969-.757l.32-1.279A6.945,6.945,0,0,0,23,54a3.03,3.03,0,0,0,.469-.047l.611,1.438A1,1,0,0,0,25,56a.984.984,0,0,0,.391-.08,1,1,0,0,0,.529-1.311l-.689-1.623A2.979,2.979,0,0,0,25.816,52H27a1,1,0,0,0,0-2Zm-9-3a1,1,0,0,1,2,0,3,3,0,0,0,3,3,1,1,0,0,1,0,2A5.006,5.006,0,0,1,18,47Z"></path>
    </g>
  </svg>
);
