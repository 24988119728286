import { API } from "../../config";
import AxiosAuth2 from "../../services/axios-service-auth";
import { getToken } from "../../services/user-service";

const listAdverseEvents = (patientId,studyId) => {
  return AxiosAuth2.get("/api/adverseEventAnswers/getPatientAdverseEvents?patientId="+patientId+"&studyId="+studyId, undefined, undefined);
};

const getAdverseEventDetails = (eventId) => {
  return AxiosAuth2.get("/api/adverseEvents/getAdverseEventByAdverseId/"+eventId, undefined, undefined);
};

const reviewAdverseEvents = (data) => {
  return AxiosAuth2.post("api/adverseEventAnswers/getAdverseEventReviewData", data, undefined);
};

const addDoctorNotesOnAdverseEvents = (data) => {
  return AxiosAuth2.post("/api/doctorAdverseEventNotes/saveDoctorAdverseEventNotes", data, undefined);
};

const getDoctorCommentsOnAdverseEvents = (eventId) => {
  return AxiosAuth2.get("/api/doctorAdverseEventNotes/getDoctorAdverseEventNotes/"+eventId, undefined, undefined);
};

const getAdverseEventImage = (data) => {
  return API+"api/adverseEventAnswers/getAdverseEventImage?imagePath="+data+"&x-auth-token="+getToken();
};

const listAllAdverseEvents  = (studyId) => {
  return AxiosAuth2.get("api/adverseEventAnswers/getAllAdverseEvents?studyId="+studyId, undefined, undefined);
};

const getSignOffInfo = (patientId,eventId) => {
  return AxiosAuth2.get("/api/adverseEventPIReview/getAdverseEventReviewData?adverseEventId="+eventId+"&patientId="+patientId, undefined, undefined);
};

const getPIUsers = () => {
  return AxiosAuth2.get("/api/admin/getPiUsers", undefined, undefined);
};

const listPIAdverseEvents = () => {
  return AxiosAuth2.get("/api/adverseEventPIReview/getPiAdverseEvents", undefined, undefined);
};

const assignToPIForSignOff = (data) => {
  return AxiosAuth2.post("/api/adverseEventPIReview/savePIReview", data, undefined);
};

const getAdverseEventLogs = (eventId) => {
  return AxiosAuth2.get("/api/adverseEventAnswers/getAdverseEventLogs?adverseEventId="+eventId, undefined, undefined);
};


export default {
    listAdverseEvents,
    getPIUsers,
    getSignOffInfo,
    reviewAdverseEvents,
    getAdverseEventDetails,
    addDoctorNotesOnAdverseEvents,
    getDoctorCommentsOnAdverseEvents,
    getAdverseEventImage,
    listAllAdverseEvents,
    assignToPIForSignOff,
    listPIAdverseEvents,
    getAdverseEventLogs
};
