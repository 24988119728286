import React from "react";
export const ServeySvg = () => (
    <svg
        version="1.1"
        id="Layer_1"
        x="0px"
        y="0px"
        width="3em"
        height="3em"
        fill="currentColor"
        viewBox="0 0 384 384.2"
    >
        <g>
            <path d="M81.6,158.9c43,0,77.9-35,77.9-77.9S124.6,3,81.6,3S3.7,37.9,3.7,80.9S38.6,158.9,81.6,158.9z M81.6,23
		c32,0,57.9,26,57.9,57.9c0,32-26,57.9-57.9,57.9s-57.9-26-57.9-57.9C23.7,49,49.7,23,81.6,23z"/>
            <path d="M81.5,121.3c18.2,0,33.8-13.8,33.8-25.2c0-5.5-4.5-10-10-10c-5,0-9.2,3.7-9.9,8.6c-1.3,1.9-6.8,6.6-13.9,6.6
		s-12.6-4.6-13.9-6.6c-0.7-4.9-4.9-8.6-9.9-8.6c-5.5,0-10,4.5-10,10C47.7,107.4,63.3,121.3,81.5,121.3z"/>
            <circle cx="60.7" cy="61.9" r="10.1" />
            <circle cx="102.7" cy="61.9" r="10.1" />
        </g>
        <g>
            <path d="M303.6,158.9c43,0,77.9-35,77.9-77.9S346.6,3,303.6,3s-77.9,35-77.9,77.9S260.6,158.9,303.6,158.9z M303.6,23
		c32,0,57.9,26,57.9,57.9c0,32-26,57.9-57.9,57.9c-32,0-57.9-26-57.9-57.9C245.7,49,271.7,23,303.6,23z"/>
            <circle cx="282.7" cy="61.9" r="10.1" />
            <circle cx="324.7" cy="61.9" r="10.1" />
            <path d="M279.7,117.3c5,0,9.2-3.7,9.9-8.6c1.3-1.9,6.8-6.6,13.9-6.6s12.6,4.6,13.9,6.6c0.7,4.9,4.9,8.6,9.9,8.6c5.5,0,10-4.5,10-10
		c0-11.3-15.6-25.2-33.8-25.2s-33.8,13.8-33.8,25.2C269.7,112.8,274.1,117.3,279.7,117.3z"/>
        </g>
        <path d="M327.2,221.6c-0.3-0.4-0.6-0.7-0.9-1c-0.3-0.3-0.7-0.6-1.1-0.9c-34.5-33.9-81.8-54.9-134-54.9c-52,0-99.3,20.9-133.8,54.7
	c-0.3,0.2-0.6,0.5-0.9,0.7c-0.4,0.4-0.7,0.8-1.1,1.2C21.3,256,0,303.6,0,356c0,5.5,4.5,10,10,10h44.6c5.5,0,10-4.4,10-9.9
	c0.5-69.3,57.3-125.8,126.7-125.8c69.9,0,126.7,56.8,126.7,126.7c0,2.7,1.1,5.3,3,7.2c1.9,1.8,4.4,2.8,7,2.8c0.1,0,0.1,0,0.2,0
	l44.6-1c5.4-0.1,9.8-4.6,9.8-10C382.6,303.6,361.4,256.1,327.2,221.6z M286.9,245.9c-23.3-20.1-53-33-85.6-35.2V185
	c39.6,2.3,75.6,18.1,103.5,42.9L286.9,245.9z M95,246.4L77.7,228c27.9-24.8,64-40.6,103.6-42.9v25.6
	C149.3,212.8,119.2,225.3,95,246.4z M45,346H20.3c2.3-39.8,18.2-76,43.2-103.9l17.3,18.4C59.9,284.5,47.4,314.2,45,346z
	 M337.6,346.8c-2.3-33.2-15.7-63.4-36.5-86.9L319,242c25,28,41,64.3,43.3,104.2L337.6,346.8z"/>
        <path d="M201.1,317.3l0.4-55.1c0-5.5-4.4-10-9.9-10.1c0,0,0,0-0.1,0c-5.5,0-10,4.4-10,9.9l-0.4,55.8c-13,4.5-22.4,17-22.4,31.5
	c0,18.4,15,33.4,33.4,33.4c18.4,0,33.4-15,33.4-33.4C225.4,334.2,215.1,321.3,201.1,317.3z M192,362.8c-7.4,0-13.4-6-13.4-13.4
	c0-7.4,6-13.4,13.4-13.4c7.4,0,13.4,6,13.4,13.4C205.4,356.8,199.4,362.8,192,362.8z"/>
    </svg>
);
