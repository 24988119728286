import { Types } from "../constants/auth-types";
const initialState = {
  userData: {},
  isLoggedIn: false,
  token: "",
  companyData: {},
  userProfile:undefined
};
export default function authReducer(state = initialState, action) {
  switch (action.type) {
    case Types.SAVE_USER:
      return {
        ...state,
        userData: action.payload.user,
        token: action.payload.token,
      };
    case Types.VALIDATE_USER:
      return {
        ...state,
        userData: action.payload.user,
        token: action.payload.token,
      };
    case Types.LOGIN_SUCCESS:
      return { ...state, isLoggedIn: action.payload };
      case Types.USER_PROFILE:
        return { ...state, userProfile: action.payload };
    case Types.LOGIN_FAIL:
      return {
        ...state,
        userData: {},
        token: "",
        isLoggedIn: false,
      };
    case Types.LOG_OUT:
      return {
        ...state,
        userData: initialState.userData,
        token: initialState.token,
        isLoggedIn: false,
      };
    case Types.SAVE_COMPANY:
      return {
        ...state,
        companyData: action.payload,
      };
    default:
      return state;
  }
}
