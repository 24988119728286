export const Types = {
  LIST_ALL_MEDICAL_HISTORY: "LIST_ALL_MEDICAL_HISTORY",
  MEDICAL_HISTORY_NOT_FOUND: "MEDICAL_HISTORY_NOT_FOUND",

  /* LIST_ADVERSE_EVENTS: "LIST_ADVERSE_EVENTS",
  LIST_ALL_ADVERSE_EVENTS:"LIST_ALL_ADVERSE_EVENTS",
  LIST_ADVERSE_EVENT_LOGS:"LIST_ADVERSE_EVENT_LOGS",
  LIST_PI_ADVERSE_EVENTS:"LIST_PI_ADVERSE_EVENTS",
  ADVERSE_EVENT_DETAILS: "ADVERSE_EVENT_DETAILS",
  ADVERSE_EVENT_DETAILS_NOT_FOUND: "ADVERSE_EVENT_DETAILS_NOT_FOUND",
  GET_DOCTOR_NOTES:"GET_DOCTOR_NOTES",
  DOCTOR_NOTES_NOT_FOUND:"DOCTOR_NOTES_NOT_FOUND",
  GET_IMAGE_URL:"GET_IMAGE_URL",
  IMAGE_URL_NOT_FOUND:"IMAGE_URL_NOT_FOUND",
  SIGN_OFF_INFO:"SIGN_OFF_INFO",
  PI_USERS:"PI_USERS" */
};