import React, { Component } from "react";
import { withRouter, } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Row, Col, Form, Input, Button, Spin, Select, Card, Layout, DatePicker } from "antd";
import SideBar from "../layout/sidebar/sidebar.jsx";
import * as AdverseEventActions from "../../redux/actions/adverse-event-actions";
import { notifyUser } from "../../services/notification-service";
import options from "../layout/sidebar/dashboard-options";
import UserHeader from "../layout/header.jsx";
// import Config from "../../config";
import moment from "moment-timezone";

const { Option } = Select;
const { Sider, Header, Content } = Layout;
const severity = [{ name: "Mild" }, { name: "Moderate" }, { name: "Severe" }, { name: "Potentially life threatening" }, { name: "Fatal Death" }]
const actions = [{ name: "None" }, { name: "Study Intervention Modified" }, { name: "Study Intervention Interrupted" }, { name: "Study Intervention discontinued" }]
const outcomes = [{ name: "Not Resolved" }, { name: "Resolved" }, { name: "Resolved w sequele" }, { name: "Recovering" }, { name: "Fatal" }, { name: "Unknown" }]
const relationships = [{ name: "unassessable/unclassifiable" }, { name: "conditional/unclassified" }, { name: "Not related" }, { name: "Unlikely" }, { name: "Possible" }, { name: "Probable" }, { name: "Definite" }]

class PiSignOff extends Component {
    constructor(props) {
        super(props);
        this.state = {
            submitted: false,
            signOff: undefined,
            signOffId: 0,
            piUsers: undefined
        };
    }

    componentDidMount() {
        this.initialze()
    }

    async initialze() {
        let patientId = this.props.match.params.userId;
        let adverseId = this.props.match.params.adverseId;
        await this.props.getPIUsers();
        if (this.props.piUsers) {
            this.setState({ piUsers: this.props.piUsers })
        }
        await this.props.getSignOffInfo(patientId, adverseId);
        if (this.props.signOffInfo) {
            this.setState({ signOff: this.props.signOffInfo });
        }
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.signOffInfo !== this.props.signOffInfo) {
            var signOff = nextProps.signOffInfo
            this.setState({ signOff: signOff })
        }
    }

    handleSubmit = async (values) => {
        let self = this;
        let patientId = this.props.match.params.userId;
        let adverseId = this.props.match.params.adverseId;
        let data = {
            action: values.action,
            adverseEvent: values.adverseEvent,
            adverseEventId: adverseId,
            // authorSign: values.author,
            startDate: moment(values.startDate).format('x'),
            endDate: moment(values.endDate).format('x'),
            date: moment(new Date()).format('x'),
            outcome: values.outcome,
            relationship: values.relationship,
            seriousAE: values.seriousAe,
            severity: values.severe,
            treatment: values.treatment,
            unexpectedAE: values.unexpectedAe,
            piSignId: values.piUsers,
            patientId: patientId
        };
        this.setState({ submitted: true });
        await self.props
            .assignToPIForSignOff(data)
            .then((response) => {
                if (response.error && response.error !== "") {
                    notifyUser(response.error.message, "error");
                    self.setState({ submitted: false });
                } else {
                    if (response.data && response.data !== "") {
                        notifyUser("Assigned to PI Successfully", "success");
                        //self.props.history.push("/adverse-events");
                        //self.props.history.push('/user-list');
                        self.setState({ submitted: false });
                    }
                }
            });
    };

    onClick = () => {
        let patientId = this.props.match.params.userId;
        this.props.history.push("/adverse-events/" + patientId);
    }

    render() {
        const { submitted, signOff, piUsers } = this.state;

        const severityOptions = severity.map(
            (severe) =>
                <Option key={severe.name} value={severe.name}>
                    {severe.name}
                </Option>
        );
        let piUserOptions = undefined;
        if (piUsers) {
            piUserOptions = piUsers.map(
                (user) =>
                    <Option key={user.id} value={user.id}>
                        {user.firstName + " " + user.lastName}
                    </Option>
            );
        }



        const outcomeOptions = outcomes.map(
            (outcome) =>
                <Option key={outcome.name} value={outcome.name}>
                    {outcome.name}
                </Option>
        );

        const actionOptions = actions.map(
            (action) =>
                <Option key={action.name} value={action.name}>
                    {action.name}
                </Option>
        );

        const relationShipOptions = relationships.map(
            (relation) =>
                <Option key={relation.name} value={relation.name}>
                    {relation.name}
                </Option>
        );

        const formLayout = "vertical";
        return (
            <Layout>
                <Sider
                    breakpoint="xl"
                    collapsedWidth="0"
                    width="260px"
                    className="sidebar"
                    onBreakpoint={(broken) => {
                        console.log(broken);
                    }}
                    onCollapse={(collapsed, type) => {
                        console.log(collapsed, type);
                    }}
                >
                    <SideBar {...this.props} options={options.dashBoardOptions} />
                </Sider>
                <Layout className="site-layout">
                    <Header
                        className="site-layout-background"
                        style={{
                            background: "#f2f5f9",
                            zIndex: 100,
                            top: 0,
                            width: "100%",
                            padding: 0,
                        }}
                    >
                        <UserHeader
                            {...this.props}
                            isCollapsed={this.state.collapsed}
                            toggleSidebar={this.toggleSidebar}
                        />
                    </Header>
                    <Content className="site-layout-background" >
                        {<Card title={"PI Sign Off"} extra={<Button onClick={() => this.onClick()}>Back</Button>}>
                            <Spin spinning={submitted}>
                                {signOff ? <Form
                                    layout={formLayout}
                                    onFinish={this.handleSubmit}
                                    className="add-user"
                                >
                                    <Row gutter={16}>
                                        <Col xs={24} sm={24} md={12}>
                                            <Form.Item
                                                name="adverseEvent"
                                                label="Adverse Event:"
                                                initialValue=
                                                {signOff && signOff.adverseEvent ? signOff.adverseEvent : ""}
                                            >
                                                <Input placeholder="Adverse Event.." autoComplete="off" />
                                            </Form.Item>
                                        </Col>
                                        <Col xs={24} sm={24} md={12}>
                                            <Form.Item
                                                name="startDate"
                                                label="Start Date and Time:"

                                            >
                                                <DatePicker defaultValue={signOff ? moment(signOff.startDate) : ""} showTime format="YYYY-MM-DD HH:mm:ss" />
                                            </Form.Item>
                                        </Col>

                                        <Col xs={24} sm={24} md={12}>
                                            <Form.Item
                                                name="endDate"
                                                label="End Date and Time:"
                                            >
                                                <DatePicker defaultValue={signOff ? moment(signOff.endDate) : ""} showTime format="YYYY-MM-DD HH:mm:ss" />
                                            </Form.Item>
                                        </Col>
                                        <Col xs={24} sm={24} md={12}>

                                            <Form.Item
                                                name="severe"
                                                label="Severity"
                                                hasFeedback
                                                initialValue={signOff && signOff.severity ? signOff.severity : ""}
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: 'Please select severity!',
                                                    },
                                                ]}
                                            >
                                                <Select placeholder="Please select a severity">
                                                    {severityOptions}
                                                </Select>
                                            </Form.Item>
                                        </Col>
                                        <Col xs={24} sm={24} md={12}>

                                            <Form.Item
                                                name="action"
                                                label="Action Taken with study intervention"
                                                hasFeedback
                                                initialValue={signOff && signOff.action ? signOff.action : ""}
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: 'Please select action taken..',
                                                    },
                                                ]}
                                            >
                                                <Select placeholder="Please select a action">
                                                    {actionOptions}
                                                </Select>
                                            </Form.Item>
                                        </Col>
                                        <Col xs={24} sm={24} md={12}>

                                            <Form.Item
                                                name="outcome"
                                                label="Outcome"
                                                hasFeedback
                                                initialValue={signOff && signOff.outcome ? signOff.outcome : ""}
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: 'Please select outcome!',
                                                    },
                                                ]}
                                            >
                                                <Select placeholder="Please select a outcome">
                                                    {outcomeOptions}
                                                </Select>
                                            </Form.Item>
                                        </Col>
                                        <Col xs={24} sm={24} md={12}>
                                            <Form.Item
                                                name="treatment"
                                                label="Treatments"
                                                hasFeedback
                                                initialValue={signOff && signOff.treatment ? signOff.treatment : ""}
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: 'Please select treatment!',
                                                    },
                                                ]}
                                            >
                                                <Select placeholder="Please select a AE">
                                                    <Option value="NA">NA</Option>
                                                    <Option value="Recorded under commeds">Recorded under commeds</Option>
                                                </Select>
                                            </Form.Item>
                                        </Col>
                                        <Col xs={24} sm={24} md={12}>

                                            <Form.Item
                                                name="unexpectedAe"
                                                label="Unexpected AE"
                                                hasFeedback
                                                initialValue={signOff && signOff.unexpectedAE ? signOff.unexpectedAE : ""}
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: 'Please select unexpected AE!',
                                                    },
                                                ]}
                                            >
                                                <Select placeholder="Please select a AE">
                                                    <Option value="Yes">Yes</Option>
                                                    <Option value="No">No</Option>
                                                </Select>
                                            </Form.Item>
                                        </Col>
                                        <Col xs={24} sm={24} md={12}>

                                            <Form.Item
                                                name="seriousAe"
                                                label="Serious AE"
                                                hasFeedback
                                                initialValue={signOff && signOff.seriousAE ? signOff.seriousAE : ""}
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: 'Please select serious AE!',
                                                    },
                                                ]}
                                            >
                                                <Select placeholder="Please select a AE">
                                                    <Option value="Yes">Yes</Option>
                                                    <Option value="No">No</Option>
                                                </Select>
                                            </Form.Item>
                                        </Col>
                                        <Col xs={24} sm={24} md={12}>

                                            <Form.Item
                                                name="relationship"
                                                label="RelationShip to study treatment "
                                                hasFeedback
                                                initialValue={signOff && signOff.relationship ? signOff.relationship : ""}
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: 'Please select relationship!',
                                                    },
                                                ]}
                                            >
                                                <Select placeholder="Please select relationship..">
                                                    {relationShipOptions}
                                                </Select>
                                            </Form.Item>
                                        </Col>
                                        <Col xs={24} sm={24} md={12}>

                                            <Form.Item
                                                name="piUsers"
                                                label="PI Sign-Off"
                                                hasFeedback
                                                //hidden={ ? true : false}
                                                initialValue={signOff && signOff.piUserName ? signOff.piUserName : ""}
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: 'Please select user!',
                                                    },
                                                ]}
                                            >
                                                <Select placeholder="Please select user..">
                                                    {piUserOptions}
                                                </Select>
                                            </Form.Item>
                                        </Col>
                                        <Col xs={24} sm={24} md={12}>
                                            <Form.Item
                                                name="date"
                                                label="Author Initial/Date:"
                                            //  initialValue=
                                            // {signOff && signOff.authorSign ? signOff.authorSign : ""}
                                            // rules={[
                                            //  {
                                            //   required: true,
                                            //  message: "Please enter author initial!",
                                            // whitespace: true
                                            // }
                                            //]}
                                            >
                                                <DatePicker defaultValue={signOff ? moment(signOff.date) : ""} showTime format="YYYY-MM-DD HH:mm:ss" />

                                            </Form.Item>
                                        </Col>

                                        <Col xs={24} sm={24} md={24} className="form-button-group">
                                            <Form.Item>
                                                <Button type="primary" htmlType="submit">Submit</Button>
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                </Form> : ""}
                            </Spin>
                        </Card>}
                    </Content>
                </Layout>
            </Layout>
        );
    }
}

function mapStateToProps(state) {
    return {
        signOffInfo: state.adverseEvents.signOffInfo,
        piUsers: state.adverseEvents.piUsers
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({ ...AdverseEventActions }, dispatch);
}
export default withRouter(
    connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(PiSignOff)
);
