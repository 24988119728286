import AxiosAuth from "../../services/axios-service";
import AxiosAuth2 from "../../services/axios-service-auth";

const getActivityLogs = (data) => {
  return AxiosAuth.post("/api/auth/authenticate", data, undefined);
};

const addUser = (data) => {
  return AxiosAuth2.post("/api/admin/addUsers", data, undefined);
};

const editUser = (userId, data) => {
  return AxiosAuth2.post("/api/admin/updateUser/" + userId, data, undefined);
};

const updateUser = (userId, data) => {
  return AxiosAuth2.post("/api/admin/updateUserProfile/" + userId, data, undefined);
};

const updateUserStatus = (userId, value) => {

  return AxiosAuth2.post("/api/admin/updateUserStatus/" + userId + "?status=" + value, undefined, undefined);
};

const getStudyTeam = () => {
  return AxiosAuth2.get("/api/admin/getStudyTeam", undefined, undefined);
};

const getUserById = (id) => {
  return AxiosAuth2.get("/api/users/user/" + id, undefined, undefined);
};

const searchByKeyword = (data) => {
    return AxiosAuth2.post("/api/admin/searchUser" , data, undefined);
};

const deletePatientsZipFile = () => {
  return AxiosAuth2.get("/api/admin/deleteZipFile", undefined);
};

const downloadAllPatientsData = (studyId) => {
  return AxiosAuth2.get("/api/admin/getAllPatientsData/"+studyId, undefined, undefined, false, true);
};

const downloadAllPatientsDataJanssen = (studyId) => {
  return AxiosAuth2.post("/api/admin/getAllPatientsDataForJanssen/"+studyId, undefined, undefined, false, true);
};


export default {
  getActivityLogs,
  getStudyTeam,
  addUser,
  editUser,
  getUserById,
  updateUserStatus,
  searchByKeyword,
  deletePatientsZipFile,
  downloadAllPatientsData,
  downloadAllPatientsDataJanssen,
  updateUser
};
