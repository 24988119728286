import { setCookie, removeCookie, getCookie } from '../services/cookies';

export function getToken() {
    let token = localStorage.getItem("tokenData");
    return token;
}

export function isLoggedIn() {
    let token = getToken();
    return token && token !== "undefined";
}