import API from "../api/tasks-api";
import { Types } from "../constants/task-types";

export function getAllTasksList(studyId,patientId) {
  return async function(dispatch, _getState) {
    try {
      let resp = await API.getAllTasksList(studyId,patientId);
      if (resp && resp.data && resp.data.length > 0) {
        dispatch({
          type: Types.TASK_LIST,
          payload: resp.data
        });
      }
      return resp;
    } catch (e) {
      return { error: true };
    }
  };
}

export function getJanssenAllTasksList(studyId,patientId) {
  return async function(dispatch, _getState) {
    try {
      let resp = await API.getJanssenAllTasksList(studyId,patientId);
      if (resp && resp.data && resp.data.length > 0) {
        dispatch({
          type: Types.TASK_LIST,
          payload: resp.data
        });
      }
      return resp;
    } catch (e) {
      return { error: true };
    }
  };
}

export function getNihAllTasksList(studyId,patientId) {
  return async function(dispatch, _getState) {
    try {
      let resp = await API.getNihAllTasksList(studyId,patientId);
      if (resp && resp.data && resp.data.length > 0) {
        dispatch({
          type: Types.TASK_LIST,
          payload: resp.data
        });
      }
      return resp;
    } catch (e) {
      return { error: true };
    }
  };
}
