import AxiosAuth2 from "../../services/axios-service-auth";

const getStudies = () => {
  return AxiosAuth2.get("/api/study/fetchStudies", undefined, undefined);
};

const getStudyById = (studyId) => {
  return AxiosAuth2.get("/api/study/fetchStudyById/"+studyId, undefined, undefined);
};

export default {
    getStudies,
    getStudyById
};
