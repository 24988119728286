import { Types } from "../constants/auth-types";
import API from "../api/auth-api";
import * as AuthService from "../../services/auth";
import Config from "../../config";
import firebase from '../../../../src/config/firebase';

export function logout() {
  return async function (dispatch, _getState) {
    try {
      AuthService.logOut();
      dispatch({
        type: Types.LOG_OUT,
        payload: true,
      });
      window.location = window.location.origin;
    } catch (e) { }
  };
}

export function login(email, password) {
  return async function (dispatch, _getState) {
    try {
      let data = {
        email: email,
        password: password,
      };

      let user = await API.login(data);
      console.log('user',user);
      if (user.data != null) {
        var redirectUrl = "login/";
          if (user.data.authority && user.data.authority[0] && user.data.authority[0].authority) {
          redirectUrl = Config.userRoleTypes[user.data.authority[0].authority].url;
        }
        user.redirect = redirectUrl;
        let [userRole] = user.data.authority;
        if (redirectUrl === "/studies/" || (redirectUrl === "/user-list/" && userRole.authority == 'ROLE_SUPERADMIN')) {
          firebase.auth().signInWithCustomToken(user.data.fireBaseToken)
            .then((userCredential) => {
              firebase.auth().onAuthStateChanged((user) => {
                if (user) {
                  var uid = user.uid;
                  // ...
                } else {
                  // User is signed out
                  // ...
                }
              });
              // ...
            })
            .catch((error) => {
              
            });
          AuthService.setUserData(user.data, Config.token.expire);
          dispatch({
            type: Types.SAVE_USER,
            payload: {
              user: user.data,
              token: user.data.authToken,
            }
          });
        }
        return user;
      } else {
        return { error: "Error logging in. Please try again!" };
      }
    } catch (e) {
      if (
        e &&
        e.response &&
        e.response.data &&
        e.response.data.error &&
        e.response.data.error.length > 0
      ) {
        return { error: e.response.data.error[0].externalMessage };
      } 
      else {
        return { error: "Invalid email / password!" };
      }
    }
  };
}

export function forgotPassword(data) {

  return async function (_dispatch, _getState) {
    try {

      return await API.forgotPassword(data);
    } catch (e) {
      if (e.response) {
        return e.response.data;
      } else {
        return { error: true, message: 'Unknown error. Please try again!' };
      }
    }
  };
}

export function resetPassword(data) {
  return async function (_dispatch, _getState) {
    try {
      return await API.resetPassword(data);
    } catch (e) {
      return e;
    }
  };
}

export function getLoggedInUser() {
  return async function (dispatch, _getState) {
    try {
     let resp = await API.getLoggedInUser();
     if (resp && resp.data) {
      dispatch({
        type: Types.USER_PROFILE,
        payload: resp.data
      });
    }
      return resp;
    } catch (e) {
      return e;
    }
  };
}


