import firebase from '../../../../src/config/firebase';
import { Types } from "../constants/chat-types";
import Config from "../../../app/config";
import moment from "moment-timezone";
import API from "./../api/message-api";
const dateFormathh = "DD/MM/YYYY";

export function getMessagesListbkp() {
  return async function (dispatch, _getState) {
    try {
      let recentMessages = [];
      const today = moment(new Date()).tz('America/Los_Angeles').format(dateFormathh);
      var ref = firebase.firestore().collection('chatrooms');
      await ref.onSnapshot((docs => {
        const chatrooms = [];

        docs.forEach((doc) => {
          const obj = { ...doc.data(), id: doc.id }
          chatrooms.push(obj)
        })
        if (chatrooms && chatrooms.length > 0) {

          const messages = [];
          chatrooms.forEach((room) => {
            if (room.id.includes(Config.server.type)) {
              ref.doc(room.id).collection('messages').orderBy('createdAt').limitToLast(1).onSnapshot((docs => {
                docs.forEach((doc) => {
                  const obj = { ...doc.data(), id: doc.id }
                  if (obj.userId === room.id) {
                    messages.push(obj)
                  }
                })
                dispatch({
                  type: Types.RECENT_MESSAGES,
                  payload: messages
                });
              }))

            }
            recentMessages = messages;
          })
        }
      }))
      return recentMessages;
    } catch (e) {
      return { error: true };
    }
  };
}

export function getMessagesList() {
  return async function (dispatch, _getState) {
    try {
      const chatroomList = await API.getChatroomList();
      var recentMessages = [];
      const asyncRes = await Promise.all(chatroomList.map(async (room) => {
        if (room.id.includes(Config.server.type)) {
          var count = await API.getUnreadMessageCount(room.id);
          var messages = await API.getMessagesList(room.id, count);
          recentMessages.push(...messages);
          return room + 1;
        }
      }));

      dispatch({
        type: Types.RECENT_MESSAGES,
        payload: recentMessages
      });
    } catch (e) {
      return { error: true };
    }
  };
}

export function getPatientMessagesList() {
  return async function (dispatch, _getState) {
    try {
      const chatroomList = await API.getChatroomList();
      var recentMessages = [];
      const asyncRes = await Promise.all(chatroomList.map(async (room) => {
        if (room.id.includes(Config.server.type)) {
          var count = await API.getUnreadMessageCount(room.id);
          var patientInfo = await API.getPatientInfoOfChatroom(room.id);
          var messages = await API.getPatientMessagesList(room.id,count,patientInfo);
          recentMessages.push(...messages);
          return room + 1;
        }
      }));

      dispatch({
        type: Types.RECENT_MESSAGES,
        payload: recentMessages
      });
    } catch (e) {
      return { error: true };
    }
  };
}


export function markMsgAdminRead(userId) {
  return async function (dispatch, _getState) {
    try {
      var batch = firebase.firestore().batch();
      var ref = firebase.firestore().collection('chatrooms').doc(userId).collection('messages').where('userId', '==', userId);
      await ref.get().then(querySnapshot => {querySnapshot.forEach(doc => {
            batch.update(doc.ref, { adminRead: true });
          });
          return batch.commit();
        }) .then(() => {});
    } catch (e) {
      return { error: true };
    }
  };
}