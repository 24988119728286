import API from "../api/study-drug-log-api";
import { Types } from "../constants/study-drug-log-types";

export function addStudyEvent(data) {

  return async function (dispatch, _getState) {
    try {
      let resp = await API.addEvent(data);
      return resp;
    } catch (e) {
      return e;
    }
  };
}

export function getStudyLogsByPatientId(patientId) {
  return async function (dispatch, getState) {
    try {
      let resp = await API.getStudyLogsByPatientId(patientId);
      if (resp && resp.data) {
        dispatch({
          type: Types.LIST_STUDY_DRUG_LOG,
          payload: resp.data
        });
      }
    } catch (e) {
      return { error: true };
    }
  };
}
