import React from "react";
export const MessagesSvg = () => (
    <svg
        version="1.1"
        id="Layer_1"
        x="0px"
        y="0px"
        width="1.1em"
        height="1.1em"
        fill="currentColor"
        viewBox="0 0 512 512"
    >
        <path d="m444.555 191.141h-238c-4.142 0-7.5 3.358-7.5 7.5s3.358 7.5 7.5 7.5h238c4.142 0 7.5-3.358 7.5-7.5s-3.358-7.5-7.5-7.5z" />
        <path d="m444.555 248.474h-238c-4.142 0-7.5 3.358-7.5 7.5s3.358 7.5 7.5 7.5h238c4.142 0 7.5-3.358 7.5-7.5s-3.358-7.5-7.5-7.5z" />
        <path d="m444.555 305.807h-238c-4.142 0-7.5 3.358-7.5 7.5s3.358 7.5 7.5 7.5h238c4.142 0 7.5-3.358 7.5-7.5s-3.358-7.5-7.5-7.5z" />
        <path d="m468.378 125.549h-285.646c-24.053 0-43.622 19.568-43.622 43.622v179.617c0 24.053 19.568 43.622 43.622 43.622h200.211c2.141 0 4.13.963 5.458 2.644l64.655 81.853c2.548 3.226 6.266 4.984 10.159 4.984 1.447 0 2.919-.243 4.363-.745 5.327-1.85 8.769-6.689 8.769-12.328v-77.153c20.225-3.778 35.653-21.659 35.653-42.877v-179.618c0-24.053-19.568-43.621-43.622-43.621zm28.622 223.239c0 15.577-12.673 28.412-28.25 28.61-4.104.053-7.404 3.395-7.404 7.5v78.305l-61.174-77.447c-4.189-5.305-10.469-8.347-17.229-8.347h-200.211c-15.782 0-28.622-12.84-28.622-28.622v-179.617c0-15.782 12.839-28.622 28.622-28.622h285.646c15.782 0 28.622 12.84 28.622 28.622z" />
        <path d="m110.494 249.561c-5.13.728-9.724 3.369-12.936 7.435l-51.521 65.226v-65.371c0-4.105-3.3-7.447-7.404-7.5-13.031-.166-23.633-10.903-23.633-23.935v-156.361c0-13.203 10.742-23.945 23.945-23.945h248.663c13.203 0 23.945 10.742 23.945 23.945v28.932c0 4.142 3.358 7.5 7.5 7.5s7.5-3.358 7.5-7.5v-28.932c0-21.474-17.471-38.945-38.945-38.945h-248.663c-21.474 0-38.945 17.471-38.945 38.945v156.361c0 18.667 13.371 34.438 31.038 38.124v66.366c0 5.328 3.252 9.901 8.286 11.649 1.365.474 2.755.704 4.123.704 3.677 0 7.192-1.662 9.599-4.709l56.284-71.256c.812-1.029 1.975-1.697 3.273-1.882 4.101-.582 6.954-4.379 6.371-8.48-.582-4.101-4.373-6.956-8.48-6.371z" />
    </svg>
);
