import React, { Component } from "react";
import { Spin, Card } from "antd";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import SideBar from "../../layout/sidebar/sidebar.jsx";
import options from "../../layout/sidebar/dashboard-options";
import { Layout, Tooltip } from "antd";
import 'antd/dist/antd.css';
import { Table, Input, Button, Space, Switch } from 'antd';
import Highlighter from 'react-highlight-words';
import { SearchOutlined } from '@ant-design/icons';
import * as PatientActions from "../../../redux/actions/patient-actions";
import * as AdminActtions from "../../../redux/actions/admin-actions";
import UserHeader from "../../layout/header.jsx";
import Icon from "@ant-design/icons";
import { ListEditSvg } from "../../shared/svg/list-edit";
import { LogsSvg } from "../../shared/svg/logs";
import { notifyUser } from "../../../services/notification-service";

const ListEditIcon = (props) => <Icon component={ListEditSvg} {...props} />;
const LogsIcon = (props) => <Icon component={LogsSvg} {...props} />;

const { Header, Sider, Content } = Layout;

class UserList extends Component {
  state = {
    searchText: '',
    searchedColumn: '',
    submitted: false,
    loading: true, data: []
  };

  componentDidMount = () => {
    this.props
      .getStudyTeam()
      .then((resp) => {
        this.setState({
          loading: false,
          data: resp.data
        });
      })
      .catch((ex) => {
        this.setState({ loading: false });
      });
  };

  getColumnSearchProps = dataIndex => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={node => {
            this.searchInput = node;
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button onClick={() => this.handleReset(clearFilters)} size="small" style={{ width: 90 }}>
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({ closeDropdown: false });
              this.setState({
                searchText: selectedKeys[0],
                searchedColumn: dataIndex,
              });
            }}
          >
            Filter
          </Button>
        </Space>
      </div>
    ),
    filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
        : '',
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        setTimeout(() => this.searchInput.select(), 100);
      }
    },
    render: text =>
      this.state.searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[this.state.searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  });

  handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    this.setState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex,
    });
  };

  handleReset = clearFilters => {
    clearFilters();
    this.setState({ searchText: '' });
  };

  onClick = () => {
    this.props.history.push("/add-user");
  }

  handleEdit = (record) => {
    this.props.history.push("/edit-user/" + record.id);
  };

  handleStatus = async (record, value) => {

    let self = this;
    this.setState({ submitted: true });
    await self.props
      .updateUserStatus(record.id, value)
      .then((response) => {
        if (response.error && response.error !== "") {
          notifyUser("Failed Updating status", "error");
          self.setState({ submitted: false });
        } else {
          if (response.data && response.data !== "" && response.data.isSuccess === true) {
            this.props
              .getStudyTeam()
              .then((resp) => {
                this.setState({
                  loading: false,
                  data: resp.data
                });
              })
              .catch((ex) => {
                this.setState({ loading: false });
              });
            notifyUser("Status Updated Successfully", "success");
            self.setState({ submitted: false });
          }
        }
      });
  };

  handleLogs = (record) => {
    this.props.history.push("/audit-logs/" + record.id);
  };

  onSearch = async (value) => {
    let self = this;
    this.setState({ submitted: true });
    if (value && value !== "") {
      await self.props
        .searchByKeyword({
          type: "STUDYTEAM",
          searchString: value,
          subType: undefined
        })
        .then((response) => {
          self.setState({
            submitted: false,
            data: response.data
          });
        });
    } else {
      self.props
        .getStudyTeam()
        .then((resp) => {
          self.setState({
            submitted: false,
            data: resp.data
          });
        })
        .catch((ex) => {
          self.setState({ submitted: false });
        });
    }
  };

  render() {
    const { submitted } = this.state;
    let columns = [
      {
        title: 'First Name',
        dataIndex: 'firstName',
        key: 'firstName',
        width: '20%',
        ...this.getColumnSearchProps('firstName'),
      },
      {
        title: 'Last Name',
        dataIndex: 'lastName',
        key: 'lastName',
        width: '20%',
        ...this.getColumnSearchProps('lastName'),
      },
      {
        title: 'Email',
        dataIndex: 'email',
        key: 'email',
        ...this.getColumnSearchProps('email'),
      },
      {
        title: 'Phone',
        dataIndex: 'phoneNumber',
        key: 'phoneNumber',
        ...this.getColumnSearchProps('phoneNumber'),
      },
      {
        title: 'Role',
        render: (_, record) => {
          let _allRoles = [];
          record.roles.map(role => {
            _allRoles.push(role.name);
          })
          return _allRoles.join(", ");
        }
      },
      {
        title: 'Status',
        dataIndex: 'status',
        key: 'status',
        render: (_text, record) => (
          <Switch
            checked={record.userStatus === "ACTIVE" ? true : false}
            onChange={
              (value) => this.handleStatus(record, value)
            }
            checkedChildren="Active"
            unCheckedChildren="InActive"
          />
        )
      },
      {
        title: 'Action',
        dataIndex: 'action',
        render: (_, record) =>
          this.state.data.length >= 1 ? (
            <div className="list-actions">
              <Tooltip title="Edit">
                <ListEditIcon
                  onClick={() => this.handleEdit(record)}
                />
              </Tooltip>
              <Tooltip title="Logs">
                <LogsIcon
                  onClick={() => this.handleLogs(record)}
                />
              </Tooltip>
            </div>) : null,
      },
     
    ];

    if(this.props.userDetail && this.props.userDetail.roles.every(item=>{
      return item.name === "SUPERADMIN"
      
    }))
      columns.splice(5,0,{
          title: 'Study',
          render: (_, record) => {
            let _allStudies = [];
            record.study.forEach(study => {
              _allStudies.push(study.title);
            })
            return _allStudies.join(", ");
          }
      })
    return (

      <Layout>
        <Sider
          breakpoint="xl"
          collapsedWidth="0"
          width="260px"
          className="sidebar"
          onBreakpoint={(broken) => {
            console.log(broken);
          }}
          onCollapse={(collapsed, type) => {
            console.log(collapsed, type);
          }}
        >
          <SideBar {...this.props} options={options.dashBoardOptions} />
        </Sider>
        <Layout className="site-layout">
          <Header
            className="site-layout-background"
            style={{
              background: "#f2f5f9",
              zIndex: 100,
              top: 0,
              width: "100%",
              padding: 0,
            }}
          >
            <UserHeader
              {...this.props}
              isCollapsed={this.state.collapsed}
              toggleSidebar={this.toggleSidebar}
            />
          </Header>
          <Content className="site-layout-background" >
            <Card
              title={<div className="search-bar"> 
              <Input.Search aria-label="search" placeholder="input search text" enterButton allowClear onSearch={this.onSearch} style={{ width: 300 }} />
              {/* <input type="search" id="searchInput"/>
              <button aria-label="search button" htmlFor="searchInput" onClick={this.onSearch}></button> */}
              </div>}
              extra={<Button onClick={this.onClick} >Add New Member</Button>}
            >
              <Spin spinning={submitted}>
                <Table columns={columns} bordered dataSource={this.state.data} />
              </Spin>
            </Card>
          </Content>
        </Layout>
      </Layout>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ ...PatientActions, ...AdminActtions }, dispatch);
}
function mapStateToProps(state) {
  return {
      userDetail:state.auth.userProfile
  };
}
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(UserList)
);
