import API from "../api/patient-activity-api";
import { Types } from "../constants/patient-activity-types";


export function getPhysicalActivities(patientId) {

  return async function (dispatch, _getState) {
    try {
      let resp = await API.getPhysicalActivities(patientId);
      if (resp && resp.data && resp.data.length > 0) {
        dispatch({
          type: Types.GET_PHYSICAL_ACTIVITY,
          payload: resp.data
        });
      }
      return resp;
    } catch (e) {
      return e;;
    }
  };
}

export function getPhysicalActivitiesNih(patientId, studyId) {

  return async function (dispatch, _getState) {
    try {
      let resp = await API.getPhysicalActivitiesNih(patientId, studyId);
      if (resp && resp.data && resp.data.length > 0) {
        dispatch({
          type: Types.GET_PHYSICAL_ACTIVITY,
          payload: resp.data
        });
      }
      return resp;
    } catch (e) {
      return e;;
    }
  };
}

export function getSleepMeasures(patientId) {
  
  return async function (dispatch, _getState) {
    try {
      let resp = await API.getSleepMeasures(patientId);
      if (resp && resp.data && resp.data.length > 0) {
        dispatch({
          type: Types.GET_SLEEP_MEASURES,
          payload: resp.data
        });
      }
      return resp;
    } catch (e) {
      return e;;
    }
  };
}

export function getSleepInfo(patientId, studyId) {
  
  return async function (dispatch, _getState) {
    try {
      let resp = await API.getSleepInfo(patientId, studyId);
      if (resp && resp.data && resp.data.length > 0) {
        dispatch({
          type: Types.GET_SLEEP_MEASURES,
          payload: resp.data
        });
      }
      return resp;
    } catch (e) {
      return e;;
    }
  };
}

export function clearEvsFactorState() {

  return async function(dispatch, getState) {
    dispatch({
      type: Types.GET_EVS_FACTORS,
      payload: undefined
    });  
  };
}

export function getEnvironmentFactors(date,patientId) {
  
  return async function (dispatch, _getState) {
    try {
      let data = {
        date: date,
        patientId: patientId,
      };
      let resp = await API.getEnvironmentFactors(data);
      if (resp && resp.data) {
        dispatch({
          type: Types.GET_EVS_FACTORS,
          payload: resp.data
        });
      }
      else{
        dispatch({
          type: Types.GET_EVS_FACTORS,
          payload: undefined
        });
      }
      return resp;
    } catch (e) {
      return e;;
    }
  };
}


export function getEnvironmentFactorsNew(date,patientId) {
  return async function (dispatch, _getState) {
    try {
      let data = {
        date: date,
        patientId: patientId,
      };
      let resp = await API.getEnvironmentFactorsNew(data);
      if (resp && resp.data) {
        dispatch({
          type: Types.GET_EVS_FACTORS,
          payload: resp.data
        });
      }
      else{
        dispatch({
          type: Types.GET_EVS_FACTORS,
          payload: undefined
        });
      }
      return resp;
    } catch (e) {
      return e;;
    }
  };
}
