import React from "react";
export const DairySvg = () => (
    <svg
        version="1.1"
        id="Layer_1"
        x="0px"
        y="0px"
        width="3em"
        height="3em"
        viewBox="0 0 352.6 384.1"
        fill="currentColor"
    >
        <g>
            <path d="M259.5,0c2,0.6,4,1.1,6,1.8c14.4,4.8,24.4,18.1,24.7,33.3c0.2,8.6,0.1,17.2,0.1,25.9c0,70.9,0,141.7,0,212.6
		c0,1.1,0,2.3,0,3.4c-0.2,3.7-2.5,6-5.8,5.9c-3.2-0.1-5.2-2.3-5.4-6c0-1.1,0-2.2,0-3.4c0-78.2,0-156.5,0-234.7
		c0-17.7-9.9-27.6-27.7-27.6c-65.7,0-131.5,0-197.2,0c-1.5,0-2.9,0-4.6,0c0,120.6,0,240.8,0,361.5c1.4,0,2.7,0,4,0
		c66.4,0,132.7,0,199.1,0c15.9,0,26.3-10.4,26.3-26.3c0-13.9-0.1-27.7,0.1-41.6c0-2,1.3-4.8,2.9-5.9c3.7-2.7,8.2,0.5,8.2,5.5
		c0.1,8,0.1,16,0,24c-0.1,8.6,0.3,17.3-0.8,25.8c-2.2,16.4-16.8,29-33.3,29.7c-1.5,0.1-3,0.1-4.5,0.1c-78.5,0-157,0-235.5,0
		C5,384,0.1,379.1,0.1,368.1c0-78.6,0-157.2,0-235.8c0-0.2,0-0.5,0-0.7c0-5.3,1.9-8,5.8-7.9c3.7,0.1,5.5,2.5,5.5,7.7
		c0,56.4,0,112.7,0,169.1c0,22.5,0,45,0,67.5c0,4.7,0.1,4.8,4.9,4.8c7.1,0,14.2,0,21.6,0c0-120.5,0-240.8,0-361.5
		c-8.1,0-16-0.1-24,0.2c-0.9,0-2.3,2.1-2.4,3.3c-0.3,4.9-0.1,9.7-0.1,14.6c0,23.5,0,47,0,70.5c0,1.2,0.1,2.5-0.1,3.7
		c-0.4,2.9-2.1,4.5-4.9,4.8c-2.8,0.3-4.9-1-5.8-3.8c-0.4-1.4-0.5-2.9-0.5-4.4c0-27.9,0.1-55.7-0.1-83.6C-0.1,8.5,2.7,2.8,10.5,0
		C93.5,0,176.5,0,259.5,0z"/>
            <path d="M348.7,0c2.8,1.9,3.7,4.6,3.7,8c-0.1,28.1-0.1,56.2-0.1,84.4c0,0.7,0,1.5,0,2.2c-0.3,3.7-2.7,6-6,5.9
		c-3.1-0.2-5.2-2.5-5.2-6.1c-0.1-10.5,0-21,0-31.5c0-1.4,0-2.7,0-4.3c-6.5,0-12.6,0-19.2,0c0,1.8,0,3.4,0,5c0,90,0.1,180-0.1,269.9
		c0,11.6,3.7,21.7,9.7,31.7c5.6-9.4,9.7-18.8,9.7-29.8c-0.1-65.4-0.1-130.7-0.1-196.1c0-5.4,0-10.7,0-16.1c0-5,2-7.7,5.6-7.6
		c3.6,0.1,5.6,2.8,5.6,7.8c0,68.2,0,136.5,0,204.7c0,4.2,0.6,8.6-0.3,12.7c-1.5,7-3.3,14-5.9,20.7c-2.5,6.5-6,12.6-9.3,18.8
		c-2.4,4.6-7.7,5.3-9.8,1.1c-5.6-10.9-12.6-21.4-15.1-33.7c-0.8-4-1.2-8.2-1.2-12.3c-0.1-109,0-218-0.1-326.9c0-3.4,0.6-6.4,3.6-8.5
		C325.7,0,337.2,0,348.7,0z M340.9,11.5c-6.3,0-12.5,0-19,0c0,11.3,0,22.4,0.1,33.4c0,0.7,1,1.9,1.6,1.9c5.8,0.1,11.6,0.1,17.3,0.1
		C340.9,34.7,340.9,23.2,340.9,11.5z"/>
            <path d="M158.9,47c24.2,0,48.5,0,72.7,0c7.2,0,8.5,1.3,8.5,8.5c0,28.5,0,57,0,85.5c0,6.6-1.6,8.2-8.1,8.2c-48.9,0-97.7,0-146.6,0
		c-6.2,0-8-1.8-8.1-8c0-28.7,0-57.5,0-86.2c0-6.3,1.7-7.9,8.1-7.9C109.9,47,134.4,47,158.9,47z M88.8,137.7c46.9,0,93.3,0,139.8,0
		c0-26.5,0-52.8,0-79.1c-46.8,0-93.3,0-139.8,0C88.8,85.1,88.8,111.3,88.8,137.7z"/>
            <path d="M158.8,312.8c-12.1,0-24.2,0-36.3,0c-4.5,0-7-1.9-7-5.4c-0.1-3.7,2.4-5.8,7.1-5.8c24.2,0,48.5,0,72.7,0c4.4,0,7,2,7.1,5.5
		c0.1,3.5-2.6,5.8-7.2,5.8C183,312.8,170.9,312.8,158.8,312.8z"/>
            <path d="M159,337c-11.7,0-23.5,0-35.2,0c-1.2,0-2.5,0-3.7-0.2c-2.8-0.5-4.5-2.2-4.6-5c-0.2-3,1.3-5,4.2-5.7
		c1.3-0.3,2.7-0.3,4.1-0.3c23.3,0,46.7,0,70,0c1.1,0,2.3-0.1,3.4,0.1c3.1,0.5,4.9,2.4,5.1,5.5c0.2,2.9-2.2,5.3-5.4,5.5
		c-3.2,0.2-6.5,0.1-9.7,0.1C177.7,337,168.4,337,159,337z"/>
            <path d="M159.3,75c16.1,0,32.2,0,48.3,0c1.1,0,2.3-0.1,3.4,0.1c3.1,0.6,4.9,2.5,4.9,5.7c0,3-1.8,4.8-4.8,5.4
		c-0.8,0.2-1.7,0.1-2.6,0.1c-33.1,0-66.2,0-99.2,0c-0.4,0-0.7,0-1.1,0c-4.1-0.1-6.4-2.3-6.4-5.7c0.1-3.3,2.4-5.4,6.2-5.5
		c0.9,0,1.7,0,2.6,0C126.9,75,143.1,75,159.3,75z"/>
            <path d="M158.5,121.1c-16.1,0-32.2,0-48.4,0c-1.4,0-2.8,0-4.1-0.2c-2.7-0.5-4.1-2.3-4.2-5c-0.1-2.8,1.1-4.9,3.9-5.6
		c1.4-0.4,3-0.4,4.5-0.4c32.4,0,64.7,0,97.1,0c5.8,0,8.5,1.7,8.6,5.5c0.1,3.9-2.7,5.8-8.7,5.8C191,121.1,174.7,121.1,158.5,121.1z"
            />
        </g>
    </svg>
);
