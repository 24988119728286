import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { List, Avatar, Card } from "antd";
import * as MessageActions from "../../../redux/actions/message-actions";
import { RightCaretSvg } from "../../shared/svg/rightcaret";
import Icon from "@ant-design/icons";

const RightCaretIcon = (props) => <Icon component={RightCaretSvg} {...props} />;
const options = {hour: "numeric", minute: "numeric"};
class ListMessages extends Component {
  state = { loading: false, data: [] ,messages:[]};

  componentDidMount(){
    this.loadRecentMesages();
  }
  async loadRecentMesages() { 
    await this.props.getMessagesList()
  };

  componentWillReceiveProps(nextProps) {
    if (this.props.recentMessages !== nextProps.recentMessages) {
     this.setState({messages:nextProps.recentMessages});
    }
   }
  viewProgress = (id) => {
    this.props.history.push("/chat/"+id);
  }

  render() {
    console.log("Recent Messages from render ==> ", this.props.recentMessages)
    const { recentMessages } = this.props;
     let messages;
    if(recentMessages && recentMessages.length >0) {
      messages = recentMessages.sort((a,b)=>a.createdAt < b.createdAt? 1 :-1);
    }
    if(messages && messages.length >0) {
      return (
      <div className="list-recent" style={{ height: "300px", overflow: "auto" }}>
        <Card bordered={false}>
          <List
            itemLayout="horizontal"
            dataSource={ messages}
            renderItem={(item) => (
              <List.Item onClick={() => this.viewProgress(item.userId.substring(0,(item.userId.length-5)))}>
                <List.Item.Meta
                  avatar={<Avatar style={{backgroundColor: "#4B4848"}}>{item.firstName?item.firstName.charAt(0).toUpperCase():"U"}</Avatar>}
                  title={<h3 style={{color: "black"}}>{item.firstName +" "+ item.lastName}</h3>}
                  description = {item.text?item.text:"attachment"}
                />
                <div className="chat-time">{item.createdAt !=null?new Date(item.createdAt.seconds ? (item.createdAt.seconds * 1000) : item.createdAt).toLocaleTimeString("en-US",options).toLocaleLowerCase():""}<span className="unread-count">{item.count && item.count>0?item.count:""}</span></div>
                <RightCaretIcon  />
              </List.Item>
            )}
          />
        </Card>
      </div>
    );
    } else {
      return (
        ""
      );
    } 
  }
}
function mapStateToProps(state) {
  return { recentMessages: state.recentMessages.recentMessages};
}
function mapDispatchToProps(dispatch) {
  return bindActionCreators({ ...MessageActions}, dispatch);
}
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(ListMessages)
);
