// import Axios from "../../services/axios-service";
import AxiosAuth2 from "../../services/axios-service-auth";

const getNotificationsList = () => {
  return AxiosAuth2.get("/api/notifications/listNotifications", undefined, undefined);
};

const updateNotificationStatus = (id,isRead) => {
  return AxiosAuth2.post("/api/notifications/read-unread/"+id+"?isRead="+isRead, undefined, undefined);
};

const updateAll = (isRead) => {
  
  return AxiosAuth2.post("/api/notifications/updateAll?isRead="+isRead, undefined, undefined);
};

const listUnReadNotifications = () => {
  return AxiosAuth2.get("/api/notifications/listUnReadNotifications", undefined, undefined);
};

export default {
    getNotificationsList,
    updateNotificationStatus,
    listUnReadNotifications,
    updateAll
};
