import firebase from '../../../../src/config/firebase';
const ref = firebase.firestore().collection('chatrooms');

const getMessagesList = (roomId,count) => {
    let messages = [];
    return new Promise((resolve) => {
      
        ref.doc(roomId).collection('messages').orderBy('createdAt').limitToLast(1).onSnapshot((docs => {
            docs.forEach((doc) => {
                let obj = { ...doc.data(), id: doc.id ,count:count}
                if (obj.userId === roomId) {
                  messages.push(obj)
                }
            })
            resolve(messages)
        }))
    })
};

const getPatientMessagesList = (roomId,count,obj2) => {
    let messages = [];
    return new Promise((resolve) => {
        ref.doc(roomId).collection('messages').orderBy('createdAt').limitToLast(1).onSnapshot((docs => {
            docs.forEach((doc) => {
              var  obj = { ...doc.data(), id: doc.id,fname:obj2 && obj2.lastMessage ?obj2.lastMessage.userName:"",lname:obj2 && obj2.lastMessage?obj2.lastMessage.text:"",roomId:roomId ,count:count}
              if(obj.text !== "Welcome !"){
              messages.push(obj)
              }
            })
            resolve(messages)
        }))
    })
};


const getChatroomList = () => {
    return new Promise((resolve) => {
        const chatrooms = [];
       ref.onSnapshot((docs => {
            docs.forEach((doc) => {
                const obj = { ...doc.data(), id: doc.id }
                chatrooms.push(obj)
            })
            resolve(chatrooms);
        }))
    });
};

const getUnreadMessageCount = (roomId) => {
    return new Promise((resolve) => {
         ref.doc(roomId).collection('messages').where('adminRead', '==', false).where('userId', '==', roomId).onSnapshot((docs) => {
            resolve(docs.size)
        });
    });
};

const getPatientInfoOfChatroom = (roomId) => {
    return new Promise((resolve) => {
        ref.doc(roomId).get().then(doc1 => {
         var obj2 = {...doc1.data()}
             resolve(obj2);
        })
    });
};

export default {
    getMessagesList,
    getChatroomList,
    getPatientMessagesList,
    getUnreadMessageCount,
    getPatientInfoOfChatroom
};
