import { Types } from "../constants/patient-activity-types";
const initialState = {
  getPhysicalActivity: undefined,
  getSleepMeasures:undefined,
  getEvsFactors:undefined
};
export default function patientActivityReducer(state = initialState, action) {
  switch (action.type) {
    case Types.GET_PHYSICAL_ACTIVITY:
      return { ...state, getPhysicalActivity: action.payload };
      case Types.GET_SLEEP_MEASURES:
        return { ...state, getSleepMeasures: action.payload };
        case Types.GET_EVS_FACTORS:
          return { ...state, getEvsFactors: action.payload };
    default:
      return state;
  }
}
