import { Types } from "../constants/survey-types";

const initialState = {
    listQuesAns: undefined,
    quesAnsListError:undefined,
    flareTask: undefined
};

export default function surveyReducer(state = initialState, action) {
    switch (action.type) {
        case Types.QUESTION_ANSWER_LIST:
            return { ...state, listQuesAns: action.payload };
        case Types.SURVEY_QUES_ANS_LIST_ERROR:
            return { ...state, quesAnsListError: action.payload };
        case Types.FLARE_TASK:
            return { ...state, flareTask: action.payload };
        default:
            return state;
    }
}
