import AxiosAuth2 from "../../services/axios-service-auth";

const addEASIHeadScores = (data) => {
  return AxiosAuth2.post("/api/easiScore/saveEasiScore", data, undefined);
};

const savePasiScore = (data) => {
  return AxiosAuth2.post("/api/nih/pasiScore/savePasiScore", data, undefined);
};

const getEasiTotal = (month,patientId) => {
  return AxiosAuth2.get("/api/easiScore/getEasiScore?month="+month+"&patientId="+patientId, undefined, undefined);
};

const getEasiScoreForJanssen = (week,patientId) => {
  return AxiosAuth2.get("/api/easiScore/getEasiScoreForJanssen?week="+week+"&patientId="+patientId, undefined, undefined);
};

const getEasiScoreForNih = (month,patientId) => {
  return AxiosAuth2.get("/api/nih/pasiScore/getPasiScore?month="+month+"&patientId="+patientId, undefined, undefined);
};

const getScoresNih = (userId,month,partType) => {
  return AxiosAuth2.get("/api/nih/pasiScore/getPasiForUserScore?userId="+userId+"&month="+month+"&partType="+partType, undefined, undefined);
};

const getGlobalAssessment = (month,patientId) => {
  return AxiosAuth2.get("/api/globalAssessment/getGlobalAssessment?month="+month+"&patientId="+patientId, undefined, undefined);
};

const getGlobalAssessmentForJanssen = (week,patientId) => {
  return AxiosAuth2.get("/api/globalAssessment/getGlobalAssessmentForJanssen?week="+week+"&patientId="+patientId, undefined, undefined);
};

const getGlobalAssessmentForNih = (month,patientId) => {
  return AxiosAuth2.get("/api/globalAssessment/getGlobalAssessment?month="+month+"&patientId="+patientId, undefined, undefined);
};

const saveGlobalAssessment = (data) => {
  return AxiosAuth2.post("/api/globalAssessment/SaveGlobalAssessment", data, undefined);
};

export default {
    addEASIHeadScores,
    getEasiTotal,
    getGlobalAssessment,
    saveGlobalAssessment,
    getEasiScoreForJanssen,
    getGlobalAssessmentForJanssen,
    savePasiScore,
    getEasiScoreForNih,
    getScoresNih,
    getGlobalAssessmentForNih
};
