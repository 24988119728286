import React from "react";
export const StudyOverviewSvg = () => (
  <svg 
    version="1.1"
    id="Layer_1"
    x="0px"
    y="0px"
    width="1.2em"
    height="1.2em"
    viewBox="0 0 496.592 496.592" 
    fill="currentColor"
  >
    <path d="M400.296,328v-51.696l-21.816,8.488l-0.064-0.144l-0.52-0.944c-8.36-13.016-21.88-22.072-37.104-24.832l-52.496-9.552
			v-4.144c19.632-11.4,34.008-30.848,38.488-53.84c14.544-3.008,25.512-15.912,25.512-31.336V80.984
			c0-22.328-14.624-41.8-35.84-48.136l-4-4C293.848,10.24,269.112,0,242.8,0c-54.312,0-98.504,44.184-98.504,98.504V160
			c0,15.424,10.968,28.328,25.512,31.336c4.488,22.992,18.856,42.448,38.488,53.84v4.144l-52.488,9.544
			c-15.208,2.76-28.72,11.8-37.088,24.808l-0.48,1.168l-21.944-8.536V328c-13.232,0-24,10.768-24,24v32c0,13.232,10.768,24,24,24
			v29.472l152,59.12l152-59.12V408c13.232,0,24-10.768,24-24v-32C424.296,338.768,413.528,328,400.296,328z M303.288,268.312
			l34.64,6.296c10.2,1.856,19.232,7.744,25.256,16.128l-77.4,30.096L303.288,268.312z M224.976,252.512
			c7.384,2.256,15.208,3.488,23.32,3.488s15.936-1.232,23.32-3.488L248.296,270L224.976,252.512z M236.12,280.864l-16.328,16.32
			L209.2,265.416l5.096-0.92L236.12,280.864z M282.296,264.504l5.096,0.92L276.8,297.192l-16.328-16.32L282.296,264.504z
			 M328.296,173.776v-27.552c4.76,2.776,8,7.88,8,13.776S333.056,171,328.296,173.776z M168.296,173.776
			c-4.76-2.776-8-7.88-8-13.776s3.24-11,8-13.776V173.776z M168.296,120v9.136c-2.848,0.744-5.52,1.864-8,3.312V98.504
			C160.296,53.016,197.304,16,242.8,16c22.032,0,42.76,8.584,58.344,24.168l7.056,7.064l2.152,0.528
			c15.28,3.816,25.944,17.48,25.944,33.224v51.464c-2.48-1.448-5.152-2.576-8-3.312V120h-25.368c-21.68,0-42.4-10.352-55.4-27.688
			l-6.36-8.496L225.272,99.72C212.184,112.8,194.792,120,176.296,120H168.296z M184.296,176v-40.368
			c19.752-1.832,38.08-10.4,52.288-24.6l3.112-3.112c16.04,17.704,39.144,28.08,63.232,28.08h9.368v40c0,35.288-28.712,64-64,64
			C213.008,240,184.296,211.288,184.296,176z M158.664,274.608l34.64-6.296l17.504,52.52l-77.416-30.104
			C139.416,282.336,148.464,276.464,158.664,274.608z M88.296,384v-32c0-4.416,3.592-8,8-8c13.232,0,24,10.768,24,24
			s-10.768,24-24,24C91.888,392,88.296,388.416,88.296,384z M240.296,476.304l-128-49.768v-21.928c14.104-6.192,24-20.248,24-36.608
			c0-16.36-9.896-30.416-24-36.608v-31.696l128,49.776V476.304z M216.544,323.072l31.752-31.76l31.752,31.752l-31.752,12.352
			L216.544,323.072z M384.296,426.528l-128,49.768V349.472l128-49.776v31.696c-14.104,6.192-24,20.248-24,36.608
			c0,16.36,9.896,30.416,24,36.608V426.528z M408.296,384c0,4.416-3.592,8-8,8c-13.232,0-24-10.768-24-24s10.768-24,24-24
			c4.408,0,8,3.584,8,8V384z"/>
  </svg>
);
