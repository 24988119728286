import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { List, Card, Spin } from "antd";
import * as AdverseEventActions from "../../../redux/actions/adverse-event-actions";
// import { notifyUser } from "../../../services/notification-service";
import Icon from "@ant-design/icons";
import { RightCaretSvg } from "../../shared/svg/rightcaret";
import moment from "moment-timezone";

const RightCaretIcon = (props) => <Icon component={RightCaretSvg} {...props} />;
const dateFormat = "Do MMM";
class ListAdverseEvents extends Component {
  state = { loading: true, data: [],dataItem:[]};

  componentDidMount = () => {
    let studyId = localStorage.getItem("studyId")

    this.props
      .listAllAdverseEvents(studyId)
      .then((resp) => {
        this.setState({
          loading: false,
          data: resp.data,
          dataItem:resp.data.slice(0,3)
        });
      })
      .catch((ex) => {
        this.setState({ loading: false });
      });
  };

  
  viewAdverseEvent = (id,patientId) => {
    this.props.history.push("/event-details/"+id+"/"+patientId);
  }

  render() {
    return (
      <div className="list-patients">
        <Spin spinning={this.state.loading}>
          <Card bordered={false}>
            <List
              itemLayout="horizontal"
              dataSource={this.state.dataItem}
              renderItem={(event) => (
                <List.Item onClick={() => this.viewAdverseEvent(event.adverseEventId,event.patientId)}>
                  <List.Item.Meta
                    title={event.date ? moment(event.date).format(dateFormat) : "date"}
                    description ={event.patientName}
                  />
                  <RightCaretIcon />
                </List.Item>
              )}
            />
          </Card>
        </Spin>
      </div>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ ...AdverseEventActions }, dispatch);
}
export default withRouter(
  connect(null, mapDispatchToProps, null, { forwardRef: true })(
    ListAdverseEvents
  )
);
