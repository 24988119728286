import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { notifyUser } from "../../services/notification-service";
import { Layout, Menu, Spin, Card, Table, Dropdown } from "antd";
import 'antd/dist/antd.css';
import * as NotificationActions from "../../redux/actions/notifications-actions";
import options from "../layout/sidebar/dashboard-options";
import UserHeader from "../layout/header.jsx";
import SideBar from "../layout/sidebar/sidebar.jsx";
import { EllipsisOutlined } from '@ant-design/icons';
import moment from "moment-timezone";

const { Sider, Header, Content } = Layout;
const dateFormathh = "MM/DD/YYYY HH:mm a ";


class Notifications extends Component {
  state = {
    searchText: '',
    searchedColumn: '',
    loading: true, data: [],
    status: false,
    submitted: false,
  };

  componentDidMount = () => {
    this.props
      .getNotificationsList()
      .then((resp) => {
        this.setState({
          loading: false,
          data: resp.data.notificationsList
        });
      })
      .catch((ex) => {
        this.setState({ loading: false });
      });
  };

  handleRead = (id, isRead) => {
    let self = this;
    this.setState({ submitted: true });
    this.props
      .updateNotificationStatus(id, isRead)
      .then((resp) => {

        this.setState({
          loading: false,
          status: resp.data.isSuccess
        });
        if (resp.error && resp.error !== "") {
          notifyUser("Failed Updating status", "error");
          self.setState({ submitted: false });
        } else {
          if (resp.data && resp.data !== "" && resp.data.isSuccess === true) {
            notifyUser("Status Updated Successfully", "success");
            self.setState({ submitted: false });
            window.location.reload()
          }
        }
      })
      .catch((ex) => {
        this.setState({ loading: false });
      });

  };

  onClick = (status) => {

    let self = this;
    this.setState({ submitted: true });
    this.props
      .updateAll(status)
      .then((resp) => {

        this.setState({
          loading: false,
          status: resp.data.isSuccess
        });
        if (resp.error && resp.error !== "") {
          notifyUser("Failed Updating status", "error");
          self.setState({ submitted: false });
        } else {
          if (resp.data && resp.data !== "" && resp.data.isSuccess === true) {
            notifyUser("Status Updated Successfully", "success");
            self.setState({ submitted: false });
            window.location.reload()
          }
        }
      })
      .catch((ex) => {
        this.setState({ loading: false });
      });
  };

  render() {
    const { submitted } = this.state;
    const menu = (
      <Menu >
        <Menu.Item key="1" onClick={() => this.onClick(true)}>
          Mark all as read
        </Menu.Item>
        <Menu.Item key="2" onClick={() => this.onClick(false)}>
          Mark all as unread
        </Menu.Item>
      </Menu>
    );

    const columns = [
      {
        title: 'Date Created',
        render: (_, record) =>
          this.state.data.length >= 1 && !record.isRead ? (
            <div>
              <span style={{ fontWeight: 'bold' }} >
                {record.description}
              </span>
            </div>)
            : <div>
              {record.description}
            </div>
      },
      {
        title: 'Content',
        dataIndex: 'kindDescription',
        key: 'kindDescription',
        width: '20%',
      },
      {
        title: 'Date Created',
        render: (_, record) =>
          this.state.data.length >= 1 ? (
            <div>
              <span>{moment(record.date).format(dateFormathh)}</span>
            </div>)
            : null,
      },
      {
        title: 'Action',
        dataIndex: 'action',
        render: (_, record) =>
          this.state.data.length >= 1 ? (
            <div>
              {record.isRead === false ?
                <button className="icon" title={"MarkAsRead"} onClick={() => { this.handleRead(record.id, !record.isRead) }}>
                  <i className="fas fa-envelope" ></i>
                </button>
                :
                <button className="icon" title={"MarkAsUnread"} onClick={() => { this.handleRead(record.id, !record.isRead) }}>
                  <i className="fas fa-envelope-open" ></i>
                </button>
              }
            </div>) : null,
      }
    ];
    return (

      <Layout>
        <Sider
          breakpoint="xl"
          collapsedWidth="0"
          width="260px"
          className="sidebar"
          onBreakpoint={(broken) => {
            console.log(broken);
          }}
          onCollapse={(collapsed, type) => {
            console.log(collapsed, type);
          }}
        >
          <SideBar {...this.props} options={options.dashBoardOptions} />
        </Sider>
        <Layout className="site-layout">
          <Header
            className="site-layout-background"
            style={{
              background: "#f2f5f9",
              zIndex: 100,
              top: 0,
              width: "100%",
              padding: 0,
            }}
          >
            <UserHeader
              {...this.props}
              isCollapsed={this.state.collapsed}
              toggleSidebar={this.toggleSidebar}
            />
          </Header>
          <Spin spinning={submitted}>
            <Content className="notifications-content">
              <Card
                title={"Notifications"}
              // extra={
              //   <Button onClick={() => this.onClick()}>Actions</Button>
              // }
              >
                <Dropdown.Button overlay={menu} placement="bottomRight" icon={<EllipsisOutlined />} className="actions-list">
                  Read/UnRead
                </Dropdown.Button>

                <Table columns={columns} dataSource={this.state.data} />

              </Card>
            </Content>
          </Spin>
        </Layout>
      </Layout>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ ...NotificationActions }, dispatch);
}
export default withRouter(
  connect(null, mapDispatchToProps, null, { forwardRef: true })(Notifications)
);
