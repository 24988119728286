import AxiosAuth2 from "../../services/axios-service-auth";

const uploadLabTest = (data) => {
  return AxiosAuth2.post("/api/patientLabTests/savePatientLabTests", data, undefined);
};

const getLabTests = (patientId) => {
    return AxiosAuth2.get("/api/patientLabTests/getPatientLabTests?patientId="+patientId,undefined, undefined);
  };
export default {
    uploadLabTest,
    getLabTests
};
