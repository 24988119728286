import firebase from '../../../../src/config/firebase';
import { Types } from "../constants/chat-types";
import API from "../api/chat-api";

export function getMessages(roomId) {
  return async function(dispatch, _getState) {
    try {
        
        firebase.firestore().collection('chatrooms').doc(roomId).collection('messages').orderBy('createdAt').onSnapshot((docs=>{
            const messages =[];
            docs.forEach((doc)=>{
              const obj ={...doc.data(),id:doc.id}
              messages.push(obj)
            })
            dispatch({
                type: Types.GET_MESSAGES,
                payload: messages
              }); 
              return messages;
        }))
      //let resp = await API.getMessagesList();
     
    } catch (e) {
      return { error: true };
    }
  };
}


export function sendPush(id,data) {
  return async function(dispatch, getState) {
    try {
      let resp = await API.sendPush(id,data);
      return resp;
    } catch (e) {
      return { error: true };
    }
  };
}
