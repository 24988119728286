import React from 'react';

const headingStyle = {
    textAlign: 'center',
    fontSize: '26px',
    fontWeight: 'bold',
    paddingBottom: '12px',
  };

const headingsStyle = {
    marginBottom: '12px',
    fontWeight: 'bold',
  };

const divStyle = {
    padding: '50px 50px',
  };

  const ulStyle = {
    listStyle: 'circle',
    paddingLeft: '20px',
    paddingRight: '20px',
    paddingBottom: '12px',
  };

  const liStyle = {
    padding: '5px 0px',
  };

  const pStyle = {
    paddingBottom: '12px',
  };


const TermsAndConditionsNih = () => {
  return (
    <div className='terms-conditions' style={divStyle}>
      <h1 style={headingStyle}>SkinTracker Application Terms & Conditions</h1>
        <p style={pStyle}>SKINTRACKER APP SERVICES ARE SUBJECT TO THESE TERMS AND CONDITIONS. PLEASE READ THE
        FOLLOWING INFORMATION CAREFULLY. YOUR CONTINUED USAGE OF THIS APP WILL INDICATE YOUR
        AGREEMENT TO BE BOUND BY THE TERMS AND CONDITIONS SET FORTH BELOW.</p>
        <h3 style={headingsStyle}>GENERAL</h3>
        <p style={pStyle}>These Terms and Conditions of Use (the “Terms and Conditions”) govern your use of this Mobile Application provided
        by the SkinTracker, involving all services, content, data, images, information and other materials posted on or
        available through this Mobile Application (collectively, the “Application”).</p>
        <h3 style={headingsStyle}>USE OF THE MOBILE APPLICATION</h3>
        <p style={pStyle}>As a condition of your use of this Application, you warrant to the SkinTracker that you will not use the Application for
        any purpose that is unlawful or prohibited by these Terms and Conditions. You may not reverse engineer,
        deconstruct, disassemble or decompile any software or technology underlying the Application or provided through the
        Application, except to the extent permitted by applicable law. If you breach any of these Terms and Conditions, your
        authorization to use this Application shall automatically terminate. The contents of the Application may not be
        distributed, modified, reproduced, or used, in whole or in part, without the prior written consent of the SkinTracker
        Application, except that you may download content from the Application to any single computer, provided you keep
        intact all copyright, trademark, and other proprietary notices. Except as provided in these Terms and Conditions, any
        use of these materials on any other Mobile Application or networked computer environment for any purpose is
        prohibited.</p>
        <h3 style={headingsStyle}>PRIVACY POLICY</h3>
        <p style={pStyle}>The SkinTracker Application vigorously guards the privacy of the information it receives from individuals. The study
        team has implemented reasonable measures to keep the Study Data it maintains safe, and to protect the
        confidentiality of your information, including storing it in systems with limited access housed in facilities using physical
        security measures. Despite our security measures, total confidentiality cannot be guaranteed.</p>
        <h3 style={headingsStyle}>COPYRIGHT</h3>
        <p style={pStyle}>All materials posted on this Application are subject to copyrights owned by the SkinTracker Application and other
            individuals or entities. Reproduction, re-transmission or reprinting is prohibited without prior written permission from
            the SkinTracker Application or the other copyright owner. The Application is protected by United States and
            international copyright and trademark laws. Any copies that you make of material or other content provided through
            the Application must contain the same copyright and other proprietary notices that appear with the material or
            content.</p>
        <h3 style={headingsStyle}>DISCLAIMER OF LIABILITY</h3>
        <p style={pStyle}>This application, including any content or information contained within it or any application-related service, is provided
            “as is,” with all faults, with no representations or warranties of any kind, either expressed or implied, including, but not
            limited to, the implied warranties of merchantability, fitness for a particular purpose, quiet enjoyment, quality of
            information, and title/non infringement. No oral or written information or advice given by the SkinTracker application or
            the application’s authorized representatives shall create a warranty or in any way increase the scope of this warranty.</p>
        <h3 style={headingsStyle}>LIMITATION OF LIABILITY</h3>
        <p style={pStyle}>The SkinTracker application and its affiliates, vendors, licensors, and other third parties mentioned on the application
            are neither responsible nor liable for any direct, indirect, incidental, consequential, special, exemplary, punitive, or
            other damages whatsoever (including, without limitation, those resulting from lost profits, lost data, or business
            interruption) arising out of or relating in any way to the application, application-related services and products, content
            or information contained within the application, and/or any hyperlinked mobile application, whether based on
            warranty, contract, tort, or any other legal theory and whether or not advised of the possibility of such damages.
            Applicable law may not allow the exclusion or limitation of incidental or consequential damages, so the above
            limitation or exclusion may not apply to you.</p>
        <h3 style={headingsStyle}>ACCURACY AND INTEGRITY OF INFORMATION</h3>
        <p style={pStyle}>Although theSkinTracker Application attempts to ensure the integrity and accurateness of the Application, it makes no
            guarantees whatsoever as to the correctness or accuracy of the Application. It is possible that the Application could
            include typographical errors, inaccuracies or other errors, and that unauthorized additions, deletions and alterations
            could be made to the Application by third parties. In the event that an inaccuracy arises, please inform the SkinTrackerApplication so that it can be corrected. Information contained on the Application may be changed or
            updated without notice</p>
        <h3 style={headingsStyle}>YOUR COMMUNICATIONS</h3>
        <p style={pStyle}>The SkinTracker Application may, but is not obligated to, monitor or review any areas of the Application where user
            Communications may be made available. The SkinTracker Application, however, will have no liability related to the
            content of any such Communications, whether or not arising under the laws of copyright, defamation, privacy,
            obscenity, or otherwise. The SkinTracker Application retains the right to remove, in its sole discretion,
            Communications that include any material deemed abusive, defamatory, obscene, or otherwise inappropriate.</p>
        <h3 style={headingsStyle}>ACCEPTABLE USE POLICY</h3>
        <p style={pStyle}>You may not transmit any material on or through the Application that (a) restricts any other user’s enjoyment of the
            Application, (b) is unlawful, threatening, abusive, libelous, defamatory, pornographic, profane or otherwise offensive,
            (c) constitutes or encourages criminal conduct, gives rise to civil liability, or otherwise violates any law, (d) violates or
            infringes the rights of any third party including, without limitation, patent, copyright, trademark, privacy or any other
            proprietary right, (e) contains a virus or other harmful component, or (f) contains false or misleading indications of
            origin or statements of fact. The SkinTracker Application reserves the right at all times to disclose any information as
            necessary to satisfy any law, regulation, government request, court order, subpoena or other legal process, or to edit
            or remove any information, in whole or in part, that in the SkinTracker Application’s sole discretion is objectionable,
            disruptive to the Application or in violation of these Terms and Conditions.</p>
        <h3 style={headingsStyle}>LINKS OR POINTERS TO OTHER APPLICATIONS</h3>
        <p style={pStyle}>The SkinTracker Application makes no representations whatsoever about any other Mobile Application that you may
            access through this Application. When you access a non-SkinTracker Application, please understand that it is
            independent from the SkinTracker Application, and that the SkinTracker Application has no control over the content
            on that Mobile Application. In addition, a hyperlink to a non-SkinTracker Application Mobile Application does not
            mean that the SkinTracker Application endorses or accepts any responsibility for the content, or the use, of the linked
            Application. It is up to you to take precautions to ensure that whatever you select for your use or download is free of
            such items as viruses, worms, Trojan horses, and other items of a destructive nature. If you decide to access any of
            the third party Applications linked to this Application, you do this entirely at your own risk.</p>
        <h4 style={headingsStyle}>Informed Consent</h4>
        <p style={pStyle}>To participate in any Studies, you must first review and sign an informed consent and authorization form (if
            applicable) (&quot;Informed Consent&quot;) for the Study. By using an App, you consent to the collection, use, and sharing of
            your information as described in this Privacy Policy and as outlined in the Informed Consent. To the extent anything
            in this Privacy Policy conflicts with the Informed Consent, the terms of the Informed Consent will control.</p>
        <p style={pStyle}>Throughout this policy we may use the term &quot;Study Team&quot; to refer to all the entities that may have access to data
            collected from or about you through the Apps or as part of the Studies. For all Studies the Informed Consent will
            include specific information about the members of the Study Team.</p>
        <h4 style={headingsStyle}>Collection and Use of Information</h4>
        <p style={pStyle}>Personal information is data that can be used to identify or contact a single person. Certain personal information and
            non-personal information, as described below, may be collected from or about you through the Apps or as part of the
            Studies.</p>
        <p style={pStyle}>The study investigators and the other Study Team members, may share the information we collect through the App
            with each other and use it consistent with this Privacy Policy.</p>
        <p style={pStyle}>If you decide to participate in the Study, below are examples of the types of information that we may collect and how
            we may use it.</p>
        <h4 style={headingsStyle}>What information we collect</h4>
        <p>When you download the SkinTracker Application and enroll in one of the Studies, below are examples of the
            categories of information (which may include some personal information) that may be collected from or about you
            through the Apps (&quot;Study Data&quot;). The specific categories of information that will be collected about you as part of a
            Study will be described in the Informed Consent.</p>
        <ul style={ulStyle}>
            <li style={liStyle}>Contact information such as your name, email address, and phone number.</li>
            <li style={liStyle}>Demographic information, such as your age, gender, state of residence, and race.</li>
            <li style={liStyle}>Medical history and information, such as your height/weight, prior medical diagnosis and testing (e.g.,
                diagnosis of an irregular heart rhythm), current and previous use of certain medications (e.g., blood thinning
                medications), certain family history (e.g., history of atrial fibrillation) and health habits (e.g., smoking). This 
                information may be collected through in-app surveys or other health surveys that you may be asked to
                complete.</li>
            <li style={liStyle}>Sensor information, such as heart rate and beat to beat calculations.</li>
            <li style={liStyle}>Technical data, which in many cases is non-personal information, i.e., data in a form that does not, on its
                own, permit direct association with any specific individual. Technical data includes, for example, information
                about your app usage (e.g., when an App was first launched), app version and installation ID, device
                identifier, and technical data about your device, such as operating system and model. The study team does
                not track its customers over time and across third party websites to provide targeted advertising and
                therefore does not respond to “Do Not Track” (DNT) signals.</li>
            <li style={liStyle}>Photographs of your body.</li>
            <li style={liStyle}>Adverse event information, such as a concern, adverse event, or other reportable matter arising in a Study.</li>
            <li style={liStyle}>Other information, if any, described in the Informed Consent.</li>
        </ul>
        <p style={pStyle}>How we use your information</p>
        <p style={pStyle}>Your personal information, such as your contact information, if collected through the App, may be used for purposes
            described in the Informed Consent including:</p>
        <ul style={ulStyle}>
            <li style={liStyle}>To enroll you in the Study, including determining your eligibility for participation in the Study</li>
            <li style={liStyle}>To conduct and support the Study</li>
            <li style={liStyle}>To contact you with surveys or other messages related to the Study through in-app notifications, email, or
                otherwise</li>
        </ul>
        <p style={pStyle}>Your Coded Study Data may be used for purposes described in the Informed Consent including:</p>
        <ul style={ulStyle}>
            <li style={liStyle}>To conduct and support the Study</li>
            <li style={liStyle}>To develop health-related products and improvement activities</li>
            <li style={liStyle}>For other research studies, if permitted in the Informed Consent</li>
            <li style={liStyle}>To issue research findings and related reports, which will not identify you</li>
        </ul>
        <p style={pStyle}>Your technical data (as described above) may also be used to determine your eligibility for a Study and general
            usage of the Apps.</p>
        <h4 style={headingsStyle}>Retention of Personal Information</h4>
        <p style={pStyle}>Your participation in any Study is voluntary. You may decide not to participate or you may leave any Study at any
            time. If you decide to withdraw, we may not delete the information that we have already collected and we may
            continue to use it, but we will stop receiving any new Study Data about you and will stop contacting you about the
            Study except in the case of serious, actionable medical need. The specific retention policies and the steps you need
            to take to withdraw will be described in the Informed Consent for each Study.</p>
    </div>
  );
};

export default TermsAndConditionsNih;