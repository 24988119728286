import AxiosAuth2 from "../../services/axios-service-auth";

const listMedications = (patientId,studyId) => {
  return AxiosAuth2.get("/api/medicationAnswers/getMedicationList?patientId="+patientId+"&studyId="+studyId, undefined, undefined);
};

const listFlaresMedications = (taskTrueId,patientId) => {
  return AxiosAuth2.get("/api/flareTask/getFlareMedicationList?taskTrueId="+taskTrueId+"&patientId="+patientId, undefined, undefined);
};

const listFlares = (userId) => {
  return AxiosAuth2.get("api/flareTask/flare-task?userId="+userId, undefined, undefined);
};

const medicationDetails = (data) => {
  return AxiosAuth2.post("api/medicationAnswers/getMedicationReviewData", data, undefined);
};

const medicationDetailsNih = (data) => {
  return AxiosAuth2.post("/api/nih/medication/getMedicationReviewData", data, undefined);
};

const flaresMedicationReview = (data) => {
  return AxiosAuth2.post("/api/flareTask/getMedicationReviewData", data, undefined);
};

const addDoctorNotesOnMedication = (data) => {
  return AxiosAuth2.post("/api/patientMedicationNotes/savePatientMedicationNotes", data, undefined);
};
const getDoctorCommentsOnMedication = (medicationId) => {
  return AxiosAuth2.get("/api/patientMedicationNotes/getPatientMedicationNotes/"+medicationId, undefined, undefined);
};

const getFlareTasks = (userId,startDateStr) => {
  return AxiosAuth2.get("/api/flareTask/getFlareTasksByDate/?userId="+userId+"&startDateStr="+startDateStr, undefined, undefined);
};

const getMedictionTypes = (userId) => {
  return AxiosAuth2.get("/api/nih/medication/getMedicationTypesByUserId?userId="+userId, undefined, undefined);
};

const newListMedications = (studyId,patientId,type) => {
  return AxiosAuth2.get("/api/nih/getPrescriptionAndOtcMedicationList?studyId="+studyId+"&patientId="+patientId+"&type="+type, undefined, undefined);
};

const newMedicationDetailsNih = (data) => {
  return AxiosAuth2.post("/api/nih/getPrescriptionAndOtcMedicationQuestionsAnswers", data, undefined);
};

export default {
    listMedications,
    medicationDetails,
    addDoctorNotesOnMedication,
    getDoctorCommentsOnMedication,
    medicationDetailsNih,
    listFlaresMedications,
    flaresMedicationReview,
    listFlares,
    getFlareTasks,
    getMedictionTypes,
    newListMedications,
    newMedicationDetailsNih
};
