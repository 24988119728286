import React from "react";
export const ProgressSvg = () => (
  <svg 
    version="1.1"
    id="Layer_1"
    x="0px"
    y="0px"
    width="1.2em"
    height="1.2em"
    viewBox="0 -1 512 511" 
    fill="currentColor"
  >
    <path d="m97.316406 510.480469c4.140625 0 7.5-3.355469 7.5-7.5v-190.644531c0-11.214844-9.125-20.339844-20.34375-20.339844h-64.128906c-11.21875 0-20.34375 9.125-20.34375 20.339844v190.644531c0 4.144531 3.359375 7.5 7.5 7.5zm-82.316406-198.144531c0-2.945313 2.394531-5.339844 5.34375-5.339844h64.128906c2.945313 0 5.339844 2.394531 5.339844 5.339844v183.144531h-74.8125zm0 0"/>
    <path d="m156.070312 241.699219c-11.214843 0-20.339843 9.125-20.339843 20.34375v240.9375c0 4.144531 3.355469 7.5 7.5 7.5h89.8125c4.144531 0 7.5-3.355469 7.5-7.5v-240.9375c0-11.21875-9.125-20.34375-20.339844-20.34375zm69.472657 20.34375v233.4375h-74.816407v-233.4375c0-2.945313 2.398438-5.34375 5.34375-5.34375h64.128907c2.949219 0 5.34375 2.398437 5.34375 5.34375zm0 0"/>
    <path d="m376.273438 502.980469v-291.230469c0-11.21875-9.125-20.34375-20.34375-20.34375h-64.128907c-11.21875 0-20.34375 9.125-20.34375 20.34375v291.230469c0 4.144531 3.359375 7.5 7.5 7.5h89.816407c4.140624 0 7.5-3.355469 7.5-7.5zm-15-7.5h-74.816407v-283.730469c0-2.945312 2.394531-5.34375 5.34375-5.34375h64.128907c2.945312 0 5.339843 2.398438 5.339843 5.34375v283.730469zm0 0"/>
    <path d="m414.6875 510.480469h89.8125c4.140625 0 7.5-3.355469 7.5-7.5v-341.527344c0-11.214844-9.125-20.339844-20.34375-20.339844h-64.128906c-11.214844 0-20.339844 9.125-20.339844 20.339844v341.527344c0 4.144531 3.355469 7.5 7.5 7.5zm7.5-349.027344c0-2.945313 2.394531-5.339844 5.339844-5.339844h64.128906c2.945312 0 5.34375 2.394531 5.34375 5.339844v334.027344h-74.8125zm0 0"/>
    <path d="m442.628906 104.996094c3.402344 4.898437 9.183594 7.410156 15.085938 6.5625 5.902344-.851563 10.738281-4.894532 12.621094-10.550782l25.632812-77.039062c1.570312-4.726562.84375-9.78125-2-13.871094-2.839844-4.089844-7.324219-6.535156-12.300781-6.710937l-81.136719-2.875c-5.984375-.21875-11.4375 2.910156-14.296875 8.144531-2.855469 5.234375-2.515625 11.527344.886719 16.425781l8.472656 12.199219c-43.84375 25.296875-147.191406 76.371094-288.003906 91.324219-4.121094.4375-7.105469 4.132812-6.667969 8.25.410156 3.851562 3.664063 6.710937 7.449219 6.707031.265625 0 .53125-.011719.800781-.039062 151.820313-16.121094 261.109375-73.515626 301.050781-97.445313 3.667969-2.195313 4.746094-7.195313 2.304688-10.710937 0 0-12.851563-18.5-13.085938-18.835938-.320312-.460938.015625-1.039062.554688-1.03125 0 0 80.804687 2.863281 81.140625 2.875.433593.015625.730469.449219.597656.859375 0 0-25.589844 76.910156-25.632813 77.035156-.167968.511719-.855468.601563-1.15625.167969l-11.605468-16.707031c-2.265625-3.265625-6.679688-4.191407-10.066406-2.125-17.496094 10.6875-46.757813 27.007812-86.832032 44.074219-3.8125 1.625-5.585937 6.027343-3.960937 9.839843s6.027343 5.585938 9.839843 3.960938c36.59375-15.585938 64.417969-30.601563 82.707032-41.425781zm0 0"/>
    <path d="m78.875 146.121094c4.132812-.269532 7.261719-3.84375 6.988281-7.976563-.269531-4.132812-3.847656-7.257812-7.976562-6.988281-16.277344 1.070312-32.664063 1.617188-48.707031 1.617188-4.144532 0-7.5 3.355468-7.5 7.5v53.886718c0 4.140625 3.355468 7.5 7.5 7.5 124.183593 0 227.800781-29.367187 292.855468-54 3.875-1.464844 5.828125-5.796875 4.359375-9.667968-1.464843-3.875-5.796875-5.828126-9.667969-4.359376-62.574218 23.691407-161.460937 51.84375-280.046874 52.988282v-38.886719c13.957031-.144531 28.117187-.683594 42.195312-1.613281zm0 0"/>
  </svg>
);
