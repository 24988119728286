import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import ReactApexChart from "react-apexcharts";
import moment from "moment-timezone";
import * as ActivityActions from "../../redux/actions/patient-activity-actions";

const dateFormat = "Do MMM";

class StandHours extends Component {
  constructor(props) {
    super(props);
    this.state = {standData:[]}
  }

 async componentDidMount() {
    let patientId = this.props.match.params.id;
    let standData = [];
    let studyId = localStorage.getItem("studyId");
    if (studyId === "4") {
      standData = await this.props.getPhysicalActivitiesNih(patientId, studyId);
    } else {
      standData = await this.props.getPhysicalActivities(patientId);
    }
    if(standData && standData.data && standData.data.length>0){
      this.setState({standData: standData.data});
    }


    // await this.props.getPhysicalActivities(patientId);
    // if(this.props.physicalActivity && this.props.physicalActivity.length>0){
    //   this.setState({standData:this.props.physicalActivity})
    // }
  };

  render() {
  const {standData} = this.state;
  
    let series = [];
    let standHours = [];
    let seriesData = {}
    if (standData) {
        standData.map((data, index) => {
        var obj = {};
        obj["x"] = moment(data.date).format(dateFormat);
        if (data.stand % 1 !== 0) {
          obj["y"] = parseFloat(data.stand).toFixed(2);
        } else {
          obj["y"] = data.stand;
        }
        // obj["y"] = data.stand;
        standHours.push(obj);
      })
      series.push({ "name":"Stand","data": standHours });
      seriesData = series;
    }
    const options = {
      chart: {
        type: 'area',
        height: 350,
        zoom: {
          enabled: true
        }
      },
      xaxis: {
        labels: {
          rotate: -90,
          // rotateAlways: false,
          // hideOverlappingLabels: true,
          showDuplicates: false,
          // trim: true,
          // minHeight: undefined,
          // maxHeight: 120,
          style: {
              colors: [],
              fontSize: '10px',
              fontFamily: 'Helvetica, Arial, sans-serif',
              fontWeight: 400,
              cssClass: 'apexcharts-xaxis-label',
          },
        },
      },
      dataLabels: {
        enabled: false
      }
    };

    return (
      <div id = "chart"  >
      {seriesData ?  <ReactApexChart options={options} series={seriesData} type="area" height={350} width={"100%"}/>:""}
      </div>
    );
  }
}
function mapStateToProps(state) {
  return {
    physicalActivity: state.activity.getPhysicalActivity,
  };
}
function mapDispatchToProps(dispatch) {
  return bindActionCreators({...ActivityActions}, dispatch);
}
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(StandHours)
);
