import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import SideBar from "../layout/sidebar/sidebar.jsx";
import UserHeader from "../layout/header.jsx";
import options from "../layout/sidebar/dashboard-options";
import * as PatientActions from "../../redux/actions/patient-actions";
import * as StudyActions from "../../redux/actions/study-actions";
import * as StudyDrugLogActions from "../../redux/actions/study-drug-log-action";
import * as AdverseEventActions from "../../redux/actions/adverse-event-actions";
import "react-h5-audio-player/lib/styles.css";
import {
  Select,
  Layout,
  Row,
  Col,
  Spin,
  Card,
  PageHeader,
  Button,
  Form,
  Input,
  Tooltip,
  DatePicker,
} from "antd";
import moment from "moment-timezone";
import { notifyUser } from "../../services/notification-service";
import firebase from "../../../config/firebase";
import Config from "../../config";
import { ChatSvg } from "../shared/svg/chatlg";
import { ServeySvg } from "../shared/svg/servey";
import { DataSvg } from "../shared/svg/dataicon";
import { DairySvg } from "../shared/svg/dairyicon";
import { AdverseEventSvg } from "../shared/svg/adverseevents";
import { LabSvg } from "../shared/svg/labicon";
import { PaymentSvg } from "../shared/svg/paymenticon";
import { PhotogallerySvg } from "../shared/svg/photogallery";
import Icon from "@ant-design/icons";
import { StudyDrugLogSvg } from "../shared/svg/studydruglog.jsx";
import Modal from "antd/lib/modal/Modal";
import {
  SaveOutlined,
  CommentOutlined,
  DeleteOutlined,
  AuditOutlined
} from "@ant-design/icons";

const { Header, Sider, Content } = Layout;
const ChatIcon = (props) => <Icon component={ChatSvg} {...props} />;
const ServeyIcon = (props) => <Icon component={ServeySvg} {...props} />;
const DataIcon = (props) => <Icon component={DataSvg} {...props} />;
const DairyIcon = (props) => <Icon component={DairySvg} {...props} />;
const AdverseEventIcon = (props) => (
  <Icon component={AdverseEventSvg} {...props} />
);
const LabIcon = (props) => <Icon component={LabSvg} {...props} />;
const PaymentIcon = (props) => <Icon component={PaymentSvg} {...props} />;
const PhotogalleryIcon = (props) => (
  <Icon component={PhotogallerySvg} {...props} />
);
const StudyDrugLogIcon = (props) => (
  <Icon component={StudyDrugLogSvg} {...props} />
);
const { Option } = Select;
const dateFormat = "Do MMM";
class StudyDrugLog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      showRemove: false,
      isModalVisible: false,
      showForm: false,
      collapsed: false,
      patientData: undefined,
      loading: true,
      submitted: false,
      patientVoiceDiary: [],
      selectItemId: "",
      selectedCol: "log",
      adverseEvent: undefined,
      currentIndex: "",
      saveButtonShow: false,
      fields: [],
      saveAllFields: undefined,
      isSet: false,
      flag: true, 
      kitNoIndexChanged: undefined
    };
  }
  async componentDidMount() {
    //this.state.fields[0].endDate.setDate(this.state.fields[0].endDate.getDate() + 1);
    let studyId = localStorage.getItem("studyId");
    this.props
      .getStudyById(studyId)
      .then((resp) => {
        this.setState({
          loading: false,
          data: resp.data,
        });
      })
      .catch((ex) => {
        this.setState({ loading: false });
      });
    let patientId = this.props.match.params.id;
    // await this.props.getPatientById(patientId);
    if (studyId == 2) {
      await this.props.getPatientByIdJanssen(patientId);
    } else if (studyId == 4) {
      await this.props.getPatientByIdNih(patientId);
    } else {
      await this.props.getPatientById(patientId);
    }
    if (
      this.props.patientData &&
      this.props.patientData.length > 0 &&
      this.props.patientData[0]
    ) {
      this.setState({ loading: false });
    } else if (this.props.patientDataError) {
      notifyUser(this.props.patientDataError, "error");
      this.props.history.push("/patients/1");
    }
    await this.props.getStudyLogsByPatientId(patientId);
    if (this.props.drugLogsList) {
      if (
        this.props.drugLogsList &&
        this.props.drugLogsList !== "No Data Found"
      ) {
        this.setState({
          fields: this.props.drugLogsList,
          loading: false,
          currentIndex: this.props.drugLogsList.length,
        });
        if (this.props.drugLogsList.length > 0) {
          this.setState({ saveButtonShow: true });
        }
      }
    }
  }
  toggleSidebar = () => {
    this.setState({
      collapsed: !this.state.collapsed,
    });
  };
  goToChat = () => {
    var patientId = this.props.match.params.id;
    var ref = firebase.firestore().collection("chatrooms");
    var type = Config.server.type;
    ref
      .where(`users.${patientId + type}`, "==", true)
      .get()
      .then((docs) => {
        let room = {};
        docs.forEach((snapshot) => {
          room = snapshot.data();
          room.id = snapshot.id;
        });
        this.props.history.push("/chat/" + patientId);
      });
  };
  goToDiary = (colId) => {
    this.setState({ selectedCol: colId });
    var patientId = this.props.match.params.id;
    this.props.history.push("/patient-diary/" + patientId);
  };
  goToPhotoGallery = (id) => {
    this.setState({ selectedCol: id });
    var patientId = this.props.match.params.id;
    this.props.history.push("/photo-gallery/" + patientId);
  };
  goToPatientProgress = () => {
    var patientId = this.props.match.params.id;
    this.props.history.push("/patient-progress/" + patientId);
  };
  goToActivityData = (id) => {
    this.setState({ selectedCol: id });
    var patientId = this.props.match.params.id;
    this.props.history.push("/activity-data/" + patientId);
  };
  async goToAdverseEvent(item) {
    this.setState({ selectItemId: item.adverseEventId, adverseEvent: item });
  }
  goToStudyDrugLog = (id) => {
    this.setState({ selectedCol: id });
    var patientId = this.props.match.params.id;
    this.props.history.push("/study-drug-log/" + patientId);
  };
  goToEASiScoring = () => {
    var patientId = this.props.match.params.id;
    this.props.history.push("/EASI-head/" + patientId);
  };
  goToAdverseEvents = (id) => {
    this.setState({ selectedCol: id });
    var patientId = this.props.match.params.id;
    this.props.history.push("/adverse-events/" + patientId);
  };
  goToLab = (id) => {
    var patientId = this.props.match.params.id;
    this.props.history.push("/lab/" + patientId);
  };

  handleAddInputFields = (event) => {
    //console.log("index: " + this.state.fields.length);
    //console.log("endDate: " + this.state.fields[this.state.fields.length - 1].endDate);
    let eDate = new Date();
    if (this.state.fields && this.state.fields.length > 0) {
      eDate = new Date(this.state.fields[this.state.fields.length - 1].endDate);
    }

    var obj = {};
    var newArray = [...this.state.fields];
    eDate.setDate(eDate.getDate() + 1);
    obj.startDate = moment(eDate).format("YYYY-MM-DD");
    eDate.setDate(eDate.getDate() + 7);
    obj.endDate = moment(eDate).format("YYYY-MM-DD");
    newArray.push(obj);
    this.setState({ fields: newArray, showRemove: true, saveButtonShow: newArray.length > 0 ? true : false });
  };

  handleInputChange = (event, index, name) => {

    const values = [...this.state.fields];
    if (name === "number") {
      values[index].number = event.target.value;
    } else if (name === "kitNo") {
      this.setState({ kitNoIndexChanged : index })
      values[index].kitNo = event.target.value;
    } else if (name === "comments") {
      values[index].comments = event.target.value;
    } else if (name === "patientComments") {
      values[index].patientComments = event.target.value;
    } else if (name === "timeUnit") {
      values[index].timeUnit = event;
    } else if (name === "number") {
      values[index].number = event.target.value;
    }
    this.setState({ fields: values });
  };

  handleSaveAllFields = (event) => {
    var newObj = [];
    var isObjOk = true;
    if (this.state.flag) {

      if (this.state.fields.length > 0) {
        for (
          let i = this.state.currentIndex;
          i < this.state.fields.length;
          i += 1
        ) {

          if (this.state.fields[i] && this.state.fields[i].number && this.state.fields[i].number !== ""
            && new Date(this.state.fields[i].endDate).getTime() > new Date(this.state.fields[i].startDate).getTime()
            && this.state.fields[i].startDate && this.state.fields[i].startDate !== ""
            && this.state.fields[i].endDate && this.state.fields[i].endDate !== ""
            && this.state.fields[i].kitNo && this.state.fields[i].kitNo !== ""
            && this.state.fields[i].timeUnit && this.state.fields[i].timeUnit !== "") {
            var obj = {
              startDate: new Date(this.state.fields[i].startDate).getTime(),
              endDate: new Date(this.state.fields[i].endDate).getTime(),
              kitNo: this.state.fields[i].kitNo,
              comments: this.state.fields[i].comments,
              patientId: this.props.match.params.id,
              //obj.week = this.state.fields[i].week;
              timeUnit: this.state.fields[i].timeUnit,
              number: this.state.fields[i].number,
            };

            newObj.push(obj);
          } else {
            isObjOk = false;
            console.log("Data not saved..")
          }
        }
        console.log("newObj: ", newObj);
        this.setState({
          saveAllFields: newObj,
        });

        if (newObj && newObj.length > 0 && isObjOk) {
          let self = this;
          self.props
            .addStudyEvent(newObj)
            .then((resp) => {
              self.setState({
                fields: resp.data,
              });
              if (resp.error && resp.error !== "") {
                notifyUser(resp.error.message, "error");
                self.setState({ submitted: false });
              } else {
                if (resp !== "") {
                  notifyUser("Study Drug Log Saved Successfully", "success");
                  window.location.reload();
                  //self.setState({ submitted: false });
                }
              }
            })
            .catch((ex) => {
              this.setState({ loading: false });
            });
        }
      }
    }
  };

  onStartDateChange = (date, dateString, index) => {
    const values = [...this.state.fields];
    values[index].startDate = dateString; //new Date(dateString).getTime();
    this.setState({ fields: values });
  };

  onEndDateChange = (date, dateString, index) => {
    const values = [...this.state.fields];
    values[index].endDate = dateString; //new Date(dateString).getTime();
    this.setState({ fields: values });
  };

  handleRemoveFields = (index, field) => {
    const values = [...this.state.fields];
    values.splice(index, 1);
    this.setState({
      fields: values,
    });
  };

  handleSubmit = async (values, index) => {
    console.log(this.state.fields[index]);

    if (this.state.flag) {

      // if (!this.state.fields[index].number || this.state.fields[index].number === "") {
      //   notifyUser("Please enter Number", "error");
      // } else if (
      //   new Date(this.state.fields[index].endDate).getTime() <
      //   new Date(this.state.fields[index].startDate).getTime()
      // ) {
      //   notifyUser("Start Date cannot be greater than End Date", "error");
      // } else if (!this.state.fields[index].startDate || this.state.fields[index].startDate === "") {
      //   notifyUser("Please select Start Date", "error");
      // } else if (!this.state.fields[index].endDate || this.state.fields[index].endDate === "") {
      //   notifyUser("Please select End Date", "error");
      // } else if (!this.state.fields[index].kitNo || this.state.fields[index].kitNo === "") {
      //   notifyUser("Please enter Kit Number", "error");
      // } else {
      if (this.state.fields[index].number && this.state.fields[index].number !== ""
        && new Date(this.state.fields[index].endDate).getTime() > new Date(this.state.fields[index].startDate).getTime()
        && this.state.fields[index].startDate && this.state.fields[index].startDate !== ""
        && this.state.fields[index].endDate && this.state.fields[index].endDate !== ""
        && this.state.fields[index].kitNo && this.state.fields[index].kitNo !== ""
        && this.state.fields[index].timeUnit && this.state.fields[index].timeUnit !== "") {
        let self = this;
        let data = {
          startDate: new Date(this.state.fields[index].startDate).getTime(),
          endDate: new Date(this.state.fields[index].endDate).getTime(),
          kitNo: this.state.fields[index].kitNo,
          comments: this.state.fields[index].comments,
          patientId: this.props.match.params.id,
          timeUnit: this.state.fields[index].timeUnit,
          number: this.state.fields[index].number,
        }
        let dataObj = [];
        dataObj.push(data);
        self.props
          .addStudyEvent(dataObj)
          .then((resp) => {
            self.setState({
              fields: resp.data,
            });
            if (resp.error && resp.error !== "") {
              notifyUser(resp.error.message, "error");
              self.setState({ submitted: false });
            } else {
              if (resp !== "") {
                notifyUser("Study Drug Log Saved Successfully", "success");
                window.location.reload();
                //self.setState({ submitted: false });
              }
            }
          })
          .catch((ex) => {
            this.setState({ loading: false });
          });
      } else {
        console.log("Data not saved..")
      }
    }
  };

  showModal = (value, index) => {
    this.setState({ commentsValue: value });
    this.setState({ isModalVisible: true });
  };

  handleOk = () => {
    this.setState({ isModalVisible: false });
  };

  handleCancel = () => {
    this.setState({ isModalVisible: false });
  };

  goToFlares = (id) => {
    this.setState({ selectedCol: id });
    var patientId = this.props.match.params.id;
    this.props.history.push("/flares/" + patientId);
  }

  render() {
    const studyId = localStorage.getItem("studyId");
    const { submitted, selectedCol, fields } = this.state;
    console.log("fields----", this.state.fields);

    const timeUnitOptions = Config.timeUnits.map((unit) => (
      <Option key={unit.name} value={unit.name}>
        {unit.name}
      </Option>
    ));
    return (
      <Layout>
        <Sider
          breakpoint="xl"
          collapsedWidth="0"
          width="260px"
          className="sidebar"
          // trigger={null}
          onBreakpoint={(broken) => {
            console.log(broken);
          }}
          // collapsible
          onCollapse={(collapsed, type) => {
            console.log(collapsed, type);
          }}
        // collapsed={this.state.collapsed}
        >
          <SideBar {...this.props} options={options.dashBoardOptions} />
        </Sider>
        <Layout className="site-layout">
          <Header
            className="site-layout-background"
            style={{
              background: "#f2f5f9",
              zIndex: 100,
              top: 0,
              width: "100%",
              padding: 0,
            }}
          >
            <UserHeader
              {...this.props}
              isCollapsed={this.state.collapsed}
              toggleSidebar={this.toggleSidebar}
            />
          </Header>
          <Content className="site-layout-background">
            <PageHeader
              className="site-header-title"
              title={
                this.state.data &&
                  this.state.data != null &&
                  this.state.data.title
                  ? this.state.data.title
                  : "Eczema Tracking Study"
              }
            ></PageHeader>
            <Spin spinning={submitted}>
              {!this.state.loading && (
                <Row gutter={16}>
                  <Col xs={24} sm={24} md={24} xl={24}>
                    <Card
                      title={"Study Drug Log"}
                      extra={
                        <Button onClick={() => this.goToPatientProgress()}>
                          Back
                        </Button>
                      }
                      style={{ marginBottom: "8px", padding: "0 16px" }}
                      className="study-drug-tables"
                    >
                      <Row className="sd-tables-header" gutter={20}>
                        <Col xs={20} sm={10} md={1} xl={1}>
                          S.No.
                        </Col>
                        <Col xs={20} sm={10} md={3} xl={3}>
                          Time Unit
                        </Col>
                        <Col xs={20} sm={10} md={3} xl={3}>
                          Number
                        </Col>
                        <Col xs={20} sm={10} md={3} xl={3}>
                          Start Date
                        </Col>
                        <Col xs={20} sm={10} md={3} xl={3}>
                          End Date
                        </Col>
                        <Col xs={20} sm={10} md={3} xl={3}>
                          Kit Number
                        </Col>
                        <Col xs={20} sm={10} md={8} xl={8}>
                          Comments
                        </Col>
                      </Row>
                      {this.state.fields ? (
                        <Form>
                          <div>
                            {this.state.fields.map((field, index) => (
                              <Row className="sd-tables-row" gutter={24}>
                                <Col xs={20} sm={10} md={1} xl={1}>
                                  <span>{index + 1}.</span>
                                </Col>
                                <Col xs={20} sm={10} md={3} xl={3}>
                                  <Form.Item
                                    name={`timeunit[${index}]`}
                                    rules={[
                                      {
                                        required: true,
                                        message: "Please select time unit!",
                                      },
                                    ]}
                                    initialValue={field.timeUnit}
                                  >
                                    <Select
                                      onChange={(e) =>
                                        this.handleInputChange(
                                          e,
                                          index,
                                          "timeUnit"
                                        )
                                      }
                                      // defaultValue={field.timeUnit}
                                      placeholder="Select timeunit"
                                    >
                                      {timeUnitOptions}
                                    </Select>
                                  </Form.Item>
                                </Col>
                                <Col xs={20} sm={10} md={3} xl={3}>
                                  <Form.Item
                                    name={`number[${index}]`}
                                    className="form-title-field"
                                    initialValue={field.number}
                                    rules={[
                                      {
                                        required: true,
                                        message: "Please enter number! ",
                                      },
                                    ]}
                                  >
                                    <Input
                                      type="text"
                                      onChange={(e) =>
                                        this.handleInputChange(
                                          e,
                                          index,
                                          "number"
                                        )
                                      }
                                    />
                                  </Form.Item>
                                </Col>
                                <Col xs={20} sm={10} md={3} xl={3}>
                                  <Form.Item
                                    name={`startDate[${index}]`}
                                    className="form-title-field1"
                                    initialValue={
                                      field.startDate
                                        ? moment(field.startDate)
                                        : ""
                                    }
                                    rules={[
                                      {
                                        required: true,
                                        message: "Please select startDate!",
                                      },
                                    ]}
                                  >
                                    <DatePicker
                                      format="yyyy-MM-DD"
                                      onChange={(date, dateString) =>
                                        this.onStartDateChange(
                                          date,
                                          dateString,
                                          index
                                        )
                                      }
                                    />

                                  </Form.Item>
                                </Col>
                                <Col xs={20} sm={10} md={3} xl={3}>
                                  <Form.Item
                                    name={`endDate[${index}]`}
                                    className="form-title-field2"
                                    initialValue={
                                      field.endDate ? moment(field.endDate) : ""
                                    }
                                    rules={[
                                      {
                                        required: true,
                                        message: "Please select endDate!",
                                      },
                                    ]}
                                  >
                                    <DatePicker
                                      onChange={(date, dateString) =>
                                        this.onEndDateChange(
                                          date,
                                          dateString,
                                          index
                                        )
                                      }
                                    />

                                  </Form.Item>
                                </Col>
                                <Col xs={20} sm={10} md={3} xl={3}>
                                  <Form.Item
                                    name={`kitNo[${index}]`}
                                    className="form-title-field"
                                    initialValue={field.kitNo}
                                    rules={[
                                      {
                                        required: true,
                                        validator: (rule, value) => {
                                          if (value) {
                                            if (this.state.kitNoIndexChanged === index && this.state.fields && this.state.fields.length > 0 && this.state.fields.some((e, i) => i !== index && Number(e.kitNo) === Number(value))) {
                                              this.setState({ flag: false })
                                              return Promise.reject("Duplicate kit number not allowed!");
                                            } else {
                                              this.setState({ flag: true })
                                              return Promise.resolve();
                                            }
                                          } else {
                                            return Promise.reject("Please enter kit number!");
                                          }
                                        }
                                      }
                                    ]}
                                  >
                                    <Input
                                      onChange={(e) =>
                                        this.handleInputChange(
                                          e,
                                          index,
                                          "kitNo"
                                        )
                                      }
                                    />
                                  </Form.Item>
                                </Col>
                                <Col xs={20} sm={10} md={5} xl={5}>
                                  <Form.Item
                                    name={`comments[${index}]`}
                                    className="form-title-field"
                                    initialValue={field.comments}
                                  >
                                    <Input
                                      type="text"
                                      id="2"
                                      onChange={(e) =>
                                        this.handleInputChange(
                                          e,
                                          index,
                                          "comments"
                                        )
                                      }
                                    />
                                  </Form.Item>
                                </Col>
                                <Col xs={20} sm={6} md={3} xl={3}>
                                  <Button
                                    style={{
                                      minWidth: "30px",
                                      padding: "0 5px",
                                      height: "35px",
                                      display: "inline-block",
                                      borderRadius: "100%",
                                    }}
                                    onClick={(values) =>
                                      this.handleSubmit(values, index)
                                    }
                                    htmlType="submit"
                                  >
                                    <Tooltip title="Save">
                                      <SaveOutlined />
                                    </Tooltip>
                                  </Button>
                                  <Button
                                    style={{
                                      minWidth: "30px",
                                      padding: "0 5px",
                                      height: "35px",
                                      display: "inline-block",
                                      borderRadius: "100%",
                                    }}
                                    onClick={(values) =>
                                      this.showModal(
                                        field.patientComments,
                                        index
                                      )
                                    }
                                  >
                                    <Tooltip title="Comments">
                                      <CommentOutlined />
                                    </Tooltip>
                                  </Button>
                                  {!field.kitNo ? (
                                    <Button
                                      style={{
                                        minWidth: "30px",
                                        padding: "0 5px",
                                        height: "35px",
                                        display: "inline-block",
                                        borderRadius: "100%",
                                      }}
                                      onClick={(values) =>
                                        this.handleRemoveFields(index, field)
                                      }
                                    >
                                      <Tooltip title="Delete">
                                        <DeleteOutlined />
                                      </Tooltip>
                                    </Button>
                                  ) : (
                                    ""
                                  )}
                                </Col>
                              </Row>
                            ))}
                          </div>
                          <Button
                            onClick={(e) => this.handleAddInputFields(e)}
                            className="small plus-add-btn"
                          >
                            <Tooltip title="Add"> +</Tooltip>
                          </Button>

                          <Button
                            disabled={!this.state.saveButtonShow}
                            onClick={(e) => this.handleSaveAllFields(e)}
                            className="small"
                            htmlType="submit"
                          >
                            <Tooltip title="Save All">
                              <SaveOutlined />
                            </Tooltip>
                          </Button>
                        </Form>
                      ) : (
                        ""
                      )}
                      <Modal
                        title="Basic Modal"
                        visible={this.state.isModalVisible}
                        onOk={() => this.handleOk()}
                        onCancel={() => this.handleCancel()}
                      >
                        <p>{this.state.commentsValue}</p>
                      </Modal>
                      {
                        // <Button
                        //   onClick={(e) => this.handleAddInputFields(e)}
                        // // className="small"
                        // >
                        //   <Tooltip title="Add"> +</Tooltip>
                        // </Button>
                      }
                      {
                        // <Button
                        //   disabled={!this.state.saveButtonShow}
                        //   onClick={(e) => this.handleSaveAllFields(e)}
                        //   className="small"
                        //   htmlType="submit"
                        // >
                        //   <Tooltip title="Save All">
                        //     <SaveOutlined />
                        //   </Tooltip>
                        // </Button>
                      }
                    </Card>
                    <ul
                      className="link-list-box study-link-list-box"
                      style={{ display: "block" }}
                    >
                      <li>
                        <div onClick={() => this.goToChat()} class="link-box">
                          <ChatIcon />
                          <h4>Chat</h4>
                        </div>
                      </li>
                      <li>
                        <div
                          onClick={() => this.goToEASiScoring()}
                          class="link-box"
                        >
                          <ServeyIcon />
                          <h4>Surveys &amp; Scores</h4>
                        </div>
                      </li>
                      <li>
                        <div
                          onClick={() => this.goToDiary("diary")}
                          className={
                            selectedCol === "diary"
                              ? "link-box selected-item-list"
                              : "link-box"
                          }
                        >
                          <DairyIcon />
                          <h4>Diary</h4>
                        </div>
                      </li>
                      <li>
                        <div
                          onClick={() => this.goToPhotoGallery("gallery")}
                          className={
                            selectedCol === "gallery"
                              ? "link-box selected-item-list"
                              : "link-box"
                          }
                        >
                          <PhotogalleryIcon />
                          <h4>Photo Gallery </h4>
                        </div>
                      </li>
                      <li>
                        <div
                          onClick={() => this.goToAdverseEvents("adverse")}
                          className={
                            selectedCol === "adverse"
                              ? "link-box selected-item-list"
                              : "link-box"
                          }
                        >
                          <AdverseEventIcon />
                          <h4>Adverse Events</h4>
                        </div>
                      </li>
                      <li>
                        <div
                          onClick={() => this.goToActivityData("activity")}
                          class="link-box"
                        >
                          <DataIcon />
                          <h4>Activity Data</h4>
                        </div>
                      </li>
                      <li>
                        <div
                          onClick={() => this.goToStudyDrugLog("log")}
                          className={
                            selectedCol === "log"
                              ? "link-box selected-item-list"
                              : "link-box"
                          }
                        >
                          <StudyDrugLogIcon />
                          <h4>Study Drug Log</h4>
                        </div>
                      </li>
                      <li>
                        <div
                          onClick={() => this.goToLab("lab")}
                          class="link-box"
                        >
                          <LabIcon />
                          <h4>Lab</h4>
                        </div>
                      </li>
                      {
                        studyId == 4 ?
                        <li>
                        <div
                          onClick={() => this.goToFlares()}
                          class="link-box"
                        >
                          <AuditOutlined style={{fontSize: "35px"}}/>
                          <h4>Flares</h4>
                        </div>
                      </li>
                      : ""
                      }
                    </ul>
                  </Col>
                </Row>
              )}
            </Spin>
          </Content>
        </Layout>
      </Layout>
    );
  }
}
function mapStateToProps(state) {
  return {
    patientData: state.patient.patientData,
    patientVoiceDiary: state.diary.voiceDiaryList,
    doctorvoiceComments: state.diary.doctorVoiceNotesList,
    textDiaryList: state.diary.textDiaryList,
    doctorTextNotesList: state.diary.doctorTextNotesList,
    drugLogsList: state.drugLogs.listDrugLogs,
  };
}
function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      ...PatientActions,
      ...StudyActions,
      ...AdverseEventActions,
      ...StudyDrugLogActions,
    },
    dispatch
  );
}
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(
    StudyDrugLog
  )
);
