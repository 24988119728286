import React, { Component } from "react";
import { Row, Col, Typography, Spin } from "antd";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import DashboardHeader from "./../layout/header.jsx";
import options from "./../layout/sidebar/dashboard-options";
import { Layout, List, Avatar} from "antd";
import SideBar from "../layout/sidebar/sidebar";
import * as PatientActions from "../../redux/actions/patient-actions";
import { notifyUser } from "../../services/notification-service";
import * as MedicalHistoryActions from "../../redux/actions/medical-history-actions";
// import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
// import { PlusCircleFilled, MinusCircleFilled } from "@ant-design/icons";
import moment from "moment-timezone";
import firebase from "../../../../src/config/firebase";
import Config from "../../config";
const { Header, Sider, Content } = Layout;
const dateFormathh = "MM/DD/YYYY";

class MedicalHistory extends Component {
  constructor(props) {
    super(props);
    this.state = {
      submitted: true,
      patientData: undefined,
      quesAnsListing: undefined,
      loading: true,
      qaLoading: true,
      isModalVisible: false,
      image: {},
      medicalHistory: undefined,
    };
  }

  async componentDidMount(event) {
    let studyId = localStorage.getItem('studyId');
    // this.setState({ submitted: true });
    // await this.props.getPatientById(this.props.match.params.id);
    if (studyId == 2) {
      await this.props.getPatientByIdJanssen(this.props.match.params.id);
    } else if (studyId == 4){
      await this.props.getPatientByIdNih(this.props.match.params.id);
    } else {
      await this.props.getPatientById(this.props.match.params.id);
    }
    if (
      this.props.patientData &&
      this.props.patientData.length > 0 &&
      this.props.patientData[0]
    ) {
      this.setState({ loading: false });
      let localStudyId = localStorage.getItem("studyId");
      let patId = this.props.patientData[0].studyId
      if (localStudyId != patId || !patId) {
        this.props.history.push("/dashboard");
        notifyUser("You are not authorized to view this Patient!", "error");
      }
      
      // await this.props.getQuesAnsList(
      //   this.props.patientData[0].studyId,
      //   "screening",
      //   this.props.patientData[0].userId
      // ).then((response) => {
      //   this.setState({ qaLoading: false });
      // });
      // if (this.props.quesAnsData && this.props.quesAnsData.length > 0) {
      //   this.setState({ qaLoading: false });
      // } else if (this.props.quesAnsListingError) {
      //   this.setState({ qaLoading: false });
      // }
    } else if (this.props.patientDataError) {
      notifyUser(this.props.patientDataError, "error");
      this.props.history.push("/patients/1");
    }

    if (studyId == "4") {
      await this.props.listAllMedicalHistoryNih(
        this.props.match.params.id,
        this.props.patientData[0].studyId
      ).then((response) => {
        this.setState({ submitted: false });
      });
    } else {
      await this.props.listAllMedicalHistory(
        this.props.match.params.id,
        this.props.patientData[0].studyId
      ).then((response) => {
        this.setState({ submitted: false });
      });
    }

    if (this.props.medicalHistoryList && this.props.medicalHistoryList.length > 0) {
      //storing data to reducers
      //set data from reducers to local state
      this.setState({ medicalHistory: this.props.medicalHistoryList, qaLoading: false });
    }
  }

  async componentWillUnmount() {
    await this.props.clearScreeningQuestionState();
  }

  approve = async (userId, status) => {
    let self = this;
    this.setState({ submitted: true });
    await self.props.approvePatient(userId, status).then((response) => {
      this.setState({
        loading: false,
      });
      if (response.error && response.error !== "") {
        notifyUser(response.error.message, "error");
        self.setState({ submitted: false });
      } else {
        notifyUser("Study Status Updated", "success");
        self.setState({ submitted: false });
        this.props.history.push("/patients/1");
      }
    });
  };

  goToChat = () => {
    var patientId = this.props.match.params.id;
    var ref = firebase.firestore().collection("chatrooms");
    var type = Config.server.type;
    ref
      .where(`users.${patientId + type}`, "==", true)
      .get()
      .then((docs) => {
        let room = {};
        docs.forEach((snapshot) => {
          room = snapshot.data();
          room.id = snapshot.id;
        });
        this.props.history.push("/chat/" + patientId);
      });
  };

  showModal = (img) => {
    this.setState({ image: img, isModalVisible: true });
  };

  handleCancel = () => {
    this.setState({ isModalVisible: false });
  };

  setDate = (date) => {
    var month = new Date(date).toLocaleString("default", { month: "short" });
    var day = new Date(date).getDate();
    var year = new Date(date).getFullYear();
    return month + " " + day + ", " + year;
  };

  formatDate = (timestamp) => {
    const date = new Date(timestamp);
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-based, so add 1
    const day = String(date.getDate()).padStart(2, '0');
    const year = date.getFullYear();
    
    return `${month}/${day}/${year}`;
  };

  render() {
    const { submitted } = this.state;
    const studyId = localStorage.getItem("studyId")

    const oldDate = this.props.patientData && this.props.patientData[0] && this.props.patientData[0].dateOfBirth
    const dateType = !isNaN(Date.parse(oldDate))
    let finalDate = "";
    if (oldDate && dateType == true) {
      finalDate = moment(oldDate).format("MM/DD/YYYY")
    } else if (oldDate && dateType == false) {
      finalDate = moment(new Date(Number(oldDate))).format("MM/DD/YYYY")
    } else {
      finalDate = "N/A"
    }
    return (
      <Layout>
        <Sider
          breakpoint="lg"
          collapsedWidth="0"
          width="260px"
          className="sidebar"
          onBreakpoint={(broken) => {
            console.log(broken);
          }}
          onCollapse={(collapsed, type) => {
            console.log(collapsed, type);
          }}
        >
          <SideBar {...this.props} options={options.dashBoardOptions} />
        </Sider>
        <Layout className="site-layout">
          <Header
            className="site-layout-background"
            style={{
              background: "#f2f5f9",
              zIndex: 100,
              top: 0,
              width: "100%",
              padding: 0,
            }}
          >
            <DashboardHeader
              {...this.props}
              isCollapsed={this.state.collapsed}
              toggleSidebar={this.toggleSidebar}
            />
          </Header>
          <Content
            className="site-layout-background"
            style={{
              margin: "24px 10px",
              padding: 10,
              minHeight: 280,
            }}
          >
            <div className="dashboard">
              {/* {this.state.loading && <Spin />} */}
              <Spin spinning={submitted}>
                {!this.state.loading && (
                  <Row gutter={16}>
                    <Col sm={24} md={12} lg={8} xl={6}>
                      {
                        <div className="white-box user-section">
                          <div className="user-profile profile-sidebar">
                            <Avatar size={84}>
                              {this.props.patientData[0].firstName
                                .toUpperCase()
                                .charAt(0) +
                                this.props.patientData[0].lastName
                                  .toUpperCase()
                                  .charAt(0)}
                            </Avatar>
                            <div className="profile-details">
                              <h3>
                                {this.props.patientData[0].firstName +
                                  " " +
                                  this.props.patientData[0].lastName}
                              </h3>
                              {
                                studyId === "1" ?
                                <button
                                className="btn"
                                onClick={() => this.goToChat()}
                              >
                                Contact
                              </button> :
                              ""
                              }
                            </div>
                          </div>
                          <List
                            style={{ marginTop: "40px", wordBreak: "break-all" }}
                            itemLayout="horizontal"
                            dataSource={this.props.patientData}
                            renderItem={(patient) => (
                              <>
                                <List.Item>
                                  <Typography.Text style={{ color: "#006297" }}>
                                    Study:
                                  </Typography.Text>{" "}
                                  {patient.title}
                                </List.Item>
                                <List.Item>
                                  <Typography.Text style={{ color: "#006297" }}>
                                    Email:
                                  </Typography.Text>{" "}
                                  {patient.email}
                                </List.Item>
                                <List.Item>
                                  <Typography.Text style={{ color: "#006297" }}>
                                    Date of Birth:
                                  </Typography.Text>{" "}
                                  {/* {patient.dateOfBirth
                                    ? moment(new Date(Number(patient.dateOfBirth))).format(
                                      "DD-MM-YYYY"
                                    )
                                    : "N/A"} */}
                                    {finalDate}
                                </List.Item>
                                <List.Item>
                                  <Typography.Text style={{ color: "#006297" }}>
                                    Age:
                                  </Typography.Text>{" "}
                                  {patient.age ? patient.age + " years" : "N/A"}
                                </List.Item>
                                <List.Item>
                                  <Typography.Text style={{ color: "#006297" }}>
                                    Phone Number:
                                  </Typography.Text>{" "}
                                  {"(" +
                                    patient.phoneCode +
                                    ") " +
                                    patient.phoneNumber}
                                </List.Item>
                                <List.Item>
                                  <Typography.Text style={{ color: "#006297" }}>
                                    Member Status:
                                  </Typography.Text>{" "}
                                  {patient.userScreeningStatus.replace(
                                    "_",
                                    " "
                                  )}
                                </List.Item>
                                <List.Item>
                                  <Typography.Text style={{ color: "#006297" }}>
                                    Registered Date:
                                  </Typography.Text>{" "}
                                  {patient.createdDate
                                    ? moment(patient.createdDate)
                                      .format(dateFormathh)
                                    : "N/A"}
                                </List.Item>
                              </>
                            )}
                          />
                        </div>
                      }
                    </Col>
                    <Col sm={24} md={12} lg={8} xl={18} style={{height: "700px", overflow: "auto"}}>
                      <div className="white-box">
                        <div className="title">
                          <h2>Medical History</h2>
                        </div>
                        {/* <Spin spinning={submitted}></Spin> */}
                        {this.props.medicalHistoryList &&
                          this.props.medicalHistoryList !==
                          "medical history not found" ? (
                          <>
                            <List
                              itemLayout="horizontal"
                              className="screening-ques"
                              dataSource={this.props.medicalHistoryList}
                              renderItem={(quesAnsData, index) => (
                                <>
                                  <List.Item>
                                    <List.Item.Meta
                                      title={
                                        <span>
                                          <b>{"Q" + (index + 1) + ". "}</b>{" "}
                                          {quesAnsData.question}
                                        </span>
                                      }
                                      description={
                                        <span>
                                          <b>{"A" + (index + 1) + ". "}</b>{" "}
                                          {quesAnsData.question === "Date of Birth" || quesAnsData.question === "Approximate date of last menses" ? this.formatDate(parseInt(quesAnsData.answer)): quesAnsData.answer}
                                        </span>
                                      }
                                    />
                                  </List.Item>
                                </>
                              )}
                            />
                          </>
                        ) : (
                          "No Data Found"
                        )}
                      </div>
                    </Col>
                  </Row>
                )}
              </Spin>
            </div>
          </Content>
        </Layout>
      </Layout>
    );
  }
}

function mapStateToProps(state) {
  return {
    ...state.patient,
    medicalHistoryList: state.medicalHistory.medicalHistoryList,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    { ...PatientActions, ...MedicalHistoryActions },
    dispatch
  );
}
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(
    MedicalHistory
  )
);
