import { Types } from "../constants/medication-types";
const initialState = {
  medicationsList: undefined,
  medicationDetail:undefined,
  doctorNotesList:undefined,
  doctorNotesError:undefined,
  medicationTypes: undefined
};
export default function medicationReducer(state = initialState, action) {
  switch (action.type) {
    case Types.LIST_MEDICATIONS:
      return { ...state, medicationsList: action.payload };
    case Types.MEDICATION_DETAILS:
      return { ...state, medicationDetail: action.payload };
    case Types.GET_DOCTOR_NOTES:
      return { ...state, doctorNotesList: action.payload };
    case Types.DOCTOR_NOTES_NOT_FOUND:
      return { ...state, doctorNotesError: action.payload };
    case Types.MEDICATIONS_TYPES:
      return { ...state, medicationType: action.payload };
    default:
      return state;
  }
}
