import { Types } from "../constants/users-types";
const initialState = {
  userData: {},
  isLoggedIn: false,
  token: "",
  redirect: "",
  currentLoggedInUser: {}
};
export default function userReducer(state = initialState, action) {
  switch (action.type) {
    case Types.USER_LISTING:
      return { ...state, userListing: action.payload };
    case Types.USER_LISTING_ERROR:
      return { ...state, userListingError: action.payload };
    case Types.SAVE_ALREADY_VERIFIED:
      return { ...state, alreadyVerfified: action.payload };
    case Types.TOKEN_EXPIRY_DATE:
      return {
        ...state, expirayDate: action.payload.token_expiry,
        token: action.payload.token, refreshToken: action.payload.refreshToken
      };
    case Types.FLUSH_TOKEN_DATA:
      return { ...state, expirayDate: initialState.expirayDate };
    case Types.UPDATE_MY_ACCOUNT:
      let _userData = state.userData;
      _userData.first_name = action.payload.first_name;
      _userData.last_name = action.payload.last_name;
      _userData.email = action.payload.email;
      _userData.contactNo = action.payload.contactNo;
      return {
        ...state,
        userData: _userData
      };
    case Types.SAVE_LOGO_URL:
      return { ...state, logo_url: action.payload };
    case Types.UPDATE_NOTIFICATION_COUNT:
      return { ...state, notificationCount: action.payload }
    case Types.INC_NOTIFICATION_COUNT:
      return { ...state, notificationCount: state.notificationCount + action.payload }
    case Types.GET_LOGGED_IN_USER:
      return { ...state, currentLoggedInUser: action.payload };
    default:
      return state;

  }
}
