import React, { Component } from "react";
import { Row, Col, Form, Select, Button, Typography, Spin } from "antd";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as StudyActions from "../../redux/actions/study-actions";
import * as AuthActions from "../../redux/actions/auth-actions";
import Logo from "../../assets/images/logo.png";

const { Option } = Select;

class ListStudies extends Component {

    constructor(props) {
        super(props);
        this.state = {
            data: undefined,
            study: [],
            roleName: [],
            loading:true
        };
    }


    componentDidMount = () => {

        this.props
            .getStudies()
            .then((resp) => {
                this.setState({
                    loading: false,
                    data: resp.data,
                });
            })
            .catch((ex) => {
                this.setState({ loading: false });
            });
            this.props
                .getLoggedInUser()
                .then((resp) => {
                    this.setState({
                    loading: false,
                    firstName: resp.data.firstName,
                    email: resp.data.email,
                    roleName:resp.data.roles[0].name,
                    avatar: resp.data.firstName.toString().substring(0, 1) + resp.data.lastName.toString().substring(0, 1),
                    study: resp.data.study
                    });
                    if(resp.data.roles[0].name=="SUPERADMIN"){
                        this.props.history.push("/user-list/");
                    }  
                    else{
                        this.setState({loading:false}) 
                    }             
                })
                .catch((ex) => {
                    this.setState({ loading: false });
                    this.setState({ submitted: false });
                }).finally(()=> this.setState({ submitted: false }));
    };

    handleSubmit = async (values) => {
        let selectedStudy = this.state.data.filter((item) => item.study.title === values.select);
        let getStudyId = selectedStudy[0];
        localStorage.setItem("studyId", getStudyId.study.id)
        localStorage.setItem("studyTitle",getStudyId.study.title);
        // this.props.setDefaultStudyId(1);
        this.props.setDefaultStudyId(getStudyId.study.title);
        if (localStorage.getItem("studyId") == "3") {
            this.props.history.push("/patients/1");
        } else {
            this.props.history.push("/dashboard/");
        }
    };

    render() {
        const studyID = localStorage.getItem("studyId");
        // let DValue = null;
        // if(studyID){
        //     const getStudyInfo = selectStudy && selectStudy.find(item=>item.study.id == studyID)
        //     DValue = getStudyInfo && getStudyInfo.study.title;
        // }
        const userRole = this.state.roleName
        const userStudy = this.state.study
        const listStudies = this.state.data
        let studies = undefined;
        if (listStudies && listStudies !== undefined && userRole == "ADMIN") {
            studies = listStudies.map((data) => <Option
                key={data.study.id} value={data.study.title} >
                {data.study.title}
            </Option>)
        } 
        else if (listStudies && listStudies !== undefined) {
            studies = userStudy.map((data) => <Option
                key={data.id} value={data.title} >
                {data.title}
            </Option>)
        }

        const formLayout = "vertical";
        return (
            this.state.loading?<Spin/>:(<>
                <div className="login">
                <div className="login-in select-study-page">
                    <section className="wrap flexbox">
                        <Row gutter={24} style={{ width: "100%" }}>
                            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                                <Row className="logo" gutter={24}>
                                    <Col xs={24}>
                                        <img src={Logo} alt="UCSF" />
                                    </Col>
                                </Row>
                                <Row gutter={24}>
                                    <Col xs={24}>
                                        <Typography.Title level={4} >
                                            Select Study
                    </Typography.Title>
                                    </Col>
                                </Row>

                                <Form
                                    layout={formLayout}
                                    className="login-form"
                                    onFinish={this.handleSubmit}
                                >
                                    <Form.Item
                                        name="select"
                                        label=""
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Please select Study!',
                                            },
                                        ]}
                                    >
                                        <Select placeholder="Please select Study" >
                                            {studies}
                                        </Select>
                                    </Form.Item>
                                    <Form.Item className="form-button-group">
                                        <Button type="primary" htmlType="submit" className="list-study">Submit</Button>
                                    </Form.Item>
                                </Form>
                            </Col>
                        </Row>
                    </section>
                </div>
            </div>
                </>)
            


        );
    }
}
function mapStateToProps(state) {
    return { defaultStudyId: state.study.defaultStudy };
}
function mapDispatchToProps(dispatch) {
    return bindActionCreators({ ...StudyActions, ...AuthActions }, dispatch);
}
export default withRouter(
    connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(ListStudies)
);
