import AxiosAuth2 from "../../services/axios-service-auth";

const reviewSurvey = (patientId,taskId) => {
  return AxiosAuth2.get("/api/survey/surveyReviewData?patientId="+patientId+"&surveyTrueId="+taskId, undefined, undefined);
};

const reviewSurveyJanssen = (patientId,taskId) => {
  return AxiosAuth2.get("/api/janssen/survey/surveyReviewData?patientId="+patientId+"&surveyTrueId="+taskId, undefined, undefined);
};

const reviewSurveyNih = (patientId,taskId) => {
  return AxiosAuth2.get("/api/nih/survey/surveyReviewData?patientId="+patientId+"&surveyTrueId="+taskId, undefined, undefined);
};

const flaresTask = (patientId,taskId) => { 
  return AxiosAuth2.get("/api/flareTask/surveyReviewData?patientId="+patientId+"&taskId="+taskId, undefined, undefined);
};

export default {
    reviewSurvey,
    reviewSurveyJanssen,
    reviewSurveyNih,
    flaresTask
};
