import { Types } from "../constants/diary-types";
const initialState = {
  voiceDiaryList: undefined,
  voiceDiaryListingError: undefined,
  textDiaryList: undefined,
  textDiaryListingError: undefined,
  doctorVoiceNotesList: undefined,
  doctorVoiceNotesListingError: undefined,
  doctorTextNotesList: undefined,
  doctorTextNotesListingError: undefined,
  doctorPhotoNotesList: undefined,
  doctorPhotoNotesListingError: undefined,
  photoDiaryList: undefined,
  photoDiaryListingError: undefined,
  photoDiaryUrl: undefined,
  photoDiaryUrlError: undefined,
  flareImages: undefined,
  flarePhotoNotFound: undefined
};

export default function diaryReducer(state = initialState, action) {

  switch (action.type) {
    case Types.GET_VOICE_DIARIES:
      return { ...state, voiceDiaryList: action.payload };
    case Types.VOICE_DIARIES_NOT_FOUND:
      return { ...state, voiceDiaryListingError: action.payload };
    case Types.GET_TEXT_DIARIES:
      return { ...state, textDiaryList: action.payload };
    case Types.TEXT_DIARIES_NOT_FOUND:
      return { ...state, textDiaryListingError: action.payload };
    case Types.GET_PHOTO_DIARIES:
      return { ...state, photoDiaryList: action.payload };
    case Types.PHOTO_DIARIES_NOT_FOUND:
      return { ...state, photoDiaryListingError: action.payload };
    case Types.GET_IMAGE_URL:
      return { ...state, photoDiaryUrl: action.payload };
    case Types.IMAGE_URL_NOT_FOUND:
      return { ...state, photoDiaryUrlError: action.payload };
    case Types.GET_DOCTOR_VOICE_NOTES:
      return { ...state, doctorVoiceNotesList: action.payload };
    case Types.DOCTOR_VOICE_NOTES_NOT_FOUND:
      return { ...state, doctorVoiceNotesListingError: action.payload };
    case Types.GET_DOCTOR_TEXT_NOTES:
      return { ...state, doctorTextNotesList: action.payload };
    case Types.DOCTOR_TEXT_NOTES_NOT_FOUND:
      return { ...state, doctorTextNotesListingError: action.payload };
    case Types.GET_DOCTOR_PHOTO_NOTES:
      return { ...state, doctorPhotoNotesList: action.payload };
    case Types.DOCTOR_PHOTO_NOTES_NOT_FOUND:
      return { ...state, doctorPhotoNotesListingError: action.payload };
    case Types.FLARE_PHOTO:
      return { ...state, flareImages: action.payload };
    case Types.FLARE_PHOTO_NOT_FOUND:
      return { ...state, flarePhotoNotFound: action.payload };
    default:
      return state;
  }
}
