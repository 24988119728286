import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { List, Avatar, Card, Spin } from "antd";
import * as PatientActions from "../../../redux/actions/patient-actions";
import { notifyUser } from "../../../services/notification-service";
import Icon from "@ant-design/icons";
import { RightCaretSvg } from "../../shared/svg/rightcaret";

const RightCaretIcon = (props) => <Icon component={RightCaretSvg} {...props} />;

class ApprovedPatients extends Component {
  state = { loading: true, data: [],dataItem:[]};

  componentDidMount = () => {
    let studyId = localStorage.getItem("studyId")

    this.props
      .getPatientsList(studyId)
      .then((resp) => {
        this.setState({
          loading: false,
          data: resp.data,
          dataItem:resp.data.slice(0,3)
        });
        this.setState({ loading: false });
      })
      .catch((ex) => {
        this.setState({ loading: false });
      });
  };

  approve = async (userId,status) => {
    let self = this;
    await self.props
      .approvePatient(userId,status)
      .then((response) => {
        this.setState({
          loading: false,
        });
	      if (response.error && response.error  !== "") {
          notifyUser(response.error.message, "error");
          self.setState({ submitted: false });
        } else {
          notifyUser("Study Status Updated", "success");
          window.location.reload();
        }
      });
      this.setState({ loading: false });
    
  };
  
  viewStudy = (id) => {
    this.props.history.push("/screening-questions/"+"newlyAdded"+"/"+id);
  }
  render() {
    return (
      <div className="list-patients">
        <Spin spinning={this.state.loading}>
          <Card bordered={false}>
            <List
              itemLayout="horizontal"
              dataSource={this.state.dataItem}
              renderItem={(patient) => (
                <List.Item onClick={() => this.viewStudy(patient.id)}>
                  <List.Item.Meta
                    avatar={<Avatar style={{backgroundColor: "#4B4848"}}>{patient.firstName.charAt(0).toUpperCase()+patient.lastName.charAt(0).toUpperCase()}</Avatar>}
                    title={<h3 style={{color: "black"}}>{patient.firstName}</h3>}
                    description={patient.content}
                  />
                  <RightCaretIcon />
                </List.Item>
              )}
              
            />
          </Card>
        </Spin>
      </div>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ ...PatientActions }, dispatch);
}
export default withRouter(
  connect(null, mapDispatchToProps, null, { forwardRef: true })(
    ApprovedPatients
  )
);
