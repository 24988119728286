import React, { Component } from "react";
import { Spin, Card } from "antd";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import SideBar from "../layout/sidebar/sidebar.jsx";
import options from "../layout/sidebar/dashboard-options";
import { Layout, Tooltip } from "antd";
import 'antd/dist/antd.css';
import { Table, Input, Button, Space, Switch,Modal } from 'antd';
import Highlighter from 'react-highlight-words';
import { SearchOutlined } from '@ant-design/icons';
import * as PatientActions from "../../redux/actions/patient-actions";
import * as TicketActions from "../../redux/actions/ticket-actions";
import UserHeader from "../layout/header.jsx";
import Icon from "@ant-design/icons";
// import { ListEditSvg } from "../shared/svg/list-edit";
import { notifyUser } from "../../services/notification-service";
import moment from "moment-timezone";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import { PlusCircleFilled, MinusCircleFilled} from "@ant-design/icons";
import { LogsSvg } from "../shared/svg/logs";

const dateFormathh = "MM/DD/YYYY HH:mm a ";
const LogsIcon = (props) => <Icon component={LogsSvg} {...props} />;

const { Header, Sider, Content } = Layout;

class Tickets extends Component {
  state = {
    searchText: '',
    searchedColumn: '',
    submitted: false,
    loading: true, 
    isModalVisible:false,
    imageUrl:'',
    photoLoading:false,
    tickets: []
  };

  async componentDidMount() {
    await this.props.getAllTicketsList();
    if (this.props.suggesstionTickets && this.props.suggesstionTickets.length > 0) {
      this.setState({ tickets: this.props.suggesstionTickets, loading: false })
    }
    else{
      this.setState({ loading: false })
    }
  };

  getColumnSearchProps = dataIndex => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={node => {
            this.searchInput = node;
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button onClick={() => this.handleReset(clearFilters)} size="small" style={{ width: 90 }}>
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({ closeDropdown: false });
              this.setState({
                searchText: selectedKeys[0],
                searchedColumn: dataIndex,
              });
            }}
          >
            Filter
          </Button>
        </Space>
      </div>
    ),
    filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
        : '',
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        setTimeout(() => this.searchInput.select(), 100);
      }
    },
    render: text =>
      this.state.searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[this.state.searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
          text
        ),
  });

  handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    this.setState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex,
    });
  };

  handleReset = clearFilters => {
    clearFilters();
    this.setState({ searchText: '' });
  };

  onClick = () => {
    this.props.history.push("/add-user");
  }

  handleEdit = (record) => {
    this.props.history.push("/edit-user/"+record.id);
  };

  async showAttachment(path){
      this.setState({ photoLoading: true })
      await this.props.getAttachmentImage(path)
      if (this.props.getTicketAttachment) {
        this.setState({ imageUrl: this.props.getTicketAttachment[0].url, photoLoading: false,isModalVisible: true })
      }
    }

  handleStatus = async (record, value) => {

    let self = this;
    this.setState({ submitted: true });
    await self.props
      .updateTicketStatus(record.ticketId)
      .then((response) => {
        if (response.error && response.error !== "") {
          notifyUser("Failed Updating status", "error");
          self.setState({ submitted: false });
        } else {
          if (response.data && response.data !== "" && response.data.isSuccess === true) {
            notifyUser("Status Updated Successfully", "success");
            self.setState({ submitted: false });
            window.location.reload()
          }
        }
      });
  };

  handleLogs = (record) => {
    this.props.history.push("/audit-logs/" + record.id);
  };

  showModal = (img) => {
    this.setState({ image: img, isModalVisible: true });
  };

  handleCancel = () => {
    this.setState({ isModalVisible: false });
  };

  

  render() {
    const { loading,isModalVisible,submitted,imageUrl,photoLoading } = this.state;
    const studyId = localStorage.getItem("stidyId");
    const columns = [
      {
        title: 'Title',
        dataIndex: 'title',
        key: 'title',
        width: '20%',
        ...this.getColumnSearchProps('title'),
        sorter: (a, b) => a.title.localeCompare(b.title),
      },
      {
        title: 'Description',
        dataIndex: 'description',
        key: 'description',
        width: '30%',
        ...this.getColumnSearchProps('description'),
        sorter: (a, b) => a.description.localeCompare(b.description),
      },
      {
        title: 'Created By',
        dataIndex: 'userName',
        key: 'userName',
        sorter: (a, b) => a.userName.localeCompare(b.userName),
        ...this.getColumnSearchProps('userName'),
      },
  
      {
        title: 'Created At',
        render: (_, record) =>
          this.state.tickets.length >= 1 ? (
            <div>
              {studyId ==1 ?
              <span>{moment(record.createdDate).format(dateFormathh)}</span>:
              <span>{moment(record.date).format(dateFormathh)}</span>

              }
            </div>)
            : null,
      },
      {
        title: 'Status',
        dataIndex: 'status',
        key: 'status',
        render: (_text, record) => (
          <Switch
            checked={record.status === "Resolved" ? true : false}
            onChange={
              (value) => this.handleStatus(record, value)
            }
            checkedChildren="Resolved"
            unCheckedChildren="pending"
          />
        )
      },
      {
        title: 'Attachment',
        dataIndex: 'action',
        render: (_, record) =>
          this.state.tickets.length >= 1 ? (
            
            <div className="list-actions">
              
             {record.attachment? <Tooltip title="View Attachment">
            
                <LogsIcon
                  onClick={() => this.showAttachment(record.attachment)}
                />
                 <Spin spinning={photoLoading} >
                <Modal centered width={450} className="photo-zoom-popup" title={record.title} visible={isModalVisible} onCancel={this.handleCancel} footer={[<></>,]}>

                  <TransformWrapper
                    defaultScale={1}
                    defaultPositionX={1}
                    defaultPositionY={1}
                  >
                    {({ zoomIn, zoomOut }) => (
                      <>
                        <PlusCircleFilled size={30} onClick={zoomIn} />
                        <MinusCircleFilled size={20} onClick={zoomOut} />
                        <TransformComponent>
                          <img width={400} src={imageUrl} alt={record.title} />
                        </TransformComponent>
                      </>
                    )}
                  </TransformWrapper>
                </Modal>
                </Spin>
              </Tooltip>:"No Attachment"}
             
            </div>
          ) : null,
      }
    ];
    return (

      <Layout>
        <Sider
          breakpoint="xl"
          collapsedWidth="0"
          width="260px"
          className="sidebar"
          onBreakpoint={(broken) => {
            console.log(broken);
          }}
          onCollapse={(collapsed, type) => {
            console.log(collapsed, type);
          }}
        >
          <SideBar {...this.props} options={options.dashBoardOptions} />
        </Sider>
        <Layout className="site-layout">
          <Header
            className="site-layout-background"
            style={{
              background: "#f2f5f9",
              zIndex: 100,
              top: 0,
              width: "100%",
              padding: 0,
            }}
          >
            <UserHeader
              {...this.props}
              isCollapsed={this.state.collapsed}
              toggleSidebar={this.toggleSidebar}
            />
          </Header>
          <Content className="site-layout-background" >
            <Card title={""} extra={<Button >Suggestion Tickets</Button>}>
              <Spin spinning={loading}>
                <Table columns={columns} bordered dataSource={this.state.tickets} />
              </Spin>
            </Card>
          </Content>
        </Layout>
      </Layout>
    );
  }
}

function mapStateToProps(state) {
  return {
    suggesstionTickets: state.ticket.listTickets,
    getTicketAttachment:state.ticket.getTicketAttachment
  };
}
function mapDispatchToProps(dispatch) {
  return bindActionCreators({ ...PatientActions, ...TicketActions }, dispatch);
}
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(Tickets)
);
