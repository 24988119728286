import React, { Component } from "react";
import { Switch } from "react-router-dom";
import routeOptions from "../app/routes/route";
import "./App.less";
import { PrivateRoute } from "./private-route.jsx";
import PublicRoute from "./public-route.jsx";
import HttpsRedirect from 'react-https-redirect';
import { connect } from "react-redux";

class App extends Component {
  render() {
    const role = localStorage.getItem("roleName")
    let routes = routeOptions.routes.map(({ path, component, exact }, i) => (
      <PublicRoute 
      selectedStudy={this.props.selectedStudy} key={Math.random() + "ROUTE_"} exact={exact} path={path} component={component} role={role}/>
    ));

    let privateRoutes = routeOptions.privateRoutes.map(({ path, component, exact, roles }, i) => (
      <PrivateRoute
        key={Math.random() + "ROUTE_"}
        exact={exact}
        path={path}
        component={component}
        roles={roles}
        selectedStudy={this.props.selectedStudy}
      />
    ));

    return (
      <div className="app-container App">
        <HttpsRedirect>
          <Switch>
            {routes}
            {privateRoutes}
          </Switch>
        </HttpsRedirect>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return { selectedStudy: state.study.defaultStudy}
  }




export default connect(mapStateToProps,null)(App);

