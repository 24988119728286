import React from "react";
export const LabSvg = () => (
    <svg
        version="1.1"
        id="Layer_1"
        x="0px"
        y="0px"
        width="3em"
        height="3em"
        fill="currentColor"
        viewBox="0 0 499.36 499.36"
    >
        <path d="M108.8,403.368c-2.52-2.384-5.064-4.784-7.608-7.208c37.896-37.176,76.488-82.848,76.488-144.8
                c0-61.952-38.592-107.624-76.488-144.808c2.544-2.424,5.088-4.824,7.608-7.208c32.672-30.864,60.88-57.52,60.88-87.984v-8h-16v8
                c0,5.272-1.36,10.6-3.712,16H29.392c-2.352-5.4-3.712-10.728-3.712-16v-8h-16v8c0,30.464,28.208,57.12,60.88,87.992
                c2.52,2.384,5.064,4.784,7.608,7.208C40.272,143.736,1.68,189.408,1.68,251.36c0,61.952,38.592,107.624,76.488,144.808
                c-2.544,2.424-5.088,4.824-7.608,7.208C37.888,434.24,9.68,460.896,9.68,491.36v8h16v-8c0-5.272,1.36-10.6,3.712-16h120.584
                c2.344,5.4,3.704,10.728,3.704,16v8h16v-8C169.68,460.896,141.472,434.24,108.8,403.368z M38.96,43.36H140.4
                c-3.904,5.224-8.536,10.552-13.664,16H52.624C47.496,53.912,42.856,48.584,38.96,43.36z M68.56,75.36h42.232
                c-4.2,4.048-8.536,8.152-12.984,12.36c-2.672,2.528-5.392,5.096-8.128,7.696c-2.744-2.6-5.456-5.168-8.128-7.696
                C77.104,83.512,72.76,79.408,68.56,75.36z M89.68,117.656c9.68,9.472,19.248,19.304,28.088,29.704H61.592
                C70.432,136.96,80,127.128,89.68,117.656z M30.08,195.36h75.6v-16H38.512c3.432-5.8,7.16-11.424,11.168-16.896v0.896h80v-0.896
                c4.008,5.464,7.736,11.096,11.168,16.896H121.68v16h27.608c2.344,5.176,4.32,10.536,6.056,16H24.024
                C25.76,205.896,27.728,200.536,30.08,195.36z M19.896,227.36h139.576c0.96,5.184,1.512,10.544,1.848,16H18.048
                C18.376,237.904,18.928,232.544,19.896,227.36z M24.016,291.36H49.68v-16H19.888c-0.96-5.184-1.512-10.544-1.848-16h143.272
                c-0.328,5.456-0.88,10.816-1.848,16H65.68v16h89.656c-1.736,5.464-3.704,10.824-6.056,16H30.072
                C27.728,302.184,25.752,296.824,24.016,291.36z M49.68,340.256c-4.008-5.472-7.736-11.096-11.168-16.896h102.336
                c-3.424,5.8-7.16,11.424-11.168,16.896v-0.896h-80V340.256z M61.592,355.36h56.176c-8.84,10.4-18.408,20.232-28.088,29.704
                C80,375.592,70.432,365.76,61.592,355.36z M81.552,415c2.672-2.528,5.392-5.096,8.128-7.696c2.744,2.608,5.456,5.176,8.128,7.696
                c4.448,4.208,8.792,8.312,12.984,12.36H68.56C72.76,423.312,77.104,419.208,81.552,415z M38.96,459.36
                c3.904-5.224,8.536-10.552,13.664-16h74.12c5.128,5.448,9.76,10.776,13.664,16H38.96z"/>
        <path d="M457.68,435.36h-12.904c18.864-34.152,28.904-72.744,28.904-112c0-64.584-27.048-126.192-74.304-170.048l21.72-21.72
                l-16.008-16.008l28.696-28.68l8.032,8.024l35.152-35.464L417.664,0l-35.152,35.672l7.936,7.936l-28.72,28.624l-15.944-15.944
                l-112,112l-16-16L182.472,187.6l107.312,107.312l35.312-35.312l-16-16l44.88-44.88c23.08,20.84,40.088,47.824,48.816,77.44
                c-14.336,3.152-25.112,15.92-25.112,31.2c0,17.384,13.952,31.504,31.232,31.92c-0.632,6.688-1.688,13.328-3.112,19.88
                c-6.256-2.416-13.024-3.8-20.12-3.8h-120c-22.056,0-40,17.944-40,40v8h104.64c-0.376,2.624-0.64,5.28-0.64,8v24h-112
                c-22.056,0-40,17.944-40,40v24h320v-24C497.68,453.304,479.736,435.36,457.68,435.36z M417.728,22.72l36.664,36.776l-12.64,12.76
                l-36.696-36.672L417.728,22.72z M401.768,54.912l20.696,20.68l-28.688,28.672l-20.728-20.728L401.768,54.912z M289.784,272.28
                l-84.688-84.688l12.688-12.688l84.688,84.688L289.784,272.28z M297.784,232.28l-52.688-52.688L345.784,78.904l52.688,52.688
                L297.784,232.28z M365.304,187.392l22.76-22.76c44.264,40.856,69.616,98.392,69.616,158.728c0,28.368-5.584,56.368-16.288,82.352
                c-1.56-15.504-9.496-29.128-21.104-38.288c2.6-10.56,4.264-21.384,4.96-32.28c9.752-5.488,16.432-15.816,16.432-27.784
                c0-14.144-9.28-26.032-22.024-30.248C410.72,242.808,391.664,211.408,365.304,187.392z M393.68,307.36c0-8.824,7.176-16,16-16
                c8.824,0,16,7.176,16,16c0,8.824-7.176,16-16,16C400.856,323.36,393.68,316.184,393.68,307.36z M243.048,387.36
                c3.304-9.312,12.2-16,22.632-16h80.88c-4.696,4.592-8.544,10.008-11.4,16H243.048z M481.68,483.36h-288v-8
                c0-13.232,10.768-24,24-24h144v-16h-16v-24c0-22.056,17.944-40,40-40c22.056,0,40,17.944,40,40v24h-48v16h80
                c13.232,0,24,10.768,24,24V483.36z"/>
    </svg>
);
