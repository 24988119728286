import React, { Component } from "react";
import { Route } from "react-router-dom";
import DashboardHome from "../components/dashboard/dashboard-home/dashboard-home";

export const routes = [
  {
    path: "",
    component: DashboardHome,
  },
];

class DashboardRouter extends Component {
  render() {
    const { url, style } = this.props;
    return (
      <div style={style}>
        {routes.map((singleRoute) => {
          const {
            component: Component,
            path,
            exact,
            ...otherProps
          } = singleRoute;
          return (
            <Route
              exact={exact === false ? false : true}
              key={singleRoute.path}
              path={`${url}/${singleRoute.path}`}
              render={(props) => <Component {...props} />}
              {...otherProps}
            />
          );
        })}
      </div>
    );
  }
}

export default DashboardRouter;
