import API from "../api/lab-api";
import { Types } from "../constants/lab-types";

export function uploadLabTest(data) {
  return async function(dispatch, _getState) {
    try {
      let resp = await API.uploadLabTest(data);
      console.log(resp)
      if (resp && resp.data) {
        dispatch({
          type: Types.UPLOAD_FILE,
          payload: resp.data
        });
      }
      return resp;
    } catch (e) {
      return { error: true };
    }
  };
}
  export function getLabTests(patientId) {
    return async function(dispatch, _getState) {
      try {
        let resp = await API.getLabTests(patientId);
        console.log(resp)
        if (resp && resp.data) {
          dispatch({
            type: Types.LIST_TESTS,
            payload: resp.data
          });
        }
        else{
            dispatch({
                type: Types.LIST_TESTS,
                payload: undefined
              });
        }
        return resp;
      } catch (e) {
        return { error: true };
      }
    };
}
