import { Types } from "../constants/study-types";
const initialState = {
  studyList: undefined,
  studyListingError:undefined,
  defaultStudy:undefined
};
export default function studyReducer(state = initialState, action) {
  switch (action.type) {
    case Types.STUDY_LIST:
      return { ...state, studyList: action.payload };
      case Types.GET_DEFAULT_STUDY:
        return { ...state, defaultStudy: action.payload };
      case Types.STUDY_NOT_FOUND:
        return { ...state, studyListingError: action.payload };
    default:
      return state;
  }
}
