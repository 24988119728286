import AxiosLib from "./axios-service-lib-auth";
import config from "../../app/config";
const _request = (method, url, data, token, isJson = true,binaryResponse = false) => {
  let options = {
    method: method,
    url: url,
    responseType: binaryResponse ? "blob" : "json",
  };
  if (data && method === "GET") {
    options.params = data;
  } else if (data) {
    options.data = data;
    if(isJson){
      options.headers = {
        "Content-Type": "application/json;",
      };
    } /*else {
      options.headers = { "Content-Type": "multipart/form-data" };
    }*/
  }

  return new Promise((resolve, reject) => {
    AxiosLib.request(options, token)
      .then((response) => {
        let data = response.data;
        if (typeof data != "object") data = JSON.parse(data);
        resolve(data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const DataProvider = {
  get(url, data, token, isJson = true, binaryResponse = false) {
    return _request("GET", url, data, token, isJson, binaryResponse);
  },
  post(url, data, token, isJson = false, binaryResponse = false) {
    return _request("POST", url, data, token, isJson, binaryResponse);
  },
  delete(url, token) {
    return _request("DELETE", url, undefined, token);
  },
  put(url, data, token) {
    return _request("PUT", url, data, token);
  },
};

const DataAccessService = {
  get(url, data, token, isJson=true , binaryResponse=false) {
    const requestOptions = {
      method: "GET",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(data),
    };
    return getFetchMethod(url) === "fake"
      ? fetch(`${config.API}` + url, requestOptions)
      : DataProvider.get(url, data, token, isJson, binaryResponse);
  },
  post(url, data, token, isJSON = false, binaryResponse = false) {
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(data),
    };
    return getFetchMethod(url) === "fake"
      ? fetch(`${config.API}` + url, requestOptions)
      : DataProvider.post(url, data, token, isJSON, binaryResponse);
  },
  delete(url, data, token) {
    const requestOptions = {
      method: "DELETE",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(data),
    };
    return getFetchMethod(url) === "fake"
      ? fetch(`${config.API}` + url, requestOptions)
      : DataProvider.delete(url, undefined, token);
  },
  put(url, data, token) {
    const requestOptions = {
      method: "PUT",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(data),
    };
    return getFetchMethod(url) === "fake"
      ? fetch(`${config.API}` + url, requestOptions)
      : DataProvider.put(url, data, token);
  },
};
function getFetchMethod(url, _data, _token) {
  return "real";
  if (
    url.indexOf("/api/ForgotPassword") !== -1 ||
    url.indexOf("/api/auth/login") !== -1 ||
    url.indexOf("/api/AuthV2/refreshtoken") !== -1 ||
    url.indexOf("/api/Roles") !== -1 ||
    url.indexOf("/api/modules") !== -1 ||
    url.indexOf("/api/permissions") !== -1 ||
    url.indexOf("/api/modulepermissions") !== -1 ||
    url.indexOf("/api/Users") !== -1 ||
    url.indexOf("/api/ExternalRoles") !== -1 ||
    url.indexOf("/api/InternalRoles") !== -1 ||
    url.indexOf("/api/UserPermissions") !== -1 ||
    url.indexOf("/api/ChangePassword") !== -1 ||
    url.indexOf("/api/AuditLogs") !== -1
  ) {
    return "real";
  } else {
    return "fake";
  }
}
export default DataAccessService;
