import { Types } from "../constants/adverse-event-types";
const initialState = {
  adverseEventsList: undefined,
  adverseEventDetail: undefined,
  doctorNotesList: undefined,
  doctorNotesError: undefined,
  adversePhotoUrl: undefined,
  adversePhotoUrlError: undefined,
  listAdverseEvents: undefined,
  signOffInfo: undefined,
  piUsers: undefined,
  listPiAdverseEvents: undefined,
  adverseEventLogsList:undefined
};
export default function adverseEventReducer(state = initialState, action) {
  switch (action.type) {
    case Types.LIST_ADVERSE_EVENTS:
      return { ...state, adverseEventsList: action.payload };
    case Types.LIST_ALL_ADVERSE_EVENTS:
        return { ...state, listAdverseEvents: action.payload };
    case Types.LIST_ADVERSE_EVENT_LOGS:
      return { ...state, adverseEventLogsList: action.payload };
    case Types.LIST_PI_ADVERSE_EVENTS:
      return { ...state, listPiAdverseEvents: action.payload };
    case Types.ADVERSE_EVENT_DETAILS:
      return { ...state, adverseEventDetail: action.payload };
    case Types.GET_DOCTOR_NOTES:
      return { ...state, doctorNotesList: action.payload };
    case Types.DOCTOR_NOTES_NOT_FOUND:
      return { ...state, doctorNotesError: action.payload };
    case Types.GET_IMAGE_URL:
      return { ...state, adversePhotoUrl: action.payload };
    case Types.SIGN_OFF_INFO:
      return { ...state, signOffInfo: action.payload };
    case Types.PI_USERS:
      return { ...state, piUsers: action.payload };
    case Types.IMAGE_URL_NOT_FOUND:
      return { ...state, adversePhotoUrlError: action.payload };
    default:
      return state;
  }
}
