import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import firebase from "../../../../src/config/firebase";
import SideBar from "../layout/sidebar/sidebar.jsx";
import options from "../layout/sidebar/dashboard-options";
import UserHeader from "../layout/header";
import {
  Layout,
  Input,
  Row,
  Col,
  Avatar,
  List,
  Comment,
  Tooltip,
  Card,
  Spin,
  Modal,
} from "antd";
import * as ChatActions from "../../redux/actions/chat-actions";
import { notifyUser } from "../../services/notification-service";
import * as PatientActions from "../../redux/actions/patient-actions";
import * as MessageActions from "../../redux/actions/message-actions";
import moment from "moment-timezone";
import Icon from "@ant-design/icons";
import { PaperClipOutlined, SendOutlined } from "@ant-design/icons";
import Config from "../../config";
import Button from "@material-ui/core/Button";
import { AddPatientSvg } from "../shared/svg/addpatient";
import { FlagSvg } from "../shared/svg/flag";
import { UnFlagSvg } from "../shared/svg/unflag";
import { DoubleCheckSvg } from "../shared/svg/double-check";
import * as StudyActions from "../../redux/actions/study-actions";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import { PlusCircleFilled, MinusCircleFilled, UserOutlined } from "@ant-design/icons";
import { Scrollbars } from "react-custom-scrollbars";

const AddPatientIcon = (props) => <Icon component={AddPatientSvg} {...props} />;
const FlagIcon = (props) => <Icon component={FlagSvg} {...props} />;
const UnFlagIcon = (props) => <Icon component={UnFlagSvg} {...props} />;
const DoubleCheckIcon = (props) => (
  <Icon component={DoubleCheckSvg} {...props} />
);
const { Search } = Input;
const timestamp = { hour: "numeric", minute: "numeric" };

const today = moment(firebase.firestore.Timestamp.now().toDate()).format(
  "DD/MM/YYYY"
);

const messagesEndRef = React.createRef();
const { Sider, Header, Content } = Layout;

class ListChat extends Component {
  state = {
    text: "",
    roomId: "",
    messages: [],
    firstName: "",
    lastName: "",
    img: "",
    imgDownLoaded: true,
    fileName: "",
    searchText: "",
    submitted: false,
    isModalVisible: false,
    image: {},
  };

  onSearch = (value) => {
    this.setState({ searchText: value });
  };

  showModal = (img) => {
    this.setState({ image: img, isModalVisible: true });
  };

  handleCancel = () => {
    this.setState({ isModalVisible: false });
  };
  
  componentDidUpdate() {
    this.scrollToBottom();
  }
  scrollToBottom = () =>
    this.el && this.el.scrollIntoView({ behavior: "smooth" });

  async componentDidMount() {
    this.scrollToBottom();
    let studyId = localStorage.getItem("studyId");
    this.props
      .getStudyById(studyId)
      .then((resp) => {
        this.setState({
          loading: false,
          data: resp.data,
        });
      })
      .catch((ex) => {
        this.setState({ loading: false });
      });
    //await this.props.markMsgAdminRead(roomId+type);

    await this.props.getPatientMessagesList();
    if (this.props.recentMessages && this.props.recentMessages > 0) {
      this.setState({ recentMessages: this.props.recentMessages });
    }
  }
  
  async sendMessage() {
    const { text, roomId, isModalVisible } = this.state;
    if (this.state.text === null || this.state.text.trim() === "") {
      notifyUser("Please enter something", "error");
    } else {
      if (roomId.includes(Config.server.type)) {
        const message = {
          text,
          createdAt: firebase.firestore.FieldValue.serverTimestamp(),
          userId: firebase.auth().currentUser.uid,
          firstName:
            firebase.auth().currentUser.displayName != null
              ? firebase.auth().currentUser.displayName.split(" ")[0]
              : "",
          lastName:
            firebase.auth().currentUser.displayName != null
              ? firebase.auth().currentUser.displayName.split(" ")[1]
              : "",
        };
        var ref = firebase.firestore().collection("chatrooms");
        ref.doc(roomId).collection("messages").add(message);
        //send push
        let data = {
          text: text,
          firstName:
            firebase.auth().currentUser.displayName != null
              ? firebase.auth().currentUser.displayName.split(" ")[0]
              : "",
        };
        this.setState({ text: "" });
        try {
          await this.props.sendPush(
            roomId.substring(0, roomId.length - 5),
            data
          );
        } catch (e) {
          console.log(e);
        }
      } else {
        var type = Config.server.type;
        const message = {
          text,
          createdAt: firebase.firestore.FieldValue.serverTimestamp(),
          userId: firebase.auth().currentUser.uid,
          firstName:
            firebase.auth().currentUser.displayName != null
              ? firebase.auth().currentUser.displayName.split(" ")[0]
              : "",
          lastName:
            firebase.auth().currentUser.displayName != null
              ? firebase.auth().currentUser.displayName.split(" ")[1]
              : "",
        };
        var ref = firebase.firestore().collection("chatrooms");
        ref
          .doc(roomId + type)
          .collection("messages")
          .add(message);
        //send push
        let data = {
          text: text,
          firstName:
            firebase.auth().currentUser.displayName != null
              ? firebase.auth().currentUser.displayName.split(" ")[0]
              : "",
        };
        this.setState({ text: "" });
        try {
          await this.props.sendPush(roomId, data);
        } catch (e) {
          console.log(e);
        }
      }
    }
  }

  redirect(path) {
    window.open(path);
  }

  markAsFlagged(id, isFlagged) {
    var type = Config.server.type;
    const { roomId } = this.state;
    var ref = firebase.firestore().collection("chatrooms");
    if (roomId.includes(Config.server.type)) {
      ref
        .doc(roomId)
        .collection("messages")
        .doc(id)
        .update({
          ["isFlagged@" + firebase.auth().currentUser.uid]: !isFlagged,
        });
    } else {
      ref
        .doc(roomId + type)
        .collection("messages")
        .doc(id)
        .update({
          ["isFlagged@" + firebase.auth().currentUser.uid]: !isFlagged,
        });
    }
  }

  uploadAttachment = (e) => {
    var type = Config.server.type;
    const { roomId } = this.state;
    const file = this.fileInput.files[0];

    this.setState({
      imgDownLoaded: false,
      fileName: file.name,
      submitted: true,
    });
    if (roomId.includes(Config.server.type)) {
      firebase
        .storage()
        .ref()
        .child(roomId + `/${file.name}`)
        .put(file)
        .then((s) => {
          firebase
            .storage()
            .ref()
            .child(roomId + `/${file.name}`)
            .getDownloadURL()
            .then((url) => {
              let message = {};
              if (
                file.name.includes(".jpeg") ||
                file.name.includes(".jpg") ||
                file.name.includes(".png") ||
                file.name.includes(".svg")
              ) {
                message = {
                  createdAt: firebase.firestore.FieldValue.serverTimestamp(),
                  userId: firebase.auth().currentUser.uid,
                  firstName:
                    firebase.auth().currentUser.displayName != null
                      ? firebase.auth().currentUser.displayName.split(" ")[0]
                      : "",
                  lastName:
                    firebase.auth().currentUser.displayName != null
                      ? firebase.auth().currentUser.displayName.split(" ")[1]
                      : "",
                  imgPath: url,
                };
              } else {
                message = {
                  createdAt: firebase.firestore.FieldValue.serverTimestamp(),
                  userId: firebase.auth().currentUser.uid,
                  firstName:
                    firebase.auth().currentUser.displayName != null
                      ? firebase.auth().currentUser.displayName.split(" ")[0]
                      : "",
                  lastName:
                    firebase.auth().currentUser.displayName != null
                      ? firebase.auth().currentUser.displayName.split(" ")[1]
                      : "",
                  attachmentPath: url,
                  attachmentName: file.name,
                };
              }
              var ref = firebase.firestore().collection("chatrooms");
              ref.doc(roomId).collection("messages").add(message);
              this.setState({
                img: url,
                imgDownLoaded: true,
                submitted: false,
              });
              //Send push
              let data = {
                text: "sent an attachment",
                firstName:
                  firebase.auth().currentUser.displayName != null
                    ? firebase.auth().currentUser.displayName.split(" ")[0]
                    : "",
              };
              try {
                this.props.sendPush(
                  roomId.substring(0, roomId.length - 5),
                  data
                );
              } catch (e) {
                console.log(e);
              }
            });
        });
    } else {
      firebase
        .storage()
        .ref()
        .child(roomId + type + `/${file.name}`)
        .put(file)
        .then((s) => {
          firebase
            .storage()
            .ref()
            .child(roomId + type + `/${file.name}`)
            .getDownloadURL()
            .then((url) => {
              let message = {};
              if (
                file.name.includes(".jpeg") ||
                file.name.includes(".jpg") ||
                file.name.includes(".png") ||
                file.name.includes(".svg")
              ) {
                message = {
                  createdAt: firebase.firestore.FieldValue.serverTimestamp(),
                  userId: firebase.auth().currentUser.uid,
                  firstName:
                    firebase.auth().currentUser.displayName != null
                      ? firebase.auth().currentUser.displayName.split(" ")[0]
                      : "",
                  lastName:
                    firebase.auth().currentUser.displayName != null
                      ? firebase.auth().currentUser.displayName.split(" ")[1]
                      : "",
                  imgPath: url,
                };
              } else {
                message = {
                  createdAt: firebase.firestore.FieldValue.serverTimestamp(),
                  userId: firebase.auth().currentUser.uid,
                  firstName:
                    firebase.auth().currentUser.displayName != null
                      ? firebase.auth().currentUser.displayName.split(" ")[0]
                      : "",
                  lastName:
                    firebase.auth().currentUser.displayName != null
                      ? firebase.auth().currentUser.displayName.split(" ")[1]
                      : "",
                  attachmentPath: url,
                  attachmentName: file.name,
                };
              }
              var ref = firebase.firestore().collection("chatrooms");
              ref
                .doc(roomId + type)
                .collection("messages")
                .add(message);
              this.setState({
                img: url,
                imgDownLoaded: true,
                submitted: false,
              });
              //send push
              let data = {
                text: "sent an attachment",
                firstName:
                  firebase.auth().currentUser.displayName != null
                    ? firebase.auth().currentUser.displayName.split(" ")[0]
                    : "",
              };
              this.setState({ text: "" });
              try {
                this.props.sendPush(roomId, data);
              } catch (e) {
                console.log(e);
              }
            });
        });
    }
  };

  async viewChat(id) {
    let studyId = localStorage.getItem('studyId');
    await this.props.markMsgAdminRead(id);
    this.setState({ roomId: id });
    if (studyId == 2) {
      await this.props.getPatientByIdJanssen(id.substring(0, id.length - 5));
    } else {
      await this.props.getPatientById(id.substring(0, id.length - 5));
    }
    // await this.props.getPatientById(id.substring(0, id.length - 5));
    if (
      this.props.patientData &&
      this.props.patientData.length > 0 &&
      this.props.patientData[0]
    ) {
      this.setState({
        firstName: this.props.patientData[0].firstName,
        lastName: this.props.patientData[0].lastName,
      });
    }
    await this.props
      .getMessages(id)
      .then((resp) => {
        this.setState({
          loading: false,
          messages: resp,
        });
      })
      .catch((ex) => {
        this.setState({ loading: false });
      });
  }
  render() {
    const { messages } = this.props.messages;
    const { searchText, isModalVisible } = this.state;
    const { firstName, lastName, submitted } = this.state;
    return (
      <Layout>
        <Sider
          breakpoint="xl"
          collapsedWidth="0"
          width="260px"
          className="sidebar"
          onBreakpoint={(broken) => {
            console.log(broken);
          }}
          onCollapse={(collapsed, type) => {
            console.log(collapsed, type);
          }}
        >
          <SideBar {...this.props} options={options.dashBoardOptions} />
        </Sider>
        <Layout className="site-layout">
          <Header
            className="site-layout-background"
            style={{
              background: "#f2f5f9",
              zIndex: 100,
              top: 0,
              width: "100%",
              padding: 0,
            }}
          >
            <UserHeader
              {...this.props}
              isCollapsed={this.state.collapsed}
              toggleSidebar={this.toggleSidebar}
            />
          </Header>
          <Content className="site-layout-background">
            <Card
              title={
                this.state.data &&
                  this.state.data != null &&
                  this.state.data.title
                  ? this.state.data.title
                  : "Eczema Tracking Study"
              }
            >
              <Row gutter={16}>
                <Col xs={24} sm={12} md={8}>
                  <div className="title">
                    <h2>
                      Messages <AddPatientIcon className="title-icon" />
                    </h2>
                  </div>
                  <Search
                    placeholder="Search.."
                    onSearch={this.onSearch}
                    enterButton
                    className="chat-search"
                  />
                  {searchText && searchText !== "" ? (
                    <div className="userchat-list">
                      {
                        <List
                          itemLayout="horizontal"
                          dataSource={this.props.recentMessages.filter(
                            (s) =>
                              s.fname
                                .toLocaleLowerCase()
                                .includes(searchText.toLocaleLowerCase()) ||
                              s.lname
                                .toLocaleLowerCase()
                                .includes(searchText.toLocaleLowerCase())
                          )}
                          renderItem={(item) => (
                            <List.Item
                              onClick={() => this.viewChat(item.roomId)}
                              actions={
                                item.createdAt
                                  ? [
                                    <div
                                      className="chat-details-meta"
                                      key="list-loadmore-more"
                                    >
                                      {item.createdAt != null
                                        ? new Date(
                                          item.createdAt.seconds ? (item.createdAt.seconds * 1000) : item.createdAt
                                        )
                                          .toLocaleTimeString(
                                            "en-US",
                                            timestamp
                                          )
                                          .toLocaleLowerCase()
                                        : ""}
                                      <span className="unread-count">
                                        {item.count && item.count > 0
                                          ? item.count
                                          : ""}
                                      </span>
                                    </div>,
                                  ]
                                  : ""
                              }

                            >
                              <List.Item.Meta
                                avatar={
                                  <Avatar>
                                    {item.fname
                                      ? item.fname.charAt(0).toUpperCase()
                                      : "U"}
                                  </Avatar>
                                }
                                title={item.fname + " " + item.lname}
                                description={
                                  item.text ? item.text : "attachment"
                                }
                              />
                            </List.Item>
                          )}
                        />
                      }
                    </div>
                  ) : (
                    <div className="userchat-list">
                      {
                        <List
                          itemLayout="horizontal"
                          dataSource={
                            this.props.recentMessages &&
                            this.props.recentMessages.sort((a, b) =>
                              a.createdAt < b.createdAt ? 1 : -1
                            )
                          }
                          renderItem={(item) => (
                            <List.Item
                              onClick={() => this.viewChat(item.roomId)}
                              actions={
                                item.createdAt
                                  ? [
                                    <div
                                      className="chat-details-meta"
                                      key="list-loadmore-more"
                                    >
                                      {item.createdAt != null
                                        ? new Date(
                                          item.createdAt.seconds ? (item.createdAt.seconds * 1000) : item.createdAt
                                        )
                                          .toLocaleTimeString(
                                            "en-US",
                                            timestamp
                                          )
                                          .toLocaleLowerCase()
                                        : ""}
                                      <span className="unread-count">
                                        {item.count && item.count > 0
                                          ? item.count
                                          : ""}
                                      </span>
                                    </div>,
                                  ]
                                  : ""
                              }
                            >
                              <List.Item.Meta
                                avatar={
                                  <Avatar>
                                    {item.fname
                                      ? item.fname.charAt(0).toUpperCase()
                                      : "U"}
                                  </Avatar>
                                }
                                title={item.fname + " " + item.lname}
                                description={
                                  item.text ? item.text : "attachment"
                                }
                              />
                            </List.Item>
                          )}
                        />
                      }
                    </div>
                  )}
                </Col>
                <Col xs={24} sm={12} md={16}>
                  {messages && messages.length > 0 && (
                    <div className="chat-box">
                      <div className="chat-header">
                        <div className="chat-avatar">
                          <Avatar>
                            {firstName.charAt(0).toUpperCase() +
                              lastName.charAt(0).toUpperCase()}
                          </Avatar>
                          <div className="chat-title">
                            <div className="chat-title-name">
                              {firstName + " " + lastName}
                            </div>
                            {/*<div className="status">Active Now</div>*/}
                          </div>
                        </div>
                      </div>
                      <Spin spinning={submitted}>
                        <div className="chat-messages">
                          <div>
                            <Scrollbars autoHeight>
                              {messages.map((message, i, arr) => {
                                const previousItem = arr[i - 1];
                                var isFlagged =
                                  "isFlagged" +
                                  "@" +
                                  firebase.auth().currentUser.uid;
                                let flagging = (
                                  <div className="flag-status">
                                    {message[isFlagged] &&
                                      message[isFlagged] ? (
                                      <FlagIcon
                                        onClick={() => {
                                          const confirmBox = window.confirm(
                                            "Do you really want to unflag this message?"
                                          );
                                          if (confirmBox === true) {
                                            this.markAsFlagged(
                                              message.id,
                                              message[isFlagged]
                                            );
                                          }
                                        }}
                                      />
                                    ) : (
                                      ""
                                    )}
                                    {!message[isFlagged] ? (
                                      <UnFlagIcon
                                        onClick={() => {
                                          const confirmBox = window.confirm(
                                            "Do you really want to flag this message?"
                                          );
                                          if (confirmBox === true) {
                                            this.markAsFlagged(
                                              message.id,
                                              message[isFlagged]
                                            );
                                          }
                                        }}
                                      />
                                    ) : (
                                      ""
                                    )}
                                  </div>
                                );
                                let span;
                                if (i === 0) {
                                  span = (
                                    <span className="chat-timespan">
                                      {message && message.createdAt != null
                                        ? new Date(
                                          message.createdAt.seconds * 1000
                                        ).toLocaleDateString("en-US")
                                        : ""}
                                    </span>
                                  );
                                }
                                if (
                                  previousItem &&
                                  message &&
                                  message.createdAt &&
                                  previousItem.createdAt &&
                                  new Date(
                                    message.createdAt.seconds * 1000
                                  ).toLocaleDateString("en-US") !==
                                  new Date(
                                    previousItem.createdAt.seconds * 1000
                                  ).toLocaleDateString("en-US")
                                ) {
                                  if (
                                    new Date(
                                      message.createdAt.seconds * 1000
                                    ).toLocaleDateString() === today
                                  ) {
                                    span = (
                                      <span className="chat-timespan">
                                        {"Today"}
                                      </span>
                                    );
                                  } else {
                                    span = (
                                      <span className="chat-timespan">
                                        {message && message.createdAt != null
                                          ? new Date(
                                            message.createdAt.seconds * 1000
                                          ).toLocaleDateString("en-US")
                                          : ""}
                                      </span>
                                    );
                                  }
                                }
                                let showContent;
                                if (message.text) {
                                  showContent = (
                                    <div className="chat-message-text">
                                      {message.text
                                        ? message.text
                                        : "attachment"}
                                      {flagging}
                                      {message.isRead &&
                                        message.isRead &&
                                        message.userId ===
                                        firebase.auth().currentUser.uid ? (
                                        <div className="seen-status">
                                          <DoubleCheckIcon />
                                        </div>
                                      ) : (
                                        ""
                                      )}
                                    </div>
                                  );
                                } else if (message.imgPath) {
                                  showContent = (
                                    <div>
                                      <img
                                        onClick={() =>
                                          this.showModal(message.imgPath)
                                        }
                                        id={message.id}
                                        src={message.imgPath}
                                        alt="img"
                                        width="200"
                                        height="100"
                                      ></img>
                                      {flagging}
                                      {message.isRead &&
                                        message.isRead &&
                                        message.userId ===
                                        firebase.auth().currentUser.uid ? (
                                        <div className="seen-status">
                                          <DoubleCheckIcon />
                                        </div>
                                      ) : (
                                        ""
                                      )}
                                      <Modal
                                        centered
                                        width={450}
                                        className="photo-zoom-popup"
                                        visible={isModalVisible}
                                        onCancel={this.handleCancel}
                                        footer={[<></>]}
                                      >
                                        <TransformWrapper
                                          defaultScale={1}
                                          defaultPositionX={1}
                                          defaultPositionY={1}
                                        >
                                          {({ zoomIn, zoomOut }) => (
                                            <>
                                              <PlusCircleFilled
                                                size={30}
                                                onClick={zoomIn}
                                              />
                                              <MinusCircleFilled
                                                size={20}
                                                onClick={zoomOut}
                                              />
                                              <TransformComponent>
                                                <img
                                                  width={400}
                                                  src={this.state.image && this.state.image}
                                                  alt={"image"}
                                                />
                                              </TransformComponent>
                                            </>
                                          )}
                                        </TransformWrapper>
                                      </Modal>
                                    </div>
                                  );
                                } else if (message.attachmentName) {
                                  showContent = (
                                    <div className="chat-message-text">
                                      {message.attachmentName}{" "}
                                      <button
                                        onClick={() =>
                                          this.redirect(message.attachmentPath)
                                        }
                                        className="attached-filebtn"
                                      >
                                        <PaperClipOutlined />
                                      </button>
                                      {flagging}
                                      {message.isRead &&
                                        message.isRead &&
                                        message.userId ===
                                        firebase.auth().currentUser.uid ? (
                                        <div className="seen-status">
                                          <DoubleCheckIcon />
                                        </div>
                                      ) : (
                                        ""
                                      )}
                                    </div>
                                  );
                                }
                                const isCurrentUserMessage =
                                  message.userId ===
                                  firebase.auth().currentUser.uid;
                                return (
                                  <div>
                                    {span}
                                    <Comment
                                      className={
                                        isCurrentUserMessage
                                          ? "sender-msg"
                                          : "user-msg"
                                      }
                                      author={<a>{message.firstName}</a>}
                                      avatar={
                                        message.firstName ? (
                                          <Avatar>
                                            {message.firstName &&
                                              message.firstName !== ""
                                              ? message.firstName
                                                .charAt(0)
                                                .toUpperCase()
                                              : "" + message.lastName &&
                                                message.lastName !== ""
                                                ? message.lastName
                                                  .charAt(0)
                                                  .toUpperCase()
                                                : ""}
                                          </Avatar>
                                        ) : <Avatar icon={<UserOutlined />} />
                                      }
                                      content={showContent}
                                      datetime={
                                        <Tooltip
                                          title={
                                            message.createdAt != null
                                              ? new Date(
                                                message.createdAt.seconds *
                                                1000
                                              )
                                                .toLocaleTimeString(
                                                  "en-US",
                                                  timestamp
                                                )
                                                .toLocaleLowerCase()
                                              : ""
                                          }
                                        >
                                          <span>
                                            {message.createdAt != null
                                              ? new Date(
                                                message.createdAt.seconds *
                                                1000
                                              )
                                                .toLocaleTimeString(
                                                  "en-US",
                                                  timestamp
                                                )
                                                .toLocaleLowerCase()
                                              : ""}
                                          </span>
                                        </Tooltip>
                                      }
                                    />
                                  </div>
                                );
                              })}
                              <div
                                style={{ float: "left", clear: "both" }}
                                ref={(el) => {
                                  this.el = el;
                                }}
                              />
                            </Scrollbars>
                          </div>
                        </div>
                      </Spin>
                      <div className="message-box">
                        <textarea
                          placeholder="Type Message here...."
                          required
                          value={this.state.text}
                          onChange={(e) => {
                            this.setState({ text: e.target.value });
                          }}
                        ></textarea>
                        <div className="chat-action-btns">
                          <button onClick={() => this.sendMessage()}>
                            <SendOutlined />
                          </button>

                          <Button
                            variant="raised"
                            color="primary"
                            component="label"
                            htmlFor="myattach"
                            className="attachment-btns"
                          >
                            <PaperClipOutlined />
                          </Button>
                          <input
                            accept="*"
                            id="myattach"
                            type="file"
                            style={{ display: "none" }}
                            onChange={this.uploadAttachment}
                            ref={(el) => (this.fileInput = el)}
                          />
                        </div>
                      </div>
                    </div>
                  )}
                </Col>
              </Row>
            </Card>
          </Content>
        </Layout>
      </Layout>
    );
  }
}

function mapStateToProps(state) {
  return {
    messages: state.chat,
    patientData: state.patient.patientData,
    recentMessages: state.recentMessages.recentMessages,
  };
}
function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    { ...ChatActions, ...PatientActions, ...MessageActions, ...StudyActions },
    dispatch
  );
}
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(
    ListChat
  )
);