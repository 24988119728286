import AxiosAuth2 from "../../services/axios-service-auth";

const getAllTicketsList = () => {
  return AxiosAuth2.get("/api/suggestionTicket/getAllTickets", undefined, undefined);
};

const updateTicketStatus = (ticketId) => {
  return AxiosAuth2.post("api/suggestionTicket/updateSuggestionTicketStatus?ticketId="+ticketId, undefined, undefined);
};

const getAttachmentImage = (imagePath) => {
  return AxiosAuth2.get("api/suggestionTicket/getTicketAttachment?imagePath="+imagePath, undefined, undefined, false, true);
};

export default {
  getAllTicketsList,
  updateTicketStatus,
  getAttachmentImage
};
