import React from "react";
export const FlagSvg = () => (
    <svg
        version="1.1"
        id="Layer_1"
        x="0px"
        y="0px"
        width="1.1em"
        height="1.1em"
        fill="currentColor"
        viewBox="0 0 307.3 409.6"
    >
        <path d="M20.9,0c10.4,3.8,14.2,11.6,13.4,22.2c-0.3,3.8,0,7.7,0,12c2.1,0,3.8,0,5.5,0c82.4,0,164.8,0,247.2,0c6.3,0,12,1,16.2,6.1
        c5.3,6.4,5.5,14,0.3,21.8c-13.7,20.7-27.5,41.3-41.2,61.9c-5.9,8.9-11.7,17.8-17.8,26.6c-1.6,2.3-1.6,3.9,0,6.3
        c19.6,29.2,39.1,58.5,58.6,87.8c5.5,8.2,5.6,15.6,0.3,22.1c-2.9,3.6-6.6,5.8-11.2,6.2c-2.1,0.2-4.3,0.2-6.4,0.2c-82,0-164,0-246,0
        c-1.7,0-3.4,0-5.5,0c0,2.1,0,3.6,0,5.2c0,37.3,0,74.7,0,112c0,9.5-4.2,16-11.7,18.5C12,412.1,1.3,405,0.2,394
        c-0.2-1.7-0.1-3.5-0.1-5.2C0.1,266.4,0.2,144,0,21.6C0,11,3.4,3.6,13.7,0C16.1,0,18.5,0,20.9,0z"/>
    </svg>
);
