import { Types } from "../constants/study-drug-log-types";

const initialState = {
    listDrugLogs: undefined,
};

export default function drugLogsReducer(state = initialState, action) {
    switch (action.type) {
        case Types.LIST_STUDY_DRUG_LOG:
            return { ...state, listDrugLogs: action.payload };
        default:
            return state;
    }
}
