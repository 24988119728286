import API from "../api/appointment-api";
import { Types } from "../constants/appointment-types";


export function addEvent(data) {

  return async function (dispatch, _getState) {
    try {
      let resp = await API.addEvent(data);
      if (resp && resp.data && resp.data.length > 0) {
        dispatch({
          type: Types.CREATE_APPOINTMENT,
          payload: resp.data
        });
      }
      return resp;
    } catch (e) {
      return e;;
    }
  };
}

export function getAppointments() {
  
  return async function (dispatch, _getState) {
    try {
      let resp = await API.getAppointments();
      if (resp && resp.data && resp.data.length > 0) {
        dispatch({
          type: Types.LIST_APPOINTMENTS,
          payload: resp.data
        });
      }
      return resp;
    } catch (e) {
      return e;;
    }
  };
}

export function deleteEvent(id) {
  
  return async function (dispatch, _getState) {
    try {
      let resp = await API.deleteEvent(id);
      return resp;
    } catch (e) {
      return e;;
    }
  };
}

export function clearAppointmentsState() {

  return async function (dispatch, getState) {
    dispatch({
      type: Types.LIST_APPOINTMENTS,
      payload: undefined
    });
  };
}

