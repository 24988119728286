import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import 'antd/dist/antd.css';
import { Table, Input, Button, Space, Card, Layout, Spin } from 'antd';
import Highlighter from 'react-highlight-words';
import { SearchOutlined } from '@ant-design/icons';
import * as PatientActions from "../../redux/actions/patient-actions";
import * as AdminActtions from "../../redux/actions/admin-actions";

class PatientList extends Component {
  state = {
    searchText: '',
    searchedColumn: '',
    loading: true, data: []
  };

  componentDidMount = () => {
    // let studyId = localStorage.getItem("studyId")

    // this.props
    //   .getPatientsList(studyId)
    //   .then((resp) => {
    //     this.setState({
    //       loading: false,
    //       data: resp.data
    //     });
    //   })
    //   .catch((ex) => {
    //     this.setState({ loading: false });
    //   });
    if (this.props.patients) {
      this.setState({
        data: this.props.patients,
        loading: false
      });
    }
  };

  componentWillReceiveProps = (props) => {
    if (props.patients) {
      this.setState({
        data: props.patients,
        loading: false
      });
    }
  }

  getColumnSearchProps = dataIndex => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={node => {
            this.searchInput = node;
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button onClick={() => this.handleReset(clearFilters)} size="small" style={{ width: 90 }}>
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({ closeDropdown: false });
              this.setState({
                searchText: selectedKeys[0],
                searchedColumn: dataIndex,
              });
            }}
          >
            Filter
          </Button>
        </Space>
      </div>
    ),
    filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
        : '',
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        setTimeout(() => this.searchInput.select(), 100);
      }
    },
    render: text =>
      this.state.searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[this.state.searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  });

  handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    this.setState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex,
    });
  };

  handleReset = clearFilters => {
    clearFilters();
    this.setState({ searchText: '' });
  };

  onClick = () => {
    this.props.history.push("/add-user");
  }

  handleEdit = (id) => {
    this.props.history.push("/screening-questions/" + "newlyAdded" + "/" + id);
  };

  handleHistory = (id) => {
    this.props.history.push("/medical-history/" + id);
  };

  render() {
    const columns = [
      {
        title: 'Last Name',
        dataIndex: 'lastName',
        key: 'lastName',
        sorter: (a, b) => a.lastName.localeCompare(b.lastName),
        width: '20%',
        ...this.getColumnSearchProps('lastName'),
      },
      {
        title: 'First Name',
        dataIndex: 'firstName',
        key: 'firstName',
        sorter: (a, b) => a.firstName.localeCompare(b.firstName),
        width: '20%',
        ...this.getColumnSearchProps('firstName'),
      },
      {
        title: 'Email',
        dataIndex: 'email',
        key: 'email',
        width: '30%',
        sorter: (a, b) => a.email.localeCompare(b.email),
        ...this.getColumnSearchProps('email'),
      },
      {
        title: 'Phone',
        dataIndex: 'phoneNumber',
        key: 'phoneNumber',
        sorter: (a, b) => a.phoneNumber.localeCompare(b.phoneNumber),
        ...this.getColumnSearchProps('phoneNumber'),
      },
      {
        title: 'Action',
        dataIndex: 'action',
        render: (_, record) =>
          this.state.data.length >= 1 ? (
            <div>
              <a onClick={() => this.handleEdit(record.id)}> Review Study</a>
            </div>) : null,
      },
      /* {
       title: 'Medical History',
       dataIndex: 'action',
       render: (_, record) =>
         this.state.data.length >= 1 ? (
           <div>
             <a onClick={() => this.handleHistory(record.id)}> Medical History</a>
           </div>) : null,
     } */
    ];
    return (

      <Layout>
        <Spin spinning={this.state.loading} >
          {this.state.data ? <Card title={"Pending Patients " + "(" + this.state.data.length + ")"}>
            <Table columns={columns} bordered dataSource={this.state.data} />
          </Card> : ''}
        </Spin>
      </Layout>

    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ ...PatientActions, ...AdminActtions }, dispatch);
}
export default withRouter(
  connect(null, mapDispatchToProps, null, { forwardRef: true })(PatientList)
);
