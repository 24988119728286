import AxiosAuth2 from "../../services/axios-service-auth";

const getAllTasksList = (studyId,patientId) => {
  return AxiosAuth2.get("/api/patient/complete-task-list/"+studyId+"/"+patientId, undefined, undefined);
};

const getJanssenAllTasksList = (studyId,patientId) => {
  return AxiosAuth2.get("/api/janssen/patient/complete-task-list/"+studyId+"/"+patientId, undefined, undefined);
};

const getNihAllTasksList = (studyId,patientId) => {
  return AxiosAuth2.get("/api/nih/patient/complete-task-list/"+studyId+"/"+patientId, undefined, undefined);
};

export default {
  getAllTasksList,
  getJanssenAllTasksList,
  getNihAllTasksList
};
